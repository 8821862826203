/* eslint-disable class-methods-use-this */
import { computed, makeObservable, observable, runInAction } from 'mobx';

import { Log } from '@smartfolly/common.utilities';
import { Tokens } from '@smartfolly/common.currencies';

import { serverConnector } from '@smartfolly/middleware.server-connector';

import type { ITokensStore, TokensMap } from '../types';

const log = new Log('TokensStore');

class TokensStore implements ITokensStore {
    private loadedValue: TokensMap | undefined = undefined;

    public constructor() {
        makeObservable<TokensStore, 'loadedValue'>(this, {
            loadedValue: observable,
            tokens: computed,
        });
    }

    public get tokens(): TokensMap {
        return this.loadedValue ?? Tokens;
    }

    public async load() {
        try {
            const data = await serverConnector.sendGETRequest<TokensMap>({
                path: 'currencies/tokens',
            });

            // Check for errors in response
            if ('error' in data) {
                const { error } = data;
                // TODO: process `errorCode` if needed
                throw new Error(error);
            }

            runInAction(() => {
                this.loadedValue = data;
            });
        } catch (error) {
            log.error('Failed to load stablecoins data form server with error', error);
        }
    }
}

export const tokensStore = new TokensStore();
