import { exchangesData, type Exchanges } from '@smartfolly/common.exchanges';

import type { Exchange } from '../types';

type BuildOptions = {
    /**
     * Exchange ID to build the asset with.
     */
    exchange: Exchanges;
};

/**
 * Function to build the asset exchange with.
 * @param options - options to build the asset exchange with.
 */
export function buildAssetExchange({ exchange }: BuildOptions): Exchange {
    const exchangeInfo = exchangesData[exchange];
    if (!exchangeInfo) {
        throw new Error(`No exchange data is found for the asset exchange: ${exchange}`);
    }

    return {
        id: exchange,
        name: exchangeInfo.name,
    };
}
