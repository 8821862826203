import { Log } from '@smartfolly/common.utilities';

import type { ExchangeCurrency, MarketCapData } from '@smartfolly/sdk';

import type { TokensDataMap } from '@smartfolly/frontend.currencies-service';

import type { Asset, AssetsMap, CustodianWallet, NonCustodianWallet } from '../types';

import type {
    BlockchainAssetWithAddressData,
    ExchangeAssetWithExchangeData,
} from '../module/types';

import { buildAssetBlockchain } from './buildAssetBlockchain';
import { buildAssetExchange } from './buildAssetExchange';
import { buildAssetPrice } from './buildAssetPrice';
import { buildAssetToken } from './buildAssetToken';

const log = new Log('enrichAssetsWithMarketCapData');

/**
 * Function to enrich the assets with the market cap data if present.
 * @param assets - the assets to enrich.
 * @param options - options should include the market cap data if any with the currency to show.
 * @returns the assets enriched with the market cap data.
 */
export function enrichAssetsWithMarketCapData(
    assets: (BlockchainAssetWithAddressData | ExchangeAssetWithExchangeData)[],
    {
        marketCapData,
        currency,
        tokensData,
    }: {
        marketCapData: MarketCapData | undefined;
        currency: ExchangeCurrency;
        tokensData: TokensDataMap;
    },
): AssetsMap {
    return assets.reduce<AssetsMap>((acc, asset) => {
        // Prepare a price property for the asset
        let price: Asset['price'] | undefined;

        // Prepare a token property for the asset
        let token: Asset['token'] | undefined;

        // Check if the market cap data is present
        if (marketCapData) {
            // Get the market cap data for the asset token
            const tokenMarketCapData = marketCapData[asset.token];

            // Check if this data is present
            if (tokenMarketCapData) {
                // Get a map of rates for the asset token
                const { quote } = tokenMarketCapData;

                // Get an exchange rate for the selected exchange currency
                const exchangeRate = quote[currency];
                if (!exchangeRate) {
                    log.error(
                        'Failed to get an exchange rate for the selected exchange currency',
                        currency,
                    );
                } else {
                    // Build the asset price with the known `exchangeRate`
                    price = buildAssetPrice({
                        balance: asset.balance,
                        currency,
                        exchangeRate,
                    });

                    // Build the asset token with the known `exchangeRate`
                    token = buildAssetToken({
                        token: asset.token,
                        currency,
                        exchangeRate,
                        tokensData,
                    });
                }
            }
        }

        // Build a price property for the asset if it wasn't built above
        if (!price) {
            price = buildAssetPrice({ balance: asset.balance, currency });
        }

        // Build a token property for the asset if it wasn't built above
        if (!token) {
            token = buildAssetToken({ token: asset.token, currency, tokensData });
        }

        // Check if the asset has a blockchain data
        if ('blockchain' in asset) {
            // Extract the non-custodian wallet related properties
            const { address, blockchain, addressData, ...rest } = asset;

            // Build a non-custodian wallet property for the asset
            const wallet: NonCustodianWallet = {
                address,
                blockchain: buildAssetBlockchain({ blockchain }),
                ...(addressData ? { addressData } : {}),
            };

            // Put the asset enriched with the price, token and wallet data into the assets map
            acc[asset.assetId] = {
                ...rest,
                price,
                token,
                wallet,
            };
        } else if ('exchange' in asset) {
            // Extract the non-custodian wallet related properties
            const { sourceId, exchange, exchangeData, ...rest } = asset;

            // Build a custodian wallet property for the asset
            const wallet: CustodianWallet = {
                sourceId,
                exchange: buildAssetExchange({ exchange }),
                ...(exchangeData ? { exchangeData } : {}),
            };

            // Put the asset enriched with the price, token and wallet data into the assets map
            acc[asset.assetId] = {
                ...rest,
                price,
                token,
                wallet,
            };
        } else {
            throw new Error('Unsupported type of the asset info');
        }

        // Return the accumulator
        return acc;
    }, {});
}
