import {
    GetAccountsResponse,
    ProviderInfo,
    ProvidersError,
    WalletConnectProvider as Provider,
} from '@smartfolly/common.providers';

import { AssetsManagerError } from '../../../constants';

import type { IAddressProvider } from './types';

type Func<T, P> = (...args: P[]) => Promise<T>;
type WrappedFunc<T, P> = Func<T, P>;

const withErrorsMapperWrapper =
    <T, P, C>(fn: Func<T, P>, context: C): WrappedFunc<T, P> =>
    async (...args: P[]) => {
        try {
            // Run the original function
            return await fn.bind(context)(...args);
        } catch (error) {
            // Map the errors
            if (error === ProvidersError.WalletConnect.AccountUnrecognized) {
                // Note: in our current implementation if any account is unrecognized,
                // which should normally not happen, then no account is returned, hence
                // we may consider it as no any account found error
                throw AssetsManagerError.Providers.WalletConnect.NoAccountFound;
            } else if (error === ProvidersError.WalletConnect.QRCodeClosed) {
                throw AssetsManagerError.Providers.WalletConnect.QRCodeClosed;
            } else if (error === ProvidersError.WalletConnect.NoAccountFound) {
                throw AssetsManagerError.Providers.WalletConnect.NoAccountFound;
            } else {
                throw error;
            }
        }
    };

export class WalletConnectProvider implements IAddressProvider<'WalletConnect'> {
    // Properties

    /**
     * A private instance of WalletConnect provider
     * Note: for some reason Wallet Connect does not work if no required namespaces are set...
     * Require Ethereum then =/
     */
    private walletConnectProvider: Provider;

    // Constructor

    public constructor(explorerRecommendedWalletIds?: string[]) {
        this.walletConnectProvider = new Provider({
            requireEthereum: true,
            ...(explorerRecommendedWalletIds ? { explorerRecommendedWalletIds } : {}),
        });
    }

    // Interface

    public async checkIfAvailable(): Promise<boolean> {
        return withErrorsMapperWrapper(
            this.walletConnectProvider.checkIfAvailable,
            this.walletConnectProvider,
        )();
    }

    public async disconnect(): Promise<void> {
        return withErrorsMapperWrapper(
            this.walletConnectProvider.disconnect,
            this.walletConnectProvider,
        )();
    }

    public async getAccounts(): Promise<GetAccountsResponse> {
        return withErrorsMapperWrapper(
            this.walletConnectProvider.getAccounts,
            this.walletConnectProvider,
        )();
    }

    public async getInfo(): Promise<ProviderInfo<'WalletConnect'>> {
        return withErrorsMapperWrapper(
            this.walletConnectProvider.getInfo,
            this.walletConnectProvider,
        )();
    }
}

export const walletConnectProvider = new WalletConnectProvider();
