/* eslint-disable max-lines */ // TODO: decompose the file
import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { ethereumMainnetChain } from '@smartfolly/common.providers';

import {
    Log,
    signMessageWithKey,
    stringifyParametersToSign,
    SubscriptionCenter,
} from '@smartfolly/common.utilities';

import type {
    AddProviderRequestParameters,
    AddProviderRequestResponse,
    Authorized,
    LogoutRequestParameters,
    LogoutRequestResponse,
    ResponseError,
    ValidateRequestParameters,
    ValidateRequestResponse,
} from '@smartfolly/server';

import { serverConnector } from '@smartfolly/middleware.server-connector';
import type { ParametersShape, SendRequestOptions } from '@smartfolly/middleware.server-connector';

import { AuthUserError, AuthProvider } from '../constants';

import type {
    ExtraAddresses,
    ExtraAddressesListener,
    ExtraAddressesListenerDisposer,
    IUserAuth,
    SessionInfo,
    SessionListener,
    SessionListenerDisposer,
    UserAuthConfig,
    UserAuthOptions,
} from '../types';

import { AuthUserRequestPath } from './constants';

import {
    AuthProviderName,
    checkForSessionInfoInStorage,
    convertStoredSessionInfo,
    deleteSessionInfoFromStorage,
    deserializeSession,
    getSessionInfoFromStorage,
    metaMastExtProvider,
    serializeSession,
    storeSessionInfoInStorage,
    telegramBotProvider,
    walletConnectProvider,
} from './helpers';
import type { StorageSessionInfo, IAuthProvider } from './helpers';

import {
    authLogin,
    authPrepare,
    authShorten,
    authSimpleLogin,
    subscribeToTelegramBotNonce,
} from './requests';

const log = new Log('UserAuth');

export class UserAuth implements IUserAuth {
    // Properties

    /**
     * A private instance of the module config.
     */
    private config?: UserAuthConfig;

    /**
     * A private instance with the current session.
     * Note: `undefined` means yet `unknown`, `null` means `unauthorized`.
     */
    private currentSession: StorageSessionInfo | null | undefined = undefined;

    /**
     * A private instance of the subscription center
     * to listen to the extra found addresses when authorizing.
     */
    private addressesSubscriptionCenter = new SubscriptionCenter<ExtraAddresses>();

    // Constructor

    public constructor() {
        makeObservable<UserAuth, 'currentSession' | 'setCurrentSession'>(this, {
            currentSession: observable,
            setCurrentSession: action,
            sessionInfo: computed,
        });
    }

    // Interface

    public get sessionInfo(): SessionInfo | null | undefined {
        // Check if the current session is present
        if (this.currentSession) {
            // Return the session info
            return convertStoredSessionInfo(this.currentSession);
        }

        // Return `null` or `undefined` as per the current session
        return this.currentSession;
    }

    public configure(config: UserAuthConfig): void {
        // Save provided config
        this.config = config;
    }

    public async auth(options: UserAuthOptions): Promise<SessionInfo> {
        // Check for config presence
        const { storage, key } = this.checkForConfigPresence();

        // Get the provider from options
        const { provider } = options;

        // Check for the current session info if any
        const { sessionInfo, isActualProvider, isExpired } = await checkForSessionInfoInStorage({
            storage,
            provider,
        });

        // Check if the current session info is actual
        // Note: normally `auth` method should no be called if we are already authorized.
        // This should be checked with the `listenToSession` method before calling `auth`.
        if (sessionInfo) {
            if (!isActualProvider) {
                // Throw an error if the provider is not actual
                throw AuthUserError.NotActualProvider;
            }

            if (isExpired) {
                // Throw an error if the session is expired
                // Note: should not happen normally, as it's recommended to do the `auth` call
                // right after we've listened to the session via `listenToSession`, which
                // handles the expired session properly by removing it from both client and server.
                throw AuthUserError.SessionExpired;
            }

            // Set current session
            this.setCurrentSession(sessionInfo);

            // And validate it immediately on the server
            await this.validateSessionOnServer();

            // Return the session info without the keys
            return convertStoredSessionInfo(sessionInfo);
        }

        // Find the proper way to auth
        if (provider === AuthProvider.SimpleAuth) {
            // Make it simple and don't use any Wallet to verify
            return this.simpleAuth();
        }

        // Find the provider instance to auth with
        let authProvider: IAuthProvider<AuthProviderName>;
        if (provider === AuthProvider.MetaMaskExt) {
            authProvider = metaMastExtProvider;
        } else if (provider === AuthProvider.WalletConnect) {
            authProvider = walletConnectProvider;
        } else if (provider === AuthProvider.TelegramBot) {
            authProvider = telegramBotProvider;
        } else {
            throw AuthUserError.UnsupportedProvider;
        }

        // Disconnect from prior provider sessions if possible
        // Note: this might be important in case we'd like to change the provider's wallet.
        await authProvider.disconnect();

        // Get the accounts from the provider
        const accounts = await authProvider.getAccounts();

        // Get the included ETH-address to authorize with
        // and get the rest to notify the subscription center once authorized
        const { ethAddress, restAddresses } = accounts.reduce<{
            ethAddress?: string;
            restAddresses: string[];
        }>(
            (acc, { address, chainId }) => {
                // Get the first ETH-account address to authorize with
                // Note: more than one ETH-accounts can be found, but we tend to use the first one
                if (!acc.ethAddress && chainId === ethereumMainnetChain.id) {
                    acc.ethAddress = address;
                    return acc;
                }

                // Append the rest addresses to notify the subscription center once authorized
                acc.restAddresses = (acc.restAddresses ?? []).concat(address);
                return acc;
            },
            { restAddresses: [] },
        );

        if (!ethAddress) {
            throw AuthUserError.NoEthAccount;
        }

        // Send unauthorized "prepare" request before the actual "login" request
        // to get the `authId` and `nonce`
        const { authId, nonce } = await authPrepare({
            ethAddress,
            provider,
        });

        // Get a signature by signing the nonce
        let { signature } = await authProvider.signMessage({
            message: nonce,
            ethAddress,
        });

        if (provider === AuthProvider.TelegramBot) {
            // Send unauthorized request to the "shorten" service
            // to store the "authId" and the "signature"
            const { id: shortenId } = await authShorten({ authId, signature });

            // Pass the "shorten" ID to the Telegram bot so the bot could authorize the user
            // and listen to the updated "nonce" once "telegramUserId" is provided by the bot
            const updatedNonce = await subscribeToTelegramBotNonce({
                authId,
                shortenId,
                ...options,
            });

            // Sign the updated nonce and pass it to the login request
            ({ signature } = await authProvider.signMessage({
                message: updatedNonce,
                ethAddress,
            }));
        }

        // Send unauthorized "login" request to obtain the session info
        // and get the `userId` and `session`
        const { userId, session } = await authLogin({
            authId,
            signature,
            permanent: true, // For MVP we decided to create permanent sessions
        });

        // Build-up the current session to store
        const currentSession: StorageSessionInfo = {
            userId,
            session: {
                ...session,
                provider: await authProvider.getInfo(),
            },
        };

        // Store the current session
        await storeSessionInfoInStorage({
            storage,
            sessionInfo: currentSession,
            key,
        });

        // Set current session with the found extra addresses (if any)
        this.setCurrentSession(currentSession, { addresses: restAddresses, provider });

        // Note: no need to validate the session on the server as it has just been provided by it

        // Return the session info without the keys
        return convertStoredSessionInfo(currentSession);
    }

    public async addProvider(options: UserAuthOptions): Promise<SessionInfo> {
        // Check if the user is authorized
        const { currentSession } = this;
        if (!currentSession) {
            throw AuthUserError.NoSessionToAddProviderTo;
        }

        // Get the provider from options
        const { provider } = options;

        // Check if the provider is supported
        if (provider !== AuthProvider.TelegramBot) {
            throw AuthUserError.NotSupportedProviderToAdd;
        }

        // Check for config presence
        const { storage, key } = this.checkForConfigPresence();

        // Find the provider instance to auth with
        const authProvider: IAuthProvider<'TelegramBot'> = telegramBotProvider;

        // Disconnect from prior provider sessions if possible
        // Note: this might be important in case we'd like to change the provider's wallet.
        await authProvider.disconnect();

        // Get the accounts from the provider
        const accounts = await authProvider.getAccounts();

        // Get the included ETH-address to authorize with
        // and get the rest to notify the subscription center once authorized
        const { ethAddress } = accounts.reduce<{
            ethAddress?: string;
        }>((acc, { address, chainId }) => {
            // Get the first ETH-account address to authorize with
            // Note: more than one ETH-accounts can be found, but we tend to use the first one
            if (!acc.ethAddress && chainId === ethereumMainnetChain.id) {
                acc.ethAddress = address;
                return acc;
            }

            // Note: the "ethAddress" used here is a temporary one and used only for one-time
            // authorization purpose, hereby we won't check for the rest addresses to process
            return acc;
        }, {});

        if (!ethAddress) {
            throw AuthUserError.NoEthAccount;
        }

        // Send unauthorized "prepare" request before the actual "addProvider" request
        // to get the `authId` and `nonce`
        const { authId, nonce } = await authPrepare({
            ethAddress,
            provider,
        });

        // Get a signature by signing the nonce
        let { signature } = await authProvider.signMessage({
            message: nonce,
            ethAddress,
        });

        // Send unauthorized request to the "shorten" service
        // to store the "authId" and the "signature"
        const { id: shortenId } = await authShorten({ authId, signature });

        // Pass the "shorten" ID to the Telegram bot so the bot could authorize the user
        // and listen to the updated "nonce" once "telegramUserId" is provided by the bot
        const updatedNonce = await subscribeToTelegramBotNonce({
            authId,
            shortenId,
            ...options,
        });

        // Sign the updated nonce and pass it to the "addProvider" request
        ({ signature } = await authProvider.signMessage({
            message: updatedNonce,
            ethAddress,
        }));

        // Send an authorized "addProvider" request to add a new provider info
        const response = await this.sendRequest<
            AddProviderRequestParameters,
            AddProviderRequestResponse
        >({
            path: AuthUserRequestPath.AddProvider,
            params: {
                authId,
                signedNonce: signature,
            },
        });

        // Check for errors in response
        if ('error' in response) {
            const { error, errorCode } = response;

            if (errorCode === 104) {
                // Telegram user is taken already
                throw AuthUserError.Providers.TelegramBot.UserIsTakenAlready;
            }

            // TODO: process `errorCode` from the response if needed
            throw new Error(error);
        }

        if (!response.added) {
            throw new Error(response.reason);
        }

        // Update the current session provider to store
        currentSession.session.provider = await authProvider.getInfo();

        // Store the current session
        await storeSessionInfoInStorage({
            storage,
            sessionInfo: currentSession,
            key,
        });

        // Set current session with the added provider info
        this.setCurrentSession(currentSession);

        // Note: no need to validate the session on the server as it has just been provided by it

        // Return the session info without the keys
        return convertStoredSessionInfo(currentSession);
    }

    public listenToSession(listener: SessionListener): SessionListenerDisposer {
        (async () => {
            try {
                // Check for config presence
                const { storage, key } = this.checkForConfigPresence();

                // Check for current session info
                const { sessionInfo, isExpired } = await getSessionInfoFromStorage({
                    storage,
                    key,
                });

                if (!sessionInfo) {
                    // Set the current session as `null` if the session is not stored
                    this.setCurrentSession(null);
                } else if (isExpired) {
                    try {
                        // Delete the current session if it's expired
                        await this.deleteSession({ storage });
                    } catch (error) {
                        log.error('Failed to delete the session with error:', error);
                    } finally {
                        // Set the current session as `null` as there is no active session info.
                        // Note: if the session removal fails (mostly due to the backend issues),
                        // it's OK to set the current session to `null` to let the user re-auth.
                        this.setCurrentSession(null);
                    }
                } else {
                    // Set the current session taken from the stored session info
                    this.setCurrentSession(sessionInfo);

                    // And validate it immediately on the server
                    await this.validateSessionOnServer();
                }
            } catch (error) {
                log.error(
                    'Failed to check for the current session from the storage due to an error:',
                    error,
                );
            }
        })();

        return reaction(
            () => this.currentSession,
            () => {
                if (this.currentSession) {
                    listener(convertStoredSessionInfo(this.currentSession));
                } else {
                    listener(null);
                }
            },
            { name: 'a reaction to listen to the current session changes' },
        );
    }

    public listenToExtraAddresses(
        listener: ExtraAddressesListener,
    ): ExtraAddressesListenerDisposer {
        // Listen to the extra found addresses using the corresponding subscription center
        return this.addressesSubscriptionCenter.subscribe(listener);
    }

    public async logout(): Promise<void> {
        // Check for config presence
        const config = this.checkForConfigPresence();
        try {
            // Delete the session from both client and server side.
            await this.deleteSession(config);
        } catch (error) {
            log.error('Failed to logout with error:', error);
        } finally {
            // Set the current session as `null` even if the session removal fails,
            // as it could happen mostly due to the backend issues and this is what user expects.
            this.setCurrentSession(null);
        }
    }

    public async sendRequest<Parameters extends ParametersShape, Response>({
        path,
        params,
    }: SendRequestOptions<Parameters>): Promise<Response | ResponseError> {
        // Build the parameters with the session key and the signature
        const authorizedParams = this.authorizeParameters(params);

        // Check for config presence
        const { storage, key } = this.checkForConfigPresence();

        // Send POST-request with the resulted parameters
        const response = await serverConnector.sendPOSTRequest<Parameters, Response>({
            path,
            params: authorizedParams,
        });

        // Check for errors in response
        if (response instanceof Object && 'error' in response) {
            const { error, errorCode } = response;
            if (errorCode === 200) {
                // Session is incorrect. Let's delete it from the storage.
                await deleteSessionInfoFromStorage({ storage, key });
                // Set the current session as `null`
                this.setCurrentSession(null);
                // Throw the error further
                throw new Error(error);
            }

            if (errorCode === 201) {
                // Session has expired. Let's delete it from the storage.
                await deleteSessionInfoFromStorage({ storage, key });
                // Set the current session as `null`
                this.setCurrentSession(null);
                // Throw the error further
                throw new Error(error);
            }

            // Return another errors in response
        }

        // Return a response if no "breaking" error
        return response;
    }

    public async exportSession(): Promise<string> {
        // Check for the current session
        if (!this.currentSession) {
            throw AuthUserError.NoCurrentSession;
        }

        // Check for the current session is not expired
        if (this.currentSession.session.expire < Date.now()) {
            throw AuthUserError.SessionExpired;
        }

        return serializeSession(this.currentSession);
    }

    public async importSession(session: string): Promise<SessionInfo> {
        // Check for the current session
        if (this.currentSession && this.currentSession.session.expire >= Date.now()) {
            throw AuthUserError.SessionAlreadyExists;
        }

        // Deserialize the session info
        const sessionInfo = await deserializeSession(session);

        // Check for config presence
        const { storage, key } = this.checkForConfigPresence();

        // Store the current session
        await storeSessionInfoInStorage({
            storage,
            sessionInfo,
            key,
        });

        // Set current session
        this.setCurrentSession(sessionInfo);

        // And validate it immediately on the server
        await this.validateSessionOnServer();

        // Return the session info without the keys
        return convertStoredSessionInfo(sessionInfo);
    }

    // Internals

    /**
     * Method to set a current session info.
     * Note: this is a Mobx's action.
     * @param sessionInfo - a session info to set as current.
     * @param extraAddress - the extra found addresses related to the authorized session.
     */
    private setCurrentSession(
        sessionInfo: StorageSessionInfo | null,
        extraAddresses?: ExtraAddresses,
    ) {
        this.currentSession = sessionInfo;

        // Once logged in trigger an event that `userAuth` module found some more addresses if any.
        // For instance, they can automatically be added by the `assetsManager` module to the user.
        if (extraAddresses && extraAddresses.addresses.length > 0) {
            this.addressesSubscriptionCenter.broadcast(extraAddresses);
        }
    }

    /**
     * Method to append a signature and a sessionKey to the parameters to authorize the user.
     * @returns the authorized parameters.
     */
    private authorizeParameters<Parameters extends ParametersShape>(
        parameters: Parameters,
    ): Authorized<Parameters> {
        // Check for the current session
        if (!this.currentSession) {
            throw AuthUserError.NoCurrentSession;
        }

        // Check for the current session is not expired
        if (this.currentSession.session.expire < Date.now()) {
            throw AuthUserError.SessionExpired;
        }

        // Build the parameters with the session key
        const paramsWithSessionKey = {
            ...parameters,
            sessionKey: this.currentSession.session.sessionKey,
        };

        // Create a message to sign
        const message = stringifyParametersToSign(paramsWithSessionKey);

        // Sign these parameters
        const { signature } = signMessageWithKey({
            message,
            privateKey: this.currentSession.session.secretKey,
        });

        // Build the parameters with the session key and the signature
        const authorizedParameters: Authorized<Parameters> = {
            ...paramsWithSessionKey,
            signature,
        };

        // And return them
        return authorizedParameters;
    }

    /**
     * Method to check for the config presence.
     * @returns the module config
     * @throws an error if the config is not found.
     */
    private checkForConfigPresence(): UserAuthConfig {
        if (!this.config) {
            // Module is not configured
            throw AuthUserError.NoConfig;
        }

        return this.config;
    }

    /**
     * Method to login without verifying the wallet.
     * @returns the session info for the authorized user.
     * @throws an error in case it's present in response.
     */
    private async simpleAuth(): Promise<SessionInfo> {
        // Check for config presence
        const { storage, key } = this.checkForConfigPresence();

        // Send unauthorized "login" request to obtain the session info
        // and get the `userId` and `session`
        const { userId, session } = await authSimpleLogin();

        // Build-up the current session to store
        const currentSession: StorageSessionInfo = {
            userId,
            session: {
                ...session,
                provider: { name: AuthProvider.SimpleAuth },
            },
        };

        // Store the current session
        await storeSessionInfoInStorage({
            storage,
            sessionInfo: currentSession,
            key,
        });

        // Set current session
        this.setCurrentSession(currentSession);

        // Note: no need to validate the session on the server as it has just been provided by it

        // Return the session info without the keys
        return convertStoredSessionInfo(currentSession);
    }

    /**
     * Method to logout from the server.
     * @returns the result of the logout.
     * @throws an error in case it's present in response.
     */
    private async logoutFromServer(): Promise<boolean> {
        const response = await this.sendRequest<LogoutRequestParameters, LogoutRequestResponse>({
            params: {},
            path: AuthUserRequestPath.Logout,
        });

        // Check for errors in response
        if ('error' in response) {
            const { error } = response;
            // TODO: process `errorCode` from the response if needed
            throw new Error(error);
        }

        // Check the `reason` property
        if (response.reason) {
            log.debug(
                `User has${response.loggedOut ? '' : ' not'} logged out due to:`,
                response.reason,
            );
        }

        // Return the logout result
        return response.loggedOut;
    }

    /**
     * Method to validate the session on the server.
     * Note: it logs out from the server and removes the current session
     * from the storage automatically in case it is incorrect or expired.
     * @returns the successful result of the validation.
     * @throws an error in case it's present in response.
     */
    private async validateSessionOnServer(): Promise<true> {
        const response = await this.sendRequest<ValidateRequestParameters, ValidateRequestResponse>(
            {
                params: {},
                path: AuthUserRequestPath.Validate,
            },
        );

        // Check for errors in response
        if ('error' in response) {
            const { error } = response;
            // TODO: process `errorCode` from the response if needed
            throw new Error(error);
        }

        // Return the validation result
        return response.valid;
    }

    /**
     * Method to delete the session info from both the client and the server side.
     * @param options - contain the module config data required to delete the current session.
     */
    private async deleteSession({ storage, key }: UserAuthConfig): Promise<void> {
        // Delete the current session from the storage first
        await deleteSessionInfoFromStorage({ storage, key });

        // Then call a logout request to clear the session info from the backend
        await this.logoutFromServer();

        // Note: it might be important to run it in a sequence instead of a parallel,
        // because in case the logout from the server happens normally, but the local
        // session info remains it would lead to the wrong session state on a client.
    }
}
