import { computed, makeObservable, when } from 'mobx';

import {
    appStorage,
    buildStorageKeyForUser,
    CommonStore,
    DocumentLoaderListener,
    Log,
    MemoDocumentLoader,
} from '@smartfolly/common.utilities';

import {
    AddressProvider,
    Asset,
    Assets,
    IAssetsManager,
    AssetsManager,
    ProvideAddressesOptions,
    ProvideExchangesOptions,
} from '@smartfolly/sdk';

import type { IAuthService } from '@smartfolly/frontend.auth-service';

import type { IAssetsStore } from '../../types';

import { ObservableFlags } from './ObservableFlags';
import { AssetsActualizer } from './AssetsActualizer';

const log = new Log('AssetsService:Assets');

const ALL_ASSETS_KEY = 'AssetsService:AllAssets';

type AssetsStoreOptions =
    | {
          /**
           * An instance of AuthService with the user to work with when dealing with the assets.
           * Note: the passed service MUST be loaded and initialized, i.e. be ready to work with.
           */
          authService: IAuthService;
      }
    | {
          /**
           * An ID of the user to work with when dealing with the assets.
           */
          userId: string;
      };

export class AssetsStore extends CommonStore implements IAssetsStore {
    // Properties

    /**
     * Options the store is created with.
     */
    private options: AssetsStoreOptions;

    /**
     * A private instance of MobX observable flags used by the store.
     */
    private flags = new ObservableFlags();

    /**
     * An instance of the AssetsManager to work with when dealing with the assets.
     */
    private assetsManager: IAssetsManager;

    /**
     * An instance of the AssetsActualizer to keep fetched assets data up to date.
     */
    private actualizer: AssetsActualizer;

    /**
     * A memo loader of all user assets.
     */
    private memoAssetsLoader?: MemoDocumentLoader<Assets>;

    /**
     * A listener to update all user assets.
     */
    private assetsLoaderListener?: DocumentLoaderListener<Assets>;

    // Constructor

    public constructor(options: AssetsStoreOptions) {
        super();

        this.options = options;

        this.assetsManager = new AssetsManager(
            'authService' in options
                ? { userAuth: options.authService.userAuth }
                : { userId: options.userId },
        );

        this.actualizer = new AssetsActualizer(this.assetsManager);

        makeObservable<AssetsStore>(this, {
            assets: computed,
        });
    }

    // Interface

    protected onLoad = async () => {
        // Load the assets
        // Note, that we do not need to await here to ensure that assets are loaded,
        // since we unsubscribe from assets listener immediately when unloading them
        this.loadAssets();
    };

    protected onUnload = async () => {
        // Unload the assets
        this.unloadAssets();
    };

    public get assets(): Asset[] {
        return Object.values(this.actualizer.allAssets);
    }

    public get isLoadingAssets(): boolean {
        return this.flags.isLoadingAssets;
    }

    public get isAddingAddress(): boolean {
        return this.flags.isAddingAddress;
    }

    public get isRemovingAddress(): boolean {
        return this.flags.isRemovingAddress;
    }

    public get isAddingExchange(): boolean {
        return this.flags.isAddingExchange;
    }

    public get isRemovingExchange(): boolean {
        return this.flags.isRemovingExchange;
    }

    public get isRescanningAssets(): boolean {
        return this.flags.isRescanningAssets;
    }

    public async provideAddress<Provider extends AddressProvider>({
        provider,
        parameters,
        prepareToAddAddresses,
    }: ProvideAddressesOptions<Provider>): Promise<boolean> {
        // Mark an address is being added
        this.flags.isAddingAddress = true;
        try {
            // Provide addresses to find assets
            // Note: we don't tend to wait for the assets to be loaded here,
            // as we do it right before adding the addresses when preparing...
            const foundAssets = await this.assetsManager.provideAddresses({
                provider,
                parameters,
                prepareToAddAddresses: async () => {
                    // First run the function to prepare to add addresses,
                    // e.g. in order to authorize the user before adding them
                    if (prepareToAddAddresses) {
                        await prepareToAddAddresses();
                    }

                    // Then wait the assets to become loaded and await the service is initialized,
                    // that will also mean that the internally used "authService" has the session
                    await this.waitUntilAssetsLoaded();
                },
            });
            log.debug('Found assets:', foundAssets);

            // Check if assets have been found
            if (Object.keys(foundAssets).length === 0) {
                // Return a negative result of the operation
                return false;
            }

            // Check if the assets are still listened to by the loader
            if (!this.assetsLoaderListener) {
                // Return a positive result of the operation, but do not update the assets
                return true;
            }

            // Update all assets via the listener
            this.assetsLoaderListener(undefined, { ...this.actualizer.allAssets, ...foundAssets });

            // Return a positive result of the operation
            return true;
        } catch (error) {
            log.error(`Failed to add an address via ${provider} with error:`, error);

            throw error;
        } finally {
            // Mark an address is not being added anymore
            this.flags.isAddingAddress = false;
        }
    }

    public removeAddress = async (address: string): Promise<boolean> => {
        // Mark an address is being removed
        this.flags.isRemovingAddress = true;
        try {
            // Wait the assets are loaded first
            await this.waitUntilAssetsLoaded();

            // Remove an address from the user and all the assets with it
            const removed = await this.assetsManager.removeAddress({ address });
            log.debug('Address removal result:', removed);

            // Check if the address has been removed
            if (!removed) {
                // Return a negative result of the operation
                return false;
            }

            // Check if the assets are still listened to by the loader
            if (!this.assetsLoaderListener) {
                // Return a positive result of the operation, but do not update the assets
                return true;
            }

            // Exclude the local assets with the corresponding address
            const updatedAssets = this.assets.reduce<Assets>((acc, asset) => {
                // Include the asset if its address is different from the removed one
                if (!('address' in asset) || asset.address !== address) {
                    acc[asset.assetId] = asset;
                }
                return acc;
            }, {});

            // Update all assets via the listener
            this.assetsLoaderListener(undefined, updatedAssets);

            // Return a positive result of the operation
            return true;
        } catch (error) {
            log.error('Failed to remove an address with error:', error);

            throw error;
        } finally {
            // Mark an address is not being removed anymore
            this.flags.isRemovingAddress = false;
        }
    };

    public async provideExchange({
        exchange,
        parameters,
        prepareToAddExchange,
    }: ProvideExchangesOptions): Promise<boolean> {
        // Mark an exchange is being added
        this.flags.isAddingExchange = true;
        try {
            // Provide an exchange source to find assets
            // Note: we don't tend to wait for the assets to be loaded here,
            // as we do it right before adding the exchange when preparing...
            const foundAssets = await this.assetsManager.provideExchange({
                exchange,
                parameters,
                prepareToAddExchange: async () => {
                    // First run the function to prepare to add an exchange,
                    // e.g. in order to authorize the user before adding them
                    if (prepareToAddExchange) {
                        await prepareToAddExchange();
                    }

                    // Then wait the assets to become loaded and await the service is initialized,
                    // that will also mean that the internally used "authService" has the session
                    await this.waitUntilAssetsLoaded();
                },
            });
            log.debug('Found assets:', foundAssets);

            // Check if assets have been found
            if (Object.keys(foundAssets).length === 0) {
                // Return a negative result of the operation
                return false;
            }

            // Check if the assets are still listened to by the loader
            if (!this.assetsLoaderListener) {
                // Return a positive result of the operation, but do not update the assets
                return true;
            }

            // Update all assets via the listener
            this.assetsLoaderListener(undefined, { ...this.actualizer.allAssets, ...foundAssets });

            // Return a positive result of the operation
            return true;
        } catch (error) {
            log.error(`Failed to add an exchange "${exchange}" with error:`, error);

            throw error;
        } finally {
            // Mark an exchange is not being added anymore
            this.flags.isAddingExchange = false;
        }
    }

    public removeExchange = async (sourceId: string): Promise<boolean> => {
        // Mark an exchange is being removed
        this.flags.isRemovingExchange = true;
        try {
            // Wait the assets are loaded first
            await this.waitUntilAssetsLoaded();

            // Remove an exchange from the user and all the assets with it
            const removed = await this.assetsManager.removeExchange({ sourceId });
            log.debug('Exchange removal result:', removed);

            // Check if the exchange has been removed
            if (!removed) {
                // Return a negative result of the operation
                return false;
            }

            // Check if the assets are still listened to by the loader
            if (!this.assetsLoaderListener) {
                // Return a positive result of the operation, but do not update the assets
                return true;
            }

            // Exclude the local assets with the corresponding exchange
            const updatedAssets = this.assets.reduce<Assets>((acc, asset) => {
                // Include the asset if its exchange source is different from the removed one
                if (!('sourceId' in asset) || asset.sourceId !== sourceId) {
                    acc[asset.assetId] = asset;
                }
                return acc;
            }, {});

            // Update all assets via the listener
            this.assetsLoaderListener(undefined, updatedAssets);

            // Return a positive result of the operation
            return true;
        } catch (error) {
            log.error('Failed to remove an exchange with error:', error);

            throw error;
        } finally {
            // Mark an exchange is not being removed anymore
            this.flags.isRemovingExchange = false;
        }
    };

    public rescanAssets = async (): Promise<boolean> => {
        // Mark the assets are being rescanned
        this.flags.isRescanningAssets = true;
        try {
            // Wait the assets are loaded first
            await this.waitUntilAssetsLoaded();

            // Rescan all assets
            const allAssets = await this.assetsManager.rescanAssets();

            // Check if the assets are still listened to by the loader
            if (!this.assetsLoaderListener) {
                // Return a positive result of the operation, but do not update the assets
                return true;
            }

            // Update all assets via the listener
            this.assetsLoaderListener(undefined, allAssets);

            // Return a positive result of the operation
            return true;
        } catch (error) {
            log.error('Failed to rescan the assets with error:', error);

            throw error;
        } finally {
            // Mark the assets are not being rescanned anymore
            this.flags.isRescanningAssets = false;
        }
    };

    // Internals

    /**
     * Method to load assets using memo loader.
     * @throws a variety of errors if the user is not authorized or assets are already loading.
     */
    private loadAssets() {
        // Check if the assets loader already exist to avoid loading the store twice
        if (this.memoAssetsLoader) {
            throw new Error('Assets are already loading');
        }

        // Mark the assets are loading
        this.flags.isLoadingAssets = true;

        // Get a storage key to memo the assets
        // Note: could throw if the user is not authorized
        const storageKey = this.getStorageKey(ALL_ASSETS_KEY);

        // Get all assets via memo loader
        this.memoAssetsLoader = new MemoDocumentLoader<Assets>({
            key: storageKey,
            storage: appStorage,
            documentFetcher: (listener: DocumentLoaderListener<Assets>) => {
                // Save the listener in order to reflect assets changes made by other methods
                this.assetsLoaderListener = listener;

                // Get all the assets from the backend
                this.assetsManager
                    .getAllAssets()
                    .then(allAssets => listener(undefined, allAssets))
                    .catch(error => listener(error))
                    .finally(() => {
                        // Mark the assets are loaded
                        this.flags.isLoadingAssets = false;
                    });
            },
            stopDocumentFetching: () => {
                // Delete the assets listener
                delete this.assetsLoaderListener;
            },
        });

        // Listen to all assets changes
        this.memoAssetsLoader.subscribe((error: Error | undefined, allAssets?: Assets) => {
            if (error) {
                log.error('Failed to fetch all assets with error:', error);
                return;
            }

            if (!allAssets) {
                log.error('No assets have been loaded');
                return;
            }

            this.actualizer.allAssets = allAssets;
        });
    }

    /**
     * Methods to unload assets with the memo loader.
     */
    private unloadAssets() {
        // Unsubscribe from the memo assets loader
        if (this.memoAssetsLoader) {
            this.memoAssetsLoader.unsubscribe();

            // And delete the loader itself
            delete this.memoAssetsLoader;
        }

        // Mark the assets are not loading anymore
        this.flags.isLoadingAssets = false;

        // Remove all assets
        // Note: this should also unsubscribe from all the assets updates.
        this.actualizer.allAssets = {};
    }

    /**
     * Method to get a storage key for the current user and a specified key.
     * @param key - a provided key to get the storage key for.
     * @returns the key bound to the current user.
     */
    private getStorageKey(key: string): string {
        if ('userId' in this.options) {
            return buildStorageKeyForUser(key, this.options.userId);
        }

        return this.options.authService.getStorageKey(key);
    }

    /**
     * Method to wait until the assets are loaded from the remote.
     */
    private async waitUntilAssetsLoaded() {
        // The service should be initialized to wait
        await this.waitUntilInitialized();

        // When until the assets are loaded,
        // i.e. not loading anymore as they start loading on the service load
        return when(() => this.isLoadingAssets === false, { name: 'wait until the assets loaded' });
    }
}
