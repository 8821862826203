import type { ResponseError } from '@smartfolly/server';
import type { SimpleSendRequestOptions } from '../types';

/**
 * Method to send any GET requests
 * @param options - contain the request path and a server host.
 * @returns an expected response of the generic type.
 */
export async function sendGETRequest<Response>({
    host,
    path,
}: {
    /**
     * A host of the server to send a request.
     */
    host: string;
} & SimpleSendRequestOptions): Promise<Response | ResponseError> {
    // Build the request to send
    const request: RequestInit = {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
        mode: 'cors',
    };

    // Fetch
    const response = await fetch(`${host}/${path}`, request);

    // Return a JSON response of the generic type
    return response.json();
}
