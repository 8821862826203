import {
    GetAccountsResponse,
    MetaMaskExtProvider as Provider,
    ProviderInfo,
    ProvidersError,
} from '@smartfolly/common.providers';

import { AssetsManagerError } from '../../../constants';

import type { IAddressProvider } from './types';

type Func<T, P> = (...args: P[]) => Promise<T>;
type WrappedFunc<T, P> = Func<T, P>;

const withErrorsMapperWrapper =
    <T, P, C>(fn: Func<T, P>, context: C): WrappedFunc<T, P> =>
    async (...args: P[]) => {
        try {
            // Run the original function
            return await fn.bind(context)(...args);
        } catch (error) {
            // Map the errors
            if (error === ProvidersError.MetaMaskExt.NoAccountFound) {
                throw AssetsManagerError.Providers.MetaMaskExt.NoAccountFound;
            } else if (error === ProvidersError.MetaMaskExt.NoProviderFound) {
                throw AssetsManagerError.Providers.MetaMaskExt.NoProviderFound;
            } else {
                throw error;
            }
        }
    };

class MetaMaskExtProvider implements IAddressProvider<'MetaMaskExt'> {
    // Properties

    /**
     * A private instance of MetaMaskExt provider
     */
    private metaMaskExtProvider = new Provider();

    // Interface

    public async checkIfAvailable(): Promise<boolean> {
        return withErrorsMapperWrapper(
            this.metaMaskExtProvider.checkIfAvailable,
            this.metaMaskExtProvider,
        )();
    }

    public async disconnect(): Promise<void> {
        return withErrorsMapperWrapper(
            this.metaMaskExtProvider.disconnect,
            this.metaMaskExtProvider,
        )();
    }

    public async getAccounts(): Promise<GetAccountsResponse> {
        return withErrorsMapperWrapper(
            this.metaMaskExtProvider.getAccounts,
            this.metaMaskExtProvider,
        )();
    }

    public async getInfo(): Promise<ProviderInfo<'MetaMaskExt'>> {
        return withErrorsMapperWrapper(
            this.metaMaskExtProvider.getInfo,
            this.metaMaskExtProvider,
        )();
    }
}

export const metaMastExtProvider = new MetaMaskExtProvider();
