// Inspired by https://github.com/christsim/multicoin-address-validator

import { Bech32Encodings, bech32Decode } from '../../common';

import type { ValidatingBlockchain } from './types';

// BIP 173 bech32 addresses (https://github.com/bitcoin/bips/blob/master/bip-0173.mediawiki)
export function isValidBIP173Address(address: string, blockchain: ValidatingBlockchain) {
    if (!blockchain.bech32Hrp) {
        return false;
    }

    const decoded = bech32Decode(address, Bech32Encodings.BECH32);
    if (!decoded) {
        return false;
    }

    if (blockchain.bech32Hrp.indexOf(decoded.hrp) === -1) {
        return false;
    }

    return true;
}
