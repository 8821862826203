import {
    GetAccountsResponse,
    MetaMaskExtProvider as Provider,
    ProviderInfo,
    ProvidersError,
    SignMessageOptions,
    SignMessageResponse,
} from '@smartfolly/common.providers';

import { AuthUserError } from '../../../constants';

import type { IAuthProvider } from './types';

type Func<T, P> = (...args: P[]) => Promise<T>;
type WrappedFunc<T, P> = Func<T, P>;

const withErrorsMapperWrapper =
    <T, P, C>(fn: Func<T, P>, context: C): WrappedFunc<T, P> =>
    async (...args: P[]) => {
        try {
            // Run the original function
            return await fn.bind(context)(...args);
        } catch (error) {
            // Map the errors
            if (error === ProvidersError.MetaMaskExt.NoAccountFound) {
                throw AuthUserError.Providers.MetaMaskExt.NoAccountFound;
            } else if (error === ProvidersError.MetaMaskExt.NoProviderFound) {
                throw AuthUserError.Providers.MetaMaskExt.NoProviderFound;
            } else if (error === ProvidersError.MetaMaskExt.NoSignatureMade) {
                throw AuthUserError.Providers.MetaMaskExt.NoSignatureMade;
            } else {
                throw error;
            }
        }
    };

class MetaMaskExtProvider implements IAuthProvider<'MetaMaskExt'> {
    // Properties

    /**
     * A private instance of MetaMaskExt provider
     */
    private metaMaskExtProvider = new Provider();

    // Interface

    public async checkIfAvailable(): Promise<boolean> {
        return withErrorsMapperWrapper(
            this.metaMaskExtProvider.checkIfAvailable,
            this.metaMaskExtProvider,
        )();
    }

    public async disconnect(): Promise<void> {
        return withErrorsMapperWrapper(
            this.metaMaskExtProvider.disconnect,
            this.metaMaskExtProvider,
        )();
    }

    public async getAccounts(): Promise<GetAccountsResponse> {
        // Note: for the auth purpose we need to deal with the ETH-account,
        // we use SECP256k1 keys (used by Ethereum) to sign messages.
        // Here we don't need to check if the accounts include any ETH-account,
        // as all of them belongs to ETH being provided by MetaMaskExt.
        return withErrorsMapperWrapper(
            this.metaMaskExtProvider.getAccounts,
            this.metaMaskExtProvider,
        )();
    }

    public async getInfo(): Promise<ProviderInfo<'MetaMaskExt'>> {
        return withErrorsMapperWrapper(
            this.metaMaskExtProvider.getInfo,
            this.metaMaskExtProvider,
        )();
    }

    public async signMessage(options: SignMessageOptions): Promise<SignMessageResponse> {
        return withErrorsMapperWrapper(
            this.metaMaskExtProvider.signMessage,
            this.metaMaskExtProvider,
        )(options);
    }
}

export const metaMastExtProvider = new MetaMaskExtProvider();
