import { computed, makeObservable, observable, runInAction } from 'mobx';

import { Log } from '@smartfolly/common.utilities';

import { symbolTokens } from '@smartfolly/common.currencies';

import { serverConnector } from '@smartfolly/middleware.server-connector';

import type { ISymbolTokensStore, SymbolTokensMap } from '../types';

const log = new Log('SymbolTokensStore');

class SymbolTokensStore implements ISymbolTokensStore {
    private loadedValue: SymbolTokensMap | undefined = undefined;

    public constructor() {
        makeObservable<SymbolTokensStore, 'loadedValue'>(this, {
            loadedValue: observable,
            symbolTokens: computed,
        });
    }

    public get symbolTokens(): SymbolTokensMap {
        return this.loadedValue ?? symbolTokens;
    }

    public async load() {
        try {
            const data = await serverConnector.sendGETRequest<SymbolTokensMap>({
                path: 'currencies/symbolTokens',
            });

            // Check for errors in response
            if ('error' in data) {
                const { error } = data;
                // TODO: process `errorCode` if needed
                throw new Error(error);
            }

            runInAction(() => {
                this.loadedValue = data;
            });
        } catch (error) {
            log.error('Failed to load symbol to tokens map form server with error', error);
        }
    }
}

export const symbolTokensStore = new SymbolTokensStore();
