// Public constants

// Enums

/**
 * A provider for the authorization.
 */
export enum AuthProvider {
    /**
     * MetaMask Extension.
     */
    MetaMaskExt = 'MetaMaskExt',

    /**
     * Any wallet powered by WalletConnect.
     */
    WalletConnect = 'WalletConnect',

    /**
     * Simple (anonymous) authentication with no wallet.
     */
    SimpleAuth = 'SimpleAuth',

    /**
     * Telegram Bot.
     * Note: it does not provide any wallet, but should create a persistent user.
     */
    TelegramBot = 'TelegramBot',
}

// Errors

export const AuthUserError = {
    Providers: {
        MetaMaskExt: {
            NoProviderFound: new Error('No MetaMask Extension provider available'),
            NoAccountFound: new Error('No account provided by MetaMask extension'),
            NoSignatureMade: new Error('No signature made by MetaMask extension'),
        },
        WalletConnect: {
            NoAccountFound: new Error('No account provided by WalletConnect'),
            NoSignatureMade: new Error('No signature made by WalletConnect'),
            NotConnected: new Error('Not connected via WalletConnect'),
            QRCodeClosed: new Error('QRCode modal has been closed for WalletConnect'),
        },
        TelegramBot: {
            NoCryptoKeys: new Error('No crypto keys to work with'),
            NoSignatureMade: new Error('No signature made for Telegram Bot'),
            WrongCryptoKeys: new Error('Wrong crypto keys to work with'),
            Stopped: new Error('Stopped processing Telegram Bot Auth forcibly'),
            UserIsTakenAlready: new Error('User is taken already'),
        },
    },
    NoConfig: new Error('No config set'),
    NoCurrentSession: new Error('No current session'),
    NoEthAccount: new Error('No ETH-account to authorize'),
    NotActualProvider: new Error('Attempt to re-authorize with a different provider'),
    NotSupportedProviderToAdd: new Error('Attempt to add a not supported provider'),
    NoSessionToAddProviderTo: new Error('No session to add an auth provider to'),
    SessionExpired: new Error('Session is expired'),
    SessionAlreadyExists: new Error('Session already exists'),
    UnsupportedProvider: new Error('Unsupported Provider'),
};
