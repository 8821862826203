import UniversalProvider from '@walletconnect/universal-provider';
import { getSdkError } from '@walletconnect/utils';
import type { ProposalTypes } from '@walletconnect/types';

import { Log, appStorage } from '@smartfolly/common.utilities';

import { WalletConnectAppMetadata, WalletConnectProjectID } from '../../configs';

import { namespaces, chainIDs, ProvidersError, ethereumMainnetChain } from '../../constants';

import type {
    GetAccountsResponse,
    IProvider,
    ProviderInfo,
    SignMessageOptions,
    SignMessageResponse,
} from '../../types';

import { findAccountsForSession } from '../../utils';

import { establishSessionWithModal } from './establishSessionWithModal';
import { openDeeplink } from './openDeeplink';

const log = new Log('WalletConnect');

type WalletConnectProviderOptions = {
    /**
     * A flag either we require Ethereum chain to be provided by the wallet.
     */
    requireEthereum: boolean;

    /**
     * Include only specified wallet IDs to establish a connection with.
     */
    explorerRecommendedWalletIds?: string[];
};

export class WalletConnectProvider implements IProvider<'WalletConnect'> {
    // Properties

    /**
     * A private instance of the WalletConnect's universal provider.
     */
    private provider?: UniversalProvider;

    // A private map of required namespaces.
    // Note: only the Ethereum blockchain can be required for now (e.g. to authorize)
    private requiredNamespaces: ProposalTypes.RequiredNamespaces = {};

    /**
     * A private list of wallet IDs to establish a connection with.
     */
    private explorerRecommendedWalletIds?: string[];

    // Constructor

    public constructor({
        requireEthereum,
        explorerRecommendedWalletIds,
    }: WalletConnectProviderOptions) {
        if (requireEthereum) {
            this.requiredNamespaces = {
                eip155: {
                    // Specify ethereum mainnet chain
                    chains: [ethereumMainnetChain.id],
                    // Set methods required to verify the user by signing a given auth message
                    methods: ['personal_sign'],
                    // No need to listen to any events, just get an address
                    events: [],
                },
            };
        }

        if (explorerRecommendedWalletIds) {
            this.explorerRecommendedWalletIds = explorerRecommendedWalletIds;
        }
    }

    // Interface

    public async checkIfAvailable(): Promise<boolean> {
        const client = await this.getClient();
        // Actually ideally the client should be always available!
        return !!client;
    }

    public async disconnect(): Promise<void> {
        // Get the provider client
        const client = await this.getClient();

        // Check if connected
        if (client.session.length === 0) {
            // Do nothing if no
            return undefined;
        }

        // Disconnect from all the sessions
        await Promise.all(
            client.session.keys.map(async key => {
                // Get the session
                const session = client.session.get(key);

                // Kill it
                await client.disconnect({
                    topic: session.topic,
                    reason: getSdkError('USER_DISCONNECTED'),
                });
            }),
        );

        // And reset the provider
        // Note: this is mostly important to fully reset the cached client data
        // such as `peer.metadata` which is not cleared after the session is killed =/
        return this.resetProvider();
    }

    public async getAccounts(): Promise<GetAccountsResponse> {
        // Get the provider client
        const client = await this.getClient();

        // Check if we are already connected
        if (client.session.length > 0) {
            // Get the latest session
            const latestSession = client.session.get(
                client.session.keys[client.session.keys.length - 1]!,
            );

            // Get the accounts of the latest session
            const accounts = findAccountsForSession(latestSession, chainIDs);

            // Check if any account is found
            if (!accounts.length) {
                // Throw if not
                throw ProvidersError.WalletConnect.NoAccountFound;
            }

            // Return the found accounts
            return accounts;
        }

        // Connect to WalletConnect SignClient
        const { provider, requiredNamespaces, optionalNamespaces } = this;

        try {
            return await establishSessionWithModal({
                provider: provider!, // must be created to this moment since the client is preset
                requiredNamespaces,
                optionalNamespaces,
                // Set the recommended wallet IDs to establish a connection with
                ...(this.explorerRecommendedWalletIds
                    ? { explorerRecommendedWalletIds: this.explorerRecommendedWalletIds }
                    : {}),
            });
        } catch (error) {
            // Reset the provider to let recreating the session even if the QRCode was closed,
            // since it happened to be that it could be only one attempt to create a session
            // for one provider instance. Hence we are to re-create it if the modal was closed
            this.resetProvider();

            // Throw an error
            throw error;
        }
    }

    public async getInfo(): Promise<ProviderInfo<'WalletConnect'>> {
        // Get the provider client
        const client = await this.getClient();

        // Build-up the provider info
        const providerInfo: ProviderInfo<'WalletConnect'> = { name: 'WalletConnect' };

        // Get the latest session
        if (client.session.length > 0) {
            const latestSession = client.session.get(
                client.session.keys[client.session.keys.length - 1]!,
            );
            if (latestSession.peer.metadata) {
                providerInfo.peer = latestSession.peer.metadata;
            }
        }

        return providerInfo;
    }

    public async signMessage({
        ethAddress,
        message,
    }: SignMessageOptions): Promise<SignMessageResponse> {
        // Get the provider client
        const client = await this.getClient();

        // Check if we are already connected
        if (client.session.length === 0) {
            throw ProvidersError.WalletConnect.NotConnected;
        }

        // Convert the message from UTF-8 to Hex
        const messageHex = `0x${Buffer.from(message, 'utf8').toString('hex')}`;

        // Get the latest session
        const latestSession = client.session.get(
            client.session.keys[client.session.keys.length - 1]!,
        );

        // Switch back to the wallet app
        // Note: it might be especially important if it was redirecting us back
        // right after the connect and we want to reopen it to sign the message
        await this.openRecentWallet();

        // Sign the message with the given ethAddress
        const signature: string = await client.request({
            topic: latestSession.topic,
            chainId: ethereumMainnetChain.id, // sign with SECP256k1 keys (used by Ethereum)
            request: {
                method: 'personal_sign',
                params: [messageHex, ethAddress],
            },
        });

        // Check for a signature presence
        if (!signature) {
            throw ProvidersError.WalletConnect.NoSignatureMade;
        }

        // Return if it's there
        return { signature };
    }

    // Internals

    /**
     * The getter for blockchains which are not required, but would be great to have.
     */
    private get optionalNamespaces(): ProposalTypes.OptionalNamespaces {
        const optionalMethodsAndEvents: ProposalTypes.BaseRequiredNamespace = {
            methods: [], // no need to do any actions, just get an address
            events: [], // no need to listen to any events, just get an address
        };

        return namespaces.reduce<ProposalTypes.OptionalNamespaces>((acc, namespace) => {
            // Map all the chain IDs
            let chains = namespace.chains.map(({ id }) => id);

            // Skip already required chains
            if (namespace.name in this.requiredNamespaces) {
                const requiredNamespace = this.requiredNamespaces[namespace.name]!;
                chains = chains.filter(
                    chainID =>
                        !requiredNamespace.chains || !requiredNamespace.chains.includes(chainID),
                );
            }

            // Add the resulted chains to the map of optional namespaces
            acc[namespace.name] = {
                chains,
                ...optionalMethodsAndEvents,
            };
            return acc;
        }, {});
    }

    /**
     * A lazy getter of the WalletConnect's provider client.
     */
    private async getClient(): Promise<UniversalProvider['client']> {
        if (this.provider) {
            return this.provider.client;
        }

        this.provider = await UniversalProvider.init({
            projectId: WalletConnectProjectID,
            metadata: WalletConnectAppMetadata,
            // relayUrl: 'wss://relay.walletconnect.com', (now it's set automatically in the lib)
        });

        return this.provider.client;
    }

    /**
     * Method to reset the provider.
     * Could be useful when we'd like to try reconnecting.
     */
    private resetProvider() {
        delete this.provider;
    }

    /**
     * Method to open the recent connected wallet app if any.
     */
    // eslint-disable-next-line class-methods-use-this
    private async openRecentWallet(): Promise<void> {
        // Get the recent wallet deeplink if any
        // Note: use the "private" key to get the deeplink href from the local storage
        const recentWalletDeeplink = await appStorage.getItem('WALLETCONNECT_DEEPLINK_CHOICE');

        // Do nothing if there is no recent wallet deeplink
        if (!recentWalletDeeplink) {
            return;
        }

        // Parse the deeplink href and try to open it
        try {
            // Note: JSON.parse might crash, hence wrapped it in try-catch
            const { href } = JSON.parse(recentWalletDeeplink) as { href: string; name: string };

            // Open the href if found
            // Note: for some reason it doesn't work for Rainbow wallet,
            // probably their "universal" link for iOS works incorrectly
            if (href) {
                openDeeplink(href);
            }
        } catch (error) {
            log.error('Failed to parse the recent wallet deeplink with error:', error);
        }
    }
}
