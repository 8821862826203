import { Log } from '@smartfolly/common.utilities';

import type { BlockchainAssetInfo } from '@smartfolly/backend.assets-manager';

import type {
    AddressProvider as ServerAddressProvider,
    AddressesProvideRequestParameters,
    AddressesProvideRequestResponse,
    ProvidedAddressData,
    AddressesRemoveRequestParameters,
    AddressesRemoveRequestResponse,
} from '@smartfolly/server';

import { AuthProvider, type IUserAuth } from '@smartfolly/middleware.user-auth';

import { AddressProvider, AssetsManagerError } from '../../constants';

import type {
    Assets,
    ProvideAddressesOptions,
    RemoveAddressOptions,
    SimpleAddressProviderParameters,
} from '../../types';

import { AddressesRequestsPath } from '../constants';
import { findAddressProvider } from '../helpers';

const log = new Log('AddressesManager');

export class AddressesManager {
    // Properties

    /**
     * A private instance of user auth module to manage user addresses.
     */
    private userAuth: IUserAuth;

    // Constructor

    public constructor(userAuth: IUserAuth) {
        this.userAuth = userAuth;
    }

    // Methods

    /**
     * Method to provide the addresses to the service in order to scan them for the assets.
     * @param options - contain the name of the provider to get the addresses from, as well as
     * the parameters for each provider if they are required.
     * @returns founded assets for the provided addresses.
     */
    public async provideAddresses<Provider extends AddressProvider>({
        provider,
        parameters,
        prepareToAddAddresses,
    }: ProvideAddressesOptions<Provider>): Promise<Assets<BlockchainAssetInfo>> {
        // Find the proper way to provider an address
        if (provider === AddressProvider.SimpleAddress) {
            // Prepare to add an address if needed
            if (prepareToAddAddresses) {
                await prepareToAddAddresses();
            }

            // Provide an address set in the parameters
            const { address, ...rest } = parameters as SimpleAddressProviderParameters;
            const assets = await this.provideAddress(address, { provider, ...rest });

            // Return added assets
            return assets;
        }

        // Find the provider instance to get an address from
        const addressProvider = findAddressProvider(provider, parameters);

        // Disconnect from prior provider sessions if possible
        // Note: this might be important in case we'd like to change the provider's wallet.
        await addressProvider.disconnect();

        // Request the accounts to be added
        const accounts = await addressProvider.getAccounts();

        // Check if accounts are provided
        if (!accounts.length) {
            // Return an empty map of assets if no
            return {};
        }

        // Prepare to add accounts if needed
        if (prepareToAddAddresses) {
            await prepareToAddAddresses();
        }

        // Provide them
        const result = await Promise.all(
            accounts.map(({ address }) =>
                this.provideAddress(address, { provider, ...parameters }),
            ),
        );

        // Combine the found assets
        const foundAssets = result.reduce<Assets<BlockchainAssetInfo>>((acc, assets) => {
            return { ...acc, ...assets };
        }, {});

        // Return the added assets
        return foundAssets;
    }

    /**
     * Method to remove all the assets of the user with the given address.
     * @param options - contain the address to remove the assets with.
     * @returns the result of the removal operation.
     */
    public async removeAddress({ address }: RemoveAddressOptions): Promise<boolean> {
        // Make a request to remove an address
        const response = await this.userAuth.sendRequest<
            AddressesRemoveRequestParameters,
            AddressesRemoveRequestResponse
        >({
            path: AddressesRequestsPath.Remove,
            params: {
                address,
            },
        });

        // Check for errors in response
        if ('error' in response) {
            const { error } = response;
            // TODO: process `errorCode` if needed
            log.error('Failed to remove the address:', address, response);
            throw new Error(error);
        }

        // Return the result of the removal operation
        return response.removed;
    }

    /**
     * Subscribe on the extra addresses which might be received from `userAuth` module
     * once the user is authorized. These addresses need to be added to the user.
     */
    public subscribeOnExtraAddressesToAdd(
        listener: (foundAssets: Assets<BlockchainAssetInfo>) => void,
    ) {
        // Listen to the extra addresses to add
        // Note: We do not dispose this subscription and leave it forever for this `assetsManager`
        // instance as it has no way to be unloaded or destroyed.
        // Besides the amount of such `assetsManager` instances normally should equal to the amount
        // of active user sessions established in the application which are persistent for the app.
        this.userAuth.listenToExtraAddresses(async ({ addresses, provider }) => {
            try {
                // Map the address provider
                let addressProvider: AddressProvider;
                if (provider === AuthProvider.WalletConnect) {
                    addressProvider = AddressProvider.WalletConnect;
                } else if (provider === AuthProvider.MetaMaskExt) {
                    addressProvider = AddressProvider.MetaMaskExt;
                } else if (provider === AuthProvider.SimpleAuth) {
                    addressProvider = AddressProvider.SimpleAddress;
                } else if (provider === AuthProvider.TelegramBot) {
                    // should not happen, since there is no way to get the user wallet address yet
                    // TODO: integrate this way once Telegram supports it!!!
                    return;
                } else {
                    addressProvider = AddressProvider.SimpleAddress;
                }

                // Add them
                const result = await Promise.all(
                    addresses.map(address =>
                        // Note: we don't provide any info to the addresses added when authorizing
                        this.provideAddress(address, { provider: addressProvider }),
                    ),
                );

                // Combine the found assets
                const foundAssets = result.reduce<Assets<BlockchainAssetInfo>>((acc, assets) => {
                    return { ...acc, ...assets };
                }, {});

                // Provide the found assets to the listener
                listener(foundAssets);
            } catch (error) {
                log.error('Failed to add extra addresses to the user with error:', error);
            }
        });
    }

    // Internals

    /**
     * Provide an address to the user and scan it for possible assets.
     * @param address - an address to add and scan for possible assets.
     * @param data - a data of the given address, which includes an address provider and the info.
     * @returns a list of assets that have been added.
     * @throws an error in case it's present in response or user is not authorized to manage.
     */
    private async provideAddress(
        address: string,
        { provider, info }: Pick<ProvidedAddressData, 'provider' | 'info'>,
    ): Promise<Assets<BlockchainAssetInfo>> {
        // Make a request to provide an address
        const response = await this.userAuth.sendRequest<
            AddressesProvideRequestParameters,
            AddressesProvideRequestResponse
        >({
            path: AddressesRequestsPath.Provide,
            params: {
                address,
                provider: provider as ServerAddressProvider,
                ...(info != null ? { info } : {}),
            },
        });

        // Check for errors in response
        if ('error' in response) {
            const { error, errorCode } = response;

            // Map the "AddressIsAlreadyPresent" error
            if (errorCode === 301) {
                throw AssetsManagerError.AddressIsAlreadyPresent;
            }

            // TODO: process the rest options of `errorCode` if needed
            log.error('Failed to provide the address:', address, response);
            throw new Error(error);
        }

        return response.assets;
    }
}
