import { gql } from '@apollo/client';

// Public constants

// Errors

export const UserManagerError = {
    NoUser: new Error('No User to get the data for'),
    NotAuthorizedToManage: new Error('User is not authorized to manage the data'),
};

// GraphQL

export const usersCollection = 'users';

// User auth provider

const userInfoFields = `
    userId 
    authProvider
    services {
        whales {
            created
        }
    }
`;

export const usersInfoQuery = gql`
    query GetUsers($users: [String!]!) {
        ${usersCollection}(users: $users) { ${userInfoFields} }
    }
`;

export const userInfoSubscription = gql`
    subscription ($userId: String!) {
        ${usersCollection}(userId: $userId) { ${userInfoFields} }
    }
`;

// User addresses

const userAddressesFields = `
    userId 
    providedAddresses {
        address
        created
        provider
        info {
            name
        }
        scanned 
    }
`;

export const usersAddressesQuery = gql`
    query GetUsers($users: [String!]!) {
        ${usersCollection}(users: $users) { ${userAddressesFields} }
    }
`;

export const userAddressesSubscription = gql`
    subscription ($userId: String!) {
        ${usersCollection}(userId: $userId) { ${userAddressesFields} }
    }
`;

// User exchanges

const userExchangesFields = `
    userId
    providedExchanges {
        sourceId
        created
        exchange
        keys {
            apiKey
        }
        info {
            name
        }
        scanned
    }
`;

export const usersExchangesQuery = gql`
    query GetUsers($users: [String!]!) {
        ${usersCollection}(users: $users) { ${userExchangesFields} }
    }
`;

export const userExchangesSubscription = gql`
    subscription ($userId: String!) {
        ${usersCollection}(userId: $userId) { ${userExchangesFields} }
    }
`;
