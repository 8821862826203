import { Environment, LogSettings } from '@smartfolly/common.utilities';

/**
 * Function to setup logs to behave properly,
 * e.g. work for development and send them to such services as Sentry in production.
 */
export function setupLogs() {
    // Setup the shared log settings
    LogSettings.shared.writer = {
        debug(...args: unknown[]) {
            console.log(...args);
        },
        info(...args: unknown[]) {
            console.info(...args);
            // TODO: support sentryLog.message(...args);
        },
        warning(...args: unknown[]) {
            console.warn(...args);
            // TODO: support sentryLog.message(...args);
        },
        error(...args: unknown[]) {
            console.error(...args);
            // TODO: support sentryLog.error(...args);
        },
    };

    // Disable debug logs for production
    if (Environment.isProduction) {
        LogSettings.shared.disableDebug();
    }
}
