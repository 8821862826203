import { Log } from '@smartfolly/common.utilities';

import type {
    BoardsAddRequestResponse,
    BoardsDeleteRequestParameters,
    BoardsDeleteRequestResponse,
    BoardsEditRequestResponse,
    BoardsQueryParameters,
    BoardsQueryResponse,
    BoardsReorderRequestParameters,
    BoardsReorderRequestResponse,
} from '@smartfolly/server';

import type { IUserAuth } from '@smartfolly/middleware.user-auth';

import { serverConnector } from '@smartfolly/middleware.server-connector';

import { boardsCollection, BoardsManagerError, boardsQuery } from '../constants';

import type {
    AddBoardOptions,
    AddBoardResponse,
    Board,
    Boards,
    DeleteBoardOptions,
    EditBoardOptions,
    EditBoardResponse,
    GetBoardOptions,
    IBoardsManager,
    ReorderBoardsOptions,
} from '../types';

import { BoardsManagerRequestPath } from './constants';

import { deleteEmptyGraphQLPropertiesIfNeeded } from './helpers';

const log = new Log('BoardsManager');

export type BoardsManagerOptions =
    | {
          /**
           * An instance of the UserAuth to work with when dealing with boards in "Write" mode.
           * Note: the passed module MUST be configured, i.e. be ready to work with.
           */
          userAuth: IUserAuth;
      }
    | {
          /**
           * An ID of the user to work with when dealing with the boards in "Read" mode.
           */
          userId: string;
      };

export class BoardsManager implements IBoardsManager {
    // Properties

    /**
     * Options the BoardsManager module is created with.
     */
    private options: BoardsManagerOptions;

    // Constructor

    public constructor(options: BoardsManagerOptions) {
        this.options = options;
    }

    // Interface

    public async addBoard<T extends AddBoardOptions>(options: T): Promise<AddBoardResponse<T>> {
        // Check if the module can manage boards
        if (!this.userAuth) {
            throw BoardsManagerError.NotAuthorizedToManage;
        }

        // Make a request to add a board
        const response = await this.userAuth.sendRequest<T, BoardsAddRequestResponse<T>>({
            path: BoardsManagerRequestPath.Add,
            params: options,
        });

        // Check for errors in response
        if ('error' in response) {
            const { error } = response;
            // TODO: process `errorCode` if needed
            log.error('Failed to add the board:', options, response);
            throw new Error(error);
        }

        // Return the added board instance
        return response.board;
    }

    // eslint-disable-next-line class-methods-use-this
    public async getBoard(options: GetBoardOptions): Promise<Board> {
        const response = await serverConnector.query<BoardsQueryParameters, BoardsQueryResponse>({
            collection: boardsCollection,
            query: boardsQuery,
            variables: {
                filter: {
                    boardId: options.boardId,
                },
            },
        });

        // Check for errors in response
        if ('error' in response) {
            const { error } = response;
            // TODO: process `errorCode` if needed
            log.error('Failed to get board:', response);
            throw new Error(error);
        }

        const board = response[0];
        if (!board) {
            throw new Error(`GraphQL doesn't return any board`);
        }

        // Return the board with deleted empty properties for proper TS processing
        return deleteEmptyGraphQLPropertiesIfNeeded(board);
    }

    public async editBoard<T extends EditBoardOptions>(options: T): Promise<EditBoardResponse<T>> {
        // Check if the module can manage boards
        if (!this.userAuth) {
            throw BoardsManagerError.NotAuthorizedToManage;
        }

        // Make a request to edit the board
        const response = await this.userAuth.sendRequest<T, BoardsEditRequestResponse<T>>({
            path: BoardsManagerRequestPath.Edit,
            params: options,
        });

        // Check for errors in response
        if ('error' in response) {
            const { error } = response;
            // TODO: process `errorCode` if needed
            log.error('Failed to edit the board:', options, response);
            throw new Error(error);
        }

        // Return the edited board instance
        return response.board;
    }

    public async deleteBoard(options: DeleteBoardOptions): Promise<boolean> {
        // Check if the module can manage boards
        if (!this.userAuth) {
            throw BoardsManagerError.NotAuthorizedToManage;
        }

        // Make a request to delete the board
        const response = await this.userAuth.sendRequest<
            BoardsDeleteRequestParameters,
            BoardsDeleteRequestResponse
        >({
            path: BoardsManagerRequestPath.Delete,
            params: options,
        });

        // Check for errors in response
        if ('error' in response) {
            const { error } = response;
            // TODO: process `errorCode` if needed
            log.error('Failed to delete the board:', options, response);
            throw new Error(error);
        }

        // Check the `reason` property
        if (response.reason) {
            log.debug(`Board is${response.deleted ? '' : ' not'} deleted due to:`, response.reason);
        }

        // Return the deletion result
        return response.deleted;
    }

    public async getAllBoards(): Promise<Boards> {
        const { userId } = this;
        if (!userId) {
            throw BoardsManagerError.NoUser;
        }

        const response = await serverConnector.query<BoardsQueryParameters, BoardsQueryResponse>({
            collection: boardsCollection,
            query: boardsQuery,
            variables: {
                filter: {
                    userId,
                },
            },
        });

        // Check for errors in response
        if ('error' in response) {
            const { error } = response;
            // TODO: process `errorCode` if needed
            log.error('Failed to get all boards:', response);
            throw new Error(error);
        }

        // Return the boards with deleted empty properties for proper TS processing
        return response.map(board => deleteEmptyGraphQLPropertiesIfNeeded(board));
    }

    public async reorderBoards(options: ReorderBoardsOptions): Promise<boolean> {
        // Check if the module can manage boards
        if (!this.userAuth) {
            throw BoardsManagerError.NotAuthorizedToManage;
        }

        // Make a request to reorder the boards
        const response = await this.userAuth.sendRequest<
            BoardsReorderRequestParameters,
            BoardsReorderRequestResponse
        >({
            path: BoardsManagerRequestPath.Reorder,
            params: options,
        });

        // Check for errors in response
        if ('error' in response) {
            const { error } = response;
            // TODO: process `errorCode` if needed
            log.error('Failed to reorder boards:', options, response);
            throw new Error(error);
        }

        // Check the `reason` property
        if (response.reason) {
            log.debug(
                `Boards are${response.reordered ? '' : ' not'} reordered due to:`,
                response.reason,
            );
        }

        // Return the reordering result
        return response.reordered;
    }

    // Internals

    /**
     * A getter for {@link IUserAuth} module from SDK to deal with boards in "Write" mode.
     */
    private get userAuth(): IUserAuth | undefined {
        return 'userAuth' in this.options ? this.options.userAuth : undefined;
    }

    /**
     * A getter for an ID of the user whose boards are loaded and processed by the module instance.
     */
    private get userId(): string | undefined {
        // Check if the userId was passed independently or indirectly with the UserAuth instance
        return 'userId' in this.options
            ? this.options.userId
            : this.options.userAuth.sessionInfo?.userId;
    }
}
