import { Blockchains, currenciesService } from '@smartfolly/frontend.currencies-service';

import { getEverSurfStakingLink, getOEthLink, getTonWhalesStakingLink } from './providers';

import type { Asset } from '../../types';

/**
 * A list of supported currencies to stake with URLs to stake.
 */
const stakingLinks: { [blockchain: string]: { [token: string]: string } } = {
    [Blockchains.ETH]: {
        get [currenciesService.tokens.ethereum]() {
            return getOEthLink();
        },
        get [currenciesService.tokens.weth]() {
            return getOEthLink();
        },
        get [currenciesService.tokens.steth]() {
            return getOEthLink();
        },
        get [currenciesService.tokens['rocket-pool-eth']]() {
            return getOEthLink();
        },
        get [currenciesService.tokens['frax-finance-frax-ether']]() {
            return getOEthLink();
        },
        get [currenciesService.tokens['frax-staked-ether']]() {
            return getOEthLink();
        },
    },
    [Blockchains.EVER]: {
        get [currenciesService.tokens.everscale]() {
            return getEverSurfStakingLink();
        },
    },
    [Blockchains.TON]: {
        get [currenciesService.tokens.toncoin]() {
            return getTonWhalesStakingLink();
        },
    },
};

/**
 * Function to get the staking link for an asset.
 * @param asset - a asset to get the staking link for.
 * @returns a link if supported or undefined.
 */
export function getStakingLinkForAsset(asset: Asset): string | undefined {
    // Get the asset wallet
    const { wallet } = asset;

    // Check if we know the asset blockchain
    if (!('blockchain' in wallet)) {
        return undefined;
    }

    // Try to find the proper staking link for the asset
    return stakingLinks[wallet.blockchain.id]?.[asset.token.id];
}
