import { gql } from '@apollo/client';
import type { ExchangeCurrency } from '../types';

// Public constants

// GraphQL

export const tokenDataCollection = 'tokenData';

function quote(currency: ExchangeCurrency): string {
    const quoteFields =
        '{ price percent_change_24h change_24h low_24h high_24h change_all_time percent_change_all_time low_all_time high_all_time }';

    return `${currency} ${quoteFields}`;
}

const tokenDataFields = `name quote {
        ${quote('USD')}
        ${quote('EUR')}
        ${quote('CNY')}
        ${quote('GBP')}
        ${quote('JPY')}
        ${quote('AED')}
        ${quote('BRL')}
    }`;

export const tokenDataQuery = gql`
    query GetTokenData($tokens: [String!]!) {
        ${tokenDataCollection}(tokens: $tokens) { ${tokenDataFields} }
    }
`;

export const tokenDataSubscription = gql`
    subscription ($token: String!) {
        ${tokenDataCollection}(token: $token) { ${tokenDataFields} }
    }
`;
