import { Exchanges } from './Exchanges';

export type ExchangeData = {
    /**
     * Name of the exchange (e.g. Binance)
     */
    name: string;
};

type ExchangesData = {
    /**
     * Exchanges keys from Exchanges (ex. OKX)
     */
    [key in Exchanges]: ExchangeData;
};

export const exchangesData: ExchangesData = {
    [Exchanges.OKX]: {
        name: 'OKX',
    },
};
