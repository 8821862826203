export enum Blockchains {
    /**
     * Cardano
     */
    ADA = 'ADA',
    /**
     * Algorand
     */
    ALGO = 'ALGO',
    /**
     * Arbitrum
     */
    ARB = 'ARB',
    /**
     * Bitcoin Cash
     */
    BCH = 'BCH',
    /**
     * Bitcoin
     */
    BTC = 'BTC',
    /**
     * Binance Beacon Chain (BEP-2)
     */
    BNB = 'BNB',
    /**
     * Binance Smart Chain (BEP-20)
     */
    BSC = 'BSC',
    /**
     * Celo
     */
    CELO = 'CELO',
    /**
     * Dogecoin
     */
    DOGE = 'DOGE',
    /**
     * MultiversX (Elrond)
     */
    EGLD = 'EGLD',
    /**
     * Ethereum
     */
    ETH = 'ETH',
    /**
     * Everscale
     */
    EVER = 'EVER',
    /**
     * Flow
     */
    FLOW = 'FLOW',
    /**
     * Kaspa
     */
    KAS = 'KAS',
    /**
     * KuCoin
     */
    KCS = 'KCS',
    /**
     * Klaytn
     */
    KLAY = 'KLAY',
    /**
     * Litecoin
     */
    LTC = 'LTC',
    /**
     * Polygon
     */
    MATIC = 'MATIC',
    /**
     * Optimism
     */
    OP = 'OP',
    /**
     * Harmony
     */
    ONE = 'ONE',
    /**
     * Solana
     */
    SOL = 'SOL',
    /**
     * Toncoin
     */
    TON = 'TON',
    /**
     * TRON
     */
    TRX = 'TRX',
    /**
     * VENOM
     */
    VENOM = 'VENOM',
    /**
     * VeChain
     */
    VET = 'VET',
    /**
     * XinFin
     */
    XDC = 'XDC',
    /**
     * Stellar
     */
    XLM = 'XLM',
    /**
     * XRP
     */
    XRP = 'XRP',
}

// Need to support the following blockchains via Node RPC of Tatum.io.
// See the list at: https://apidoc.tatum.io/tag/Node-RPC#operation/NodeJsonPostRpcDriver
// Arbitrum Nova
// Aurora
// Avalanche C-Chain
// Avalanche P-Chain
// Avalanche X-Chain
// Cronos
// Dogecoin
// EOSIO
// Fantom
// Flow
// Gnosis
// Kusama
// Lisk
// NEAR
// Oasis Network
// Palm
// Polkadot
// RSK

// List of important missing blockchains even via Node RPC of Tatum.io:
// AVAX (Avalanche) - but available via Token in BSC
// ATOM (Cosmos) - but available via Token in BSC, ETH, MATIC
// ETC (Ethereum Classic) - but available via Token in BSC
// XMR (Monero) - has no representation even via Token!!!
// FIL (Filecoin) - but available via Token in BSC
// LUNC (Terra Classic) - but available via Token in ETH, BSC, SOL, MATIC
// TON (Toncoin) - but available via Token in ETH, BSC
// HBAR (Hedera) - has no representation even via Token!!!
// THETA (Theta Network) - has no representation even via Token!!!
// BSV (Bitcoin SV) - has no representation even via Token!!!
// MIOTA (IOTA) - has no representation even via Token!!!
// XEC (eCash) - has no representation even via Token!!!
// HNT (Helium) - has no representation even via Token!!!
// DASH (Dash) - has no representation even via Token!!!
// STX (Stacks) - has no representation even via Token!!!
// RVN (Ravencoin) - has no representation even via Token!!!
// WAVES (Waves) - has no representation even via Token!!!
// MINA (Mina) - has no representation even via Token!!!
// BTG (Bitcoin Gold) - has no representation even via Token!!!
// DCR (Decred) - has no representation even via Token!!!
// XEM (NEM) - has no representation even via Token!!!
// AR (Arweave) - has no representation even via Token!!!
