import { Blockchains } from '../../constants';

import { isValidAdaAddress } from './isValidAdaAddress';
import { isValidBtcAddress } from './isValidBtcAddress';
import { isValidLtcAddress } from './isValidLtcAddress';
import { isValidSolAddress } from './isValidSolAddress';

// hints!
// hex: [a-fA-F0-9]
// base58: [a-km-zA-HJ-NP-Z1-9]

export function falsyRegex(_address: string): boolean {
    return false;
}

export function ethRegex(address: string): boolean {
    return /(?:^0x[a-fA-F0-9]{40}$)/.test(address);
}

export function algoRegex(address: string): boolean {
    return /(?:^[A-Z2-7]{58}$)/.test(address);
}

export function bchRegex(address: string): boolean {
    return /(?:^((bitcoincash:)?(q|p)[a-z0-9]{41}|(BITCOINCASH:)?(Q|P)[A-Z0-9]{41})|[13]{1}[a-km-zA-HJ-NP-Z1-9]{26,33}$)/.test(
        address,
    );
}

export function bnbRegex(address: string): boolean {
    return /(?:^(bnb)([a-z0-9]{39})$)/.test(address);
}

export function dogeRegex(address: string): boolean {
    return /(?:^D{1}[5-9A-HJ-NP-U]{1}[1-9A-HJ-NP-Za-km-z]{32}$)/.test(address);
}

export function egldRegex(address: string): boolean {
    return /(?:^(erd1)([a-z0-9]{58})$)/.test(address);
}

export function flowRegex(address: string): boolean {
    return /(?:^(0x)([a-f0-9]{16})$)/.test(address);
}

export function oneRegex(address: string): boolean {
    return /(?:^((one1)([qpzry9x8gf2tvdw0s3jn54khce6mua7l]{38})|0x[a-fA-F0-9]{40})$)/.test(address);
}

export function kasRegex(address: string): boolean {
    return /(?:^(kaspa:)([a-z0-9]{61,63})$)/.test(address);
}

/**
 * Not used at the moment.
 */
export function neoRegex(address: string): boolean {
    return /(?:^[A][a-km-zA-HJ-NP-Z1-9]{33}$)/.test(address);
}

export function trxRegex(address: string): boolean {
    return /(?:^(T)[a-km-zA-HJ-NP-Z1-9]{33}$)/.test(address);
}

export function xrpRegex(address: string): boolean {
    return /(?:^(r)[a-km-zA-HJ-NP-Z1-9]{33}$)/.test(address);
}

export function xlmRegex(address: string): boolean {
    return /(?:^[A-Z2-7]{56}$)/.test(address);
}

export function xdcRegex(address: string): boolean {
    return /(?:^(xdc)[a-fA-F0-9]{40}$)/.test(address);
}

function everRegex(address: string): boolean {
    return /(?:^(-1|[a-f0-9]+)(:)([a-f0-9]{64})$)/.test(address);
}

function tonRegex(address: string): boolean {
    return /(?:^[0-9a-zA-Z\-_+/=]{48}$)/.test(address);
}

// Everscale and Ton coin blockchains support the same address formats
// while the Hex-like is more common for Everscale, and Base64-like - for Ton coin
export function tvmRegex(address: string): boolean {
    return everRegex(address) || tonRegex(address);
}

// https://github.com/Swyftx/crypto-address-validator
// https://github.com/christsim/multicoin-address-validator
// TODO: need to investigate and test in future

export const blockchainTests: Record<
    Exclude<Blockchains, Blockchains.BCH>,
    (address: string) => boolean
> = {
    [Blockchains.ALGO]: algoRegex,
    [Blockchains.ARB]: ethRegex,
    [Blockchains.BTC]: isValidBtcAddress,
    [Blockchains.BSC]: ethRegex,
    [Blockchains.BNB]: bnbRegex,
    [Blockchains.ADA]: isValidAdaAddress,
    [Blockchains.CELO]: ethRegex,
    [Blockchains.DOGE]: dogeRegex,
    [Blockchains.EGLD]: egldRegex,
    [Blockchains.ETH]: ethRegex,
    [Blockchains.EVER]: tvmRegex,
    [Blockchains.TON]: tvmRegex,
    [Blockchains.FLOW]: flowRegex,
    [Blockchains.ONE]: oneRegex,
    [Blockchains.OP]: ethRegex,
    [Blockchains.KAS]: kasRegex,
    [Blockchains.KLAY]: ethRegex,
    [Blockchains.KCS]: ethRegex,
    [Blockchains.LTC]: isValidLtcAddress,
    [Blockchains.MATIC]: ethRegex,
    [Blockchains.SOL]: isValidSolAddress,
    [Blockchains.XLM]: xlmRegex,
    [Blockchains.TRX]: trxRegex,
    [Blockchains.VENOM]: tvmRegex,
    [Blockchains.VET]: ethRegex,
    [Blockchains.XDC]: xdcRegex,
    [Blockchains.XRP]: xrpRegex,
};

/**
 * Try to detect available blockchain networks for current address format using Regex
 * @param address - provided by user
 * @returns array of detected blockchains (could be empty if no one found)
 */
export function detectCrypto(address: string): Blockchains[] {
    return (Object.keys(blockchainTests) as (keyof typeof blockchainTests)[]).reduce<Blockchains[]>(
        (blockchains, blockchain) => {
            const test = blockchainTests[blockchain];
            if (test(address)) {
                return blockchains.concat([blockchain]);
            }
            return blockchains;
        },
        [],
    );
}
