/* eslint-disable class-methods-use-this */
import { Log } from '@smartfolly/common.utilities';

import { ethereumMainnetChain, ProvidersError } from '../constants';

import type {
    Account,
    GetAccountsResponse,
    IProvider,
    ProviderInfo,
    SignMessageOptions,
    SignMessageResponse,
} from '../types';

import { checkForEthereumProvider } from '../utils';

const log = new Log('MetaMaskExtProvider');

export class MetaMaskExtProvider implements IProvider<'MetaMaskExt'> {
    // Interface

    public async checkIfAvailable(): Promise<boolean> {
        try {
            const provider = await checkForEthereumProvider();
            return !!provider;
        } catch (error) {
            if (error === ProvidersError.MetaMaskExt.NoProviderFound) {
                return false;
            }

            throw error;
        }
    }

    public async disconnect(): Promise<void> {
        // Note: there is no real way to disconnect from the client side.
        // Instead we can try to re-request the permissions to re-select the address to deal with.

        // Check for the Ethereum provider
        const provider = await checkForEthereumProvider();

        // The following request should "reset" everything and allow to pick an account again
        // Note: wrap the following with "try-catch" since it might crash on Windows with error:
        // "Request method wallet_requestPermissions is not supported" which is not OK
        // as it should be supported as per the documentation. See:
        // https://docs.metamask.io/wallet/reference/rpc-api/#wallet_requestpermissions
        try {
            await provider.request({
                method: 'wallet_requestPermissions',
                params: [
                    {
                        eth_accounts: {},
                    },
                ],
            });
        } catch (error) {
            log.error(
                'Failed to reset MetaMask requesting empty wallet permissions with error:',
                error,
            );
        }
    }

    public async getAccounts(): Promise<GetAccountsResponse> {
        // Check for the Ethereum provider
        const provider = await checkForEthereumProvider();

        // Request the accounts from MetaMask
        const ethAccounts = await provider.request<string[]>({ method: 'eth_requestAccounts' });

        // Get the accounts
        const accounts = (ethAccounts ?? []).reduce<Account[]>((acc, address) => {
            // Filter `undefined` accounts
            if (address === undefined) {
                return acc;
            }

            return acc.concat({
                address,
                // Set the chain as Ethereum Mainnet taken from the WalletConnect related constants
                // since this chain ID is general and can be used anywhere as CAIP-2 standard value
                chainId: ethereumMainnetChain.id,
            });
        }, []);

        // Check if any account is found
        if (!accounts.length) {
            // Throw if not
            throw ProvidersError.MetaMaskExt.NoAccountFound;
        }

        // Return the found accounts
        return accounts;
    }

    public async getInfo(): Promise<ProviderInfo<'MetaMaskExt'>> {
        return {
            name: 'MetaMaskExt',
        };
    }

    public async signMessage({
        message,
        ethAddress,
    }: SignMessageOptions): Promise<SignMessageResponse> {
        // Check for the Ethereum provider
        const provider = await checkForEthereumProvider();

        // Convert the message from UTF-8 to Hex
        const messageHex = `0x${Buffer.from(message, 'utf8').toString('hex')}`;

        // Sign the message with the given ethAddress
        const signature = await provider.request<string>({
            method: 'personal_sign',
            params: [messageHex, ethAddress],
        });

        // Check for a signature presence
        if (!signature) {
            throw ProvidersError.MetaMaskExt.NoSignatureMade;
        }

        // Return if it's there
        return { signature };
    }
}
