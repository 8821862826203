import classNames from 'classnames/bind';
import { memo } from 'react';

import { Button } from '@smartfolly/frontend.web-ui';

import { BlockchainLandingIcon } from '../BlockchainLandingIcon/BlockchainLandingIcon';

import styles from './Header.module.scss';

import { config } from '../../config';

const cnb = classNames.bind(styles);

export const Header = memo(function Header({ openApp }: { openApp: () => void }) {
    return (
        <nav className={`navbar fixed-top ${cnb('header')}`}>
            <div className="m-l-1.5">
                <a
                    className="m-r-0.25 text-white hover:text-blockchain"
                    href={config.url}
                    target="_blank"
                    rel="noreferrer"
                >
                    <BlockchainLandingIcon icon={config.blockchain ?? 'default-token'} />
                </a>
                <BlockchainLandingIcon icon="x" className="m-r-0.25" />
                <a
                    className="text-white hover:text-blockchain"
                    href="https://mooli.app"
                    target="_blank"
                    rel="noreferrer"
                >
                    <BlockchainLandingIcon icon="mooli-symbol" />
                </a>
            </div>
            <div className="m-r-1.5">
                <Button
                    className="background-blockchain hover:background-blockchain b-r-6"
                    onClick={openApp}
                >
                    Get Started
                </Button>
            </div>
        </nav>
    );
});
