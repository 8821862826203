import type { Metadata } from '@walletconnect/universal-provider';

export const WalletConnectProjectID = 'fbd9a6882204f28ab0294b2bb426536c';

export const WalletConnectAppMetadata: Metadata = {
    name: 'Mooli App',
    description:
        'Create an entire Web3 and DeFi portfolio and adjust it by your needs, keep track of all assets in one handy place',
    url: 'https://mooli.app',
    icons: ['https://beta.mooli.app/logo192.png'],
    redirect: {
        native: `mooli://`,
    },
};
