import { Blockchains, Currency, Symbols } from '@smartfolly/frontend.currencies-service';

import type { Asset } from '../../../types';

/**
 * A list of supported currencies by mercuryo.io
 */
const mercuryoSupportedCurrencies: { [blockchain: string]: Set<Symbols> } = {
    [Blockchains.BTC]: new Set([Symbols.BTC]),
    [Blockchains.ETH]: new Set([
        Symbols.ETH,
        Symbols.BAT,
        Symbols.USDT,
        Symbols.OKB,
        Symbols.DAI,
        Symbols.SAND,
        Symbols.ETHDYDX,
        Symbols.MANA,
        Symbols.CRV,
    ]),
    [Blockchains.TRX]: new Set([Symbols.TRX, Symbols.USDT, Symbols.USDC]),
    [Blockchains.ALGO]: new Set([Symbols.ALGO]),
    [Blockchains.BCH]: new Set([Symbols.BCH]),
    [Blockchains.TON]: new Set([Symbols.TON]),
    [Blockchains.BNB]: new Set([Symbols.BNB]),
    [Blockchains.BSC]: new Set([Symbols.BSC, Symbols['1INCH'], Symbols.BUSD]),
    // TODO: add NEAR which is not supported by us
    [Blockchains.SOL]: new Set([Symbols.SOL]),
    // TODO: add DOT which is not supported by us
    [Blockchains.ADA]: new Set([Symbols.ADA]),
    // TODO: add KSM which is not supported by us
    [Blockchains.MATIC]: new Set([Symbols.MATIC]),
    // TODO: add ATOM which is not supported by us
    // TODO: add AVAX which is not supported by us
    [Blockchains.XLM]: new Set([Symbols.XLM]),
    [Blockchains.XRP]: new Set([Symbols.XRP]),
    [Blockchains.LTC]: new Set([Symbols.LTC]),
    // TODO: add FTM which is not supported by us
    [Blockchains.DOGE]: new Set([Symbols.DOGE]),
    // TODO: add XTZ which is not supported by us
};

/**
 * Function to check if Mercuryo supports the blockchain and token of the asset.
 * @param asset - an asset to check.
 * @returns the result of the check
 */
function doesMercuryoSupportAsset(asset: Asset): boolean {
    // Get the asset wallet
    const { wallet } = asset;

    // Check the asset has an appropriate blockchain and token for the mercuryo support
    return (
        'blockchain' in wallet &&
        !!mercuryoSupportedCurrencies[wallet.blockchain.id]?.has(asset.token.symbol)
    );
}

/**
 * Function to normalize the symbol string for Mercuryo.
 * Ex: BSC is not supported and BNB is used instead.
 * @param symbol - a symbol of the currency to normalize.
 */
function normalizeMercuryoCurrency(symbol: Symbols) {
    if (symbol === Symbols.BSC) {
        return Symbols.BNB;
    }
    return symbol;
}

export type MercuryoOperationType = 'buy' | 'sell';

export type MercuryoLinkOptions = {
    /**
     * An asset to get a link for.
     */
    asset: Asset;
    /**
     * A fiat currency used to buy or sell the tokens for an asset.
     */
    fiatCurrency: Currency;
    /**
     * A type of the operation.
     */
    type: MercuryoOperationType;
};

/**
 * Function to get a link on the Mercuryo exchanger.
 */
export function getMercuryoLink(type: MercuryoOperationType): string {
    return `https://exchange.mercuryo.io?type=${type}`;
}

/**
 * Function to get a link on the Mercuryo exchanger for an asset.
 * @param options - to get a link for.
 * @returns a link if supported or undefined.
 */
export function getMercuryoLinkForAsset({
    asset,
    fiatCurrency,
    type,
}: MercuryoLinkOptions): string | undefined {
    // Get the asset wallet and token
    const { wallet, token } = asset;

    // Check if the asset has an address to work with Mercuryo
    if (!('address' in wallet)) {
        return undefined;
    }

    // Check if Mercuryo supports the asset
    if (!doesMercuryoSupportAsset(asset)) {
        return undefined;
    }

    // Normalize the currency to pass into the URL
    const currency = normalizeMercuryoCurrency(token.symbol);

    // Return the resulted URL to buy or sell tokens
    // Note: passing an `address` doesn't seem to work in some browsers >>>
    return `https://exchange.mercuryo.io?type=${type}&fiat_currency=${fiatCurrency}&currency=${currency}&address=${wallet.address}`;
}

// Note: exchange.mercuryo.io can be parameterized with url params:
// "widget_id",
// "type",
// "currencies",
// "currency",
// "fix_currency",
// "fiat_currencies",
// "fiat_currency",
// "fix_fiat_currency",
// "amount",
// "fix_amount",
// "fiat_amount",
// "fix_fiat_amount",
// "address",
// "address_map",
// "signature",
// "refund_address",
// "refund_address_map",
// "hide_refund_address",
// "rates_fee_off",
// "country_code",
// "phone",
// "first_name",
// "last_name",
// "birthdate",
// "birthday",
// "email",
// "merchant_transaction_id",
// "utm_source",
// "utm_medium",
// "ref_code",
// "face",
// "passport",
// "id_card_front",
// "id_card_back",
// "lang",
// "theme",
// "share_token",
// "return_url",
// "init_token",
// "init_token_type",
// "ledger",
// "partner_flow",
// "payment_method",
// "fix_payment_method",
// "redirect_url",
// "order_id",
// "wallet_address",
// "network",
// "external_mobile_pay"
