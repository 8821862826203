import type { UsersQueryParameters, UsersQueryResponse } from '@smartfolly/server';

import { Log } from '@smartfolly/common.utilities';

import { serverConnector } from '@smartfolly/middleware.server-connector';

import { usersCollection, usersExchangesQuery } from '../../constants';

import type { ProvidedExchanges } from '../../types';

const log = new Log('user-manager:queryProvidedExchanges');

/**
 * A function to query all provided exchanges by the user.
 * @param userId - an ID of the user to query the provided exchanges for.
 * @returns the map of all provided exchanges.
 */
export async function queryProvidedExchanges(userId: string): Promise<ProvidedExchanges> {
    const response = await serverConnector.query<
        UsersQueryParameters,
        UsersQueryResponse<'providedExchanges'>
    >({
        collection: usersCollection,
        query: usersExchangesQuery,
        variables: {
            users: [userId],
        },
    });

    // Check for errors in response
    if ('error' in response) {
        const { error } = response;
        // TODO: process `errorCode` if needed
        log.error('Failed to get the provided exchanges:', response);
        throw new Error(error);
    }

    // Check if the response has users
    if (response.length === 0) {
        const error = new Error('No user found');
        log.error('Failed to get the provided exchanges:', error);
        throw error;
    }

    // Pick the first user (should be the only one for the "userId")
    const user = response[0]!;

    // Return the found user addresses converting the response into the map
    return user.providedExchanges.reduce<ProvidedExchanges>((acc, { sourceId, ...rest }) => {
        acc[sourceId] = rest;
        return acc;
    }, {});
}
