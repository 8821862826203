import BigNumber from 'bignumber.js';
import Papa from 'papaparse';

import { Log } from '@smartfolly/common.utilities';

import type { Asset } from '../types';

import { getExplorerLinkToAsset } from './getExplorerLinkToAsset';

const log = new Log('exportAssetsToCSV');

/**
 * Function to export the list of assets as data for the CSV string.
 * @param assets - a list of assets to export the data from.
 * @returns the CSV string.
 */
export function exportAssetsToCSV(assets: Asset[]): string {
    // Build the data for assets to fill the CSV string
    const data = assets
        // Sort the given assets by their price value
        .sort((a, b) => {
            // Consider the price of the asset with an unknown value as zero
            const aAssetPrice = a.price.value ?? new BigNumber(0);
            const bAssetPrice = b.price.value ?? new BigNumber(0);

            // Sort the assets by the price in the descending order
            return bAssetPrice.comparedTo(aAssetPrice);
        })
        // Fill the CSV data
        .map(asset => {
            const csvRow: Record<string, string> = {};

            // Set the First column as the Asset Type
            const assetTypeKey = 'Asset Type';
            if ('contract' in asset) {
                // Note: older assets might miss "contractType" property value
                if (!asset.contractType || asset.contractType === 'FT') {
                    csvRow[assetTypeKey] = 'Fungible';
                } else if (asset.contractType === 'StakingPool') {
                    csvRow[assetTypeKey] = 'Staked';
                } else {
                    log.error('Failed to identify the asset type for unsupported "contractType"');

                    // Fallback to the unknown contract type property value
                    // Note: apply the custom name for rest contract types
                    csvRow[assetTypeKey] = asset.contractType;
                }
            } else {
                csvRow[assetTypeKey] = 'Native';
            }

            // Set the Contract as the Second column
            if ('contract' in asset) {
                csvRow.Contract = asset.contract;
            } else {
                csvRow.Contract = '-';
            }

            // Get the asset wallet
            const { wallet } = asset;

            // Find the asset Address if any
            const address = 'address' in wallet ? wallet.address : '-';

            // Find the Asset Name if any
            const assetName =
                'address' in wallet
                    ? wallet.addressData?.info?.name ?? address
                    : `${wallet.exchange.name}: ${asset.token.name}`;

            // Find the asset Blockchain if any
            const blockchain = 'blockchain' in wallet ? wallet.blockchain.name : '-';

            // Specify the next columns
            Object.assign(csvRow, {
                'Asset Name': assetName,
                Address: address,
                Blockchain: blockchain,
                Token: asset.token.name,
                Symbol: asset.token.symbol,
                Balance: asset.balance,
                Price: asset.price.string,
                'Explorer Link': getExplorerLinkToAsset(asset) ?? 'Link not found',
            });

            return csvRow;
        });

    // Export the resulted data to CSV
    return Papa.unparse(data); // leave the function config with default values
}
