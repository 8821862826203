import { computed, makeObservable, observable, runInAction } from 'mobx';

import { Log } from '@smartfolly/common.utilities';

import type {
    AssetsQueryParameters,
    AssetsQueryResponse,
    AssetsRescanRequestParameters,
    AssetsRescanRequestResponse,
} from '@smartfolly/server';

import type { IUserAuth } from '@smartfolly/middleware.user-auth';

import { serverConnector } from '@smartfolly/middleware.server-connector';

import {
    AddressProvider,
    assetsCollection,
    assetsSubscription,
    AssetsManagerError,
    assetsQuery,
} from '../constants';

import type {
    Asset,
    AssetListener,
    AssetListenerDisposer,
    Assets,
    GetAssetOptions,
    IAssetsManager,
    ProvideAddressesOptions,
    ProvideExchangesOptions,
    RemoveAddressOptions,
    RemoveExchangeOptions,
} from '../types';

import { AssetsRequestPath } from './constants';

import { deleteEmptyGraphQLPropertiesIfNeeded } from './helpers';

import { AddressesManager, ExchangesManager } from './submanagers';

const log = new Log('AssetsManager');

export type AssetsManagerOptions =
    | {
          /**
           * An instance of the UserAuth to work with when dealing with assets in "Write" mode.
           * Note: the passed module MUST be configured, i.e. be ready to work with.
           */
          userAuth: IUserAuth;
      }
    | {
          /**
           * An ID of the user to work with when dealing with the assets in "Read" mode.
           */
          userId: string;
      };

export class AssetsManager implements IAssetsManager {
    // Properties

    /**
     * Options the AssetsManager module is created with.
     */
    private options: AssetsManagerOptions;

    /**
     * An optional AddressesManager instance to manager the addresses and connected assets.
     */
    private addressesManager?: AddressesManager;

    /**
     * An optional ExchangesManager instance to manager the exchanges and connected assets.
     */
    private exchangesManager?: ExchangesManager;

    // Constructor

    public constructor(options: AssetsManagerOptions) {
        this.options = options;

        if ('userAuth' in options) {
            this.addressesManager = new AddressesManager(options.userAuth);
            this.exchangesManager = new ExchangesManager(options.userAuth);
        }

        this.subscribeOnExtraAddressesToAdd();

        makeObservable<AssetsManager, 'assets'>(this, {
            assets: observable,
            allAssets: computed,
        });
    }

    /**
     * All assets have gotten from the {@link getAllAssets} or {@link rescanAssets} calls.
     */
    private assets: Assets = {};

    // Getters & Setters

    /**
     * All loaded assets.
     * Note: This getter is MobX computed.
     */
    public get allAssets() {
        return this.assets;
    }

    /**
     * Set all loaded assets.
     */
    private set allAssets(assets: Assets) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.assets = assets;
    }

    // Interface

    public async provideAddresses<Provider extends AddressProvider>(
        options: ProvideAddressesOptions<Provider>,
    ): Promise<Assets> {
        // Check if the module can manage addresses
        if (!this.addressesManager) {
            throw AssetsManagerError.NotAuthorizedToManage;
        }

        // Provide the addresses
        const foundAssets = await this.addressesManager.provideAddresses(options);

        // Add the found assets to all assets
        if (Object.keys(foundAssets).length > 0) {
            this.allAssets = { ...this.allAssets, ...foundAssets };
        }

        // Return the found assets
        return foundAssets;
    }

    public async removeAddress(options: RemoveAddressOptions): Promise<boolean> {
        // Check if the module can manage addresses
        if (!this.addressesManager) {
            throw AssetsManagerError.NotAuthorizedToManage;
        }

        // Remove an address
        const removed = await this.addressesManager.removeAddress(options);

        // Exclude the local assets with the corresponding address
        this.allAssets = Object.keys(this.allAssets).reduce<Assets>((acc, assetId) => {
            const asset = this.allAssets[assetId]!;
            // Include the asset if its address is different from the removed one
            if (!('address' in asset) || asset.address !== options.address) {
                acc[assetId] = asset;
            }
            return acc;
        }, {});

        // Return the result of the removal operation
        return removed;
    }

    public async provideExchange(options: ProvideExchangesOptions): Promise<Assets> {
        // Check if the module can manage exchanges
        if (!this.exchangesManager) {
            throw AssetsManagerError.NotAuthorizedToManage;
        }

        // Provide the exchanges
        const foundAssets = await this.exchangesManager.provideExchange(options);

        // Add the found assets to all assets
        if (Object.keys(foundAssets).length > 0) {
            this.allAssets = { ...this.allAssets, ...foundAssets };
        }

        // Return the found assets
        return foundAssets;
    }

    public async removeExchange(options: RemoveExchangeOptions): Promise<boolean> {
        // Check if the module can manage exchanges
        if (!this.exchangesManager) {
            throw AssetsManagerError.NotAuthorizedToManage;
        }

        // Remove an exchange
        const removed = await this.exchangesManager.removeExchange(options);

        // Exclude the local assets with the corresponding exchange source
        this.allAssets = Object.keys(this.allAssets).reduce<Assets>((acc, assetId) => {
            const asset = this.allAssets[assetId]!;
            // Include the asset if its exchange source is different from the removed one
            if (!('sourceId' in asset) || asset.sourceId !== options.sourceId) {
                acc[assetId] = asset;
            }
            return acc;
        }, {});

        // Return the result of the removal operation
        return removed;
    }

    public async getAllAssets(): Promise<Assets> {
        const { userId } = this;
        if (!userId) {
            throw AssetsManagerError.NoUser;
        }

        const response = await serverConnector.query<AssetsQueryParameters, AssetsQueryResponse>({
            collection: assetsCollection,
            query: assetsQuery,
            variables: {
                filter: {
                    userId,
                },
            },
        });

        // Check for errors in response
        if ('error' in response) {
            const { error } = response;
            // TODO: process `errorCode` if needed
            log.error('Failed to get all assets:', response);
            throw new Error(error);
        }

        // Get and set all known assets
        const assets = response.reduce<Assets>((acc, item) => {
            // Set the up-to-date asset with deleted empty properties for proper TS processing
            acc[item.assetId] = deleteEmptyGraphQLPropertiesIfNeeded(item);
            return acc;
        }, {});
        this.allAssets = assets;

        // Return them
        return assets;
    }

    public async rescanAssets(): Promise<Assets> {
        // Check if the module can manage assets
        // Note: ".Rescan" is a heavy request and only authorized users can rescan their assets!
        if (!this.userAuth) {
            throw AssetsManagerError.NotAuthorizedToManage;
        }

        const response = await this.userAuth.sendRequest<
            AssetsRescanRequestParameters,
            AssetsRescanRequestResponse
        >({
            path: AssetsRequestPath.Rescan,
            params: {},
        });

        // Check for errors in response
        if ('error' in response) {
            const { error } = response;
            // TODO: process `errorCode` if needed
            log.error('Failed to rescan assets:', response);
            throw new Error(error);
        }

        // Get and set all up-to-date assets
        const { assets } = response;
        this.allAssets = assets;

        // Return them
        return assets;
    }

    public async getAsset({ assetId }: GetAssetOptions): Promise<Asset> {
        const response = await serverConnector.query<AssetsQueryParameters, AssetsQueryResponse>({
            collection: assetsCollection,
            query: assetsQuery,
            variables: {
                filter: {
                    assetId,
                },
            },
        });

        // Check for errors in response
        if ('error' in response) {
            const { error } = response;
            // TODO: process `errorCode` if needed
            log.error('Failed to get asset:', assetId, response);
            throw new Error(error);
        }

        // Get and set up-to-date asset(s) in MobX action
        const asset = response[0];

        if (!asset) {
            throw new Error(`GraphQL doesn't return any asset`);
        }

        runInAction(() => {
            // Set the up-to-date asset with deleted empty properties for proper TS processing
            this.allAssets[asset.assetId] = deleteEmptyGraphQLPropertiesIfNeeded(asset);
        });

        // Return the gotten asset
        return asset;
    }

    public subscribeToAsset(assetId: string, listener: AssetListener): AssetListenerDisposer {
        // Proxy a listener to update allAssets if some data has been received
        const proxyListener = (error: Error | undefined, updatedAsset?: Asset | null) => {
            runInAction(() => {
                if (updatedAsset) {
                    // Set the asset with deleted empty properties for proper TS processing
                    const asset = deleteEmptyGraphQLPropertiesIfNeeded(updatedAsset);
                    this.allAssets[updatedAsset.assetId] = asset;

                    listener(error, asset);
                } else {
                    // Delete the asset if it's `null`
                    if (updatedAsset === null) {
                        delete this.allAssets[assetId];
                    }

                    listener(error, updatedAsset);
                }
            });
        };

        return serverConnector.subscribe<{ assetId: string }, Asset>(
            {
                collection: assetsCollection,
                query: assetsSubscription,
                variables: {
                    assetId,
                },
            },
            proxyListener,
        );
    }

    // Internals

    /**
     * A getter for {@link IUserAuth} module from SDK to deal with assets in "Write" mode.
     */
    private get userAuth(): IUserAuth | undefined {
        return 'userAuth' in this.options ? this.options.userAuth : undefined;
    }

    /**
     * A getter for an ID of the user whose assets are loaded and processed by the module instance.
     */
    private get userId(): string | undefined {
        // Check if the userId was passed independently or indirectly with the UserAuth instance
        return 'userId' in this.options
            ? this.options.userId
            : this.options.userAuth.sessionInfo?.userId;
    }

    /**
     * Subscribe on the extra addresses which might be received from `userAuth` module
     * once the user is authorized. These addresses need to be added to the user.
     */
    private subscribeOnExtraAddressesToAdd() {
        if (!this.addressesManager) {
            // Do nothing as there is no way to subscribe on extra addresses
            return;
        }

        this.addressesManager.subscribeOnExtraAddressesToAdd((foundAssets: Assets) => {
            // Add the found assets to all assets
            this.allAssets = { ...this.allAssets, ...foundAssets };
        });
    }
}
