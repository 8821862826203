import { Log } from '@smartfolly/common.utilities';

import type { ExchangeAssetInfo } from '@smartfolly/backend.assets-manager';

import type {
    ExchangesProvideRequestParameters,
    ExchangesProvideRequestResponse,
    ExchangesRemoveRequestParameters,
    ExchangesRemoveRequestResponse,
} from '@smartfolly/server';

import type { IUserAuth } from '@smartfolly/middleware.user-auth';

import { AssetsManagerError } from '../../constants';

import type { Assets, ProvideExchangesOptions, RemoveExchangeOptions } from '../../types';

import { ExchangesRequestsPath } from '../constants';

const log = new Log('ExchangesManager');

export class ExchangesManager {
    // Properties

    /**
     * A private instance of user auth module to manage user exchanges.
     */
    private userAuth: IUserAuth;

    // Constructor

    public constructor(userAuth: IUserAuth) {
        this.userAuth = userAuth;
    }

    // Methods

    /**
     * Method to provide the exchanges to the service in order to scan it for the assets.
     * @param options - contain the ID of the exchange to get the addresses from, as well as
     * the parameters for the exchange.
     * @returns founded assets for the provided exchanges.
     */
    public async provideExchange({
        exchange,
        parameters,
        prepareToAddExchange,
    }: ProvideExchangesOptions): Promise<Assets<ExchangeAssetInfo>> {
        // Prepare to add an exchange if needed
        if (prepareToAddExchange) {
            await prepareToAddExchange();
        }

        // Make a request to provide an address
        const response = await this.userAuth.sendRequest<
            ExchangesProvideRequestParameters,
            ExchangesProvideRequestResponse
        >({
            path: ExchangesRequestsPath.Provide,
            params: {
                exchange,
                ...parameters,
            },
        });

        // Check for errors in response
        if ('error' in response) {
            const { error, errorCode } = response;

            // Map the "ExchangeSourceIsAlreadyPresent" error
            if (errorCode === 305) {
                throw AssetsManagerError.ExchangeSourceIsAlreadyPresent;
            }

            // TODO: process the rest options of `errorCode` if needed
            log.error('Failed to add the exchange:', exchange, response);
            throw new Error(error);
        }

        // Get the assets from the response
        const { assets } = response;

        // Return added assets
        return assets;
    }

    /**
     * Method to remove all the assets of the user with the given exchange source.
     * @param options - contain the exchange source to remove the assets with.
     * @returns the result of the removal operation.
     */
    public async removeExchange({ sourceId }: RemoveExchangeOptions): Promise<boolean> {
        // Make a request to remove an address
        const response = await this.userAuth.sendRequest<
            ExchangesRemoveRequestParameters,
            ExchangesRemoveRequestResponse
        >({
            path: ExchangesRequestsPath.Remove,
            params: {
                sourceId,
            },
        });

        // Check for errors in response
        if ('error' in response) {
            const { error } = response;
            // TODO: process `errorCode` if needed
            log.error('Failed to remove the exchange with source:', sourceId, response);
            throw new Error(error);
        }

        // Return the result of the removal operation
        return response.removed;
    }
}
