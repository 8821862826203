import { WalletConnectModal } from '@walletconnect/modal';
import { ExplorerCtrl } from '@walletconnect/modal-core';

import { WalletConnectProjectID } from '../../../configs';

import { ProvidersError, chainIDs } from '../../../constants';

import { findAccountsForSession } from '../../../utils';

import type { GetAccountsResponse } from '../../../types';

import type { EstablishSessionWithModalOptions } from './types';

/**
 * A list of previously loaded wallet IDs to be able reloading them if needed.
 */
let previouslyLoadedWalletIds: EstablishSessionWithModalOptions['explorerRecommendedWalletIds'];

/**
 * Function to wait for the recommended wallets change.
 * @param previouslyRecommendedWallets - a list of recommended wallets to wait for the change with.
 * Note: for that matter we could also use `valtio` to subscribe to the ExplorerCtrl state changes,
 * since `valtio` is used internally by the `@walletconnect/modal-core` package.
 */
async function waitForRecommendedWalletsChange(
    previouslyRecommendedWallets: typeof ExplorerCtrl.state.recomendedWallets,
) {
    // Subscribe on the recommended wallets change by comparing it with the previous value
    await new Promise<void>(resolve => {
        function checkIfRecommendedWalletsChanged() {
            // Check if the wallets have changed
            if (ExplorerCtrl.state.recomendedWallets !== previouslyRecommendedWallets) {
                resolve();
                return;
            }

            // Check again in a while
            setTimeout(checkIfRecommendedWalletsChanged, 100);
        }

        // Check if the wallet have changed
        checkIfRecommendedWalletsChanged();
    });
}

/**
 * Method to establish the session with modal.
 * @param options - options to establish the session with.
 * @returns the list of the connected accounts.
 */
export async function establishSessionWithModal({
    provider,
    requiredNamespaces,
    optionalNamespaces,
    explorerRecommendedWalletIds,
}: EstablishSessionWithModalOptions): Promise<GetAccountsResponse> {
    // Remove any previously created wallet connect modal
    const modals = document.getElementsByTagName('wcm-modal');
    for (let i = 0; i < modals.length; i += 1) {
        modals.item(i)!.remove();
    }
    // This resolves a memory-leak issue and removes previously shown cached wallets
    // Note that this problem is internally present in "@walletconnect/modal" library

    // Save the previously loaded recommended wallets
    const previouslyRecommendedWallets = ExplorerCtrl.state.recomendedWallets;

    // Create a new wallet connect modal now
    const walletConnectModal = new WalletConnectModal({
        // Set WalletConnect projectId
        projectId: WalletConnectProjectID,
        // Overlap everything by setting the max safe z-index (works in Safari 3)
        themeVariables: {
            '--wcm-z-index': '16777271',
        },
        // Configure the recommended wallets if specified
        ...(explorerRecommendedWalletIds
            ? { explorerRecommendedWalletIds, explorerExcludedWalletIds: 'ALL' }
            : // The following is required in order to reload all wallets if none are specified
              { explorerRecommendedWalletIds: [], explorerExcludedWalletIds: [] }),
    });

    const [{ uri, approval }] = await Promise.all([
        // Connect to WalletConnect client
        provider.client.connect({
            ...(Object.keys(requiredNamespaces).length > 0 ? { requiredNamespaces } : {}),
            ...(Object.keys(optionalNamespaces).length > 0 ? { optionalNamespaces } : {}),
        }),
        // At the same time wait for the wallets to be loaded if the required wallets have changed
        (async () => {
            if (previouslyLoadedWalletIds !== explorerRecommendedWalletIds) {
                previouslyLoadedWalletIds = explorerRecommendedWalletIds;
                // Subscribe to the recommended wallets change by comparing with the previous ones
                await waitForRecommendedWalletsChange(previouslyRecommendedWallets);
            }
        })(),
    ]);

    // Try to get WalletConnect wallet accounts
    return new Promise((resolve, reject) => {
        // Open the modal if the URI was provided above
        let modalDisposer: (() => void) | undefined;
        if (uri) {
            // Open the modal
            walletConnectModal.openModal({ uri });

            // Subscribe on the modal closing event
            modalDisposer = walletConnectModal.subscribeModal(({ open }) => {
                if (!open) {
                    // No need to listen anymore
                    if (modalDisposer) {
                        modalDisposer();
                    }

                    // Throw an error that the modal was closed
                    reject(ProvidersError.WalletConnect.QRCodeClosed);
                }
            });
        }

        // Create a session now
        (async () => {
            try {
                // Establish the session
                const latestSession = await approval();

                // Get the accounts of the latest session
                const accounts = findAccountsForSession(latestSession, chainIDs);

                // Check if any account is found
                if (!accounts.length) {
                    // Throw if not
                    throw ProvidersError.WalletConnect.NoAccountFound;
                }

                // Resolve the found accounts
                resolve(accounts);
            } catch (error) {
                // Reject an error if any
                reject(error);
            } finally {
                // Unsubscribe from modal events and close it
                modalDisposer?.();
                walletConnectModal?.closeModal();
            }
        })();
    });
}
