import type { Tokens } from '@smartfolly/common.currencies';

import {
    appStorage,
    buildStorageKeyForUser,
    findIndexToInsert,
    Log,
    MemoDocumentLoader,
} from '@smartfolly/common.utilities';
import type { DocumentLoaderListener } from '@smartfolly/common.utilities';

import type {
    HistoricalQueryParameters,
    HistoricalQueryResponse,
    HistoricalSubscriptionResponse,
} from '@smartfolly/server';

import { serverConnector } from '@smartfolly/middleware.server-connector';
import type { DataListenerDisposer } from '@smartfolly/middleware.server-connector';

import { historicalCollection, historicalQuery, historicalSubscription } from '../constants';

import type {
    IHistoricalData,
    HistoricalDataRecord,
    HistoricalDataDisposer,
    HistoricalDataListener,
} from '../types';

const log = new Log('HistoricalData');

const HISTORICAL_DATA_DATA_KEY = 'HistoricalData:Data';

type HistoricalDataOptions = {
    /**
     * An optional ID of the user to work with when dealing with the historical data.
     */
    userId?: string;
};

export class HistoricalData implements IHistoricalData {
    /**
     * Options the HistoricalData module is created with.
     */
    private options: HistoricalDataOptions;

    /**
     * Currently active subscriptions.
     */
    private subscriptions: HistoricalDataListener[] = [];

    /**
     * Historical data Loader instance.
     */
    private historicalDataLoader?: MemoDocumentLoader<HistoricalDataRecord>;

    /**
     * Currently known historical data.
     */
    private historicalData?: HistoricalDataRecord;

    /**
     * An array of GraphQL Historical Data subscriptions disposers.
     */
    private disposers?: DataListenerDisposer[];

    // Constructor

    public constructor(options: HistoricalDataOptions) {
        this.options = options;
    }

    // Interface

    public get(): HistoricalDataRecord | undefined {
        return this.historicalData;
    }

    public subscribe(
        tokens: Tokens[],
        historicalDataListener: HistoricalDataListener,
    ): HistoricalDataDisposer {
        // Add the listener to active subscriptions
        this.subscriptions.push(historicalDataListener);

        // Ensure to create a historical data loader
        if (!this.historicalDataLoader) {
            this.historicalDataLoader = new MemoDocumentLoader<HistoricalDataRecord>({
                key: this.options.userId
                    ? buildStorageKeyForUser(HISTORICAL_DATA_DATA_KEY, this.options.userId)
                    : HISTORICAL_DATA_DATA_KEY,
                storage: appStorage,
                documentFetcher: (listener: DocumentLoaderListener<HistoricalDataRecord>) => {
                    // Create a function to fetch the historical data
                    const fetchHistoricalData = async () => {
                        try {
                            // Get the Historical Data for the specified tokens via GraphQL query
                            const response = await serverConnector.query<
                                HistoricalQueryParameters,
                                HistoricalQueryResponse
                            >({
                                collection: historicalCollection,
                                query: historicalQuery,
                                variables: {
                                    filter: {
                                        tokens,
                                    },
                                },
                            });

                            // Check for errors in response
                            if ('error' in response) {
                                const { error } = response;

                                // Create a error instance
                                const fetchError = new Error(error);

                                // Return the error to the listener
                                listener(fetchError);

                                // And throw it to catch and log bellow
                                throw fetchError;
                            }

                            // Process and resolve the historical data
                            const data = response.reduce<HistoricalDataRecord>((acc, item) => {
                                acc[item.token] = item.data;
                                return acc;
                            }, {});

                            listener(undefined, data);
                        } catch (error) {
                            log.error('Failed to fetch historical data with error:', error);

                            // Return the subscription error to the listener
                            listener(error);
                        }
                    };

                    // Fetch historical data
                    fetchHistoricalData();

                    // Create Historical Data subscriptions and store them in the disposers array
                    this.disposers = tokens.map(token => {
                        return serverConnector.subscribe<
                            { token: string },
                            HistoricalSubscriptionResponse
                        >(
                            {
                                collection: historicalCollection,
                                query: historicalSubscription,
                                variables: {
                                    token,
                                },
                            },
                            (error?: Error, data?: HistoricalSubscriptionResponse | null) => {
                                if (error) {
                                    log.error(
                                        `Failed to get ${token} data by subscription with error:`,
                                        error,
                                    );
                                    listener(error);
                                }

                                if (data) {
                                    // Update and resolve the historical data

                                    // Get the historical token data list for the token
                                    let tokenHistoricalDataList = this.historicalData?.[data.token];
                                    if (!tokenHistoricalDataList) {
                                        tokenHistoricalDataList = [];
                                    }

                                    // Update or push the newly received historical token data
                                    const index = tokenHistoricalDataList.findIndex(
                                        tokenHistoricalData =>
                                            tokenHistoricalData.date === data.date,
                                    );
                                    if (index >= 0) {
                                        // Update the token historical data
                                        tokenHistoricalDataList[index] = data;
                                    } else {
                                        // Push the newly received historical token data
                                        const indexToInsert = findIndexToInsert(
                                            tokenHistoricalDataList,
                                            data,
                                            (a, b) => {
                                                // Sort the data by the date in the ascending order
                                                return a.date.localeCompare(b.date);
                                            },
                                        );
                                        tokenHistoricalDataList.splice(indexToInsert, 0, data);
                                    }

                                    const updatedData = {
                                        ...this.historicalData,
                                        [data.token]: tokenHistoricalDataList,
                                    };
                                    listener(undefined, updatedData);
                                }
                            },
                        );
                    });
                },
                stopDocumentFetching: () => {
                    // Dispose all the created Historical Data subscriptions
                    if (this.disposers) {
                        this.disposers.forEach(disposer => disposer());
                        delete this.disposers;
                    }
                },
            });

            // Subscribe on the historical data
            this.historicalDataLoader.subscribe(
                (error: Error | undefined, data?: HistoricalDataRecord) => {
                    if (error) {
                        log.error('Failed to fetch historical data with error:', error);
                        this.broadcastDataToListeners(error);
                        return;
                    }

                    if (!data) {
                        const noDataError = new Error('No historical data has been loaded');
                        log.error(noDataError);
                        this.broadcastDataToListeners(noDataError);
                        return;
                    }

                    this.historicalData = data;
                    this.broadcastDataToListeners(undefined, data);
                },
            );
        }

        // Return a disposer
        return () => {
            // Remove the current listener from the subscriptions
            const index = this.subscriptions.indexOf(historicalDataListener);
            if (index !== 1) {
                this.subscriptions.splice(index, 1);
            } else {
                // Normally should never happen, since the listener must be removed only once
                log.error('Failed to remove an already removed listener from the subscriptions');
            }

            // Check if the subscriptions are present
            // Stop listening to the historical data if no
            if (!this.subscriptions.length) {
                if (this.historicalDataLoader) {
                    this.historicalDataLoader.unsubscribe();
                    delete this.historicalDataLoader;
                } else {
                    // Normally should never happen, since the loader should be destroyed only once
                    // when the last subscription is removed
                    log.error('Failed to unsubscribe from already removed historical data loader');
                }
            }
        };
    }

    // Internals

    /**
     * Method to broadcast the data changes from the Historical Data loader
     * to the subscribed listeners.
     * @param error - a error to broadcast if any.
     * @param data - a historical data to broadcast if any.
     */
    private broadcastDataToListeners(error: Error | undefined, data?: HistoricalDataRecord) {
        this.subscriptions.forEach(listener => listener(error, data));
    }
}
