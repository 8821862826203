import {
    action,
    computed,
    IReactionDisposer,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from 'mobx';
import { computedFn } from 'mobx-utils';

import { appStorage, buildStorageKeyForUser, CommonStore, Log } from '@smartfolly/common.utilities';

import { MarketCap } from '@smartfolly/sdk';
import type { ExchangeCurrency, MarketCapData, MarketCapDisposer } from '@smartfolly/sdk';

import type { IAuthService } from '@smartfolly/frontend.auth-service';

import { currenciesService, CurrencySymbols } from '@smartfolly/frontend.currencies-service';
import type { Tokens } from '@smartfolly/frontend.currencies-service';

import { buildAssetToken, enrichAssetsWithMarketCapData } from '../../utils';

import type { AssetsMap, Token } from '../../types';

import { DEFAULT_EXCHANGE_CURRENCY, EXCHANGE_CURRENCY_KEY } from '../constants';

import type {
    BlockchainAssetWithAddressData,
    ExchangeAssetWithExchangeData,
    IRatesStore,
} from '../types';

const log = new Log('AssetsService:Rates');

type RatesStoreOptions =
    | {
          /**
           * An instance of AuthService with the user to work with when dealing with the assets.
           * Note: the passed service MUST be loaded and initialized, i.e. be ready to work with.
           */
          authService: IAuthService;
      }
    | {
          /**
           * An ID of the user to work with when dealing with the assets.
           */
          userId: string;
      };

export class RatesStore extends CommonStore implements IRatesStore {
    // Properties

    /**
     * Options the store is created with.
     */
    private options: RatesStoreOptions;

    /**
     * A list of tokens to listen the rates data for.
     */
    private listenedTokens: Tokens[] = [];

    /**
     * A private instance of the MarketCap module from SDK.
     * Note: we use a new instance for each rates store as we can have several RatesStore instances
     * for different users, e.g. when creating several AssetsService instances.
     */
    private marketCap: MarketCap;

    /**
     * An observable private instance of the market cap data.
     */
    private marketCapData: MarketCapData | undefined = undefined;

    /**
     * A market cap subscription disposer.
     */
    private marketCapDisposer?: MarketCapDisposer;

    /**
     * An observable private instance of the selected exchange currency.
     * Note: `USD` by default.
     */
    private exchangeCurrency: ExchangeCurrency = DEFAULT_EXCHANGE_CURRENCY;

    /**
     * A disposer for the listened tokens MobX reaction.
     */
    private tokensReactionDisposer?: IReactionDisposer;

    // Constructor

    public constructor(options: RatesStoreOptions) {
        super();

        this.options = options;

        // Create the MarketCap module instance once the options are set
        const { userId } = this;
        this.marketCap = new MarketCap(userId ? { userId } : {});

        makeObservable<RatesStore, 'marketCapData' | 'exchangeCurrency' | 'listenedTokens'>(this, {
            marketCapData: observable,
            exchangeCurrency: observable,
            selectedExchangeCurrency: computed,
            listenedTokens: observable,
            selectListenedTokens: action,
        });
    }

    // Interface

    protected onLoad = async () => {
        // Load the selected exchange currency from the storage first
        await this.loadSelectedExchangeCurrency();

        // Load the market cap data then
        this.loadMarketCapData();

        // Create a reaction to reload the rates data once the list of listened tokens changes
        this.tokensReactionDisposer = reaction(
            () => this.listenedTokens,
            () => this.reloadMarketCapData(),
            {
                name: 'a reaction to reload the rates data once the listened tokens list changes',
            },
        );
    };

    protected onUnload = async () => {
        // Dispose a reaction for the listened tokens
        if (this.tokensReactionDisposer) {
            this.tokensReactionDisposer();
            delete this.tokensReactionDisposer;
        }

        // Unload the market cap data
        this.unloadMarketCapData();

        // Then deselect the exchange currency
        this.unloadSelectedExchangeCurrency();
    };

    // eslint-disable-next-line class-methods-use-this
    public get availableExchangeCurrencies(): ExchangeCurrency[] {
        return Object.keys(CurrencySymbols) as ExchangeCurrency[];
    }

    public get selectedExchangeCurrency(): ExchangeCurrency {
        return this.exchangeCurrency;
    }

    /**
     * Private setter to select an exchange currency
     * in order to calculate the price of the assets with.
     * @param exchangeCurrency - an exchange currency to change.
     */
    private set selectedExchangeCurrency(exchangeCurrency: ExchangeCurrency) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.exchangeCurrency = exchangeCurrency;
    }

    public selectListenedTokens(tokens: Tokens[]) {
        this.listenedTokens = tokens;
    }

    public enrichAssets = computedFn(
        (assets: (BlockchainAssetWithAddressData | ExchangeAssetWithExchangeData)[]): AssetsMap => {
            const { marketCapData, selectedExchangeCurrency } = this;

            return enrichAssetsWithMarketCapData(assets, {
                marketCapData,
                currency: selectedExchangeCurrency,
                tokensData: currenciesService.tokensData,
            });
        },
    );

    public enrichTokens = computedFn((tokens: Tokens[]): Token[] => {
        const { selectedExchangeCurrency: currency } = this;

        return tokens.map(token => {
            // Build a token
            let assetToken = buildAssetToken({
                token,
                currency,
                tokensData: currenciesService.tokensData,
            });

            // Check if the market cap data is present
            if (this.marketCapData) {
                // Get the market cap data for the token
                const tokenMarketCapData = this.marketCapData[token];

                // Check if this data is present
                if (tokenMarketCapData) {
                    // Get a map of rates for the token
                    const { quote } = tokenMarketCapData;

                    // Get an exchange rate for the selected exchange currency
                    const exchangeRate = quote[currency];
                    if (!exchangeRate) {
                        log.error(
                            'Failed to get an exchange rate for the selected exchange currency',
                            currency,
                        );
                    } else {
                        // Rebuild the token with the known `exchangeRate`
                        assetToken = buildAssetToken({
                            token,
                            currency,
                            exchangeRate,
                            tokensData: currenciesService.tokensData,
                        });
                    }
                }
            }

            return assetToken;
        });
    });

    public selectExchangeCurrency = async (exchangeCurrency: ExchangeCurrency) => {
        // Apply MobX action to select the exchange currency
        this.selectedExchangeCurrency = exchangeCurrency;

        // Save it to the storage
        try {
            await this.saveSelectedExchangeCurrency(exchangeCurrency);
        } catch (error) {
            // Note: it's OK to fail saving the exchange currency
            // as it doesn't affect the service workflow.
            log.error('Failed to save the selected exchange currency with error:', error);
        }
    };

    // Internals

    /**
     * Method to renew the rates data subscriptions.
     * Note: should be used when the list of listened tokens has been changed.
     */
    private reloadMarketCapData = () => {
        this.unloadMarketCapData();
        this.loadMarketCapData();
    };

    /**
     * Method to load market cap data using memo loader.
     * @throws a variety of errors if the user is not authorized or data is already loading.
     */
    private loadMarketCapData() {
        // Check if the market cap data subscription already exist to avoid loading it twice
        if (this.marketCapDisposer) {
            throw new Error('Market cap data is already loading');
        }

        // No need to start load the market cap data if there are no listened tokens
        if (this.listenedTokens.length === 0) {
            return;
        }

        // Note: no need to use memo loader to load the market cap data,
        // since it's already memoized inside of `marketCap` module of SDK.

        // Get the market cap data in case it's already known
        const knownData = this.marketCap.get();

        // Resolve if any market cap data is present
        if (knownData) {
            runInAction(() => {
                this.marketCapData = knownData;
            });
        }

        // Subscribe on the market cap data changes for the given list of tokens
        this.marketCapDisposer = this.marketCap.subscribe(
            this.listenedTokens,
            (error: Error | undefined, data?: MarketCapData) => {
                if (error) {
                    log.error('Failed to fetch the market cap data with error:', error);
                    return;
                }

                if (!data) {
                    log.error('No market cap data has been loaded');
                    return;
                }

                runInAction(() => {
                    // The received market cap data might be partial and include only some tokens,
                    // hereby we need to update the market cap data for received tokens only
                    this.marketCapData = {
                        ...this.marketCapData,
                        ...data,
                    };
                });
            },
        );
    }

    /**
     * Method to unload the market cap data as well as unsubscribing from the corresponding loader.
     */
    private unloadMarketCapData() {
        // Dispose the market cap subscription
        if (this.marketCapDisposer) {
            this.marketCapDisposer();

            // And delete the disposer itself
            delete this.marketCapDisposer;
        }

        // Delete the market cap data
        runInAction(() => {
            this.marketCapData = undefined;
        });
    }

    /**
     * Method to load the selected exchange currency from the storage.
     */
    private async loadSelectedExchangeCurrency() {
        // Get a storage key to keep the selected exchange currency
        // Note: could throw if the user is not authorized
        const storageKey = this.getStorageKey(EXCHANGE_CURRENCY_KEY);

        // Get a stored exchange currency value
        const exchangeCurrency = await appStorage.getItem(storageKey);

        // Set the selected exchange currency to the stored one if present.
        if (exchangeCurrency) {
            this.selectedExchangeCurrency = exchangeCurrency as ExchangeCurrency;
        }
    }

    /**
     * Method to unload the selected exchange currency to its default state.
     */
    private unloadSelectedExchangeCurrency() {
        this.selectedExchangeCurrency = DEFAULT_EXCHANGE_CURRENCY;
    }

    /**
     * Method to save the selected exchange currency value in the storage.
     * @param exchangeCurrency - a selected exchange currency value to store.
     */
    // eslint-disable-next-line class-methods-use-this
    private async saveSelectedExchangeCurrency(exchangeCurrency: ExchangeCurrency) {
        // Get a storage key to keep the selected exchange currency
        // Note: could throw if the user is not authorized
        const storageKey = this.getStorageKey(EXCHANGE_CURRENCY_KEY);

        // Save the selected exchange currency into the storage
        await appStorage.setItem(storageKey, exchangeCurrency);
    }

    /**
     * A getter for an ID of the user whose market cap data is loaded by the module instance.
     */
    private get userId(): string | undefined {
        // Check if the userId was passed independently or indirectly with the AuthService instance
        return 'userId' in this.options
            ? this.options.userId
            : this.options.authService.session?.userId;
    }

    /**
     * Method to get a storage key for the current user and a specified key.
     * @param key - a provided key to get the storage key for.
     * @returns the key bound to the current user.
     */
    private getStorageKey(key: string): string {
        if ('userId' in this.options) {
            return buildStorageKeyForUser(key, this.options.userId);
        }

        return this.options.authService.getStorageKey(key);
    }
}
