import detectEthereumProvider from '@metamask/detect-provider';
import type { MetaMaskInpageProvider } from '@metamask/providers';

import { ProvidersError } from '../constants';

/**
 * Function to check if MetaMask Extension provider is present
 * @returns a MetaMask Extension provider instance
 * @throws an error if there is no MetaMask Extension provider
 */
export async function checkForEthereumProvider(): Promise<MetaMaskInpageProvider> {
    const provider = (await detectEthereumProvider()) as MetaMaskInpageProvider | null;
    if (!provider) {
        throw ProvidersError.MetaMaskExt.NoProviderFound;
    }

    return provider;
}
