import { Bech32Encodings, bech32Decode, bech32Encode, convertBits } from '../../common';

import type { ValidatingBlockchain } from './types';

function decode(hrp: string, addr: string) {
    let bech32m = false;
    let dec = bech32Decode(addr, Bech32Encodings.BECH32);
    if (dec === null) {
        dec = bech32Decode(addr, Bech32Encodings.BECH32M);
        bech32m = true;
    }
    if (dec === null || dec.hrp !== hrp || dec.data.length < 1 || dec.data[0]! > 16) {
        return null;
    }
    const res = convertBits(dec.data.slice(1), 5, 8, false);
    if (res === null || res.length < 2 || res.length > 40) {
        return null;
    }
    if (dec.data[0] === 0 && res.length !== 20 && res.length !== 32) {
        return null;
    }
    if (dec.data[0] === 0 && bech32m) {
        return null;
    }
    if (dec.data[0] !== 0 && !bech32m) {
        return null;
    }
    return { version: dec.data[0]!, program: res };
}

function encode(hrp: string, version: number, program: Buffer) {
    let enc = Bech32Encodings.BECH32;
    if (version > 0) {
        enc = Bech32Encodings.BECH32M;
    }
    const bits = convertBits(program, 8, 5, true);
    if (bits === null) {
        return null;
    }
    const ret = bech32Encode(hrp, Buffer.concat([Buffer.from([version]), bits]), enc);
    if (decode(hrp, ret) === null) {
        return null;
    }
    return ret;
}

export function isValidSegwitAddress(address: string, blockchain: ValidatingBlockchain) {
    if (!blockchain.bech32Hrp || !blockchain.bech32Hrp.length) {
        return false;
    }

    const hrp = blockchain.bech32Hrp.find(chrp => {
        const ret = decode(chrp, address);
        if (ret) {
            return encode(chrp, ret.version, ret.program) === address.toLowerCase();
        }
        return false;
    });
    if (!hrp) {
        return false;
    }

    return true;
}
