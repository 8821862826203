import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';

import { SolanaProvider } from '../helpers';

import type { IProvider } from '../types';

export class SolflareProvider
    extends SolanaProvider<'Solflare', SolflareWalletAdapter>
    implements IProvider<'Solflare'>
{
    public constructor() {
        super('Solflare', new SolflareWalletAdapter());
    }
}
