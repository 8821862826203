import type { ResponseError } from '@smartfolly/server';
import type { ParametersShape, SendRequestOptions } from '../types';

/**
 * Method to send any POST requests
 * @param options - contain the request path, optional POST-parameters and a server host.
 * @returns an expected response of the generic type.
 */
export async function sendPOSTRequest<Parameters extends ParametersShape, Response>({
    host,
    params,
    path,
}: {
    /**
     * A host of the server to send a request.
     */
    host: string;
} & SendRequestOptions<Parameters>): Promise<Response | ResponseError> {
    // Build the request to send
    const request: RequestInit = {
        body: JSON.stringify(params),
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        mode: 'cors',
    };

    // Fetch
    const response = await fetch(`${host}/${path}`, request);

    // Return a JSON response of the generic type
    return response.json();
}
