import {
    ethereumMainnetChain,
    GetAccountsResponse,
    ProviderInfo,
    ProvidersError,
    SignMessageOptions,
    SignMessageResponse,
    WalletConnectProvider as Provider,
} from '@smartfolly/common.providers';

import { AuthUserError } from '../../../constants';

import type { IAuthProvider } from './types';

type Func<T, P> = (...args: P[]) => Promise<T>;
type WrappedFunc<T, P> = Func<T, P>;

const withErrorsMapperWrapper =
    <T, P, C>(fn: Func<T, P>, context: C): WrappedFunc<T, P> =>
    async (...args: P[]) => {
        try {
            // Run the original function
            return await fn.bind(context)(...args);
        } catch (error) {
            // Map the errors
            if (error === ProvidersError.WalletConnect.AccountUnrecognized) {
                // Note: in our current implementation if any account is unrecognized,
                // which should normally not happen, then no account is returned, hence
                // we may consider it as no any account found error
                throw AuthUserError.Providers.WalletConnect.NoAccountFound;
            } else if (error === ProvidersError.WalletConnect.QRCodeClosed) {
                throw AuthUserError.Providers.WalletConnect.QRCodeClosed;
            } else if (error === ProvidersError.WalletConnect.NoAccountFound) {
                throw AuthUserError.Providers.WalletConnect.NoAccountFound;
            } else if (error === ProvidersError.WalletConnect.NotConnected) {
                throw AuthUserError.Providers.WalletConnect.NotConnected;
            } else if (error === ProvidersError.WalletConnect.NoSignatureMade) {
                throw AuthUserError.Providers.WalletConnect.NoSignatureMade;
            } else {
                throw error;
            }
        }
    };

export class WalletConnectProvider implements IAuthProvider<'WalletConnect'> {
    // Properties

    /**
     * A private instance of WalletConnect provider.
     * Note: Require Ethereum in order to Authorize the user (with SECP256k1 keys).
     */
    private walletConnectProvider: Provider;

    // Constructor

    public constructor(explorerRecommendedWalletIds?: string[]) {
        this.walletConnectProvider = new Provider({
            requireEthereum: true,
            ...(explorerRecommendedWalletIds ? { explorerRecommendedWalletIds } : {}),
        });
    }

    // Interface

    public async checkIfAvailable(): Promise<boolean> {
        return withErrorsMapperWrapper(
            this.walletConnectProvider.checkIfAvailable,
            this.walletConnectProvider,
        )();
    }

    public async disconnect(): Promise<void> {
        return withErrorsMapperWrapper(
            this.walletConnectProvider.disconnect,
            this.walletConnectProvider,
        )();
    }

    public async getAccounts(): Promise<GetAccountsResponse> {
        // Get the accounts
        const accounts = await withErrorsMapperWrapper(
            this.walletConnectProvider.getAccounts,
            this.walletConnectProvider,
        )();

        // Note: for the auth purpose we need to deal with the ETH-account,
        // we use SECP256k1 keys (used by Ethereum) to sign messages.
        // Hence check if any ETH-account present
        const ethAccount = accounts.find(({ chainId }) => chainId === ethereumMainnetChain.id);
        if (!ethAccount) {
            // Throw an error if not
            throw AuthUserError.NoEthAccount;
        }

        // Return the found accounts
        return accounts;
    }

    public async getInfo(): Promise<ProviderInfo<'WalletConnect'>> {
        return withErrorsMapperWrapper(
            this.walletConnectProvider.getInfo,
            this.walletConnectProvider,
        )();
    }

    public async signMessage(options: SignMessageOptions): Promise<SignMessageResponse> {
        return withErrorsMapperWrapper(
            this.walletConnectProvider.signMessage,
            this.walletConnectProvider,
        )(options);
    }
}

export const walletConnectProvider = new WalletConnectProvider();
