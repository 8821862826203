// Inspired by https://github.com/christsim/multicoin-address-validator

import base58 from 'bs58';
import { hash } from '@stablelib/sha256';

import type { ValidatingBlockchain } from './types';

function decodeAddress(address: string) {
    try {
        return base58.decode(address);
    } catch (e) {
        // If decoding fails assume it's an invalid address
        return undefined;
    }
}

function toHex(array: Uint8Array): string {
    return Buffer.from(array).toString('hex');
}

function sha256Checksum(array: Uint8Array): string {
    return toHex(hash(hash(array))).substr(0, 8);
}

function getAddressType(address: string) {
    // Note: the following consts can be configured for some blockchains if needed,
    // meanwhile they suit BTC and DOGE blockchains
    const expectedLength = 25; // 25 bytes as per btc address spec

    const decoded = decodeAddress(address);
    if (!decoded) {
        return undefined;
    }

    const { length } = decoded;
    if (length !== expectedLength) {
        return undefined;
    }

    const body = decoded.slice(0, length - 4);
    const checksum = decoded.slice(length - 4, length);
    if (toHex(checksum) !== sha256Checksum(body)) {
        return undefined;
    }

    return toHex(decoded.slice(0, expectedLength - 24));
}

// P2PKH addresses (https://learnmeabitcoin.com/technical/p2pkh)
// P2PSH (BIP-16) addresses (https://learnmeabitcoin.com/technical/p2sh)
export function isValidP2PKHandP2SHAddress(address: string, blockchain: ValidatingBlockchain) {
    if (!blockchain.addressTypes || !blockchain.addressTypes.length) {
        return false;
    }

    const addressType = getAddressType(address);
    if (!addressType) {
        return false;
    }

    if (blockchain.addressTypes.indexOf(addressType) === -1) {
        return false;
    }

    return true;
}
