import {
    GetAccountsResponse,
    TelegramBotProvider as Provider,
    ProviderInfo,
    ProvidersError,
    SignMessageOptions,
    SignMessageResponse,
} from '@smartfolly/common.providers';

import { AuthUserError } from '../../../constants';

import type { IAuthProvider } from './types';

type Func<T, P> = (...args: P[]) => Promise<T>;
type WrappedFunc<T, P> = Func<T, P>;

const withErrorsMapperWrapper =
    <T, P, C>(fn: Func<T, P>, context: C): WrappedFunc<T, P> =>
    async (...args: P[]) => {
        try {
            // Run the original function
            return await fn.bind(context)(...args);
        } catch (error) {
            // Map the errors
            if (error === ProvidersError.TelegramBot.NoCryptoKeys) {
                throw AuthUserError.Providers.TelegramBot.NoCryptoKeys;
            } else if (error === ProvidersError.TelegramBot.NoSignatureMade) {
                throw AuthUserError.Providers.TelegramBot.NoSignatureMade;
            } else if (error === ProvidersError.TelegramBot.WrongCryptoKeys) {
                throw AuthUserError.Providers.TelegramBot.WrongCryptoKeys;
            } else {
                throw error;
            }
        }
    };

class TelegramBotProvider implements IAuthProvider<'TelegramBot'> {
    // Properties

    /**
     * A private instance of TelegramBot provider
     */
    private telegramBotProvider = new Provider();

    // Interface

    public async checkIfAvailable(): Promise<boolean> {
        return withErrorsMapperWrapper(
            this.telegramBotProvider.checkIfAvailable,
            this.telegramBotProvider,
        )();
    }

    public async disconnect(): Promise<void> {
        return withErrorsMapperWrapper(
            this.telegramBotProvider.disconnect,
            this.telegramBotProvider,
        )();
    }

    public async getAccounts(): Promise<GetAccountsResponse> {
        return withErrorsMapperWrapper(
            this.telegramBotProvider.getAccounts,
            this.telegramBotProvider,
        )();
    }

    public async getInfo(): Promise<ProviderInfo<'TelegramBot'>> {
        return withErrorsMapperWrapper(
            this.telegramBotProvider.getInfo,
            this.telegramBotProvider,
        )();
    }

    public async signMessage(options: SignMessageOptions): Promise<SignMessageResponse> {
        return withErrorsMapperWrapper(
            this.telegramBotProvider.signMessage,
            this.telegramBotProvider,
        )(options);
    }
}

export const telegramBotProvider = new TelegramBotProvider();
