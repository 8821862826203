import { computed, makeObservable } from 'mobx';

import { stablecoinsStore, tokensDataStore, tokensStore, symbolTokensStore } from './stores';

import type {
    TokensMap,
    ITokensStore,
    ICurrenciesStore,
    IStablecoinsStore,
    StablecoinsMap,
    ITokensDataStore,
    TokensDataMap,
    ISymbolTokensStore,
    SymbolTokensMap,
} from './types';

class CurrenciesService implements ICurrenciesStore {
    private tokensStore: ITokensStore = tokensStore;

    private tokensDataStore: ITokensDataStore = tokensDataStore;

    private stablecoinsStore: IStablecoinsStore = stablecoinsStore;

    private symbolTokensStore: ISymbolTokensStore = symbolTokensStore;

    public constructor() {
        makeObservable(this, {
            tokens: computed,
            tokensData: computed,
            stablecoins: computed,
        });
    }

    public get tokens(): TokensMap {
        return this.tokensStore.tokens;
    }

    public get tokensData(): TokensDataMap {
        return this.tokensDataStore.tokensData;
    }

    public get stablecoins(): StablecoinsMap {
        return this.stablecoinsStore.stablecoins;
    }

    public get symbolTokens(): SymbolTokensMap {
        return this.symbolTokensStore.symbolTokens;
    }

    public async load() {
        await Promise.all([
            this.tokensStore.load(),
            this.tokensDataStore.load(),
            this.stablecoinsStore.load(),
            // this.symbolTokensStore.load(), no need for now
        ]);
    }
}

export const currenciesService = new CurrenciesService();
