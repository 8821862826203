import type { UsersQueryParameters, UsersQueryResponse } from '@smartfolly/server';

import { Log } from '@smartfolly/common.utilities';

import { serverConnector } from '@smartfolly/middleware.server-connector';

import { usersCollection, usersInfoQuery } from '../../constants';

import type { UserInfo } from '../../types';

const log = new Log('user-manager:queryUserInfo');

/**
 * A function to query the user info.
 * @param userId - an ID of the user to query the info for.
 * @returns the user info.
 */
export async function queryUserInfo(userId: string): Promise<UserInfo> {
    const response = await serverConnector.query<
        UsersQueryParameters,
        UsersQueryResponse<'authProvider' | 'services'>
    >({
        collection: usersCollection,
        query: usersInfoQuery,
        variables: {
            users: [userId],
        },
    });

    // Check for errors in response
    if ('error' in response) {
        const { error } = response;
        // TODO: process `errorCode` if needed
        log.error('Failed to get the user info:', response);
        throw new Error(error);
    }

    // Check if the response has users
    if (response.length === 0) {
        const error = new Error('No user found');
        log.error('Failed to get the user info:', error);
        throw error;
    }

    // Pick the first user (should be the only one for the "userId")
    const user = response[0]!;

    // Return the found user info
    return user;
}
