import classNames from 'classnames/bind';
import { memo } from 'react';

import { FlexContainer, Flex, Icon } from '@smartfolly/frontend.web-ui';
import styles from './Showcase.module.scss';
import { BlockchainLandingIcon } from '../BlockchainLandingIcon';

const cnb = classNames.bind(styles);

export const Showcase = memo(function Showcase({
    blockchain,
    name,
    tokenName,
}: {
    blockchain: string | undefined;
    name: string | undefined;
    tokenName: string | undefined;
}) {
    return (
        <div className={`${cnb('showcase')}`}>
            <div className={cnb('block', 'one')}>
                <FlexContainer>
                    <Flex className="action action-large">
                        Now{' '}
                        <BlockchainLandingIcon
                            icon={`${blockchain}-big` ?? 'default-blockchain'}
                            className="color-blockchain m-r-0.25 m-l-0.25"
                        />{' '}
                        and <span className={cnb('blockchain-number')}>20</span> blockchains
                    </Flex>
                </FlexContainer>
            </div>
            <div className={cnb('block', 'two')}>
                <FlexContainer direction="column">
                    <Flex>
                        <BlockchainLandingIcon icon="responsive-interface" />
                    </Flex>
                    <Flex className="action action-special m-t-0.75">Responsive interface</Flex>
                </FlexContainer>
            </div>
            <div className={cnb('block', 'three')}>
                <FlexContainer direction="column">
                    <Flex className="action action-medium m-b-2">
                        <Icon icon="add-plus" />
                        <div className="m-t-0.25">Add</div>
                    </Flex>
                    <Flex className="action action-medium m-b-2">
                        <Icon icon="filter-alt" />
                        <div className="m-t-0.25">Group</div>
                    </Flex>
                    <Flex className="action action-medium m-b-2">
                        <Icon icon="monitor-off" />
                        <div className="m-t-0.25">View</div>
                    </Flex>
                    <Flex className="action action-medium m-b-2">
                        <Icon icon="refresh" />
                        <div className="m-t-0.25">Repeat</div>
                    </Flex>
                    <Flex className="action action-tiny color-text-tertiary">
                        Simple steps
                        <br /> to get insights
                    </Flex>
                </FlexContainer>
            </div>
            <div className={cnb('block', 'four')}>
                <FlexContainer direction="column">
                    <Flex className={`action action-large ${cnb('strikethrough')}`}>Sign in</Flex>
                    <Flex className={`action action-large ${cnb('strikethrough')}`}>
                        Registration
                    </Flex>
                    <Flex className={`action action-large ${cnb('strikethrough')}`}>
                        Private key
                    </Flex>
                    <Flex className={`action action-large ${cnb('strikethrough')}`}>
                        Seed phrase
                    </Flex>
                    <Flex className="action action-large">
                        <span className={cnb('udnerline')}>only</span> Address
                        <span className="color-blockchain">.</span>
                    </Flex>
                </FlexContainer>
            </div>
            <div className={cnb('block', 'five')}>
                <FlexContainer direction="column" className="text-left">
                    <Flex
                        className={`back-secondary m-b-0.5 b-r-3 p-t-0.75 p-b-0.75 p-l-0.75 p-r-0.75 ${cnb(
                            'move-top',
                        )}`}
                    >
                        <FlexContainer align="center">
                            <Flex grow={1} className="title title-normal m-r-0.5">
                                Total Board
                            </Flex>
                            <Flex className="paragraph paragraph-normal m-r-0.5">$8,342.82</Flex>
                            <Flex>
                                <div className="back-tertiary p-1 px-2 b-r-2">
                                    <span className="mono mono-small color-blockchain">
                                        ↑ 3.12 %
                                    </span>
                                </div>
                            </Flex>
                        </FlexContainer>
                    </Flex>
                    <Flex className="back-secondary m-b-0.5 b-r-3 p-t-0.75 p-b-0.75 p-l-0.75 p-r-0.75">
                        <FlexContainer align="center">
                            <Flex grow={1} className="title title-normal m-r-0.5">
                                {name}
                            </Flex>
                            <Flex className="paragraph paragraph-normal m-r-0.5">$4,580.33</Flex>
                            <Flex>
                                <div className="back-tertiary p-1 px-2 b-r-2">
                                    <span className="mono mono-small color-blockchain">
                                        ↑ 2.76 %
                                    </span>
                                </div>
                            </Flex>
                        </FlexContainer>
                    </Flex>
                    <Flex
                        className={`back-secondary b-r-3 p-t-0.75 p-b-0.75 p-l-0.75 p-r-0.75 ${cnb(
                            'move-bottom',
                        )}`}
                    >
                        <FlexContainer align="center">
                            <Flex grow={1} className="title title-normal m-r-0.5">
                                Stablecoins
                            </Flex>
                            <Flex className="paragraph paragraph-normal m-r-0.5">$2,580.62</Flex>
                            <Flex>
                                <div className="back-tertiary p-1 px-2 b-r-2">
                                    <span className="mono mono-small color-blockchain">
                                        ↑ 0.49 %
                                    </span>
                                </div>
                            </Flex>
                        </FlexContainer>
                    </Flex>
                </FlexContainer>
            </div>
            <div className={cnb('block', 'six', 'start')}>
                <FlexContainer align="center" justify="space-between">
                    <Flex>
                        <BlockchainLandingIcon icon="half-pie" />
                    </Flex>
                    <Flex className="m-l-1.5 text-left">
                        <div
                            className={`paragraph paragraph-small p-1 text-nowrap ${cnb('bullet')}`}
                        >
                            12.52% {tokenName}
                        </div>
                        <div
                            className={`paragraph paragraph-small p-1 text-nowrap ${cnb('bullet')}`}
                        >
                            12.26% ETH
                        </div>
                        <div
                            className={`paragraph paragraph-small p-1 text-nowrap ${cnb('bullet')}`}
                        >
                            10.63% TWT
                        </div>
                        <div
                            className={`paragraph paragraph-small p-1 text-nowrap ${cnb('bullet')}`}
                        >
                            10.62% BTC
                        </div>
                    </Flex>
                </FlexContainer>
            </div>
            <div className={cnb('block', 'seven')}>
                <FlexContainer direction="column">
                    <Flex>
                        <BlockchainLandingIcon icon="private-secure" />
                    </Flex>
                    <Flex className="action action-default m-t-0.75">
                        Private
                        <br /> & Secure
                    </Flex>
                </FlexContainer>
            </div>
            <div className={cnb('block', 'eight')}>
                <FlexContainer direction="column">
                    <Flex>
                        <BlockchainLandingIcon icon="pulse" className="w-100" />
                    </Flex>
                    <Flex className="action action-special">
                        {tokenName}
                        {` `}pulse
                    </Flex>
                </FlexContainer>
            </div>
            <div className={cnb('block', 'nine')}>
                <FlexContainer direction="column">
                    <Flex>
                        <BlockchainLandingIcon icon="unlimited-portfolio" />
                    </Flex>
                    <Flex className="action action-special m-t-0.75">Unlimited portfolio</Flex>
                </FlexContainer>
            </div>
            <div className={cnb('block', 'ten')}>
                <FlexContainer>
                    <Flex className="action action-large">
                        A ssssspeedily start{' '}
                        <BlockchainLandingIcon icon="5s" className="m-l-0.25" />
                    </Flex>
                </FlexContainer>
            </div>
        </div>
    );
});
