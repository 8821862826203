import {
    action,
    computed,
    IReactionDisposer,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from 'mobx';
import { computedFn } from 'mobx-utils';

import { appStorage, buildStorageKeyForUser, CommonStore, Log } from '@smartfolly/common.utilities';

import { HistoricalData } from '@smartfolly/sdk';
import type {
    HistoricalDataDisposer,
    HistoricalDataRecord,
    ExchangeCurrency,
    MarketCapData,
} from '@smartfolly/sdk';

import type { IAuthService } from '@smartfolly/frontend.auth-service';

import { currenciesService, type Tokens } from '@smartfolly/frontend.currencies-service';

import { buildAssetToken, enrichAssetsWithMarketCapData } from '../../utils';

import { DEFAULT_EXCHANGE_CURRENCY, EXCHANGE_CURRENCY_KEY } from '../constants';

import type { HistoricalAssets } from '../../types';

import type {
    BlockchainAssetWithAddressData,
    ExchangeAssetWithExchangeData,
    HistoricalToken,
    IHistoricalStore,
} from '../types';

const log = new Log('AssetsService:Historical');

type HistoricalStoreOptions =
    | {
          /**
           * An instance of AuthService with the user to work with when dealing with the assets.
           * Note: the passed service MUST be loaded and initialized, i.e. be ready to work with.
           */
          authService: IAuthService;
      }
    | {
          /**
           * An ID of the user to work with when dealing with the assets.
           */
          userId: string;
      };

export class HistoricalStore extends CommonStore implements IHistoricalStore {
    // Properties

    /**
     * Options the store is created with.
     */
    private options: HistoricalStoreOptions;

    /**
     * A list of tokens to listen the rates data for.
     */
    private listenedTokens: Tokens[] = [];

    /**
     * A private instance of the HistoricalData module from SDK.
     * Note: we use a new instance for each historical store
     * as we can have several HistoricalStore instances
     * for different users, e.g. when creating several AssetsService instances.
     */
    private historicalData: HistoricalData;

    /**
     * An observable private instance of the historical data record.
     */
    private historicalDataRecord: HistoricalDataRecord | undefined = undefined;

    /**
     * A historical data subscription disposer.
     */
    private historicalDataDisposer?: HistoricalDataDisposer;

    /**
     * An observable private instance of the selected exchange currency.
     * Note: `USD` by default.
     */
    private exchangeCurrency: ExchangeCurrency = DEFAULT_EXCHANGE_CURRENCY;

    /**
     * A disposer for the listened tokens MobX reaction.
     */
    private tokensReactionDisposer?: IReactionDisposer;

    // Constructor

    public constructor(options: HistoricalStoreOptions) {
        super();

        this.options = options;

        // Create the HistoricalData module instance once the options are set
        const { userId } = this;
        this.historicalData = new HistoricalData(userId ? { userId } : {});

        makeObservable<
            HistoricalStore,
            | 'exchangeCurrency'
            | 'historicalDataRecord'
            | 'historicalMarketCapData'
            | 'listenedTokens'
            | 'selectedExchangeCurrency'
        >(this, {
            exchangeCurrency: observable,
            historicalDataRecord: observable,
            historicalMarketCapData: computed,
            selectedExchangeCurrency: computed,
            listenedTokens: observable,
            selectListenedTokens: action,
        });
    }

    // Interface

    protected onLoad = async () => {
        // Load the selected exchange currency from the storage first
        await this.loadSelectedExchangeCurrency();

        // Load the historical data then
        this.loadHistoricalData();

        // Create a reaction to reload the rates data once the list of listened tokens changes
        this.tokensReactionDisposer = reaction(
            () => this.listenedTokens,
            () => this.reloadHistoricalData(),
            {
                name: 'a reaction to reload the rates data once the listened tokens list changes',
            },
        );
    };

    protected onUnload = async () => {
        // Dispose a reaction for the listened tokens
        if (this.tokensReactionDisposer) {
            this.tokensReactionDisposer();
            delete this.tokensReactionDisposer;
        }

        // Unload the historical data
        this.unloadHistoricalData();

        // Then deselect the exchange currency
        this.unloadSelectedExchangeCurrency();
    };

    public selectListenedTokens(tokens: Tokens[]) {
        this.listenedTokens = tokens;
    }

    public selectExchangeCurrency = async (exchangeCurrency: ExchangeCurrency) => {
        // Apply MobX action to select the exchange currency
        this.selectedExchangeCurrency = exchangeCurrency;

        // Don't need to save it to the storage as it's already done in RatesStore
    };

    public enrichAssetsWithHistoricalData = computedFn(
        (
            assets: (BlockchainAssetWithAddressData | ExchangeAssetWithExchangeData)[],
        ): HistoricalAssets => {
            const { historicalMarketCapData, selectedExchangeCurrency } = this;
            return Object.keys(historicalMarketCapData).reduce<HistoricalAssets>((acc, date) => {
                const marketCapData = historicalMarketCapData[date];
                acc[date] = enrichAssetsWithMarketCapData(assets, {
                    currency: selectedExchangeCurrency,
                    marketCapData,
                    tokensData: currenciesService.tokensData,
                });
                return acc;
            }, {});
        },
    );

    public enrichTokenWithHistoricalData = computedFn((token: Tokens): HistoricalToken => {
        const { historicalMarketCapData, selectedExchangeCurrency } = this;
        return Object.keys(historicalMarketCapData).reduce<HistoricalToken>((acc, date) => {
            const marketCapData = historicalMarketCapData[date]!;
            const exchangeRate = marketCapData[token]?.quote[selectedExchangeCurrency];
            acc[date] = buildAssetToken({
                currency: selectedExchangeCurrency,
                ...(exchangeRate ? { exchangeRate } : {}),
                token,
                tokensData: currenciesService.tokensData,
            });
            return acc;
        }, {});
    });

    // Internals

    /**
     * An observable exchange currency selected with {@link selectExchangeCurrency} method.
     * Note: if not prior selected, the selected exchange currency is considered to be `USD`.
     */
    private get selectedExchangeCurrency(): ExchangeCurrency {
        return this.exchangeCurrency;
    }

    /**
     * Private setter to select an exchange currency
     * in order to calculate the price of the assets with.
     * @param exchangeCurrency - an exchange currency to change.
     */
    private set selectedExchangeCurrency(exchangeCurrency: ExchangeCurrency) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.exchangeCurrency = exchangeCurrency;
    }

    /**
     * Method to renew the rates data subscriptions.
     * Note: should be used when the list of listened tokens has been changed.
     */
    private reloadHistoricalData = () => {
        this.unloadHistoricalData();
        this.loadHistoricalData();
    };

    /**
     * Method to load historical data using memo loader.
     * @throws a variety of errors if the user is not authorized or data is already loading.
     */
    private loadHistoricalData() {
        // Check if the historical data subscription already exist to avoid loading it twice
        if (this.historicalDataDisposer) {
            throw new Error('Historical data is already loading');
        }

        // No need to start load the historical data if there are no listened tokens
        if (this.listenedTokens.length === 0) {
            return;
        }

        // Note: no need to use memo loader to load the historical data,
        // since it's already memoized inside of `historicalData` module of SDK.

        // Get the historical data in case it's already known
        const knownData = this.historicalData.get();

        // Resolve if any historical data is present
        if (knownData) {
            runInAction(() => {
                this.historicalDataRecord = knownData;
            });
        }

        // Subscribe on the historical data changes for the given list of tokens
        this.historicalDataDisposer = this.historicalData.subscribe(
            this.listenedTokens,
            (error: Error | undefined, data?: HistoricalDataRecord) => {
                if (error) {
                    log.error('Failed to fetch the historical data with error:', error);
                    return;
                }

                if (!data) {
                    log.error('No historical data has been loaded');
                    return;
                }

                runInAction(() => {
                    // The received historical data might be partial and include only some tokens,
                    // hereby we need to update the historical data for received tokens only
                    this.historicalDataRecord = {
                        ...this.historicalDataRecord,
                        ...data,
                    };
                });
            },
        );
    }

    /**
     * Method to unload the historical data as well as unsubscribing from the corresponding loader.
     */
    private unloadHistoricalData() {
        // Dispose the historical data subscription
        if (this.historicalDataDisposer) {
            this.historicalDataDisposer();

            // And delete the disposer itself
            delete this.historicalDataDisposer;
        }

        // Delete the historical data
        runInAction(() => {
            this.historicalDataRecord = undefined;
        });
    }

    /**
     * Method to load the selected exchange currency from the storage.
     */
    private async loadSelectedExchangeCurrency() {
        // Get a storage key to keep the selected exchange currency
        // Note: could throw if the user is not authorized
        const storageKey = this.getStorageKey(EXCHANGE_CURRENCY_KEY);

        // Get a stored exchange currency value
        const exchangeCurrency = await appStorage.getItem(storageKey);

        // Set the selected exchange currency to the stored one if present.
        if (exchangeCurrency) {
            this.selectedExchangeCurrency = exchangeCurrency as ExchangeCurrency;
        }
    }

    /**
     * Method to unload the selected exchange currency to its default state.
     */
    private unloadSelectedExchangeCurrency() {
        this.selectedExchangeCurrency = DEFAULT_EXCHANGE_CURRENCY;
    }

    /**
     * A getter for an ID of the user whose historical data is loaded by the module instance.
     */
    private get userId(): string | undefined {
        // Check if the userId was passed independently or indirectly with the AuthService instance
        return 'userId' in this.options
            ? this.options.userId
            : this.options.authService.session?.userId;
    }

    /**
     * Method to get a storage key for the current user and a specified key.
     * @param key - a provided key to get the storage key for.
     * @returns the key bound to the current user.
     */
    private getStorageKey(key: string): string {
        if ('userId' in this.options) {
            return buildStorageKeyForUser(key, this.options.userId);
        }

        return this.options.authService.getStorageKey(key);
    }

    /**
     * An observable map of available historical market cap data for different dates.
     */
    private get historicalMarketCapData(): { [date: string]: MarketCapData } {
        const { historicalDataRecord } = this;
        if (!historicalDataRecord) {
            return {};
        }

        // Convert the historical data record to the map with the market cap data for known dates.
        return Object.keys(historicalDataRecord).reduce<{ [date: string]: MarketCapData }>(
            (acc, token) => {
                historicalDataRecord[token]!.forEach(({ date, quote }, index) => {
                    // Create a market cap data empty object if missing for the date
                    if (!acc[date]) {
                        acc[date] = {};
                    }

                    // Convert the quote to the market cap format
                    const marketCapQuote = Object.keys(quote).reduce<
                        MarketCapData[typeof token]['quote']
                    >(
                        (quoteAcc, exchangeCurrency) => {
                            const { price, high, low } =
                                quote[exchangeCurrency as ExchangeCurrency];
                            // Calculate the value change during 24 hours period if index is not zero,
                            // since we cannot do it not knowing the data for the previous date
                            const previousDatePrice =
                                index > 0
                                    ? historicalDataRecord[token]![index - 1]!.quote[
                                          exchangeCurrency as ExchangeCurrency
                                      ]?.price
                                    : undefined;
                            const change24h =
                                price != null && previousDatePrice != null
                                    ? previousDatePrice - price
                                    : undefined;

                            // Calculate the percent change during the 24 hours period
                            const percentChange24h =
                                change24h != null &&
                                previousDatePrice != null &&
                                previousDatePrice !== 0
                                    ? change24h / previousDatePrice
                                    : undefined;

                            // eslint-disable-next-line no-param-reassign
                            quoteAcc[exchangeCurrency as ExchangeCurrency] = {
                                ...(price != null ? { price } : {}),
                                ...(high != null ? { high_24h: high } : {}),
                                ...(low != null ? { low_24h: low } : {}),
                                ...(change24h != null ? { change_24h: change24h } : {}),
                                ...(percentChange24h != null
                                    ? { percent_change_24h: percentChange24h }
                                    : {}),
                            };
                            return quoteAcc;
                        },
                        {} as MarketCapData[typeof token]['quote'],
                    );

                    // Save the quote for the current token on the specified date
                    acc[date]![token] = { quote: marketCapQuote };
                });
                return acc;
            },
            {},
        );
    }
}
