/* eslint-disable class-methods-use-this */
import { computed, makeObservable, observable, runInAction } from 'mobx';

import { Log } from '@smartfolly/common.utilities';
import { StableCoins } from '@smartfolly/common.currencies';

import { serverConnector } from '@smartfolly/middleware.server-connector';

import type { IStablecoinsStore, StablecoinsMap } from '../types';

const log = new Log('StablecoinsStore');

class StablecoinsStore implements IStablecoinsStore {
    private loadedValue: StablecoinsMap | undefined = undefined;

    public constructor() {
        makeObservable<StablecoinsStore, 'loadedValue'>(this, {
            loadedValue: observable,
            stablecoins: computed,
        });
    }

    public get stablecoins(): StablecoinsMap {
        return this.loadedValue ?? StableCoins;
    }

    public async load() {
        try {
            const data = await serverConnector.sendGETRequest<StablecoinsMap>({
                path: 'currencies/stablecoins',
            });

            // Check for errors in response
            if ('error' in data) {
                const { error } = data;
                // TODO: process `errorCode` if needed
                throw new Error(error);
            }

            runInAction(() => {
                this.loadedValue = data;
            });
        } catch (error) {
            log.error('Failed to load stablecoins data form server with error', error);
        }
    }
}

export const stablecoinsStore = new StablecoinsStore();
