import { gql } from '@apollo/client';

// Public constants

// Enums

/**
 * An address provider.
 * Note: same enum as on the backend.
 */
export enum AddressProvider {
    /**
     * MetaMask Extension
     */
    MetaMaskExt = 'MetaMaskExt',

    /**
     * Phantom wallet
     */
    Phantom = 'Phantom',

    /**
     * Manually provided address
     */
    SimpleAddress = 'SimpleAddress',

    /**
     * Solflare wallet
     */
    Solflare = 'Solflare',

    /**
     * SurfKeeper Extension
     */
    SurfKeeperExt = 'SurfKeeperExt',

    /**
     * Any wallet powered by TonConnect
     */
    TonConnect = 'TonConnect',

    /**
     * Any wallet powered by WalletConnect
     */
    WalletConnect = 'WalletConnect',
}

// Errors

export const AssetsManagerError = {
    Providers: {
        MetaMaskExt: {
            NoProviderFound: new Error('No MetaMask Extension provider available'),
            NoAccountFound: new Error('No account provided by MetaMask extension'),
        },
        Phantom: {
            NoAccountFound: new Error('No account provided by Phantom'),
            NoProviderFound: new Error('No Phantom provider available'),
        },
        Solflare: {
            NoAccountFound: new Error('No account provided by Solflare'),
            NoProviderFound: new Error('No Solflare provider available'),
        },
        SurfKeeperExt: {
            NoProviderFound: new Error('No SurfKeeper Extension provider available'),
            NoAccountFound: new Error('No account provided by SurfKeeper extension'),
        },
        TonConnect: {
            NoAccountFound: new Error('No account provided by TonConnect'),
            NotReadyToInitialize: new Error('Not ready to initialize the TonConnect client'),
            QRCodeClosed: new Error('QRCode modal has been closed for TonConnect'),
        },
        WalletConnect: {
            NoAccountFound: new Error('No account provided by WalletConnect'),
            QRCodeClosed: new Error('QRCode modal has been closed for WalletConnect'),
        },
    },
    UnsupportedProvider: new Error('Unsupported Provider'),
    NoUser: new Error('No User to get the assets for'),
    NotAuthorizedToManage: new Error('User is not authorized to manage assets'),
    AddressIsAlreadyPresent: new Error('The provided address is already present for the user'),
    ExchangeSourceIsAlreadyPresent: new Error(
        'The provided exchange source is already present for the user',
    ),
};

// GraphQL

export const assetsCollection = 'assets';

const assetsFields = `
    address
    blockchain
    contract
    contractType
    isNative
    exchange
    sourceId
    token
    balance
    created
    updated
    isHidden
    name
    userId
    assetId
`;

export const assetsQuery = gql`
    query GetAsset($filter: AssetFilter!) {
        ${assetsCollection}(filter: $filter) { ${assetsFields} }
    }
`;

export const assetsSubscription = gql`
    subscription ($assetId: String!) {
        ${assetsCollection}(assetId: $assetId) { ${assetsFields} }
    }
`;
