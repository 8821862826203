import {
    GetAccountsResponse,
    SurfKeeperExtProvider as Provider,
    ProviderInfo,
    ProvidersError,
} from '@smartfolly/common.providers';

import { AssetsManagerError } from '../../../constants';

import type { IAddressProvider } from './types';

type Func<T, P> = (...args: P[]) => Promise<T>;
type WrappedFunc<T, P> = Func<T, P>;

const withErrorsMapperWrapper =
    <T, P, C>(fn: Func<T, P>, context: C): WrappedFunc<T, P> =>
    async (...args: P[]) => {
        try {
            // Run the original function
            return await fn.bind(context)(...args);
        } catch (error) {
            // Map the errors
            if (error === ProvidersError.SurfKeeperExt.NoAccountFound) {
                throw AssetsManagerError.Providers.SurfKeeperExt.NoAccountFound;
            } else if (error === ProvidersError.SurfKeeperExt.NoProviderFound) {
                throw AssetsManagerError.Providers.SurfKeeperExt.NoProviderFound;
            } else {
                throw error;
            }
        }
    };

class SurfKeeperExtProvider implements IAddressProvider<'SurfKeeperExt'> {
    // Properties

    /**
     * A private instance of SurfKeeperExt provider
     */
    private surfKeeperExtProvider = new Provider();

    // Interface

    public async checkIfAvailable(): Promise<boolean> {
        return withErrorsMapperWrapper(
            this.surfKeeperExtProvider.checkIfAvailable,
            this.surfKeeperExtProvider,
        )();
    }

    public async disconnect(): Promise<void> {
        return withErrorsMapperWrapper(
            this.surfKeeperExtProvider.disconnect,
            this.surfKeeperExtProvider,
        )();
    }

    public async getAccounts(): Promise<GetAccountsResponse> {
        return withErrorsMapperWrapper(
            this.surfKeeperExtProvider.getAccounts,
            this.surfKeeperExtProvider,
        )();
    }

    public async getInfo(): Promise<ProviderInfo<'SurfKeeperExt'>> {
        return withErrorsMapperWrapper(
            this.surfKeeperExtProvider.getInfo,
            this.surfKeeperExtProvider,
        )();
    }
}

export const surfKeeperExtProvider = new SurfKeeperExtProvider();
