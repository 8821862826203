import BigNumber from 'bignumber.js';

import {
    Currency,
    priceChangeToString,
    priceToString,
} from '@smartfolly/frontend.currencies-service';
import { localePercentage, reflectValueChanges } from '@smartfolly/common.utilities';

import type { Asset, ExchangeRate } from '../types';

type BuildOptions = {
    /**
     * An amount of tokens for the asset.
     */
    balance: string;
    /**
     * Currency to specify the asset price with.
     */
    currency: Currency;
    /**
     * Optional exchange rate data for the asset token.
     */
    exchangeRate?: ExchangeRate;
};

/**
 * Function to build the asset price with.
 * @param options - options to build the asset price with.
 */
export function buildAssetPrice({ balance, currency, exchangeRate }: BuildOptions): Asset['price'] {
    // Check if exchange rate is provided
    if (!exchangeRate) {
        return {
            currency,
            change24h: {
                string: undefined,
                value: undefined,
            },
            high24h: {
                string: undefined,
                value: undefined,
            },
            low24h: {
                string: undefined,
                value: undefined,
            },
            percentChange24h: {
                string: undefined,
                value: undefined,
            },
            value: undefined,
            string: undefined,
        };
    }

    // Get the balance value in BigNumber representation
    const balanceValue = new BigNumber(balance);

    // Calculate the price of the asset knowing its balance and the token price
    const price =
        exchangeRate.price != null
            ? balanceValue.times(new BigNumber(exchangeRate.price))
            : undefined;

    // Get the percent price change value of the asset during the 24 hours period
    // Convert the provided percent change value to a proper numeric value
    // suitable for percentage processing, i.e. by dividing it by 100.0:
    const percentChangeValue24h =
        exchangeRate.percent_change_24h != null
            ? new BigNumber(exchangeRate.percent_change_24h).div(new BigNumber(100.0))
            : undefined;

    // Get the price change of the asset during the 24 hours period
    const priceChangeValue24h =
        exchangeRate.change_24h != null
            ? balanceValue.times(new BigNumber(exchangeRate.change_24h))
            : undefined;
    // OR as
    // const priceChangeValue24h = price.times(percentChangeValue24h);

    // Get the highest price of the asset during the 24 hours period
    const highestPrice24h =
        exchangeRate.high_24h != null
            ? balanceValue.times(new BigNumber(exchangeRate.high_24h))
            : undefined;

    // Get the lowest price of the asset during the 24 hours period
    const lowestPrice24h =
        exchangeRate.low_24h != null
            ? balanceValue.times(new BigNumber(exchangeRate.low_24h))
            : undefined;

    // Build the asset price
    const assetPrice: Asset['price'] = {
        currency,
        // Set the percent change of the asset price during the 24 hours period
        // Note: should be the same as the token price change!
        percentChange24h: reflectValueChanges({
            value: percentChangeValue24h,
            string: percentChangeValue24h ? localePercentage(percentChangeValue24h) : undefined,
        }),
        change24h: {
            value: priceChangeValue24h,
            string: priceChangeValue24h
                ? priceChangeToString(priceChangeValue24h, currency)
                : undefined,
        },
        high24h: {
            value: highestPrice24h,
            string: highestPrice24h ? priceToString(highestPrice24h, currency) : undefined,
        },
        low24h: {
            value: lowestPrice24h,
            string: lowestPrice24h ? priceToString(lowestPrice24h, currency) : undefined,
        },
        value: price,
        string: price ? priceToString(price, currency) : undefined,
    };

    // Get the percent price change value of the asset during the "all time" period
    // Convert the provided percent change value to a proper numeric value
    // suitable for percentage processing, i.e. by dividing it by 100.0:
    const percentChangeValueAllTime =
        exchangeRate.percent_change_all_time != null
            ? new BigNumber(exchangeRate.percent_change_all_time).div(new BigNumber(100.0))
            : undefined;

    // Set the percent price change value of the asset during the "all time" period if present
    if (percentChangeValueAllTime != null) {
        assetPrice.percentChangeAllTime = reflectValueChanges({
            value: percentChangeValueAllTime,
            string: localePercentage(percentChangeValueAllTime),
        });
    }

    // Get the price change of the asset during the "all time" period
    const priceChangeValueAllTime =
        exchangeRate.change_all_time != null
            ? balanceValue.times(new BigNumber(exchangeRate.change_all_time))
            : undefined;
    // OR as
    // const priceChangeValueAllTime = price.times(percentChangeValueAllTime);

    // Set the price change of the asset during the "all time" period if present
    if (priceChangeValueAllTime != null) {
        assetPrice.changeAllTime = {
            value: priceChangeValueAllTime,
            string: priceChangeToString(priceChangeValueAllTime, currency),
        };
    }

    // Get the highest price of the asset during the "all time" period
    const highestPriceAllTime =
        exchangeRate.high_all_time != null
            ? balanceValue.times(new BigNumber(exchangeRate.high_all_time))
            : undefined;

    // Set the highest price of the asset during the "all time" period if present
    if (highestPriceAllTime != null) {
        assetPrice.highAllTime = {
            value: highestPriceAllTime,
            string: priceToString(highestPriceAllTime, currency),
        };
    }

    // Get the lowest price of the asset during the "all time" period
    const lowestPriceAllTime =
        exchangeRate.low_all_time != null
            ? balanceValue.times(new BigNumber(exchangeRate.low_all_time))
            : undefined;

    // Set the lowest price of the asset during the "all time" period if present
    if (lowestPriceAllTime != null) {
        assetPrice.lowAllTime = {
            value: lowestPriceAllTime,
            string: priceToString(lowestPriceAllTime, currency),
        };
    }

    return assetPrice;
}
