import type { Currency, Tokens, TokensDataMap } from '@smartfolly/frontend.currencies-service';

import type { Asset, ExchangeRate } from '../types';
import { buildAssetPrice } from './buildAssetPrice';

type BuildOptions = {
    /**
     * Token ID to build the asset token.
     */
    token: Tokens;
    /**
     * Currency to specify the asset token price with.
     */
    currency: Currency;
    /**
     * Optional exchange rate data for the specified token.
     */
    exchangeRate?: ExchangeRate;
    /**
     * Map with the all supported tokens data.
     */
    tokensData: TokensDataMap;
};

/**
 * Function to build the asset token with.
 * @param options - options to build the asset token with.
 */
export function buildAssetToken({
    currency,
    token,
    exchangeRate,
    tokensData,
}: BuildOptions): Asset['token'] {
    // Note: the token price can be calculated as the asset price with the balance equal to `1`.
    return {
        id: token,
        price: buildAssetPrice({
            balance: '1',
            currency,
            ...(exchangeRate ? { exchangeRate } : {}),
        }),
        ...tokensData[token],
    };
}
