import { computed, makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

import {
    appStorage,
    buildStorageKeyForUser,
    CommonStore,
    DocumentLoaderListener,
    Log,
    MemoDocumentLoader,
} from '@smartfolly/common.utilities';

import type { Exchanges } from '@smartfolly/common.exchanges';

import {
    ProvidedAddresses,
    ProvidedAddressData,
    ProvidedAddressInfo,
    Asset,
    UserAddressesListenerDisposer,
    IUserManager,
    UserManager,
    ProvidedAddress,
} from '@smartfolly/sdk';

import type { IAuthService } from '@smartfolly/frontend.auth-service';

import type { BlockchainAssetWithAddressData, IAddressesStore } from '../types';

const log = new Log('AssetsService:Addresses');

const ALL_ADDRESSES_KEY = 'AssetsService:AllAddresses';

type AddressesStoreOptions =
    | {
          /**
           * An instance of AuthService with the user to work with when dealing with the assets.
           * Note: the passed service MUST be loaded and initialized, i.e. be ready to work with.
           */
          authService: IAuthService;
      }
    | {
          /**
           * An ID of the user to work with when dealing with the assets.
           */
          userId: string;
      };

export class AddressesStore extends CommonStore implements IAddressesStore {
    // Properties

    /**
     * Options the store is created with.
     */
    private options: AddressesStoreOptions;

    /**
     * An instance of the UserManager to work with when dealing with the user addresses.
     */
    private userManager: IUserManager;

    /**
     * A private observable map of all user addresses provided by {@link userManager}.
     */
    private allAddressesMap: ProvidedAddresses = {};

    /**
     * A private observable instance of the `isEditingAddressInfo` flag.
     */
    private isEditingAddressInfoFlag: boolean = false;

    /**
     * A memo loader of all user addresses.
     */
    private memoAddressesLoader?: MemoDocumentLoader<ProvidedAddresses>;

    /**
     * A listener to update all user addresses.
     */
    private addressesLoaderListener?: DocumentLoaderListener<ProvidedAddresses>;

    /**
     * A GraphQL User Addresses subscription disposer.
     */
    private subscriptionDisposer?: UserAddressesListenerDisposer;

    // Constructor

    public constructor(options: AddressesStoreOptions) {
        super();

        this.options = options;

        this.userManager = new UserManager(
            'authService' in options
                ? { userAuth: options.authService.userAuth }
                : { userId: options.userId },
        );

        makeObservable<
            AddressesStore,
            'allAddresses' | 'allAddressesMap' | 'isEditingAddressInfoFlag'
        >(this, {
            allAddresses: computed,
            allAddressesMap: observable,
            isEditingAddressInfo: computed,
            isEditingAddressInfoFlag: observable,
        });
    }

    // Getters & Setters

    /**
     * A computed map of all user addresses.
     */
    private get allAddresses(): ProvidedAddresses {
        return this.allAddressesMap;
    }

    /**
     * Set the map of all user addresses.
     */
    private set allAddresses(allAddresses: ProvidedAddresses) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.allAddressesMap = allAddresses;
    }

    // Interface

    protected onLoad = async () => {
        // Load the user addresses
        this.loadAddresses();
    };

    protected onUnload = async () => {
        // Unload the user addresses
        this.unloadAddresses();
    };

    public get providedAddresses(): ProvidedAddress[] {
        return Object.keys(this.allAddresses).map(address => {
            const data = this.allAddresses[address]!;

            return { address, ...data };
        });
    }

    public get isEditingAddressInfo(): boolean {
        return this.isEditingAddressInfoFlag;
    }

    private set isEditingAddressInfo(isEditingAddressInfo: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.isEditingAddressInfoFlag = isEditingAddressInfo;
    }

    public enrichAssets = computedFn(
        (
            assets: Asset[],
        ): (Extract<Asset, { exchange: Exchanges }> | BlockchainAssetWithAddressData)[] => {
            return assets.map<
                Extract<Asset, { exchange: Exchanges }> | BlockchainAssetWithAddressData
            >(asset => {
                // Enrich the asset with the address data if it's present
                const addressData = 'address' in asset && this.allAddresses[asset.address];
                if (addressData) {
                    return {
                        ...asset,
                        addressData,
                    };
                }

                // Return the asset unchanged if there is no address data
                return asset;
            });
        },
    );

    public addAddressData = (
        address: string,
        { info, provider }: Pick<ProvidedAddressData, 'info' | 'provider'>,
    ) => {
        // Check if the addresses are still listened to by the loader
        if (!this.addressesLoaderListener) {
            // No way to update the addresses
            return;
        }

        // Add and address with the info to local user addresses
        const updatedAddresses = { ...this.allAddresses }; // copy

        // Add the address with the info
        updatedAddresses[address] = {
            created: Date.now(), // this timestamp will be taken from the backend later
            provider,
            ...(info ? { info } : {}),
        };

        // Update all user addresses via the listener
        this.addressesLoaderListener(undefined, updatedAddresses);
    };

    public editAddressInfo = async (
        address: string,
        info: ProvidedAddressInfo,
    ): Promise<boolean> => {
        // Mark an address info is being edited
        this.isEditingAddressInfo = true;
        try {
            // Edit an address info
            const edited = await this.userManager.editAddressInfo(address, info);
            log.debug('Editing result:', edited);

            if (!edited) {
                // Return a negative result of the operation
                return false;
            }

            // Check if the addresses are still listened to by the loader
            if (!this.addressesLoaderListener) {
                // Return a positive result of the operation, but do not update the addresses
                return true;
            }

            // Edit info of the corresponding local user address
            const updatedAddresses = { ...this.allAddresses }; // copy
            const addressToUpdate = updatedAddresses[address];
            if (!addressToUpdate) {
                // Return a positive result of the operation, but do not update the addresses,
                // as there is nothing to update
                return true;
            }

            // Edit the address info
            addressToUpdate.info = info;

            // Update all user addresses via the listener
            this.addressesLoaderListener(undefined, updatedAddresses);

            // Return the positive result of the operation
            return true;
        } catch (error) {
            log.error('Failed to remove an address with error:', error);

            throw error;
        } finally {
            // Mark an address is not being edited anymore
            this.isEditingAddressInfo = false;
        }
    };

    public removeAddressData = (address: string): void => {
        // Check if the addresses are still listened to by the loader
        if (!this.addressesLoaderListener) {
            // No way to update the addresses
            return;
        }

        // Exclude the corresponding local user address
        const updatedAddresses = { ...this.allAddresses }; // copy
        const addressToUpdate = updatedAddresses[address];
        if (!addressToUpdate) {
            // Do nothing as there is nothing to update
            return;
        }

        // Remove the address
        delete updatedAddresses[address];

        // Update all user addresses via the listener
        this.addressesLoaderListener(undefined, updatedAddresses);
    };

    // Internals

    /**
     * Method to load user addresses using memo loader.
     * @throws a variety of errors if the user is not authorized or addresses are already loading.
     */
    private loadAddresses() {
        // Check if the addresses loader already exist to avoid loading the store twice
        if (this.memoAddressesLoader) {
            throw new Error('Addresses are already loading');
        }

        // Get a storage key to memo the addresses
        // Note: could throw if the user is not authorized
        const storageKey = this.getStorageKey(ALL_ADDRESSES_KEY);

        // Get all user addresses via memo loader
        this.memoAddressesLoader = new MemoDocumentLoader<ProvidedAddresses>({
            key: storageKey,
            storage: appStorage,
            documentFetcher: (listener: DocumentLoaderListener<ProvidedAddresses>) => {
                // Save the listener in order to reflect addresses changes made by other methods
                this.addressesLoaderListener = listener;

                // Get all the user addresses from the backend
                (async () => {
                    try {
                        const allAddresses = await this.userManager.getProvidedAddresses();
                        listener(undefined, allAddresses);
                    } catch (error) {
                        listener(error);
                    }
                })();

                // Subscribe to the server to update the provided addresses
                this.subscriptionDisposer = this.userManager.subscribeToAddresses(
                    (error: Error | undefined, addresses?: ProvidedAddresses | null) => {
                        if (error) {
                            listener(error);
                        }

                        if (addresses) {
                            listener(undefined, addresses);
                        }
                    },
                );
            },
            stopDocumentFetching: () => {
                // Dispose the subscription if any
                if (this.subscriptionDisposer) {
                    this.subscriptionDisposer();
                    delete this.subscriptionDisposer;
                }

                // Delete the addresses listener
                delete this.addressesLoaderListener;
            },
        });

        // Listen to all user addresses changes
        this.memoAddressesLoader.subscribe(
            (error: Error | undefined, allAddresses?: ProvidedAddresses) => {
                if (error) {
                    log.error('Failed to fetch all user addresses with error:', error);
                    return;
                }

                if (!allAddresses) {
                    log.error('No addresses have been loaded');
                    return;
                }

                this.allAddresses = allAddresses;
            },
        );
    }

    /**
     * Methods to unload user addresses with the memo loader.
     */
    private unloadAddresses() {
        // Unsubscribe from the memo addresses loader
        if (this.memoAddressesLoader) {
            this.memoAddressesLoader.unsubscribe();

            // And delete the loader itself
            delete this.memoAddressesLoader;
        }

        // Remove all addresses
        this.allAddresses = {};
    }

    /**
     * Method to get a storage key for the current user and a specified key.
     * @param key - a provided key to get the storage key for.
     * @returns the key bound to the current user.
     */
    private getStorageKey(key: string): string {
        if ('userId' in this.options) {
            return buildStorageKeyForUser(key, this.options.userId);
        }

        return this.options.authService.getStorageKey(key);
    }
}
