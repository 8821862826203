/* eslint-disable no-irregular-whitespace */
/* eslint-disable max-lines */
export enum Symbols {
    /** Cardano */
    'ADA' = 'ADA',
    /** Snek */
    'SNEK' = 'SNEK',
    /** Minswap */
    'MIN' = 'MIN',
    /** Indigo Protocol */
    'INDY' = 'INDY',
    /** Djed */
    'DJED' = 'DJED',
    /** KubeCoin */
    'KUBE' = 'KUBE',
    /** AdaSwap */
    'ASW' = 'ASW',
    /** MELD */
    'MELD' = 'MELD',
    /** Indigo Protocol - iUSD */
    'IUSD' = 'IUSD',
    /** IAGON */
    'IAG' = 'IAG',
    /** Lenfi */
    'LENFI' = 'LENFI',
    /** Liqwid Finance */
    'LQ' = 'LQ',
    /** Revuto */
    'REVU' = 'REVU',
    /** Indigo Protocol - iBTC */
    'IBTC' = 'IBTC',
    /** Book.io */
    'BOOK' = 'BOOK',
    /** Genius Yield */
    'GENS' = 'GENS',
    /** ENCOINS */
    'ENCS' = 'ENCS',
    /** VyFinance */
    'VYFI' = 'VYFI',
    /** CamelCoin */
    'CML' = 'CML',
    /** WingRiders Governance Token */
    'WRT' = 'WRT',
    /** Clay Nation */
    'CLAY' = 'CLAY',
    /** NEWM */
    'NEWM' = 'NEWM',
    /** Empowa */
    'EMP' = 'EMP',
    /** Pavia */
    'PAVIA' = 'PAVIA',
    /** Blueshift */
    'BLUES' = 'BLUES',
    /** Hosky Token */
    'HOSKY' = 'HOSKY',
    /** Shen */
    'SHEN' = 'SHEN',
    /** Ardana */
    'DANA' = 'DANA',
    /** Orcfax */
    'FACT' = 'FACT',
    /** AnetaBTC */
    'cNETA' = 'cNETA',
    /** Ray Network */
    'XRAY' = 'XRAY',
    /** NMKR */
    'NMKR' = 'NMKR',
    /** Charli3 */
    'C3' = 'C3',
    /** MuesliSwap MILK */
    'MILK' = 'MILK',
    /** Chains of War */
    'MIRA' = 'MIRA',
    /** BankerCoinAda */
    'BANK' = 'BANK',
    /** Stablecoin */
    'STABLE' = 'STABLE',
    /** Venom */
    'VNM' = 'VNM',
    /** ADAX */
    'ADAX' = 'ADAX',
    /** Tangent */
    'TANG' = 'TANG',
    /** Ibilecoin */
    'BLC' = 'BLC',
    /** GeroWallet */
    'GERO' = 'GERO',
    /** KICK.IO */
    'KICK' = 'KICK',
    /** DOEX */
    'DOEX' = 'DOEX',
    /** Adalend */
    'ADAL' = 'ADAL',
    /** Yummi Universe */
    'YUMMI' = 'YUMMI',
    /** Danketsu */
    'NINJAZ' = 'NINJAZ',
    /** Tether USDt */
    'USDT' = 'USDT',
    /** Chainlink */
    'LINK' = 'LINK',
    /** USDC */
    'USDC' = 'USDC',
    /** SingularityNET */
    'AGIX' = 'AGIX',
    /** Wrapped Bitcoin */
    'WBTC' = 'WBTC',
    /** Wrapped BNB */
    'WBNB' = 'WBNB',
    /** World Mobile Token */
    'WMT' = 'WMT',
    /** NuNet */
    'NTX' = 'NTX',
    /** Paribus */
    'PBX' = 'PBX',
    /** WETH */
    'WETH' = 'WETH',
    /** Wrapped AVAX */
    'WAVAX' = 'WAVAX',
    /** Wrapped Fantom */
    'WFTM' = 'WFTM',
    /** Cornucopias */
    'COPI' = 'COPI',
    /** Rejuve.AI */
    'RJV' = 'RJV',
    /** MAI */
    'MIMATIC' = 'MIMATIC',
    /** Wrapped Cardano */
    'WADA' = 'WADA',
    /** SundaeSwap */
    'SUNDAE' = 'SUNDAE',
    /** Adadao */
    'ADAO' = 'ADAO',
    /** YaySwap */
    'YAY' = 'YAY',
    /** Algorand */
    'ALGO' = 'ALGO',
    /** PlanetWatch */
    'PLANETS' = 'PLANETS',
    /** Monerium EUR emoney */
    'EURe' = 'EURe',
    /** Brazilian Digital Token */
    'BRZ' = 'BRZ',
    /** Buying.com */
    'BUY' = 'BUY',
    /** HEADLINE */
    'HDL' = 'HDL',
    /** Yieldly */
    'YLDY' = 'YLDY',
    /** AlgoGems */
    'GEMS' = 'GEMS',
    /** Xfinite Entertainment Token */
    'XET' = 'XET',
    /** Opulous */
    'OPUL' = 'OPUL',
    /** Choice Coin */
    'CHOICE' = 'CHOICE',
    /** Smile Coin */
    'SMILE' = 'SMILE',
    /** Algomint */
    'GOBTC' = 'GOBTC',
    /** Algomint */
    'GOETH' = 'GOETH',
    /** ZONE */
    'ZONE' = 'ZONE',
    /** Maricoin */
    'MCOIN' = 'MCOIN',
    /** AlgoStake */
    'STKE' = 'STKE',
    /** Akita Inu */
    'AKTA' = 'AKTA',
    /** XGLI DAO Protocol */
    'XGLI' = 'XGLI',
    /** Carbon Credit */
    'CCT' = 'CCT',
    /** Vestige */
    'VEST' = 'VEST',
    /** Algodex Token */
    'ALGX' = 'ALGX',
    /** pTokens BTC */
    'pBTC' = 'pBTC',
    /** Wrapped SOL */
    'XSOL' = 'XSOL',
    /** Governance ALGO */
    'gALGO' = 'gALGO',
    /** FrysCrypto */
    'FRY' = 'FRY',
    /** Day By Day */
    'DBD' = 'DBD',
    /** Gora */
    'GORA' = 'GORA',
    /** Polkagold */
    'PGOLD' = 'PGOLD',
    /** Ethereum */
    'ETH' = 'ETH',
    /** Arbitrum */
    'ARB' = 'ARB',
    /** Magic */
    'MAGIC' = 'MAGIC',
    /** Vertex Protocol */
    'VRTX' = 'VRTX',
    /** ZTX */
    'ZTX' = 'ZTX',
    /** ArbDoge AI */
    'AIDOGE' = 'AIDOGE',
    /** Hydranet */
    'HDN' = 'HDN',
    /** Zyberswap */
    'ZYB' = 'ZYB',
    /** BlueSale Finance */
    'BLS' = 'BLS',
    /** POGAI */
    'pogai' = 'pogai',
    /** Camelot Token */
    'GRAIL' = 'GRAIL',
    /** Vela Exchange */
    'VELA' = 'VELA',
    /** WINR Protocol */
    'WINR' = 'WINR',
    /** A3S Protocol */
    'AA' = 'AA',
    /** Sex One */
    'SEX' = 'SEX',
    /** Sperax USD */
    'USDs' = 'USDs',
    /** AiShiba */
    'shibai' = 'shibai',
    /** AI CODE */
    'AICODE' = 'AICODE',
    /** AI Meta Club */
    'AMC' = 'AMC',
    /** Equation */
    'EQU' = 'EQU',
    /** Rekt */
    'REKT' = 'REKT',
    /** Cradles */
    'CRDS' = 'CRDS',
    /** FORE Protocol */
    'FORE' = 'FORE',
    /** Realm Anima */
    'ANIMA' = 'ANIMA',
    /** TridentDAO */
    'PSI' = 'PSI',
    /** Roseon */
    'ROSX' = 'ROSX',
    /** Arbitrove Protocol */
    'TROVE' = 'TROVE',
    /** CrazyRabbit */
    'CRAYRABBIT' = 'CRAYRABBIT',
    /** LFGSwap Finance(Arbitrum) */
    'LFG' = 'LFG',
    /** Kabosu Family */
    'KABOSU' = 'KABOSU',
    /** AlphaScan */
    'ASCN' = 'ASCN',
    /** Chronos */
    'CHR' = 'CHR',
    /** OtterHome */
    'HOME' = 'HOME',
    /** Savvy */
    'SVY' = 'SVY',
    /** Kunji Finance */
    'KNJ' = 'KNJ',
    /** Tigris */
    'TIG' = 'TIG',
    /** HOLD VIP */
    'HOLD' = 'HOLD',
    /** Hepton */
    'HTE' = 'HTE',
    /** Plutus RDNT */
    'PLSRDNT' = 'PLSRDNT',
    /** Vesta Finance */
    'VSTA' = 'VSTA',
    /** Tender.fi */
    'TND' = 'TND',
    /** GND Protocol */
    'GND' = 'GND',
    /** Antspace */
    'ANT' = 'ANT',
    /** ELLERIUM (NEW) */
    'ELM' = 'ELM',
    /** Mizar */
    'MZR' = 'MZR',
    /** SpaceAI */
    'SPAI' = 'SPAI',
    /** Exponential Capital */
    'EXPO' = 'EXPO',
    /** AutoDCA */
    'DCA' = 'DCA',
    /** Umami Finance */
    'UMAMI' = 'UMAMI',
    /** MMFinance (Arbitrum) */
    'MMF' = 'MMF',
    /** Jones GLP */
    'JGLP' = 'JGLP',
    /** LEXER Markets */
    'LEX' = 'LEX',
    /** Perpy */
    'PRY' = 'PRY',
    /** FOXAI */
    'FOXAI' = 'FOXAI',
    /** ArbiDex Token */
    'ARX' = 'ARX',
    /** Arbidoge */
    'ADOGE' = 'ADOGE',
    /** Modular Wallet */
    'MOD' = 'MOD',
    /** TraderDAO */
    'POT' = 'POT',
    /** ArbGPT */
    'GPT' = 'GPT',
    /** ArbInu */
    'ARBINU' = 'ARBINU',
    /** Hood AI */
    'HOOD' = 'HOOD',
    /** ArbiNYAN */
    'NYAN' = 'NYAN',
    /** Kortana */
    'KORA' = 'KORA',
    /** Funny Coin */
    'FUC' = 'FUC',
    /** DSquared Governance Token */
    'DSQ' = 'DSQ',
    /** Smart Aliens */
    'SAS' = 'SAS',
    /** AIENGLISH */
    'AIEN' = 'AIEN',
    /** Sector Finance */
    'SECT' = 'SECT',
    /** Liquid Finance */
    'LIQD' = 'LIQD',
    /** Mugen Finance */
    'MGN' = 'MGN',
    /** GND Protocol */
    'GMUSD' = 'GMUSD',
    /** aEGGS */
    'aEGGS' = 'aEGGS',
    /** SolidLizard */
    'SLIZ' = 'SLIZ',
    /** Unlimited Network */
    'UWU' = 'UWU',
    /** Ether Wars */
    'WAR' = 'WAR',
    /** Unstoppable:DeFi */
    'UND' = 'UND',
    /** Alien */
    'ALIEN' = 'ALIEN',
    /** Arcadeum */
    'ARC' = 'ARC',
    /** LSDoge */
    'LSDOGE' = 'LSDOGE',
    /** AsgardX */
    'ODIN' = 'ODIN',
    /** Monopoly Finance Layer 3 */
    'POLY' = 'POLY',
    /** Gridex */
    'GDX' = 'GDX',
    /** Cheese */
    'CHEESE' = 'CHEESE',
    /** Doubloon */
    'DBL' = 'DBL',
    /** DigitalDollar */
    'DUSD' = 'DUSD',
    /** NitroShiba */
    'NISHIB' = 'NISHIB',
    /** Kostren Finance */
    'KTN' = 'KTN',
    /** Petroleum OIL */
    'OIL' = 'OIL',
    /** Compounded Marinated UMAMI */
    'CMUMAMI' = 'CMUMAMI',
    /** OreoSwap */
    'OREO' = 'OREO',
    /** Stake Goblin */
    'GOBLIN' = 'GOBLIN',
    /** Ai Card Render */
    'ACR' = 'ACR',
    /** LUXWORLD */
    'LUX' = 'LUX',
    /** opxSliz */
    'OPXVESLIZ' = 'OPXVESLIZ',
    /** YieldFarming Index */
    'YFX' = 'YFX',
    /** ZenithSwap */
    'ZSP' = 'ZSP',
    /** Auragi */
    'AGI' = 'AGI',
    /** Array Capital */
    'ARRAY' = 'ARRAY',
    /** GoSleep NGT */
    'NGT' = 'NGT',
    /** Forge */
    'FORGE' = 'FORGE',
    /** PigsCanFly */
    'PORK' = 'PORK',
    /** UrDEX Finance */
    'URD' = 'URD',
    /** Quick Intel */
    'QUICKI' = 'QUICKI',
    /** Legends */
    'LG' = 'LG',
    /** MOETA COIN */
    'MOETA' = 'MOETA',
    /** Beluga Protocol */
    'BELA' = 'BELA',
    /** TAIKULA COIN */
    'TAIKULA' = 'TAIKULA',
    /** Mayfair */
    'MAY' = 'MAY',
    /** Goldbank Finance */
    'GB' = 'GB',
    /** Gnosis */
    'GNO' = 'GNO',
    /** TrueUSD */
    'TUSD' = 'TUSD',
    /** Dai */
    'DAI' = 'DAI',
    /** The Graph */
    'GRT' = 'GRT',
    /** Frax Share */
    'FXS' = 'FXS',
    /** Uniswap */
    'UNI' = 'UNI',
    /** PancakeSwap */
    'CAKE' = 'CAKE',
    /** Ankr */
    'ANKR' = 'ANKR',
    /** Orbs */
    'ORBS' = 'ORBS',
    /** Tellor */
    'TRB' = 'TRB',
    /** Balancer */
    'BAL' = 'BAL',
    /** yearn.finance */
    'YFI' = 'YFI',
    /** WOO Network */
    'WOO' = 'WOO',
    /** JOE */
    'JOE' = 'JOE',
    /** GMX */
    'GMX' = 'GMX',
    /** Axelar */
    'AXL' = 'AXL',
    /** Cartesi */
    'CTSI' = 'CTSI',
    /** Frax */
    'FRAX' = 'FRAX',
    /** Seedify.fund */
    'SFUND' = 'SFUND',
    /** Pendle */
    'PENDLE' = 'PENDLE',
    /** Gains Network */
    'GNS' = 'GNS',
    /** Kujira */
    'KUJI' = 'KUJI',
    /** Stargate Finance */
    'STG' = 'STG',
    /** IQ */
    'IQ' = 'IQ',
    /** DODO */
    'DODO' = 'DODO',
    /** Badger DAO */
    'BADGER' = 'BADGER',
    /** Ankr Staked ETH */
    'ankrETH' = 'ankrETH',
    /** Kyber Network Crystal v2 */
    'KNC' = 'KNC',
    /** Radiant Capital */
    'RDNT' = 'RDNT',
    /** Bloktopia */
    'BLOK' = 'BLOK',
    /** MUX Protocol */
    'MCB' = 'MCB',
    /** Hegic */
    'HEGIC' = 'HEGIC',
    /** Beefy */
    'BIFI' = 'BIFI',
    /** Aurory */
    'AURY' = 'AURY',
    /** Symbiosis */
    'SIS' = 'SIS',
    /** Multichain */
    'MULTI' = 'MULTI',
    /** GameSwift */
    'GSWIFT' = 'GSWIFT',
    /** MXC */
    'MXC' = 'MXC',
    /** Ferrum Network */
    'FRM' = 'FRM',
    /** dForce */
    'DF' = 'DF',
    /** Fuse */
    'FUSE' = 'FUSE',
    /** Sperax */
    'SPA' = 'SPA',
    /** GYEN */
    'GYEN' = 'GYEN',
    /** ICHI */
    'ICHI' = 'ICHI',
    /** DSLA Protocol */
    'DSLA' = 'DSLA',
    /** AntiMatter Token */
    'MATTER' = 'MATTER',
    /** ROOBEE */
    'ROOBEE' = 'ROOBEE',
    /** ApeSwap */
    'BANANA' = 'BANANA',
    /** Penpie */
    'PNP' = 'PNP',
    /** Xaya */
    'CHI' = 'CHI',
    /** Deri Protocol */
    'DERI' = 'DERI',
    /** X World Games */
    'XWG' = 'XWG',
    /** Bridge Oracle */
    'BRG' = 'BRG',
    /** O3 Swap */
    'O3' = 'O3',
    /** FEAR */
    'FEAR' = 'FEAR',
    /** Kommunitas */
    'KOM' = 'KOM',
    /** Tarot */
    'TAROT' = 'TAROT',
    /** Kromatika */
    'KROM' = 'KROM',
    /** OpenLeverage */
    'OLE' = 'OLE',
    /** Edgeware */
    'EDG' = 'EDG',
    /** MahaDAO */
    'MAHA' = 'MAHA',
    /** ZUSD */
    'ZUSD' = 'ZUSD',
    /** Hord */
    'HORD' = 'HORD',
    /** UBXS Token */
    'UBXS' = 'UBXS',
    /** Goons of Balatroon */
    'GOB' = 'GOB',
    /** RigoBlock */
    'GRG' = 'GRG',
    /** Project TXA */
    'TXA' = 'TXA',
    /** OpenBlox */
    'OBX' = 'OBX',
    /** Football World Community */
    'FWC' = 'FWC',
    /** BlackHat */
    'BLKC' = 'BLKC',
    /** BullBear AI */
    'AIBB' = 'AIBB',
    /** Plasma Finance */
    'PPAY' = 'PPAY',
    /** Dev Protocol */
    'DEV' = 'DEV',
    /** Flashstake */
    'FLASH' = 'FLASH',
    /** Neutra Finance */
    'NEU' = 'NEU',
    /** WeFi */
    'WEFI' = 'WEFI',
    /** Aluna.Social */
    'ALN' = 'ALN',
    /** FantomStarter */
    'FS' = 'FS',
    /** USD Coin Bridged */
    'USDCE' = 'USDCE',
    /** DFX Finance */
    'DFX' = 'DFX',
    /** Lido wstETH */
    'WSTETH' = 'WSTETH',
    /** Superpower Squad */
    'SQUAD' = 'SQUAD',
    /** Axelar Wrapped USDC */
    'axlUSDC' = 'axlUSDC',
    /** Frax Finance - Frax Ether */
    'FRXETH' = 'FRXETH',
    /** tBTC */
    'TBTC' = 'TBTC',
    /** The Doge NFT */
    'DOG' = 'DOG',
    /** USD+ */
    'USD+' = 'USD+',
    /** Thrupenny */
    'TPY' = 'TPY',
    /** Verified USD */
    'USDV' = 'USDV',
    /** GenomesDao */
    '$GENE' = '$GENE',
    /** Frax Staked Ether */
    'SFRXETH' = 'SFRXETH',
    /** Rabbit */
    'RAB' = 'RAB',
    /** Magic Internet Money */
    'MIM' = 'MIM',
    /** Lumerin */
    'LMR' = 'LMR',
    /** DOLA */
    'DOLA' = 'DOLA',
    /** KAP Games */
    'KAP' = 'KAP',
    /** Hello Pets */
    'PET' = 'PET',
    /** Thales */
    'THALES' = 'THALES',
    /** Dopex Rebate Token */
    'RDPX' = 'RDPX',
    /** Governance OHM */
    'GOHM' = 'GOHM',
    /** Risitas */
    'RISITA' = 'RISITA',
    /** Minati Coin */
    'MNTC' = 'MNTC',
    /** Franklin */
    'FLY' = 'FLY',
    /** GNOME */
    'GNOME' = 'GNOME',
    /** Magpie */
    'MGP' = 'MGP',
    /** Mozaic */
    'MOZ' = 'MOZ',
    /** Rubic */
    'RBC' = 'RBC',
    /** Alongside Crypto Market Index */
    'AMKT' = 'AMKT',
    /** Reunit Wallet */
    'REUNI' = 'REUNI',
    /** DegenReborn */
    'DEGEN' = 'DEGEN',
    /** Dopex */
    'DPX' = 'DPX',
    /** Starfish Finance */
    'SEAN' = 'SEAN',
    /** PepePAD */
    'PEPE' = 'PEPE',
    /** Artichoke Protocol */
    'CHOKE' = 'CHOKE',
    /** Silo Finance */
    'SILO' = 'SILO',
    /** PlutusDAO */
    'PLS' = 'PLS',
    /** Arbswap */
    'ARBS' = 'ARBS',
    /** Real USD */
    'USDR' = 'USDR',
    /** Jones DAO */
    'JONES' = 'JONES',
    /** ApeX Protocol */
    'APEX' = 'APEX',
    /** Yieldification */
    'YDF' = 'YDF',
    /** unshETHing_Token */
    'USH' = 'USH',
    /** Fluidity */
    'FUSDC' = 'FUSDC',
    /** Buffer Finance */
    'BFR' = 'BFR',
    /** YachtingVerse */
    'YACHT' = 'YACHT',
    /** Sanko GameCorp */
    'DMT' = 'DMT',
    /** BattleFly */
    'GFLY' = 'GFLY',
    /** Astra DAO */
    'ASTRADAO' = 'ASTRADAO',
    /** EUROe Stablecoin */
    'EUROE' = 'EUROE',
    /** Wrapped USDR */
    'WUSDR' = 'WUSDR',
    /** AiAkita */
    'AIAKITA' = 'AIAKITA',
    /** MemePad */
    'MEPAD' = 'MEPAD',
    /** XDAO */
    'XDAO' = 'XDAO',
    /** PLEXUS */
    'PLX' = 'PLX',
    /** Premia */
    'PREMIA' = 'PREMIA',
    /** GMD Protocol */
    'GMD' = 'GMD',
    /** Poison Finance */
    'POI$ON' = 'POI$ON',
    /** Lodestar */
    'LODE' = 'LODE',
    /** Zen Panda Coin */
    'ZPC' = 'ZPC',
    /** UniDex */
    'UNIDX' = 'UNIDX',
    /** Elk Finance */
    'ELK' = 'ELK',
    /** Spool */
    'SPOOL' = 'SPOOL',
    /** Bolide */
    'BLID' = 'BLID',
    /** Neptune Mutual */
    'NPM' = 'NPM',
    /** Kenshi V3 */
    'KNS' = 'KNS',
    /** Flourishing AI */
    'AI' = 'AI',
    /** handleFOREX */
    'FOREX' = 'FOREX',
    /** ArbiPad */
    'ARBI' = 'ARBI',
    /** Volta Club */
    'VOLTA' = 'VOLTA',
    /** BetSwirl */
    'BETS' = 'BETS',
    /** Factor */
    'FCTR' = 'FCTR',
    /** Spherium */
    'SPHRI' = 'SPHRI',
    /** MAGIKAL.ai */
    'MGKL' = 'MGKL',
    /** Oath */
    'OATH' = 'OATH',
    /** Archi Finance */
    'ARCHI' = 'ARCHI',
    /** Wombex Finance */
    'WMX' = 'WMX',
    /** Y2K */
    'Y2K' = 'Y2K',
    /** YURI */
    'YURI' = 'YURI',
    /** Equilibria Finance */
    'EQB' = 'EQB',
    /** Wonderful Memories */
    'WMEMO' = 'WMEMO',
    /** Cap */
    'CAP' = 'CAP',
    /** Ramses Exchange */
    'RAM' = 'RAM',
    /** Digitex */
    'DGTX' = 'DGTX',
    /** RadioShack */
    'RADIO' = 'RADIO',
    /** Frax Price Index Share */
    'FPIS' = 'FPIS',
    /** Hamachi Finance */
    'HAMI' = 'HAMI',
    /** Rodeo Finance */
    'RDO' = 'RDO',
    /** EthereumMax */
    'EMAX' = 'EMAX',
    /** AICORE */
    'AICORE' = 'AICORE',
    /** Guardian */
    'GUARD' = 'GUARD',
    /** Popcorn */
    'POP' = 'POP',
    /** SHARBI */
    'SHARBI' = 'SHARBI',
    /** Altitude */
    'ALTD' = 'ALTD',
    /** NFTEarthOFT */
    'NFTE' = 'NFTE',
    /** Ghast */
    'GHA' = 'GHA',
    /** GBOT */
    'GBOT' = 'GBOT',
    /** Onchain Trade */
    'OT' = 'OT',
    /** LionDEX */
    'LION' = 'LION',
    /** Bitindi Chain */
    'BNI' = 'BNI',
    /** Bitcointry Token */
    'BTTY' = 'BTTY',
    /** Arken Finance */
    'ARKEN' = 'ARKEN',
    /** Wrapped Accumulate */
    'WACME' = 'WACME',
    /** Overnight DAI+ */
    'DAI+' = 'DAI+',
    /** The White Lotus */
    'LOTUS' = 'LOTUS',
    /** Crypto Volatility Token */
    'CVOL' = 'CVOL',
    /** LeverageInu */
    'LEVI' = 'LEVI',
    /** Honor World Token */
    'HWT' = 'HWT',
    /** El Dorado Exchange (Arbitrum) */
    'EDE' = 'EDE',
    /** Sarcophagus */
    'SARCO' = 'SARCO',
    /** Future AI */
    'FUTURE-AI' = 'FUTURE-AI',
    /** SB Group */
    'SBG' = 'SBG',
    /** WardenSwap */
    'WAD' = 'WAD',
    /** Swapr */
    'SWPR' = 'SWPR',
    /** DEI */
    'DEI' = 'DEI',
    /** StereoAI */
    'STAI' = 'STAI',
    /** Minato */
    'MNTO' = 'MNTO',
    /** Nulswap */
    'NSWAP' = 'NSWAP',
    /** unshETH Ether */
    'UNSHETH' = 'UNSHETH',
    /** Wagmi */
    'WAGMI' = 'WAGMI',
    /** ETHforestAI */
    'ETHFAI' = 'ETHFAI',
    /** Zenland */
    'ZENF' = 'ZENF',
    /** Twoge Inu */
    'TWOGE' = 'TWOGE',
    /** MyMetaTrader */
    'MMT' = 'MMT',
    /** xDollar Stablecoin */
    'XUSD' = 'XUSD',
    /** 0xACID */
    'ACID' = 'ACID',
    /** Quoll Finance */
    'QUO' = 'QUO',
    /** Sharky Swap */
    'SHARKY' = 'SHARKY',
    /** ToxicGarden.finance SEED */
    'SEED' = 'SEED',
    /** Flux Protocol */
    'FLUX' = 'FLUX',
    /** Hundred Finance */
    'HND' = 'HND',
    /** Layer2DAO */
    'L2DAO' = 'L2DAO',
    /** Falcon */
    'FLN' = 'FLN',
    /** PetLFG */
    'LICK' = 'LICK',
    /** Okcash */
    'OK' = 'OK',
    /** dForce USD */
    'USX' = 'USX',
    /** LiquidDriver */
    'LQDR' = 'LQDR',
    /** WePiggy Coin */
    'WPC' = 'WPC',
    /** Phonon DAO */
    'PHONON' = 'PHONON',
    /** Frax Price Index */
    'FPI' = 'FPI',
    /** 3xcalibur */
    'XCAL' = 'XCAL',
    /** Mintera Token */
    'MNTE' = 'MNTE',
    /** AIPeople */
    'AIPEOPLE' = 'AIPEOPLE',
    /** Kewl */
    'KEWL' = 'KEWL',
    /** Apecode */
    'APECODE' = 'APECODE',
    /** Pepe Rew */
    'PEPEREW' = 'PEPEREW',
    /** Moonlight */
    'MNLT' = 'MNLT',
    /** TLSD Coin */
    'TLSD' = 'TLSD',
    /** Arbalest */
    'AET' = 'AET',
    /** Mountain Sea World */
    'MAW' = 'MAW',
    /** Ethereum+ (Overnight) */
    'ETH+' = 'ETH+',
    /** Bitcoin Cash */
    'BCH' = 'BCH',
    /** Green Ben */
    'EBEN' = 'EBEN',
    /** Wrapped Bitcoin Cash */
    'WBCH' = 'WBCH',
    /** Bitcoin */
    'BTC' = 'BTC',
    /** Majo */
    'MAJO' = 'MAJO',
    /** BNB */
    'BNB' = 'BNB',
    /** BUSD */
    'BUSD' = 'BUSD',
    /** FTX Token */
    'FTT' = 'FTT',
    /** Bitcoin BEP2 */
    'BTCB' = 'BTCB',
    /** WazirX */
    'WRX' = 'WRX',
    /** Contentos */
    'COS' = 'COS',
    /** TROY */
    'TROY' = 'TROY',
    /** BIDR */
    'BIDR' = 'BIDR',
    /** ShareToken */
    'SHR' = 'SHR',
    /** Mithril */
    'MITH' = 'MITH',
    /** GoWithMi */
    'GMAT' = 'GMAT',
    /** Atlas Protocol */
    'ATP' = 'ATP',
    /** CryptoBonusMiles */
    'CBM' = 'CBM',
    /** Ormeus Ecosystem */
    'ECO' = 'ECO',
    /** Aeron */
    'ARNX' = 'ARNX',
    /** Newton */
    'NEW' = 'NEW',
    /** Aditus */
    'ADI' = 'ADI',
    /** Raven Protocol */
    'RAVEN' = 'RAVEN',
    /** Lition */
    'LIT' = 'LIT',
    /** CPChain */
    'CPC' = 'CPC',
    /** Honest */
    'HNST' = 'HNST',
    /** Mcashchain */
    'MCASH' = 'MCASH',
    /** Fantom */
    'FTM' = 'FTM',
    /** Chiliz */
    'CHZ' = 'CHZ',
    /** Kava */
    'KAVA' = 'KAVA',
    /** Trust Wallet Token */
    'TWT' = 'TWT',
    /** Nexo */
    'NEXO' = 'NEXO',
    /** Viction */
    'VIC' = 'VIC',
    /** Aergo */
    'AERGO' = 'AERGO',
    /** COTI */
    'COTI' = 'COTI',
    /** Dusk */
    'DUSK' = 'DUSK',
    /** LTO Network */
    'LTO' = 'LTO',
    /** Rupiah Token */
    'IDRT' = 'IDRT',
    /** Oxen */
    'OXEN' = 'OXEN',
    /** MultiVAC */
    'MTV' = 'MTV',
    /** ChangeNOW Token */
    'NOW' = 'NOW',
    /** Cashaa */
    'CAS' = 'CAS',
    /** Swingby */
    'SWINGBY' = 'SWINGBY',
    /** Wagerr */
    'WGR' = 'WGR',
    /** TrueChain */
    'TRUE' = 'TRUE',
    /** SafePal */
    'SFP' = 'SFP',
    /** Cheelee */
    'CHEEL' = 'CHEEL',
    /** Venus BNB */
    'vBNB' = 'vBNB',
    /** Venus BTC */
    'vBTC' = 'vBTC',
    /** Venus */
    'XVS' = 'XVS',
    /** Open Campus */
    'EDU' = 'EDU',
    /** Ontology Gas */
    'ONG' = 'ONG',
    /** Hooked Protocol */
    'HOOK' = 'HOOK',
    /** Venus USDC */
    'vUSDC' = 'vUSDC',
    /** BinaryX */
    'BNX' = 'BNX',
    /** BakeryToken */
    'BAKE' = 'BAKE',
    /** Venus ETH */
    'vETH' = 'vETH',
    /** Metars Genesis */
    'MRS' = 'MRS',
    /** MOBOX */
    'MBOX' = 'MBOX',
    /** GameFi.org */
    'GAFI' = 'GAFI',
    /** Games for a Living */
    'GFAL' = 'GFAL',
    /** Unifi Protocol DAO */
    'UNFI' = 'UNFI',
    /** Venus BUSD */
    'vBUSD' = 'vBUSD',
    /** Metacraft */
    'MCT' = 'MCT',
    /** Altura */
    'ALU' = 'ALU',
    /** MEMETOON */
    'MEME' = 'MEME',
    /** ChainGPT */
    'CGPT' = 'CGPT',
    /** Sologenic */
    'SOLO' = 'SOLO',
    /** TokenFi */
    'TOKEN' = 'TOKEN',
    /** Biswap */
    'BSW' = 'BSW',
    /** Toko Token */
    'TKO' = 'TKO',
    /** Clash of Lilliput */
    'COL' = 'COL',
    /** Venus XVS */
    'vXVS' = 'vXVS',
    /** H2O DAO */
    'H2O' = 'H2O',
    /** Splintershards */
    'SPS' = 'SPS',
    /** Venus USDT */
    'vUSDT' = 'vUSDT',
    /** Zebec */
    'ZBC' = 'ZBC',
    /** RichQUACK.com */
    'QUACK' = 'QUACK',
    /** TokenPocket */
    'TPT' = 'TPT',
    /** Alpaca Finance */
    'ALPACA' = 'ALPACA',
    /** StrikeX */
    'STRX' = 'STRX',
    /** APX */
    'APX' = 'APX',
    /** BurgerCities */
    'BURGER' = 'BURGER',
    /** Tranchess */
    'CHESS' = 'CHESS',
    /** TABOO TOKEN */
    'TABOO' = 'TABOO',
    /** Poolz Finance */
    'POOLX' = 'POOLX',
    /** Alitas */
    'ALT' = 'ALT',
    /** Velo */
    'VELO' = 'VELO',
    /** Gifto */
    'GFT' = 'GFT',
    /** Tenset */
    '10SET' = '10SET',
    /** Petals */
    'PTS' = 'PTS',
    /** Alpine F1 Team Fan Token */
    'ALPINE' = 'ALPINE',
    /** Carbon browser */
    'CSIX' = 'CSIX',
    /** Defigram */
    'DFG' = 'DFG',
    /** UNS TOKEN */
    'UNS' = 'UNS',
    /** Genopets */
    'GENE' = 'GENE',
    /** SpaceN */
    'SN' = 'SN',
    /** Everdome */
    'DOME' = 'DOME',
    /** YooShi */
    'YOOSHI' = 'YOOSHI',
    /** BSCPAD */
    'BSCPAD' = 'BSCPAD',
    /** S.S. Lazio Fan Token */
    'LAZIO' = 'LAZIO',
    /** Metahero */
    'HERO' = 'HERO',
    /** Ellipsis */
    'EPX' = 'EPX',
    /** LitLab Games */
    'LITT' = 'LITT',
    /** PlayZap */
    'PZP' = 'PZP',
    /** Ellipsis */
    'EPS' = 'EPS',
    /** FC Porto Fan Token */
    'PORTO' = 'PORTO',
    /** Santos FC Fan Token */
    'SANTOS' = 'SANTOS',
    /** Pitbull */
    'PIT' = 'PIT',
    /** Scallop */
    'SCLP' = 'SCLP',
    /** Venus LINK */
    'vLINK' = 'vLINK',
    /** SquidGrow */
    'SQUIDGROW' = 'SQUIDGROW',
    /** Dark Frontiers */
    'DARK' = 'DARK',
    /** Diamond Launch */
    'DLC' = 'DLC',
    /** ETHAX */
    'ETHAX' = 'ETHAX',
    /** MarsDAO */
    'MDAO' = 'MDAO',
    /** Pallapay */
    'PALLA' = 'PALLA',
    /** OpenOcean */
    'OOE' = 'OOE',
    /** Sinverse */
    'SIN' = 'SIN',
    /** Decubate */
    'DCB' = 'DCB',
    /** Torum */
    'XTM' = 'XTM',
    /** VIMworld */
    'VEED' = 'VEED',
    /** Gulf Coin */
    'GULF' = 'GULF',
    /** WeWay */
    'WWY' = 'WWY',
    /** Landshare */
    'LAND' = 'LAND',
    /** KALM */
    'KALM' = 'KALM',
    /** Catgirl */
    'CATGIRL' = 'CATGIRL',
    /** Thetan Arena */
    'THG' = 'THG',
    /** Venus LTC */
    'vLTC' = 'vLTC',
    /** Venus DAI */
    'vDAI' = 'vDAI',
    /** Blocto Token */
    'BLT' = 'BLT',
    /** BullPerks */
    'BLP' = 'BLP',
    /** Meta Apes */
    'PEEL' = 'PEEL',
    /** BabySwap */
    'BABY' = 'BABY',
    /** RealFevr */
    'FEVR' = 'FEVR',
    /** DeHub */
    'DHB' = 'DHB',
    /** Solidus Ai Tech */
    'AITECH' = 'AITECH',
    /** Renewable Energy */
    'RET' = 'RET',
    /** WeSendit */
    'WSI' = 'WSI',
    /** MagicCraft */
    'MCRT' = 'MCRT',
    /** SHILL Token */
    'SHILL' = 'SHILL',
    /** Bitcoin Standard Hashrate Token */
    'BTCST' = 'BTCST',
    /** Hamster */
    'HAM' = 'HAM',
    /** Vai */
    'VAI' = 'VAI',
    /** NFTb */
    'NFTB' = 'NFTB',
    /** BSCS */
    'BSCS' = 'BSCS',
    /** ACA Token */
    'ACA' = 'ACA',
    /** Ertha */
    'ERTHA' = 'ERTHA',
    /** THENA */
    'THE' = 'THE',
    /** Inflation Hedging Coin */
    'IHC' = 'IHC',
    /** TopGoal */
    'GOAL' = 'GOAL',
    /** MOVEZ */
    'MOVEZ' = 'MOVEZ',
    /** DOGGY */
    'DOGGY' = 'DOGGY',
    /** Horizon Protocol */
    'HZN' = 'HZN',
    /** Venus SXP */
    'vSXP' = 'vSXP',
    /** Fabwelt */
    'WELT' = 'WELT',
    /** Gamestarter */
    'GAME' = 'GAME',
    /** Venus Reward Token */
    'VRT' = 'VRT',
    /** CumRocket */
    'CUMMIES' = 'CUMMIES',
    /** TiFi Token */
    'TIFI' = 'TIFI',
    /** NuriTopia */
    'NBLU' = 'NBLU',
    /** Sonar */
    'PING' = 'PING',
    /** Infinity PAD */
    'IPAD' = 'IPAD',
    /** IguVerse */
    'IGU' = 'IGU',
    /** Seascape Crowns */
    'CWS' = 'CWS',
    /** Mint Club */
    'MINT' = 'MINT',
    /** Venus DOT */
    'vDOT' = 'vDOT',
    /** SafeMoon */
    'SAFEMOON' = 'SAFEMOON',
    /** Monsta Infinite */
    'MONI' = 'MONI',
    /** Wall Street Games */
    'WSG' = 'WSG',
    /** Dreams Quest */
    'DREAMS' = 'DREAMS',
    /** Defina Finance */
    'FINA' = 'FINA',
    /** Spores Network */
    'SPO' = 'SPO',
    /** Pokemon Play */
    'PPC' = 'PPC',
    /** My DeFi Pet */
    'DPET' = 'DPET',
    /** BRN Metaverse */
    'BRN' = 'BRN',
    /** Green Satoshi Token (BSC) */
    'GST' = 'GST',
    /** AstroSwap */
    'ASTRO' = 'ASTRO',
    /** JulSwap */
    'JULD' = 'JULD',
    /** Revolve Games */
    'RPG' = 'RPG',
    /** PearDAO */
    'PEX' = 'PEX',
    /** King Shiba */
    'KINGSHIB' = 'KINGSHIB',
    /** The Dons */
    'DONS' = 'DONS',
    /** Auto */
    'AUTO' = 'AUTO',
    /** DRIFE */
    'DRF' = 'DRF',
    /** TRAVA.FINANCE */
    'TRAVA' = 'TRAVA',
    /** Position Exchange */
    'POSI' = 'POSI',
    /** City Tycoon Games */
    'CTG' = 'CTG',
    /** Kryptomon */
    'KMON' = 'KMON',
    /** BeNFT Solutions */
    'BeAI' = 'BeAI',
    /** XCAD Network Play */
    'PLAY' = 'PLAY',
    /** AgeOfGods */
    'AOG' = 'AOG',
    /** FaraLand */
    'FARA' = 'FARA',
    /** ThreeFold */
    'TFT' = 'TFT',
    /** DogeBonk */
    'DOBO' = 'DOBO',
    /** PornRocket */
    'PORNROCKET' = 'PORNROCKET',
    /** Creo Engine */
    'CREO' = 'CREO',
    /** GamesPad */
    'GMPD' = 'GMPD',
    /** Okratech Token */
    'ORT' = 'ORT',
    /** KISSAN */
    'KSN' = 'KSN',
    /** HoDooi.com */
    'HOD' = 'HOD',
    /** Spore */
    'SPORE' = 'SPORE',
    /** Education Ecosystem */
    'LEDU' = 'LEDU',
    /** IBStoken */
    'IBS' = 'IBS',
    /** April */
    'APRIL' = 'APRIL',
    /** aiRight */
    'AIRI' = 'AIRI',
    /** CryptoBlades */
    'SKILL' = 'SKILL',
    /** Venus BCH */
    'vBCH' = 'vBCH',
    /** NFT Art Finance */
    'NFTART' = 'NFTART',
    /** Lightning */
    'LIGHT' = 'LIGHT',
    /** LABS Group */
    'LABS' = 'LABS',
    /** Streamity */
    'STM' = 'STM',
    /** Belt Finance */
    'BELT' = 'BELT',
    /** GoCrypto Token */
    'GOC' = 'GOC',
    /** Azbit */
    'AZ' = 'AZ',
    /** Oggy Inu (BSC) */
    'OGGY' = 'OGGY',
    /** TripCandy */
    'CANDY' = 'CANDY',
    /** KCCPAD */
    'KCCPAD' = 'KCCPAD',
    /** AcknoLedger */
    'ACK' = 'ACK',
    /** Cyclone Protocol */
    'CYC' = 'CYC',
    /** Spartan Protocol */
    'SPARTA' = 'SPARTA',
    /** Metaverse Face */
    'MEFA' = 'MEFA',
    /** Elemon */
    'ELMON' = 'ELMON',
    /** Portuma */
    'POR' = 'POR',
    /** Binemon */
    'BIN' = 'BIN',
    /** OptionRoom */
    'ROOM' = 'ROOM',
    /** MetaSoccer */
    'MSU' = 'MSU',
    /** Nafter */
    'NAFT' = 'NAFT',
    /** Rainbow Token */
    'RAINBOW' = 'RAINBOW',
    /** Lunar */
    'LNR' = 'LNR',
    /** Billion Happiness */
    'BHC' = 'BHC',
    /** Kaby Arena */
    'KABY' = 'KABY',
    /** Avatly */
    'AVA' = 'AVA',
    /** STARSHIP */
    'STARSHIP' = 'STARSHIP',
    /** Monsterra (MSTR) */
    'MSTR' = 'MSTR',
    /** Terran Coin */
    'TRR' = 'TRR',
    /** HyperChainX */
    'HYPER' = 'HYPER',
    /** Dexsport */
    'DESU' = 'DESU',
    /** XP NETWORK */
    'XPNET' = 'XPNET',
    /** Mist */
    'MIST' = 'MIST',
    /** CryptoZoon */
    'ZOON' = 'ZOON',
    /** ritestream */
    'RITE' = 'RITE',
    /** KingdomStarter */
    'KDG' = 'KDG',
    /** Lanceria */
    'LANC' = 'LANC',
    /** Little Rabbit v2 */
    'LTRBT' = 'LTRBT',
    /** Plant Vs Undead */
    'PVU' = 'PVU',
    /** Propel */
    'PEL' = 'PEL',
    /** Infinity Games */
    'ING' = 'ING',
    /** Amazy */
    'AZY' = 'AZY',
    /** Binamon */
    'BMON' = 'BMON',
    /** Centric Swap */
    'CNS' = 'CNS',
    /** Hunter Token / Digital Arms */
    'HNTR' = 'HNTR',
    /** PolkaWar */
    'PWAR' = 'PWAR',
    /** Linked Finance World */
    'LFW' = 'LFW',
    /** My Master War */
    'MAT' = 'MAT',
    /** HashBX */
    'HBX' = 'HBX',
    /** BLink */
    'BLINK' = 'BLINK',
    /** Battle Hero */
    'BATH' = 'BATH',
    /** Cryptopolis */
    'CPO' = 'CPO',
    /** Oikos */
    'OKS' = 'OKS',
    /** Bitteam token */
    'BTT' = 'BTT',
    /** Dragon Kart */
    'KART' = 'KART',
    /** yieldwatch */
    'WATCH' = 'WATCH',
    /** Pacoca */
    'PACOCA' = 'PACOCA',
    /** Chirpley */
    'CHRP' = 'CHRP',
    /** Refinable */
    'FINE' = 'FINE',
    /** Argon */
    'ARGON' = 'ARGON',
    /** Chain of Legends */
    'CLEG' = 'CLEG',
    /** HeroesTD */
    'HTD' = 'HTD',
    /** Nerve Finance */
    'NRV' = 'NRV',
    /** Mrweb Finance (new) */
    'AMA' = 'AMA',
    /** CryptEx */
    'CRX' = 'CRX',
    /** Metaverse VR */
    'MEVR' = 'MEVR',
    /** Helmet.insure */
    'HELMET' = 'HELMET',
    /** ASPO World */
    'ASPO' = 'ASPO',
    /** Silva Token */
    'SILVA' = 'SILVA',
    /** Metaverser */
    'MTVT' = 'MTVT',
    /** COGI */
    'COGI' = 'COGI',
    /** AirNFTs */
    'AIRT' = 'AIRT',
    /** ShredN */
    'SHRED' = 'SHRED',
    /** Step® */
    'STEP' = 'STEP',
    /** Wolf Safe Poor People */
    'WSPP' = 'WSPP',
    /** Hero Blaze: Three Kingdoms */
    'MUDOL2' = 'MUDOL2',
    /** BHO Network */
    'BHO' = 'BHO',
    /** Qrkita Token */
    'QRT' = 'QRT',
    /** MetaverseX */
    'METAX' = 'METAX',
    /** Dynamix */
    'DYNA' = 'DYNA',
    /** Rune */
    'RUNE' = 'RUNE',
    /** Shield Protocol */
    'SHIELD' = 'SHIELD',
    /** AFEN Blockchain Network */
    'AFEN' = 'AFEN',
    /** Ninneko */
    'NINO' = 'NINO',
    /** The Corgi of PolkaBridge */
    'CORGIB' = 'CORGIB',
    /** Aera */
    'DIE' = 'DIE',
    /** SeedOn */
    'SEON' = 'SEON',
    /** CryptoTanks */
    'TANK' = 'TANK',
    /** ShopNEXT */
    'NEXT' = 'NEXT',
    /** MMOCoin */
    'MMO' = 'MMO',
    /** Pancake Bunny */
    'BUNNY' = 'BUNNY',
    /** Gunstar Metaverse */
    'GSTS' = 'GSTS',
    /** Mytheria */
    'MYRA' = 'MYRA',
    /** Supreme Finance */
    'HYPE' = 'HYPE',
    /** KIWIGO */
    'KGO' = 'KGO',
    /** FOTA - Fight Of The Ages */
    'FOTA' = 'FOTA',
    /** Sable Finance */
    'SABLE' = 'SABLE',
    /** BEMIL Coin */
    'BEM' = 'BEM',
    /** Elpis Battle */
    'EBA' = 'EBA',
    /** Kawaii Islands */
    'KWT' = 'KWT',
    /** Jade Currency */
    'JADE' = 'JADE',
    /** WOWswap */
    'WOW' = 'WOW',
    /** SONM (BEP-20) */
    'SNM' = 'SNM',
    /** CafeSwap Token */
    'BREW' = 'BREW',
    /** MoneySwap */
    'MSWAP' = 'MSWAP',
    /** Lucretius */
    'LUC' = 'LUC',
    /** MicroPets */
    'PETS' = 'PETS',
    /** TheForce Trade */
    'FOC' = 'FOC',
    /** Bunicorn */
    'BUNI' = 'BUNI',
    /** CryptoTycoon */
    'CTT' = 'CTT',
    /** Berry Data */
    'BRY' = 'BRY',
    /** SugarBounce */
    'TIP' = 'TIP',
    /** Rigel Protocol */
    'RGP' = 'RGP',
    /** Citadel.one */
    'XCT' = 'XCT',
    /** Beer Money */
    'BEER' = 'BEER',
    /** ByteNext */
    'BNU' = 'BNU',
    /** BetU */
    'BETU' = 'BETU',
    /** CoinAlpha */
    'ALP' = 'ALP',
    /** Hertz Network */
    'HTZ' = 'HTZ',
    /** Goose Finance */
    'EGG' = 'EGG',
    /** ClassicDoge */
    'XDOGE' = 'XDOGE',
    /** Pixer Eternity */
    'PXT' = 'PXT',
    /** SparkPoint Fuel */
    'SFUEL' = 'SFUEL',
    /** Smoothy */
    'SMTY' = 'SMTY',
    /** Twinci */
    'TWIN' = 'TWIN',
    /** beFITTER */
    'FIU' = 'FIU',
    /** Warena */
    'RENA' = 'RENA',
    /** Ancient Kingdom */
    'DOM' = 'DOM',
    /** SPRINT */
    'SWP' = 'SWP',
    /** PrivaCoin */
    'PRVC' = 'PRVC',
    /** Swirge */
    'SWG' = 'SWG',
    /** BSClaunch */
    'BSL' = 'BSL',
    /** Era Token (Era7) */
    'ERA' = 'ERA',
    /** Luna Rush */
    'LUS' = 'LUS',
    /** Moniwar */
    'MOWA' = 'MOWA',
    /** Paybswap */
    'PAYB' = 'PAYB',
    /** Knight War - The Holy Trio */
    'KWS' = 'KWS',
    /** ETNA Network */
    'ETNA' = 'ETNA',
    /** Waterfall DeFi */
    'WTF' = 'WTF',
    /** Mate */
    'MATE' = 'MATE',
    /** MinerBlox */
    'MBLOX' = 'MBLOX',
    /** Cubiex Power */
    'CBIX-P' = 'CBIX-P',
    /** PIAS */
    'PIAS' = 'PIAS',
    /** CryptoPlanes */
    'CPAN' = 'CPAN',
    /** YFFII Finance */
    'YFFII' = 'YFFII',
    /** Open Source Network */
    'OPN' = 'OPN',
    /** Venus CAKE */
    'vCAKE' = 'vCAKE',
    /** Cub Finance */
    'CUB' = 'CUB',
    /** AlphaDEX */
    'ROAR' = 'ROAR',
    /** Ark Rivals */
    'ARKN' = 'ARKN',
    /** REVOLAND TOKEN */
    'REVO' = 'REVO',
    /** Satoshi Island */
    'STC' = 'STC',
    /** Venus Cardano */
    'vADA' = 'vADA',
    /** Venus XRP */
    'vXRP' = 'vXRP',
    /** INTOverse */
    'TOX' = 'TOX',
    /** Fame MMA */
    'FAME' = 'FAME',
    /** Thunder Brawl */
    'THB' = 'THB',
    /** Venus MATIC */
    'vMATIC' = 'vMATIC',
    /** Sakai Vault */
    'SAKAI' = 'SAKAI',
    /** Absolute Sync */
    'AST' = 'AST',
    /** Venus Dogecoin */
    'vDOGE' = 'vDOGE',
    /** Trillioner */
    'TLC' = 'TLC',
    /** IL CAPO OF CRYPTO */
    'CAPO' = 'CAPO',
    /** Cyber Arena */
    'CAT' = 'CAT',
    /** L7 */
    'LSD' = 'LSD',
    /** Disney */
    'DIS' = 'DIS',
    /** MangoMan Intelligent */
    'MMIT' = 'MMIT',
    /** Galactic Quadrant */
    'GQ' = 'GQ',
    /** Baby Grok */
    'BABYGROK' = 'BABYGROK',
    /** Venus Filecoin */
    'vFIL' = 'vFIL',
    /** Hype Meme Token */
    'HMTT' = 'HMTT',
    /** REV3AL */
    'REV3L' = 'REV3L',
    /** Beacon ETH */
    'BETH' = 'BETH',
    /** Good Games Guild */
    'GGG' = 'GGG',
    /** STEMX */
    'STEMX' = 'STEMX',
    /** MetFi */
    'METFI' = 'METFI',
    /** Lux King Tech */
    'LKT' = 'LKT',
    /** BULL BTC CLUB */
    'BBC' = 'BBC',
    /** Venus BETH */
    'VBETH' = 'VBETH',
    /** GROK */
    'GROK' = 'GROK',
    /** Venus AAVE */
    'vAAVE' = 'vAAVE',
    /** Libra Incentix */
    'LIXX' = 'LIXX',
    /** Bit.Store */
    'STORE' = 'STORE',
    /** Venus TUSD */
    'vTUSD' = 'vTUSD',
    /** Friend3 */
    'F3' = 'F3',
    /** ULTRON */
    'ULX' = 'ULX',
    /** Vyvo Coin */
    'VSC' = 'VSC',
    /** Venus TRX */
    'vTRX' = 'vTRX',
    /** Acet */
    'ACT' = 'ACT',
    /** SOUNI */
    'SON' = 'SON',
    /** Openfabric AI */
    'OFN' = 'OFN',
    /** LumiShare */
    'LUMI' = 'LUMI',
    /** Meta Merge */
    'MMM' = 'MMM',
    /** HAY */
    'HAY' = 'HAY',
    /** BIBI */
    'BIBI' = 'BIBI',
    /** TryHards */
    'TRY' = 'TRY',
    /** SAFE(AnWang) */
    'SAFE' = 'SAFE',
    /** Frutti Dino */
    'FDT' = 'FDT',
    /** GetKicks */
    'KICKS' = 'KICKS',
    /** Areon Network */
    'AREA' = 'AREA',
    /** WORLD ID */
    'WOID' = 'WOID',
    /** APEcoin.dev */
    'APE' = 'APE',
    /** Platform of meme coins */
    'PAYU' = 'PAYU',
    /** EGO */
    'EGO' = 'EGO',
    /** ARTT Network */
    'ARTT' = 'ARTT',
    /** Venus UNI */
    'vUNI' = 'vUNI',
    /** PRIVATEUM GLOBAL */
    'PRI' = 'PRI',
    /** WAM */
    'WAM' = 'WAM',
    /** Sportium */
    'SPRT' = 'SPRT',
    /** TAP FANTASY */
    'TAP' = 'TAP',
    /** MIR Token */
    'MIR' = 'MIR',
    /** Chappyz */
    'CHAPZ' = 'CHAPZ',
    /** Kyberdyne */
    'KBD' = 'KBD',
    /** HALO NFT OFFICIAL */
    'HALO' = 'HALO',
    /** Milo Inu */
    'MILO' = 'MILO',
    /** Gym Network */
    'GYMNET' = 'GYMNET',
    /** READ2N */
    'RCM' = 'RCM',
    /** MetaQ */
    'METAQ' = 'METAQ',
    /** RoboFi */
    'VICS' = 'VICS',
    /** Grok X */
    'GROK X' = 'GROK X',
    /** TypeIt */
    'TYPE' = 'TYPE',
    /** Ankr Staked BNB */
    'ankrBNB' = 'ankrBNB',
    /** Metaland Shares */
    'MLS' = 'MLS',
    /** HyperGPT */
    'HGPT' = 'HGPT',
    /** Race Kingdom */
    'ATOZ' = 'ATOZ',
    /** Ozone metaverse */
    'OZONE' = 'OZONE',
    /** Capitalrock */
    'CR' = 'CR',
    /** LunaOne */
    'XLN' = 'XLN',
    /** Infinity Rocket Token */
    'IRT' = 'IRT',
    /** BunnyPark */
    'BP' = 'BP',
    /** Space Misfits */
    'SMCW' = 'SMCW',
    /** Irena Coin Apps */
    'IRENA' = 'IRENA',
    /** Nelore Coin */
    'NLC' = 'NLC',
    /** myDid */
    'SYL' = 'SYL',
    /** LYO Credit */
    'LYO' = 'LYO',
    /** Hebeto */
    'HBT' = 'HBT',
    /** LUCA */
    'LUCA' = 'LUCA',
    /** Miner Arena */
    'MINAR' = 'MINAR',
    /** BEFE */
    'BEFE' = 'BEFE',
    /** Metatime Coin */
    'MTC' = 'MTC',
    /** Intelly */
    'INTL' = 'INTL',
    /** Pomerium */
    'PMG' = 'PMG',
    /** Love Earn Enjoy */
    'LEE' = 'LEE',
    /** FitBurn */
    'CAL' = 'CAL',
    /** MMPRO Token */
    'MMPRO' = 'MMPRO',
    /** Legion Network */
    'LGX' = 'LGX',
    /** Coloniume Network */
    'CLNX' = 'CLNX',
    /** MetaMecha */
    'MM' = 'MM',
    /** ONINO */
    'ONI' = 'ONI',
    /** MEFLEX */
    'MEF' = 'MEF',
    /** DexNet */
    'DEXNET' = 'DEXNET',
    /** DarkShield Games Studio */
    'DKS' = 'DKS',
    /** BIDZ Coin */
    'BIDZ' = 'BIDZ',
    /** Limoverse */
    'LIMO' = 'LIMO',
    /** WECOIN */
    'WECO' = 'WECO',
    /** Metagalaxy Land */
    'MEGALAND' = 'MEGALAND',
    /** GLI TOKEN */
    'GLI' = 'GLI',
    /** MTH Network */
    'MTHN' = 'MTHN',
    /** Level Finance */
    'LVL' = 'LVL',
    /** Lucky Coin */
    'LKC' = 'LKC',
    /** Custodiy */
    'CTY' = 'CTY',
    /** The Debt Box */
    'DEBT' = 'DEBT',
    /** Black Whale */
    'xXx' = 'xXx',
    /** Pink BNB */
    'PNB' = 'PNB',
    /** KAIF Platform */
    'KAF' = 'KAF',
    /** Spintop */
    'SPIN' = 'SPIN',
    /** Biblecoin */
    'BIBL' = 'BIBL',
    /** LIGHTCYCLE */
    'LILC' = 'LILC',
    /** Protocon */
    'PEN' = 'PEN',
    /** Chumbi Valley */
    'CHMB' = 'CHMB',
    /** WallStreetBets DApp */
    'WSB' = 'WSB',
    /** Doge CEO */
    'DOGECEO' = 'DOGECEO',
    /** D3D Social */
    'D3D' = 'D3D',
    /** Utility Cjournal */
    'UCJL' = 'UCJL',
    /** Ctomorrow Platform */
    'CTP' = 'CTP',
    /** CRAZY DOGE */
    'CRAZYDOGE' = 'CRAZYDOGE',
    /** Eldarune */
    'ELDA' = 'ELDA',
    /** Lucky7 */
    '#7' = '7',
    /** CheersLand */
    'CHEERS' = 'CHEERS',
    /** Mechaverse */
    'MC' = 'MC',
    /** Agatech */
    'AGATA' = 'AGATA',
    /** Virtual Tourist */
    'VT' = 'VT',
    /** Inery */
    'INR' = 'INR',
    /** Arker */
    'ARKER' = 'ARKER',
    /** CelebrityCoinV2 */
    'CCV2' = 'CCV2',
    /** Connex */
    'CONX' = 'CONX',
    /** FalconsInu */
    'FALCONS' = 'FALCONS',
    /** Distributed Autonomous Organization */
    'DAO' = 'DAO',
    /** Metafluence */
    'METO' = 'METO',
    /** GAIA Everworld */
    'GAIA' = 'GAIA',
    /** Utility Web3Shot */
    'UW3S' = 'UW3S',
    /** Eternal World */
    'ETL' = 'ETL',
    /** Metastrike */
    'MTS' = 'MTS',
    /** PandAI */
    'PANDAI' = 'PANDAI',
    /** Smarty Pay */
    'SPY' = 'SPY',
    /** Venus Luna */
    'vLUNA' = 'vLUNA',
    /** Sweeptoken */
    'SWEEP' = 'SWEEP',
    /** MRHB DeFi Network */
    'MRHB' = 'MRHB',
    /** Web3Shot */
    'W3S' = 'W3S',
    /** XSpace */
    'XSP' = 'XSP',
    /** Pop Social */
    'PPT' = 'PPT',
    /** FintraDao */
    'FDC' = 'FDC',
    /** Palmswap */
    'PALM' = 'PALM',
    /** TARALITY */
    'TARAL' = 'TARAL',
    /** KStarNFT */
    'KNFT' = 'KNFT',
    /** The X Protocol */
    'TXP' = 'TXP',
    /** BitBook */
    'BBT' = 'BBT',
    /** Daw Currency */
    'DAW' = 'DAW',
    /** BitX Exchange */
    'BITX' = 'BITX',
    /** Satellite Doge-1 */
    'DOGE-1' = 'DOGE-1',
    /** Alaska Gold Rush */
    'CARAT' = 'CARAT',
    /** Felix */
    'FLX' = 'FLX',
    /** KingdomX */
    'KT' = 'KT',
    /** pSTAKE Staked BNB */
    'STKBNB' = 'STKBNB',
    /** KittyCake */
    'KCAKE' = 'KCAKE',
    /** Top Down Survival Shooter */
    'SHOOTER' = 'SHOOTER',
    /** Diamond Token */
    'DNT' = 'DNT',
    /** CherrySwap */
    'CHE' = 'CHE',
    /** FaceDAO */
    'FACEDAO' = 'FACEDAO',
    /** Biconomy Exchange Token */
    'BIT' = 'BIT',
    /** Continuum Finance */
    'CTN' = 'CTN',
    /** REGENT COIN */
    'REGENT' = 'REGENT',
    /** Zambesigold */
    'ZGD' = 'ZGD',
    /** Agro Global */
    'AGRO' = 'AGRO',
    /** ASYAGRO */
    'ASY' = 'ASY',
    /** Real Estate Token */
    'R3T' = 'R3T',
    /** PVC Meta */
    'PVC' = 'PVC',
    /** Gyrowin */
    'GW' = 'GW',
    /** Babydoge 2.0 */
    'BABYDOGE2.0' = 'BABYDOGE2.0',
    /** Fantaverse */
    'UT' = 'UT',
    /** FLOKI CEO */
    'FLOKICEO' = 'FLOKICEO',
    /** Redluna */
    'REDLUNA' = 'REDLUNA',
    /** Trivians */
    'TRIVIA' = 'TRIVIA',
    /** Canadian Inuit Dog(NEW) */
    'CADINU' = 'CADINU',
    /** Euro Shiba Inu */
    'EShib' = 'EShib',
    /** GreenEnvCoalition */
    'GEC' = 'GEC',
    /** C+Charge */
    'CCHG' = 'CCHG',
    /** Plxyer */
    'PLXY' = 'PLXY',
    /** IGUP (IguVerse) */
    'IGUP' = 'IGUP',
    /** Streakk Chain */
    'STKC' = 'STKC',
    /** Stella Fantasy */
    'SFTY' = 'SFTY',
    /** Coinzix */
    'ZIX' = 'ZIX',
    /** Wizardia */
    'WZRD' = 'WZRD',
    /** SpaceXCoin */
    'SPXC' = 'SPXC',
    /** DfiStarter */
    'DFI' = 'DFI',
    /** Menzy */
    'MNZ' = 'MNZ',
    /** TrustFi Network */
    'TFI' = 'TFI',
    /** PRIMAL */
    'PRIMAL' = 'PRIMAL',
    /** Pacman */
    'PAC' = 'PAC',
    /** Play Kingdom */
    'PKT' = 'PKT',
    /** Baby Doge CEO */
    'BABYCEO' = 'BABYCEO',
    /** Minto */
    'BTCMT' = 'BTCMT',
    /** Milo CEO */
    'MILOCEO' = 'MILOCEO',
    /** Humanize */
    '$HMT' = '$HMT',
    /** UnityMeta */
    'UMT' = 'UMT',
    /** MoveApp */
    'MOVE' = 'MOVE',
    /** BLUEART TOKEN */
    'BLA' = 'BLA',
    /** SafeMoo */
    'SAFEMOO' = 'SAFEMOO',
    /** Oxbull.tech */
    'OXB' = 'OXB',
    /** LUNCARMY */
    'LUNCARMY' = 'LUNCARMY',
    /** eFin Decentralized */
    'WEFIN' = 'WEFIN',
    /** InpulseX(New) */
    'IPX' = 'IPX',
    /** Konstellation Network */
    'DARC' = 'DARC',
    /** Starly */
    '$STARLY' = '$STARLY',
    /** NFTCloud */
    'CLOUD' = 'CLOUD',
    /** CeBioLabs */
    'CBSL' = 'CBSL',
    /** Ookeenga */
    'OKG' = 'OKG',
    /** Thing */
    'THING' = 'THING',
    /** Atem Network */
    'ATEM' = 'ATEM',
    /** Mind Matrix */
    'AIMX' = 'AIMX',
    /** Pundi X PURSE */
    'PURSE' = 'PURSE',
    /** Battle Infinity */
    'IBAT' = 'IBAT',
    /** Tradetomato */
    'TTM' = 'TTM',
    /** DaoVerse */
    'DVRS' = 'DVRS',
    /** Artrade */
    'ATR' = 'ATR',
    /** Scotty Beam */
    'SCOTTY' = 'SCOTTY',
    /** Youclout */
    'YCT' = 'YCT',
    /** Burn Floki */
    'BFLOKI' = 'BFLOKI',
    /** Let's Go Brandon Token */
    'LGBT' = 'LGBT',
    /** Wiki Cat */
    'WKC' = 'WKC',
    /** Wizarre Scroll */
    'SCRL' = 'SCRL',
    /** marumaruNFT */
    'MARU' = 'MARU',
    /** CEO */
    'CEO' = 'CEO',
    /** Cogito Protocol */
    'CGV' = 'CGV',
    /** 2024PUMP */
    'PUMP' = 'PUMP',
    /** Oceanland */
    'OLAND' = 'OLAND',
    /** Byepix */
    'EPIX' = 'EPIX',
    /** ORDI 2.0 */
    'ORDI2' = 'ORDI2',
    /** Optimus AI */
    'OPTIMUS AI' = 'OPTIMUS AI',
    /** Storepay */
    'SPC' = 'SPC',
    /** DeHealth */
    'DHLT' = 'DHLT',
    /** French Connection Finance */
    'FCF' = 'FCF',
    /** Layerium */
    'LYUM' = 'LYUM',
    /** Kryptonite */
    'SEILOR' = 'SEILOR',
    /** Strip Finance */
    'STRIP' = 'STRIP',
    /** Day Of Defeat 2.0 */
    'DOD' = 'DOD',
    /** Black Phoenix */
    'BPX' = 'BPX',
    /** Egoras Credit */
    'EGC' = 'EGC',
    /** Sandwich Network */
    'SANDWICH' = 'SANDWICH',
    /** Optimism PEPE */
    'OPEPE' = 'OPEPE',
    /** Digihealth */
    'DGH' = 'DGH',
    /** Infiblue World */
    'MONIE' = 'MONIE',
    /** Trans Pepe */
    'TRANS' = 'TRANS',
    /** Buff Doge Coin */
    'DOGECOIN' = 'DOGECOIN',
    /** CODAI */
    'CODAI' = 'CODAI',
    /** Seedify NFT Space */
    'SNFTS' = 'SNFTS',
    /** Heroes & Empires */
    'HE' = 'HE',
    /** SEOR Network */
    'SEOR' = 'SEOR',
    /** Rare FND */
    'FND' = 'FND',
    /** Blockasset */
    'BLOCK' = 'BLOCK',
    /** Blast Frontiers */
    'BLAST' = 'BLAST',
    /** Sourceless */
    'STR' = 'STR',
    /** Espento */
    'SPENT' = 'SPENT',
    /** PodFast */
    'FAST' = 'FAST',
    /** GAMER */
    'GMR' = 'GMR',
    /** Game Coin */
    'GMEX' = 'GMEX',
    /** BattleVerse */
    'BVC' = 'BVC',
    /** Pesabase */
    'PESA' = 'PESA',
    /** Goat Gang */
    'GGT' = 'GGT',
    /** Windfall Token */
    'WFT' = 'WFT',
    /** Pin Token */
    'PIN' = 'PIN',
    /** DogeCola */
    'DOGECOLA' = 'DOGECOLA',
    /** Pig Finance */
    'PIG' = 'PIG',
    /** Octavus Prime */
    'OCTAVUS' = 'OCTAVUS',
    /** BearAI */
    'BAI' = 'BAI',
    /** CZ THE GOAT */
    'CZGOAT' = 'CZGOAT',
    /** Trustpad (New) */
    'TPAD' = 'TPAD',
    /** Marvelous NFTs (Bad Days) */
    'MNFT' = 'MNFT',
    /** REBorn(New) */
    'RB' = 'RB',
    /** KTX.Finance */
    'KTC' = 'KTC',
    /** Fistbump */
    'FIST' = 'FIST',
    /** Mecha Morphing */
    'MAPE' = 'MAPE',
    /** HELPER COIN */
    'HLPR' = 'HLPR',
    /** OMarket Global LLC */
    'OPV' = 'OPV',
    /** Galactic Arena: The NFTverse */
    'GAN' = 'GAN',
    /** Black Token */
    'BLACK' = 'BLACK',
    /** DeFi Warrior (FIWA) */
    'FIWA' = 'FIWA',
    /** KINGU */
    'KINGU' = 'KINGU',
    /** MetaFighter */
    'MF' = 'MF',
    /** LONG */
    'LONG' = 'LONG',
    /** VinuChain */
    'VC' = 'VC',
    /** SpaceY */
    'SPAY' = 'SPAY',
    /** Dragon Mainland Shards */
    'DMS' = 'DMS',
    /** 4JNET */
    '4JNET' = '4JNET',
    /** Papa Bear */
    'PAPA' = 'PAPA',
    /** Ginoa */
    'GINOA' = 'GINOA',
    /** GAMI World */
    'GAMI' = 'GAMI',
    /** Rencom Network */
    'RNT' = 'RNT',
    /** Holdex Finance */
    'HOLDEX' = 'HOLDEX',
    /** Baby Lovely Inu */
    'BLOVELY' = 'BLOVELY',
    /** CrossWallet */
    'CWT' = 'CWT',
    /** MetaShooter */
    'MHUNT' = 'MHUNT',
    /** Fanadise */
    'FAN' = 'FAN',
    /** Husky.AI */
    'HUS' = 'HUS',
    /** Digimon Rabbit */
    'DRB' = 'DRB',
    /** Puffin Global */
    'PUFFIN' = 'PUFFIN',
    /** Elephant Money */
    'ELEPHANT' = 'ELEPHANT',
    /** CATCOIN */
    'CATS' = 'CATS',
    /** Octopus Protocol */
    'OPS' = 'OPS',
    /** Shiba Lite */
    'SHIBLITE' = 'SHIBLITE',
    /** Coinscope */
    'COINSCOPE' = 'COINSCOPE',
    /** DeHeroGame Amazing Token */
    'AMG' = 'AMG',
    /** Joltify */
    'JOLT' = 'JOLT',
    /** FLIGHTCLUPCOIN */
    'FLIGHT' = 'FLIGHT',
    /** NetZero */
    'NZERO' = 'NZERO',
    /** Karus Starter */
    'KST' = 'KST',
    /** Shockwaves */
    'NEUROS' = 'NEUROS',
    /** Centcex */
    'CENX' = 'CENX',
    /** Reign of Terror */
    'REIGN' = 'REIGN',
    /** Outter Finance */
    'OUT' = 'OUT',
    /** Hero Arena */
    'HERA' = 'HERA',
    /** EXATECH PoAI Blockchain */
    'EXT' = 'EXT',
    /** Insurabler */
    'INSR' = 'INSR',
    /** Zodium */
    'ZODI' = 'ZODI',
    /** MBD Financials */
    'MBD' = 'MBD',
    /** Gaming Stars */
    'GAMES' = 'GAMES',
    /** BitShiba */
    'SHIBA' = 'SHIBA',
    /** Metapioneers */
    'MPI' = 'MPI',
    /** MASHIDA */
    'MSHD' = 'MSHD',
    /** PlatinX */
    'PTX' = 'PTX',
    /** DAOLaunch */
    'DAL' = 'DAL',
    /** Voice Street */
    'VST' = 'VST',
    /** Web3Camp */
    '3P' = '3P',
    /** BFG Token */
    'BFG' = 'BFG',
    /** BLOKPAD */
    'BPAD' = 'BPAD',
    /** BSC TOOLS */
    'TOOLS' = 'TOOLS',
    /** Froggy */
    'FROGGY' = 'FROGGY',
    /** Ratscoin */
    'RATS' = 'RATS',
    /** Pikaster */
    'RBP' = 'RBP',
    /** NFTBomb */
    'NBP' = 'NBP',
    /** Vulkania */
    'VLK' = 'VLK',
    /** SUPE */
    'SUPE' = 'SUPE',
    /** Mobipad */
    'MBP' = 'MBP',
    /** KAKA NFT World */
    'KAKA' = 'KAKA',
    /** Gay Pepe */
    'GAYPEPE' = 'GAYPEPE',
    /** LS Coin */
    'LSC' = 'LSC',
    /** LimoCoin Swap */
    'LMCSWAP' = 'LMCSWAP',
    /** BNB Whales */
    'BNB WHALES' = 'BNB WHALES',
    /** Bogey */
    'BOGEY' = 'BOGEY',
    /** ASSAPLAY */
    'ASSA' = 'ASSA',
    /** Cpos Cloud Payment */
    'CPOS' = 'CPOS',
    /** MetaWear */
    'WEAR' = 'WEAR',
    /** KickPad */
    'KPAD' = 'KPAD',
    /** Tethereum */
    'T99' = 'T99',
    /** PIPI */
    'PIPI' = 'PIPI',
    /** Seek Tiger */
    'STI' = 'STI',
    /** TBCC */
    'TBCC' = 'TBCC',
    /** Wednesday Inu */
    'WED' = 'WED',
    /** EG Token */
    'EG' = 'EG',
    /** DNAxCAT Token */
    'DXCT' = 'DXCT',
    /** T23 */
    'T23' = 'T23',
    /** A2DAO */
    'ATD' = 'ATD',
    /** MacaronSwap */
    'MCRN' = 'MCRN',
    /** ELON DOGE */
    'ELONDOGE' = 'ELONDOGE',
    /** Zone of Avoidance */
    'ZOA' = 'ZOA',
    /** Planet Sandbox */
    'PSB' = 'PSB',
    /** Smart World Union */
    'SWU' = 'SWU',
    /** Titan Hunters */
    'TITA' = 'TITA',
    /** Defi For You */
    'DFY' = 'DFY',
    /** Janex */
    'JNX' = 'JNX',
    /** Lil Floki */
    'LILFLOKI' = 'LILFLOKI',
    /** Hollywood Capital Group WARRIOR */
    'WOR' = 'WOR',
    /** Cosmic FOMO */
    'COSMIC' = 'COSMIC',
    /** SELF Crypto */
    'SELF' = 'SELF',
    /** SafeMars */
    'SMARS' = 'SMARS',
    /** Poollotto.finance */
    'PLT' = 'PLT',
    /** Gode Chain */
    'GODE' = 'GODE',
    /** Evulus Token */
    'EVU' = 'EVU',
    /** HUGHUG Coin */
    'HGHG' = 'HGHG',
    /** Streakk */
    'STKK' = 'STKK',
    /** Great Bounty Dealer */
    'GBD' = 'GBD',
    /** Cloudname */
    'CNAME' = 'CNAME',
    /** Darik */
    'DARIK' = 'DARIK',
    /** Pomerium Community Meme Token */
    'PME' = 'PME',
    /** PolyPad */
    'POLYPAD' = 'POLYPAD',
    /** DONASWAP v2 */
    'DONA' = 'DONA',
    /** CryptoMines */
    'ETERNAL' = 'ETERNAL',
    /** PlayPad */
    'PPAD' = 'PPAD',
    /** Victory Gem */
    'VTG' = 'VTG',
    /** FART COIN */
    'FRTC' = 'FRTC',
    /** Bitlocus */
    'BTL' = 'BTL',
    /** MELI */
    'MELI' = 'MELI',
    /** KWAI */
    'KWAI' = 'KWAI',
    /** MikeToken */
    'MKT' = 'MKT',
    /** Green Beli */
    'GRBE' = 'GRBE',
    /** Global Token */
    'GBL' = 'GBL',
    /** Hydraverse */
    'HDV' = 'HDV',
    /** Operon Origins */
    'ORO' = 'ORO',
    /** The Killbox */
    'KBOX' = 'KBOX',
    /** Twenty22 */
    '#2022' = '2022',
    /** Supernova Shards */
    'LFC' = 'LFC',
    /** CryptoXpress */
    'XPRESS' = 'XPRESS',
    /** Moomonster */
    'MOO' = 'MOO',
    /** Cherry Network */
    'CHER' = 'CHER',
    /** A.I.Earn */
    'AIE' = 'AIE',
    /** Nomad Exiles */
    'PRIDE' = 'PRIDE',
    /** WaultSwap */
    'WEX' = 'WEX',
    /** NeoCortexAI */
    'nCORAI' = 'nCORAI',
    /** Safemars */
    'SAFEMARS' = 'SAFEMARS',
    /** Trossard */
    'TROSS' = 'TROSS',
    /** NIX */
    'NIX' = 'NIX',
    /** Vemate */
    'VMT' = 'VMT',
    /** ITEMVERSE */
    'ITEM' = 'ITEM',
    /** Ftribe Fighters (F2 NFT) */
    'F2C' = 'F2C',
    /** League of Ancients */
    'LOA' = 'LOA',
    /** The Big Five Token */
    'BFT' = 'BFT',
    /** OptionPanda */
    'OPA' = 'OPA',
    /** The Three Kingdoms */
    'TTK' = 'TTK',
    /** Wally */
    'WALLY' = 'WALLY',
    /** Demole */
    'DMLG' = 'DMLG',
    /** Hashtagger.com */
    'MOOO' = 'MOOO',
    /** Lucro */
    'LCR' = 'LCR',
    /** Getaverse */
    'GETA' = 'GETA',
    /** PlaceWar */
    'PLACE' = 'PLACE',
    /** CryptoUnity */
    'CUT' = 'CUT',
    /** Metagame Arena */
    'MGA' = 'MGA',
    /** Babylons */
    'BABI' = 'BABI',
    /** Meta Ricaro */
    'CARO' = 'CARO',
    /** Metarun */
    'MRUN' = 'MRUN',
    /** Teddy Doge */
    'Teddy V2' = 'Teddy V2',
    /** Australian Safe Shepherd */
    'ASS' = 'ASS',
    /** Real Realm */
    'REAL' = 'REAL',
    /** STA */
    'STA' = 'STA',
    /** AISwap */
    'AIS' = 'AIS',
    /** SuperCells Token */
    'SCT' = 'SCT',
    /** FERMA SOSEDI */
    'FERMA' = 'FERMA',
    /** Space Token */
    'SPACE' = 'SPACE',
    /** Broovs Projects */
    'BRS' = 'BRS',
    /** YouCoin Metaverse (new) */
    'UCON' = 'UCON',
    /** CyberDragon Gold */
    'GOLD' = 'GOLD',
    /** Nero Token */
    'NERO' = 'NERO',
    /** GROK 2.0 */
    'GROK2' = 'GROK2',
    /** Froggies Token */
    'FRGST' = 'FRGST',
    /** MoonStarter */
    'MNST' = 'MNST',
    /** Metan Evolutions */
    'METAN' = 'METAN',
    /** Stader BNBx */
    'BNBX' = 'BNBX',
    /** Drunk Robots */
    'METAL' = 'METAL',
    /** Animal Farm Dogs */
    'AFD' = 'AFD',
    /** Altbase */
    'ALTB' = 'ALTB',
    /** MuratiAI */
    'MURATIAI' = 'MURATIAI',
    /** ShytCoin */
    'SHYTCOIN' = 'SHYTCOIN',
    /** MetaGods */
    'MGOD' = 'MGOD',
    /** Starpad */
    'SRP' = 'SRP',
    /** Hello Puppy */
    'BOB' = 'BOB',
    /** Nemesis PRO */
    'NMSP' = 'NMSP',
    /** AiMalls */
    'AIT' = 'AIT',
    /** Edufex */
    'EDUX' = 'EDUX',
    /** GoldMiner */
    'GM' = 'GM',
    /** Furio */
    '$FUR' = '$FUR',
    /** Project X */
    'XIL' = 'XIL',
    /** Street Runner NFT */
    'SRG' = 'SRG',
    /** Local Traders */
    'LCT' = 'LCT',
    /** Blin Metaverse */
    'BLIN' = 'BLIN',
    /** MetaWars */
    'WARS' = 'WARS',
    /** Supreme Finance */
    'HYPES' = 'HYPES',
    /** MetaRim */
    'RIM' = 'RIM',
    /** BNB48 Club Token */
    'KOGE' = 'KOGE',
    /** Steam Exchange */
    'STEAMX' = 'STEAMX',
    /** iFortune */
    'IFC' = 'IFC',
    /** Shihtzu Exchange */
    'STZU' = 'STZU',
    /** StarSharks (SSS) */
    'SSS' = 'SSS',
    /** BITS FACTOR */
    'BFTC' = 'BFTC',
    /** Idle Ninja Online */
    'NINKY' = 'NINKY',
    /** Grizzly Honey */
    'GHNY' = 'GHNY',
    /** The Kingdom Coin */
    'TKC' = 'TKC',
    /** Block Commerce Protocol */
    'BCP' = 'BCP',
    /** Banus.Finance */
    'BANUS' = 'BANUS',
    /** Floki SafeMoon */
    'FSM' = 'FSM',
    /** Alium Finance */
    'ALM' = 'ALM',
    /** Gays Inu */
    'LGBTQ' = 'LGBTQ',
    /** Seba */
    'SEBA' = 'SEBA',
    /** QUINT */
    'QUINT' = 'QUINT',
    /** Dogs Rock */
    'DOGSROCK' = 'DOGSROCK',
    /** Monopoly Millionaire Control */
    'MMC' = 'MMC',
    /** SpacePi */
    'SPACEPI' = 'SPACEPI',
    /** Paragen */
    'RGEN' = 'RGEN',
    /** ELON MARS */
    'ELONMARS' = 'ELONMARS',
    /** RefundToken */
    'RFT' = 'RFT',
    /** XcelDefi */
    'XLD' = 'XLD',
    /** ArtDraw */
    'ARTDRAW' = 'ARTDRAW',
    /** Animal Farm Pigs */
    'AFP' = 'AFP',
    /** Stabledoc */
    'SDT' = 'SDT',
    /** Pikaster */
    'SRBP' = 'SRBP',
    /** Cake Monster */
    'MONSTA' = 'MONSTA',
    /** ReadFi */
    '$RDF' = '$RDF',
    /** BinStarter */
    'BSR' = 'BSR',
    /** Froge */
    'FROGE' = 'FROGE',
    /** Vulture Peak */
    'VPK' = 'VPK',
    /** Green Blue Coin */
    'GBC' = 'GBC',
    /** NOOT */
    '$NOOT' = '$NOOT',
    /** Dexagon */
    'DXC' = 'DXC',
    /** BabyBNBTiger */
    'BABYBNBTIGER' = 'BABYBNBTIGER',
    /** MTG Token */
    'MTG' = 'MTG',
    /** The Wasted Lands */
    'WAL' = 'WAL',
    /** BNBTiger */
    'BNBTIGER' = 'BNBTIGER',
    /** Shitcoin */
    'SHIT' = 'SHIT',
    /** Nobility */
    'NBL' = 'NBL',
    /** Gem Guardian */
    'GEMG' = 'GEMG',
    /** Medamon */
    'MON' = 'MON',
    /** Momentum */
    'MASS' = 'MASS',
    /** EGOLD */
    'EGOLD' = 'EGOLD',
    /** Malgo Finance */
    'MGXG' = 'MGXG',
    /** Bit Hotel */
    'BTH' = 'BTH',
    /** PARMA Fan Token */
    'PARMA' = 'PARMA',
    /** Hudi */
    'HUDI' = 'HUDI',
    /** saxumdao */
    'SXM' = 'SXM',
    /** MR BOB COIN */
    'MRBOB' = 'MRBOB',
    /** Wojak Finance */
    'WOJ' = 'WOJ',
    /** Krypton DAO */
    'KRD' = 'KRD',
    /** Neurashi */
    'NEI' = 'NEI',
    /** Satozhi */
    'SATOZ' = 'SATOZ',
    /** Baby Meme Coin */
    'BABYMEME' = 'BABYMEME',
    /** New Year Token */
    'NYT' = 'NYT',
    /** NFTGamingStars */
    'GS1' = 'GS1',
    /** Odesis */
    '$ODS' = '$ODS',
    /** ViteX Coin */
    'VX' = 'VX',
    /** Asva */
    'ASVA' = 'ASVA',
    /** Chitaverse */
    'BCT' = 'BCT',
    /** Metarix */
    'MTRX' = 'MTRX',
    /** Baby WOJ */
    'BWJ' = 'BWJ',
    /** Embr */
    'EMBR' = 'EMBR',
    /** Meta Game City */
    'MECI' = 'MECI',
    /** Titi Financial */
    'TITI' = 'TITI',
    /** BlocX */
    'BLX' = 'BLX',
    /** FUFU */
    'FUFU' = 'FUFU',
    /** Child Support */
    'CS' = 'CS',
    /** iVipCoin */
    'IVIP' = 'IVIP',
    /** DOGE-1SATELLITE */
    'DOGE-1SAT' = 'DOGE-1SAT',
    /** What Do You Meme */
    'WDYM' = 'WDYM',
    /** Pandora Finance */
    'PNDR' = 'PNDR',
    /** Bone */
    'BONE' = 'BONE',
    /** Neuralink */
    'NLINK' = 'NLINK',
    /** StarSharks SEA */
    'SEA' = 'SEA',
    /** Level Finance */
    'LGO' = 'LGO',
    /** TipsyCoin */
    '$TIPSY' = '$TIPSY',
    /** Bitcoin City Coin */
    'BCITY' = 'BCITY',
    /** Aussie Digital */
    'AUD' = 'AUD',
    /** ZakumiFi */
    'ZAFI' = 'ZAFI',
    /** SEKUYA */
    'SKUY' = 'SKUY',
    /** Murasaki */
    'MURA' = 'MURA',
    /** FelixCoin */
    'FELIX' = 'FELIX',
    /** OBRok Token */
    'OBROK' = 'OBROK',
    /** Pepe 3.0 */
    'PEPE3.0' = 'PEPE3.0',
    /** Nominex */
    'NMX' = 'NMX',
    /** LoopSwap */
    'LSWAP' = 'LSWAP',
    /** SKY FRONTIER */
    'GSKY' = 'GSKY',
    /** Cakebot */
    'CAKEBOT' = 'CAKEBOT',
    /** BasketCoin */
    'BSKT' = 'BSKT',
    /** Coinary Token */
    'CYT' = 'CYT',
    /** Dex on Crypto */
    'DOCSWAP' = 'DOCSWAP',
    /** Jesus Coin (BSC) */
    'JESUS' = 'JESUS',
    /** Bitbull */
    'BITBULL' = 'BITBULL',
    /** Birb */
    'BIRB' = 'BIRB',
    /** BLU */
    'BLU' = 'BLU',
    /** Multi AI */
    'MAI' = 'MAI',
    /** GBURN */
    'GBURN' = 'GBURN',
    /** Coinracer Reloaded */
    'CRACER' = 'CRACER',
    /** Moonlight Token */
    'MOONLIGHT' = 'MOONLIGHT',
    /** Pi INU */
    'PINU' = 'PINU',
    /** RYI Unity */
    'RYIU' = 'RYIU',
    /** Scrooge */
    'SCROOGE' = 'SCROOGE',
    /** IMOV */
    'IMT' = 'IMT',
    /** ScarQuest */
    'SCAR' = 'SCAR',
    /** Crypteriumcoin */
    'CCOIN' = 'CCOIN',
    /** VIP Token */
    'VIP' = 'VIP',
    /** SpritzMoon Crypto Token */
    'spritzmoon' = 'spritzmoon',
    /** SKUYX */
    'SKYX' = 'SKYX',
    /** COCKROACH-COIN */
    'ROACHCOIN' = 'ROACHCOIN',
    /** STACKS */
    'STACKS' = 'STACKS',
    /** Pepa Inu */
    'PEPA' = 'PEPA',
    /** AI DogeX */
    'AIDOGEX' = 'AIDOGEX',
    /** UvToken */
    'UVT' = 'UVT',
    /** Sphynx Labs */
    'SPHYNX' = 'SPHYNX',
    /** HODL */
    'HODL' = 'HODL',
    /** Echo Of The Horizon */
    'EOTH' = 'EOTH',
    /** Lenda */
    'LENDA' = 'LENDA',
    /** TRUSTxGAMING */
    'TXG' = 'TXG',
    /** OXO Network */
    'OXO' = 'OXO',
    /** Digital Files */
    'DIFI' = 'DIFI',
    /** Biokript */
    'BKPT' = 'BKPT',
    /** Synclub staked BNB */
    'SnBNB' = 'SnBNB',
    /** Revault Network */
    'REVA' = 'REVA',
    /** LiveGreen Coin */
    'LGC' = 'LGC',
    /** T-mac DAO */
    'TMG' = 'TMG',
    /** Dominant Kong */
    'DK' = 'DK',
    /** SafeLaunch */
    'SFEX' = 'SFEX',
    /** SPECIEX */
    'SPEX' = 'SPEX',
    /** MultiFunctional Environmental Token */
    'MFET' = 'MFET',
    /** KENKA METAVERSE */
    'KENKA' = 'KENKA',
    /** JK Coin */
    'JK' = 'JK',
    /** TRONPAD */
    'TRONPAD' = 'TRONPAD',
    /** xAI */
    'XAI' = 'XAI',
    /** BODA Token */
    'BODAV2' = 'BODAV2',
    /** Sonic Inu */
    'SONIC' = 'SONIC',
    /** Affinity */
    'AFNTY' = 'AFNTY',
    /** Carmin */
    'CARMIN' = 'CARMIN',
    /** TopManager */
    'TMT' = 'TMT',
    /** Vibing */
    'VBG' = 'VBG',
    /** Hummingbird Finance (New) */
    'HMNG' = 'HMNG',
    /** Avocado Blockchain Group */
    'AVO' = 'AVO',
    /** Libera Financial */
    'LIBERA' = 'LIBERA',
    /** Football At AlphaVerse */
    'FAV' = 'FAV',
    /** Project Quantum */
    'QBIT' = 'QBIT',
    /** Avrora Metaverse */
    'AVR' = 'AVR',
    /** TrustNFT */
    'TRUSTNFT' = 'TRUSTNFT',
    /** Iotec */
    'IOT' = 'IOT',
    /** Freedom. Jobs. Business. */
    'FJB' = 'FJB',
    /** Block Buster Tech Inc */
    'BBTF' = 'BBTF',
    /** Copiosa Coin */
    'COP' = 'COP',
    /** CoinMarketPrime */
    'CMP' = 'CMP',
    /** MicroCreditToken */
    '1MCT' = '1MCT',
    /** Lunatics */
    'LUNAT' = 'LUNAT',
    /** SatoshiSwap */
    'SWAP' = 'SWAP',
    /** Decentralized Community Investment Protocol */
    'DCIP' = 'DCIP',
    /** CZUSD */
    'CZUSD' = 'CZUSD',
    /** Viddli */
    'MTY' = 'MTY',
    /** hong kong floki */
    'HKFLOKI' = 'HKFLOKI',
    /** Bart Simpson Coin */
    'BART' = 'BART',
    /** Dogai */
    'DOGAI' = 'DOGAI',
    /** Pomerium Utility Token */
    'PMR' = 'PMR',
    /** CoinClaim */
    'CLM' = 'CLM',
    /** Hongkong */
    'HK' = 'HK',
    /** skyup */
    'SU' = 'SU',
    /** OSK */
    'OSK' = 'OSK',
    /** NerveFlux */
    'NERVE' = 'NERVE',
    /** ASENIX */
    'ENIX' = 'ENIX',
    /** Cairo Finance */
    'CAF' = 'CAF',
    /** World of Defish */
    'WOD' = 'WOD',
    /** Lucky Block (V1) */
    'LBLOCK' = 'LBLOCK',
    /** Crogecoin */
    'CROGE' = 'CROGE',
    /** Knoknok */
    'KKC' = 'KKC',
    /** Expert Infra */
    'EIM' = 'EIM',
    /** Thoreum V3 */
    'THOREUM' = 'THOREUM',
    /** Drip Network */
    'DRIP' = 'DRIP',
    /** JEN COIN */
    'JEN' = 'JEN',
    /** RPS LEAGUE */
    'RPS' = 'RPS',
    /** Floki New Year */
    'FLOKINY' = 'FLOKINY',
    /** 4REALZA COIN */
    '4RZ' = '4RZ',
    /** BNbitcoin */
    'BNBTC' = 'BNBTC',
    /** Navis */
    'NVS' = 'NVS',
    /** LULU */
    'LULU' = 'LULU',
    /** Yoda Coin Swap */
    'JEDALS' = 'JEDALS',
    /** TRUMP */
    'MAGA' = 'MAGA',
    /** X AI */
    'X' = 'X',
    /** DogeKing */
    'DOGEKING' = 'DOGEKING',
    /** BOUNTYKINDS */
    'YU' = 'YU',
    /** Anypad */
    'APAD' = 'APAD',
    /** Superalgos */
    'SA' = 'SA',
    /** Crypto Fight Club */
    'FIGHT' = 'FIGHT',
    /** Steakd */
    'SDX' = 'SDX',
    /** Decode Coin */
    'DECODE' = 'DECODE',
    /** GreenTrust */
    'GNT' = 'GNT',
    /** Hatchyverse */
    'HATCHY' = 'HATCHY',
    /** SHELTERZ */
    'TERZ' = 'TERZ',
    /** BlockRock */
    'BRO$' = 'BRO$',
    /** TRUST AI */
    'TRT' = 'TRT',
    /** Shelling */
    'SHL' = 'SHL',
    /** Tribalisland game Token */
    'TIC' = 'TIC',
    /** King Finance */
    'KING' = 'KING',
    /** PooCoin */
    'POOCOIN' = 'POOCOIN',
    /** Baka Casino */
    'BAKAC' = 'BAKAC',
    /** CorgiCoin */
    'CORGI' = 'CORGI',
    /** BR34P */
    'BR34P' = 'BR34P',
    /** BeFaster Holder Token */
    'BFHT' = 'BFHT',
    /** VELOREX */
    'VEX' = 'VEX',
    /** Traders coin */
    'TRDC' = 'TRDC',
    /** ZMINE */
    'ZMN' = 'ZMN',
    /** Kingdom Karnage */
    'KKT' = 'KKT',
    /** Nusa */
    'NUSA' = 'NUSA',
    /** Camly Coin */
    'CAMLY' = 'CAMLY',
    /** WEAVERS TOKEN */
    'VRS' = 'VRS',
    /** Luigi Inu */
    'LUIGI' = 'LUIGI',
    /** SaverToken */
    'SVR' = 'SVR',
    /** EZZY GAME GEZY */
    'GEZY' = 'GEZY',
    /** The Bend */
    'BEND' = 'BEND',
    /** Iris Ecosystem */
    'IRISTOKEN' = 'IRISTOKEN',
    /** Lucky Roo */
    'ROO' = 'ROO',
    /** Taroverse */
    'TARO' = 'TARO',
    /** WatchDO */
    'WDO' = 'WDO',
    /** Liquidus (new) */
    'LIQ' = 'LIQ',
    /** Etherconnect */
    'ECC' = 'ECC',
    /** Thetan Coin */
    'THC' = 'THC',
    /** KoinBülteni Token */
    'KOINB' = 'KOINB',
    /** God Of Wealth */
    'GOW39' = 'GOW39',
    /** Santa Floki v2.0 */
    'HOHOHO V2.0' = 'HOHOHO V2.0',
    /** Age of Tanks */
    'A.O.T' = 'A.O.T',
    /** ZeLoop Eco Reward */
    'ERW' = 'ERW',
    /** FoxGPT */
    'FGPT' = 'FGPT',
    /** Poochain */
    'POOP' = 'POOP',
    /** Baby Squid Game */
    'BSG' = 'BSG',
    /** Planet */
    'GAMMA' = 'GAMMA',
    /** Finance Token */
    'FT TOKEN' = 'FT TOKEN',
    /** Ecoin Finance */
    'ECOIN' = 'ECOIN',
    /** Ivy Live */
    'IVY' = 'IVY',
    /** IMO */
    'IMO' = 'IMO',
    /** DragonKing */
    'DRAGONKING' = 'DRAGONKING',
    /** Shambala */
    'BALA' = 'BALA',
    /** Boss Token */
    'BOSS' = 'BOSS',
    /** Wrapped Pi */
    'WPI' = 'WPI',
    /** IVIRSE */
    'IVI' = 'IVI',
    /** ONE Token */
    'ONE' = 'ONE',
    /** Triathon */
    'GEON' = 'GEON',
    /** GO HAYYA */
    'HAYYA' = 'HAYYA',
    /** Subawu Token */
    'SUBAWU' = 'SUBAWU',
    /** Zinari */
    'ZINA' = 'ZINA',
    /** Baby Luffy */
    'BLF' = 'BLF',
    /** RIMAUNANGIS */
    'RXT' = 'RXT',
    /** NDB */
    'NDB' = 'NDB',
    /** NXD Next */
    'NXDT' = 'NXDT',
    /** BOCOIN */
    'BOC' = 'BOC',
    /** KnightSwap */
    'KNIGHT' = 'KNIGHT',
    /** Leve Invest */
    'LEVE' = 'LEVE',
    /** Bullshit Inu */
    'BULL' = 'BULL',
    /** Dot Dot Finance */
    'DDD' = 'DDD',
    /** HowInu */
    'HOW' = 'HOW',
    /** Google AI */
    'GAI' = 'GAI',
    /** Golden Ball */
    'GLB' = 'GLB',
    /** Viblos */
    'VIBLO' = 'VIBLO',
    /** NGA Tiger */
    'NGA' = 'NGA',
    /** MsgSender */
    'MSG' = 'MSG',
    /** MetaVerse-M */
    'M' = 'M',
    /** Pepe AI */
    'PEPEAI' = 'PEPEAI',
    /** CAW CEO */
    'CAWCEO' = 'CAWCEO',
    /** CRED COIN PAY */
    'CRED' = 'CRED',
    /** FlokiPepe */
    'FLOKIPEPE' = 'FLOKIPEPE',
    /** USD mars */
    'USDm' = 'USDm',
    /** Gojo Coin */
    'GOJOCOIN' = 'GOJOCOIN',
    /** Baby Pepe Floki */
    'BPEPEF' = 'BPEPEF',
    /** ShadowFi */
    'SDF' = 'SDF',
    /** Dao Space */
    'DAOP' = 'DAOP',
    /** SINSO */
    'SINSO' = 'SINSO',
    /** Wanda Exchange */
    'WE' = 'WE',
    /** Real Smurf Cat (BSC) */
    'ШАЙЛУШАЙ' = 'ШАЙЛУШАЙ',
    /** Defi Tiger */
    'DTG' = 'DTG',
    /** Santa Coin */
    'SANTA' = 'SANTA',
    /** Optimus */
    'OPTCM' = 'OPTCM',
    /** AutoCrypto */
    'AU' = 'AU',
    /** CharityDAO */
    'CHD' = 'CHD',
    /** Philcoin */
    'PHL' = 'PHL',
    /** Safuu */
    'SAFUU' = 'SAFUU',
    /** Saba Finance */
    'SABA' = 'SABA',
    /** Virgo */
    'VGO' = 'VGO',
    /** Tribalisland Token */
    'TIT' = 'TIT',
    /** Solidray (new) */
    'SRT' = 'SRT',
    /** OciCat */
    'OCICAT' = 'OCICAT',
    /** Moonfarm Finance */
    'MFO' = 'MFO',
    /** Starship Doge */
    'STARSHIPDOGE' = 'STARSHIPDOGE',
    /** CHRISTMAS SHIBA */
    'XSHIB' = 'XSHIB',
    /** Metaverse Miner */
    'META' = 'META',
    /** Baby Floki (BSC) */
    'BABYFLOKI' = 'BABYFLOKI',
    /** GenshinFlokiInu */
    'GFloki' = 'GFloki',
    /** LunaGens */
    'LUNG' = 'LUNG',
    /** BABY SHARK INU */
    'SHARK' = 'SHARK',
    /** UC Finance */
    'UCF' = 'UCF',
    /** FUTURECOIN */
    'FUTURE' = 'FUTURE',
    /** WEB4 AI */
    'WEB4' = 'WEB4',
    /** Homie Wars */
    'HOMIECOIN' = 'HOMIECOIN',
    /** InteractWith */
    'INTER' = 'INTER',
    /** JP */
    'JP' = 'JP',
    /** Unidef */
    'U' = 'U',
    /** Freedom God Dao */
    'FGD' = 'FGD',
    /** Heaven Token */
    'HEAVEN' = 'HEAVEN',
    /** Uniwhale */
    'UNW' = 'UNW',
    /** SpookyShiba */
    'SPKY' = 'SPKY',
    /** Wanaka Farm */
    'WANA' = 'WANA',
    /** Avante */
    'AXT' = 'AXT',
    /** RFOX Finance */
    'VFOX' = 'VFOX',
    /** Bot Planet */
    'BOT' = 'BOT',
    /** Revenue Coin */
    'RVC' = 'RVC',
    /** Ari10 */
    'Ari10' = 'Ari10',
    /** Swapz */
    'SWAPZ' = 'SWAPZ',
    /** Socaverse */
    'SOCA' = 'SOCA',
    /** Moby Dick V2 */
    'MOBY' = 'MOBY',
    /** Germany Rabbit Token */
    'GERMANY' = 'GERMANY',
    /** Stuart Inu */
    'STUART' = 'STUART',
    /** Hungarian Vizsla Inu */
    'HVI' = 'HVI',
    /** DEXO */
    'DEXO' = 'DEXO',
    /** United */
    'UTED' = 'UTED',
    /** CHICA */
    'CHICA' = 'CHICA',
    /** OXM Protocol */
    'OXM' = 'OXM',
    /** Moonscape */
    'MSCP' = 'MSCP',
    /** LindaYacc Ceo */
    'LINDACEO' = 'LINDACEO',
    /** SincroniX */
    'SNX' = 'SNX',
    /** TemplarDAO */
    'TEM' = 'TEM',
    /** Zetos */
    'ZES' = 'ZES',
    /** Clear Water */
    '$CLEAR' = '$CLEAR',
    /** Equilibrium */
    'EDX' = 'EDX',
    /** CryptHub */
    'CRHT' = 'CRHT',
    /** Sonic Space Cat */
    'SCAT' = 'SCAT',
    /** New Cat */
    'NCT' = 'NCT',
    /** TOKEN 2049 */
    '#2049' = '2049',
    /** PumpETH */
    'PETH' = 'PETH',
    /** CRAZY CAT */
    'CRAZYCAT' = 'CRAZYCAT',
    /** Flag Network */
    'FLAG' = 'FLAG',
    /** BeatGen NFT */
    'BGN' = 'BGN',
    /** TEN */
    'TENFI' = 'TENFI',
    /** Hololoot */
    'HOL' = 'HOL',
    /** Jetset */
    'JTS' = 'JTS',
    /** Justus */
    'JTT' = 'JTT',
    /** Block Ape Scissors */
    'BAS' = 'BAS',
    /** TriipMiles */
    'TIIM' = 'TIIM',
    /** Soroosh Smart Ecosystem */
    'SSE' = 'SSE',
    /** Wild Island Game */
    'WILD' = 'WILD',
    /** Aarma */
    'ARMA' = 'ARMA',
    /** TurboDEX */
    'TURBO' = 'TURBO',
    /** Cryptostone */
    'CPS' = 'CPS',
    /** Keeps Coin */
    'KVERSE' = 'KVERSE',
    /** BNBFROG */
    'BNBFROG' = 'BNBFROG',
    /** SavePlanetEarth */
    'SPE' = 'SPE',
    /** Kepple */
    'KPL' = 'KPL',
    /** Optimus Inu */
    'OPINU' = 'OPINU',
    /** Snowmantastic */
    'SNOWMANTASTIC' = 'SNOWMANTASTIC',
    /** GBANK APY */
    'GBK' = 'GBK',
    /** H2O Securities */
    'H2ON' = 'H2ON',
    /** HUNNY FINANCE */
    'HUNNY' = 'HUNNY',
    /** EZZY GAME */
    'EZY' = 'EZY',
    /** Multiverse */
    'MVS' = 'MVS',
    /** BNB DOG INU */
    'BNBDOG' = 'BNBDOG',
    /** Dogecoin 2.0 */
    'DOGE2' = 'DOGE2',
    /** Kingdom Raids */
    'KRS' = 'KRS',
    /** Bitpaid Token */
    'BTP' = 'BTP',
    /** SOPDAP AI */
    'SDP' = 'SDP',
    /** Travel Care */
    'TRAVEL' = 'TRAVEL',
    /** KyotoSwap */
    'KSWAP' = 'KSWAP',
    /** Automated Income Machine */
    'AIM' = 'AIM',
    /** TeaDAO */
    'TEA' = 'TEA',
    /** Dpad Finance */
    'DPAD' = 'DPAD',
    /** Midway AI */
    'MIDAI' = 'MIDAI',
    /** GaStream */
    'GSTRM' = 'GSTRM',
    /** GamingShiba */
    'GAMINGSHIBA' = 'GAMINGSHIBA',
    /** Tag Protocol */
    'TAG' = 'TAG',
    /** BLOCK GALAXY NETWORK */
    'GLAX' = 'GLAX',
    /** Gyro */
    'GYRO' = 'GYRO',
    /** Alion Tech Food */
    '$ATF' = '$ATF',
    /** Sorcery */
    'SOR' = 'SOR',
    /** Ninneko (Mata) */
    'MATA' = 'MATA',
    /** GPT Guru */
    'GPTG' = 'GPTG',
    /** Laqira Protocol */
    'LQR' = 'LQR',
    /** WifeDoge */
    'WIFEDOGE' = 'WIFEDOGE',
    /** LaunchVerse */
    'XLV' = 'XLV',
    /** Perry The BNB V2 */
    'PERRY' = 'PERRY',
    /** HappyLand */
    'HPL' = 'HPL',
    /** GG Token */
    'GGTKN' = 'GGTKN',
    /** Candylad */
    'CANDYLAD' = 'CANDYLAD',
    /** Dogewhale */
    'DOGEWHALE' = 'DOGEWHALE',
    /** NewB.Farm */
    'NEWB' = 'NEWB',
    /** Mobster */
    'MOB' = 'MOB',
    /** AddMeFast */
    'AMF' = 'AMF',
    /** MilkshakeSwap */
    'Milk' = 'Milk',
    /** WHITE TIGER MOON */
    '$WHTETGRMOON' = '$WHTETGRMOON',
    /** Moonarch.app */
    'MOONARCH' = 'MOONARCH',
    /** ROB */
    'ROB' = 'ROB',
    /** FLOKI X */
    'FLOKIX' = 'FLOKIX',
    /** Shibking Inu */
    'SHIBKING' = 'SHIBKING',
    /** Onlinebase */
    'ONLINE' = 'ONLINE',
    /** Defi Connect (new) */
    'DFC' = 'DFC',
    /** Mars Ecosystem Token */
    'XMS' = 'XMS',
    /** ZILLION AAKAR XO */
    'ZILLIONXO' = 'ZILLIONXO',
    /** FIDELIS */
    'FDLS' = 'FDLS',
    /** HarryPotterObamaSonic10Inu (BSC) */
    'BITCOIN' = 'BITCOIN',
    /** bHBD */
    'BHBD' = 'BHBD',
    /** PANDAINU */
    'PWT' = 'PWT',
    /** DEXART */
    'DXA' = 'DXA',
    /** Yuse Token */
    'YUSE' = 'YUSE',
    /** Rottolabs (new) */
    'ROTTO' = 'ROTTO',
    /** MetaFame */
    'BMF' = 'BMF',
    /** TourismX Token */
    'TRMX' = 'TRMX',
    /** Etermon */
    'ETM' = 'ETM',
    /** ShibaZilla2.0 (old) */
    'SBZ2.0' = 'SBZ2.0',
    /** BNBcat */
    'BNBCAT' = 'BNBCAT',
    /** Square Token */
    'SQUA' = 'SQUA',
    /** Txbit Token */
    'TXBIT' = 'TXBIT',
    /** SHIBU INU */
    'SHIBU' = 'SHIBU',
    /** Growth DeFi */
    'xGRO' = 'xGRO',
    /** Drover Inu */
    'DROVERS' = 'DROVERS',
    /** DiveWallet Token */
    'DWT' = 'DWT',
    /** Milady Coin */
    'LADYS' = 'LADYS',
    /** Bombcrypto */
    'BCOIN' = 'BCOIN',
    /** AiONE */
    'AIONE' = 'AIONE',
    /** Vetter Token */
    'VETTER' = 'VETTER',
    /** Profit Blue */
    'BLUE' = 'BLUE',
    /** Koduck */
    'KLD' = 'KLD',
    /** Mother of Memes */
    'MOM' = 'MOM',
    /** Vanity */
    'VNY' = 'VNY',
    /** Genesis Universe */
    'GUT' = 'GUT',
    /** LOFI */
    'LOFI' = 'LOFI',
    /** Bedrock */
    'ROCK' = 'ROCK',
    /** BURNZ */
    'BURNZ' = 'BURNZ',
    /** Shinjiru Inu V2 */
    'SHINJI' = 'SHINJI',
    /** Duckie Land */
    'MMETA' = 'MMETA',
    /** The Parallel */
    'PRL' = 'PRL',
    /** Immutable */
    'DARA' = 'DARA',
    /** Cyrus Exchange */
    'CYRUS' = 'CYRUS',
    /** Dives Defi */
    'DDF' = 'DDF',
    /** BIT GAME VERSE TOKEN */
    'BGVT' = 'BGVT',
    /** PAM */
    'PAM' = 'PAM',
    /** Punk Panda Messenger */
    'PPM' = 'PPM',
    /** Soma Network */
    'SMA' = 'SMA',
    /** Goatcoin */
    'GOAT' = 'GOAT',
    /** PepeDAO Coin */
    'PEPED' = 'PEPED',
    /** OSL AI */
    'OSL' = 'OSL',
    /** BIB Token */
    'BIB' = 'BIB',
    /** Bob LION Inu */
    'BNBLION' = 'BNBLION',
    /** Omniswap */
    'OMNI' = 'OMNI',
    /** Qubit */
    'QBT' = 'QBT',
    /** Kamaleont */
    'KLT' = 'KLT',
    /** Cloak */
    'CLOAK' = 'CLOAK',
    /** Troll Face */
    'TROLL' = 'TROLL',
    /** Sports 2K75 */
    'S2K' = 'S2K',
    /** Lulu Market */
    'LUCK' = 'LUCK',
    /** Dollarmoon */
    'DMOON' = 'DMOON',
    /** Vetter Skylabs */
    'VSL' = 'VSL',
    /** CleanCarbon */
    'CARBO' = 'CARBO',
    /** Monsterra (MAG) */
    'MAG' = 'MAG',
    /** BlockChainGames */
    'BCG' = 'BCG',
    /** VINCI */
    'VINCI' = 'VINCI',
    /** SEEDx */
    'SEEDX' = 'SEEDX',
    /** CMC Coin */
    'CMCC' = 'CMCC',
    /** DELOT.IO */
    'DELOT' = 'DELOT',
    /** Musk Metaverse */
    'METAMUSK' = 'METAMUSK',
    /** bHIVE */
    'BHIVE' = 'BHIVE',
    /** BFK Warzone */
    'BFK' = 'BFK',
    /** The Everlasting Parachain */
    'ELP' = 'ELP',
    /** Tao Te Ching */
    'TTC' = 'TTC',
    /** Pepe Potato */
    '$MRPEPE' = '$MRPEPE',
    /** REX */
    'XRX' = 'XRX',
    /** BTAF token */
    'BTAF' = 'BTAF',
    /** Soccer Crypto */
    'SOT' = 'SOT',
    /** Arena Token */
    'ARENA' = 'ARENA',
    /** Infinite Launch */
    'ILA' = 'ILA',
    /** BNBPot */
    'BNBP' = 'BNBP',
    /** Crypto Inu */
    'ABCD' = 'ABCD',
    /** MiniBNBTiger */
    'MINIBNBTIGER' = 'MINIBNBTIGER',
    /** DogeFather */
    'FATHER' = 'FATHER',
    /** SOULS OF META */
    'SOM' = 'SOM',
    /** OragonX */
    'ORGN' = 'ORGN',
    /** Verve */
    'VERVE' = 'VERVE',
    /** Calo */
    'CALO' = 'CALO',
    /** Nekocoin */
    'NEKOS' = 'NEKOS',
    /** Amazy Move Token */
    'AMT' = 'AMT',
    /** Diamond DND */
    'DND' = 'DND',
    /** DZD */
    'DZD' = 'DZD',
    /** Planet */
    'AQUA' = 'AQUA',
    /** DeathRoad */
    'DRACE' = 'DRACE',
    /** MetaSetGO */
    'MSGO' = 'MSGO',
    /** Cryptotem */
    'TOTEM' = 'TOTEM',
    /** Cremation Coin */
    'CREMAT' = 'CREMAT',
    /** Kitten Coin */
    'KITTENS' = 'KITTENS',
    /** Apple */
    'AMB' = 'AMB',
    /** iinjaz (new) */
    'IJZ' = 'IJZ',
    /** DegenDuckRace */
    '$QUACK' = '$QUACK',
    /** Poodl Token */
    'POODL' = 'POODL',
    /** xWIN Finance */
    'XWIN' = 'XWIN',
    /** BOLICAI */
    'BOAI' = 'BOAI',
    /** Gagarin */
    'GGR' = 'GGR',
    /** Walk To Earn */
    'W2E' = 'W2E',
    /** Graphene */
    'GFN' = 'GFN',
    /** Vention */
    'VENTION' = 'VENTION',
    /** PornVerse */
    'PVERSE' = 'PVERSE',
    /** Niob Finance */
    'NIOB' = 'NIOB',
    /** Bearium */
    'BRIUM' = 'BRIUM',
    /** Vege Token */
    'VEGE' = 'VEGE',
    /** PeePee */
    'PP' = 'PP',
    /** Revolotto */
    'RVL' = 'RVL',
    /** Doge Floki Coin */
    'DOFI' = 'DOFI',
    /** Sell Token */
    'SELLC' = 'SELLC',
    /** QuantumXC */
    'QXC' = 'QXC',
    /** Rabbit Finance */
    'RABBIT' = 'RABBIT',
    /** GINZA NETWORK */
    'GINZA' = 'GINZA',
    /** Ruby Play Network */
    'RUBY' = 'RUBY',
    /** HerityNetwork */
    'HER' = 'HER',
    /** CRAZY TIGER */
    'CRAZYTIGER' = 'CRAZYTIGER',
    /** TigerMoon */
    'TIGERMOON' = 'TIGERMOON',
    /** CubeBase */
    'CUBEB' = 'CUBEB',
    /** Get Money Token */
    'GMTKN' = 'GMTKN',
    /** CATpay */
    'CATPAY' = 'CATPAY',
    /** TATA Coin */
    'TATA' = 'TATA',
    /** JERRYINU */
    'JERRYINU' = 'JERRYINU',
    /** AEROTYME */
    'IND' = 'IND',
    /** Eifi FInance */
    'EIFI' = 'EIFI',
    /** WEB5 Inu */
    'WEB5' = 'WEB5',
    /** Neutrinos */
    'NEUTR' = 'NEUTR',
    /** Green Block Token */
    'GBT' = 'GBT',
    /** Dhahab Sports */
    'DHS' = 'DHS',
    /** PAW PATROL INU */
    'PAW' = 'PAW',
    /** Shiba Floki Inu */
    'FLOKI' = 'FLOKI',
    /** FRZ Solar System Coin */
    'FRZSSCOIN' = 'FRZSSCOIN',
    /** RhinoMars */
    'RhinoMars' = 'RhinoMars',
    /** TokoQrt */
    'TQRT' = 'TQRT',
    /** Arora */
    'AROR' = 'AROR',
    /** GoYoo */
    'GOYOO' = 'GOYOO',
    /** DINGO TOKEN (old) */
    'DINGO' = 'DINGO',
    /** KRYZA Network */
    'KRN' = 'KRN',
    /** MetaDoge */
    'METADOGE' = 'METADOGE',
    /** Jerry Inu */
    'JERRY' = 'JERRY',
    /** The SIMPSONS BNB */
    'SIMPSONS' = 'SIMPSONS',
    /** Koda Cryptocurrency */
    'KODA' = 'KODA',
    /** Little Angry Bunny v2 */
    'LAB v2' = 'LAB v2',
    /** Bitcoin Pay */
    'BTCPAY' = 'BTCPAY',
    /** ALIF COIN */
    'ALIF' = 'ALIF',
    /** ElonTech */
    'ETCH' = 'ETCH',
    /** Bomb Money */
    'BOMB' = 'BOMB',
    /** ShibKiller */
    'SHIBKILLER' = 'SHIBKILLER',
    /** MELEGA */
    'MARCO' = 'MARCO',
    /** Monetas */
    'MNTG' = 'MNTG',
    /** Realital Metaverse */
    'RETA' = 'RETA',
    /** DKEY BANK */
    'DKEY' = 'DKEY',
    /** Bored Coin */
    'BRD' = 'BRD',
    /** ASIX+ */
    'ASIX+' = 'ASIX+',
    /** CryptoMoonShot */
    '$CMOON' = '$CMOON',
    /** CreamPYE */
    'PYE' = 'PYE',
    /** iAssets */
    'ASSET' = 'ASSET',
    /** Hotel of Secrets */
    'HOS' = 'HOS',
    /** Fenomy */
    'FENOMY' = 'FENOMY',
    /** Ainu Token */
    'AINU' = 'AINU',
    /** Tiny Coin */
    'TINC' = 'TINC',
    /** NexBox */
    'NEXBOX' = 'NEXBOX',
    /** NFTNetwork */
    'NFTN' = 'NFTN',
    /** OwlDAO */
    'OWL' = 'OWL',
    /** Royal Gold */
    'RGOLD' = 'RGOLD',
    /** VizslaSwap */
    'VIZSLASWAP' = 'VIZSLASWAP',
    /** Galaxy War */
    'GWT' = 'GWT',
    /** Glory Finance */
    'GLR' = 'GLR',
    /** SafeBull */
    'SAFEBULL' = 'SAFEBULL',
    /** BNBDragon */
    'BNBDRGN' = 'BNBDRGN',
    /** YoCoin */
    'YOCO' = 'YOCO',
    /** Frog Ceo */
    'FROGCEO' = 'FROGCEO',
    /** DYOR Token */
    'DYOR' = 'DYOR',
    /** Bomb Money */
    'BSHARE' = 'BSHARE',
    /** NEMO */
    'NEMO' = 'NEMO',
    /** Hummingbird Egg */
    'HEGG' = 'HEGG',
    /** SPIDERMAN BITCOIN */
    'SPIDERMAN' = 'SPIDERMAN',
    /** Safereum (BSC) */
    'SAFEREUM' = 'SAFEREUM',
    /** PayRue (Propel) */
    'PROPEL' = 'PROPEL',
    /** BunnyPark Game */
    'BG' = 'BG',
    /** Add Finance */
    'ADD' = 'ADD',
    /** Search Optimizatio */
    'SO' = 'SO',
    /** CEO DOGE */
    'CEODOGE' = 'CEODOGE',
    /** Joey Inu */
    'JOEY' = 'JOEY',
    /** Crazy Bunny */
    'CRAZYBUNNY' = 'CRAZYBUNNY',
    /** DecentralFree */
    'FREELA' = 'FREELA',
    /** Orbitau Taureum */
    'TAUM' = 'TAUM',
    /** Crypto Ai */
    'CAI' = 'CAI',
    /** Vira-lata Finance */
    'REAU' = 'REAU',
    /** Million Dollar Baby */
    'MDB' = 'MDB',
    /** LinkDao Network */
    'LKD' = 'LKD',
    /** Metakings */
    'MTK' = 'MTK',
    /** Pepe Classic */
    'PEPC' = 'PEPC',
    /** MonoMoney */
    'MONO' = 'MONO',
    /** HALLOWEEN X */
    'HALLOWEEN' = 'HALLOWEEN',
    /** DOGE SATELLITE INU */
    'DOGE' = 'DOGE',
    /** Monsta XRP */
    'MXRP' = 'MXRP',
    /** Ethera */
    'ETA' = 'ETA',
    /** Stamen Tellus Token */
    'STT' = 'STT',
    /** Starterpool */
    'SPOL' = 'SPOL',
    /** Doge Ipa */
    'DIPA' = 'DIPA',
    /** EverETH Reflect */
    'EVERETH' = 'EVERETH',
    /** Wrapped NewYorkCoin */
    'WNYC' = 'WNYC',
    /** Bitcoin Legend */
    'BCL' = 'BCL',
    /** StickMan */
    'STIC' = 'STIC',
    /** Bullpepe */
    'BULLPEPE' = 'BULLPEPE',
    /** Nekoin */
    'NEKOIN' = 'NEKOIN',
    /** RocketFi */
    'ROCKETFI' = 'ROCKETFI',
    /** SOLCash */
    'SOLCASH' = 'SOLCASH',
    /** Jaiho Crypto */
    'JAIHO' = 'JAIHO',
    /** SafeMoneyUP */
    'SMU' = 'SMU',
    /** Baby Floki Billionaire */
    'BabyFB' = 'BabyFB',
    /** DatBoi Token */
    'DATBOI' = 'DATBOI',
    /** DOLLAR SHIBA INU */
    'DSHIB' = 'DSHIB',
    /** GIANT */
    'GTAN' = 'GTAN',
    /** Frenchie Network */
    'FREN' = 'FREN',
    /** YUMMY */
    'YUMMY' = 'YUMMY',
    /** GlieseCoin */
    'GLC' = 'GLC',
    /** zkVAULT */
    'ZKVAULT' = 'ZKVAULT',
    /** FoxGirl */
    'FOXGIRL' = 'FOXGIRL',
    /** Suteku */
    'SUTEKU' = 'SUTEKU',
    /** MoMo KEY */
    'KEY' = 'KEY',
    /** ElonDoge */
    'EDOGE' = 'EDOGE',
    /** JOJO */
    'JOJO' = 'JOJO',
    /** Samurai Legends */
    'SMG' = 'SMG',
    /** Topshelf Finance */
    'LIQR' = 'LIQR',
    /** DsunDAO */
    'DSUN' = 'DSUN',
    /** Pepelon */
    'PEPELON' = 'PEPELON',
    /** GenomicDao G-Stroke */
    'PCSP' = 'PCSP',
    /** Galaxy Finance */
    'GLF' = 'GLF',
    /** Bull inu */
    'BULLINU' = 'BULLINU',
    /** PawStars */
    'PAWS' = 'PAWS',
    /** Copycat Finance */
    'COPYCAT' = 'COPYCAT',
    /** Value Finance */
    'VFT' = 'VFT',
    /** Baby Bali */
    'BB' = 'BB',
    /** 1Move */
    '1MT' = '1MT',
    /** Valas Finance */
    'VALAS' = 'VALAS',
    /** Tom Coin */
    'TMC' = 'TMC',
    /** Shiba Inu Pay */
    'SHIBAY' = 'SHIBAY',
    /** MoonStar */
    'MOONSTAR' = 'MOONSTAR',
    /** Dibs Share */
    'DSHARE' = 'DSHARE',
    /** Bee Capital */
    'BEE' = 'BEE',
    /** ShopNEXT */
    'STE' = 'STE',
    /** Jswap.Finance */
    'JF' = 'JF',
    /** REAL PEPE CEO */
    'PEPECEO' = 'PEPECEO',
    /** Chihuahua Token */
    'CHH' = 'CHH',
    /** YES Token */
    'YES' = 'YES',
    /** AI DogeMini */
    'AIDOGEMINI' = 'AIDOGEMINI',
    /** ChitCAT */
    'CHITCAT' = 'CHITCAT',
    /** DNA(RXC) */
    'DNA' = 'DNA',
    /** XPROJECT */
    'XPRO' = 'XPRO',
    /** Ferzan */
    'FERZAN' = 'FERZAN',
    /** RevolutionGames */
    'RVLNG' = 'RVLNG',
    /** Shib Generating */
    'SHG' = 'SHG',
    /** Adroverse */
    'ADR' = 'ADR',
    /** CALO INDOOR */
    'IFIT' = 'IFIT',
    /** Xrpcashone */
    'XCE' = 'XCE',
    /** AppleSwap AI */
    'AP' = 'AP',
    /** CZodiac Farming Token */
    'CZF' = 'CZF',
    /** Himo World */
    'HIMO' = 'HIMO',
    /** Kaby Gaming Token */
    'KGT' = 'KGT',
    /** Pepe Original Version */
    'POV' = 'POV',
    /** Reflecto */
    'RTO' = 'RTO',
    /** LEOPARD */
    'LEOPARD' = 'LEOPARD',
    /** WARIO COIN */
    'WCOIN' = 'WCOIN',
    /** Peoplez */
    'LEZ' = 'LEZ',
    /** Optimus */
    'OPT' = 'OPT',
    /** Vancat */
    'VANCAT' = 'VANCAT',
    /** 1NFT */
    '1NFT' = '1NFT',
    /** The Wrong Token */
    'WRONG' = 'WRONG',
    /** BNBsongoku */
    'BNBSONGOKU' = 'BNBSONGOKU',
    /** CryptoZoo  (new) */
    'ZOO' = 'ZOO',
    /** Dancing Lizard Coin */
    'LIZD' = 'LIZD',
    /** Digital Bank of Africa */
    'DBA' = 'DBA',
    /** Dinowars */
    'DINW' = 'DINW',
    /** Coin Of Champions */
    'COC' = 'COC',
    /** SHAUN INU */
    'SHAUN' = 'SHAUN',
    /** Control2XY */
    'CTRL2XY' = 'CTRL2XY',
    /** Marsverse */
    'MMS' = 'MMS',
    /** DoragonLand */
    'DOR' = 'DOR',
    /** MetaUFO */
    'METAUFO' = 'METAUFO',
    /** PinHub */
    'PUB' = 'PUB',
    /** Spider Man */
    'Spider' = 'Spider',
    /** Wirtual */
    'WIRTUAL' = 'WIRTUAL',
    /** SONGOKU */
    'SONGOKU' = 'SONGOKU',
    /** RocketXRP Official */
    'RXO' = 'RXO',
    /** PEPPA */
    'PEPPA' = 'PEPPA',
    /** SaveYourAssets */
    'SYA' = 'SYA',
    /** Story */
    'STORY' = 'STORY',
    /** Moonie NFT */
    'MNY' = 'MNY',
    /** Dinosaureggs */
    'DSG' = 'DSG',
    /** Safe Energy */
    'EnergyX' = 'EnergyX',
    /** Cylum Finance */
    'CYM' = 'CYM',
    /** Magic Beasties */
    'BSTS' = 'BSTS',
    /** Reflecto USD */
    'RUSD' = 'RUSD',
    /** Pepe The Frog */
    'PEPEBNB' = 'PEPEBNB',
    /** TRUTH SEEKERS */
    'TRUTH' = 'TRUTH',
    /** MiniPepe */
    'MINIPEPE' = 'MINIPEPE',
    /** Greenheart CBD */
    'CBD' = 'CBD',
    /** HajiIni */
    'HINU' = 'HINU',
    /** COXSWAP V2 */
    'COX' = 'COX',
    /** Bananace */
    'NANA' = 'NANA',
    /** BabyKitty */
    'BABYKITTY' = 'BABYKITTY',
    /** Hokkaido Inu */
    'HOKA' = 'HOKA',
    /** Fame Reward Plus */
    'FRP' = 'FRP',
    /** ADACash */
    'ADACASH' = 'ADACASH',
    /** BNBdoge */
    'BNBDOGE' = 'BNBDOGE',
    /** ZCore Finance */
    'ZEFI' = 'ZEFI',
    /** Have Fun */
    'HF' = 'HF',
    /** Manga Token */
    '$MANGA' = '$MANGA',
    /** ChinaZilla */
    'CHINAZILLA' = 'CHINAZILLA',
    /** Fathom */
    'FATHOM' = 'FATHOM',
    /** Web3 Inu */
    'WEB3' = 'WEB3',
    /** POTENT */
    'PTT' = 'PTT',
    /** EarnX V2 */
    'EARNX' = 'EARNX',
    /** LocalTrade */
    'LTT' = 'LTT',
    /** PiBridge */
    'PIB' = 'PIB',
    /** Green Climate World */
    'WGC' = 'WGC',
    /** Trump Army */
    'TRUMPARMY' = 'TRUMPARMY',
    /** OLYMPIC GAMES DOGE */
    'OGD' = 'OGD',
    /** GeoLeaf */
    'GLT' = 'GLT',
    /** SANGKARA MISA */
    'MISA' = 'MISA',
    /** Defi Coin */
    'DEFC' = 'DEFC',
    /** Sanji Inu */
    'SANJI' = 'SANJI',
    /** Lego Pepe Vision */
    '$LPV' = '$LPV',
    /** Yorkie Doge */
    'YDOGE' = 'YDOGE',
    /** Pipa Coin */
    'PIPA' = 'PIPA',
    /** Meta FPS */
    'MFPS' = 'MFPS',
    /** BHAX Token */
    'BHAX' = 'BHAX',
    /** ORE Token */
    'ORE' = 'ORE',
    /** Hare Plus */
    'HARE PLUS' = 'HARE PLUS',
    /** Metabit */
    'MBIT' = 'MBIT',
    /** Wombex WOM */
    'WMXWOM' = 'WMXWOM',
    /** OSEAN */
    'OSEAN' = 'OSEAN',
    /** Jimizz */
    'JMZ' = 'JMZ',
    /** BabyDogeZilla */
    'BABYDOGEZILLA' = 'BABYDOGEZILLA',
    /** Bixi */
    'BIXI' = 'BIXI',
    /** Moonseer (BSC) */
    'MOON' = 'MOON',
    /** Kephi Gallery */
    'KPHI' = 'KPHI',
    /** NBA BSC */
    'NBABSC' = 'NBABSC',
    /** ALL IN PEPE */
    'PEPEA' = 'PEPEA',
    /** PEPEARMY */
    'PEPEARMY' = 'PEPEARMY',
    /** BabyDoge CEO */
    'BCEO' = 'BCEO',
    /** MMSC PLATFORM */
    'MMSC' = 'MMSC',
    /** PepVerse */
    'PEPVERS' = 'PEPVERS',
    /** BlockSAFU */
    'BSAFU' = 'BSAFU',
    /** Sleep Future */
    'SLEEPEE' = 'SLEEPEE',
    /** GOGU Coin */
    'GOGU' = 'GOGU',
    /** Pepeburn */
    'PEPEBURN' = 'PEPEBURN',
    /** BabyHkTiger */
    'BABYHKTIGER' = 'BABYHKTIGER',
    /** Presale.World */
    'PRESALE' = 'PRESALE',
    /** Optimism Doge */
    'ODOGE' = 'ODOGE',
    /** Dot Finance */
    'PINK' = 'PINK',
    /** Pepe 2.0 */
    'PEPE 2.0' = 'PEPE 2.0',
    /** FIT Token */
    'FIT' = 'FIT',
    /** MarsRise */
    'MARSRISE' = 'MARSRISE',
    /** Baby Cat Coin */
    'BABYCATS' = 'BABYCATS',
    /** Two Monkey Juice Bar */
    'TMON' = 'TMON',
    /** Mech Master */
    'MECH' = 'MECH',
    /** TradeX AI */
    'TRADEX' = 'TRADEX',
    /** AirCoin */
    'AIR' = 'AIR',
    /** UpDeFi */
    'UP' = 'UP',
    /** BitNautic Token */
    'BTNTV2' = 'BTNTV2',
    /** Walter Inu */
    '$WINU' = '$WINU',
    /** UFC WIN */
    'UFC' = 'UFC',
    /** Afrostar */
    'AFRO' = 'AFRO',
    /** Kalata */
    'KALA' = 'KALA',
    /** Wateenswap */
    'WTN' = 'WTN',
    /** BMW */
    'BMW' = 'BMW',
    /** SmurfsINU */
    'SMURF' = 'SMURF',
    /** XTblock */
    'XTT-B20' = 'XTT-B20',
    /** Strite */
    'STRI' = 'STRI',
    /** Multiverse Capital */
    'MVC' = 'MVC',
    /** X/Twitter */
    'X/TWITTER' = 'X/TWITTER',
    /** BSC FARM */
    'BSC' = 'BSC',
    /** USD Open Dollar */
    'USDO' = 'USDO',
    /** Hugo Inu */
    'HUGO' = 'HUGO',
    /** DFS MAFIA (V2) */
    'DFSM' = 'DFSM',
    /** Trump Inu */
    'TRUMPINU' = 'TRUMPINU',
    /** TWELVE ZODIAC */
    'TWELVE' = 'TWELVE',
    /** RamenSwap */
    'RAMEN' = 'RAMEN',
    /** ShibaCorgi */
    'SHICO' = 'SHICO',
    /** PEPEMOON */
    'PEPEMOON' = 'PEPEMOON',
    /** Allna Protocol */
    'ALPTC' = 'ALPTC',
    /** BNB Tiger AI */
    'AITIGER' = 'AITIGER',
    /** AiWallet Token */
    'AIWALLET' = 'AIWALLET',
    /** xMooney */
    'XM' = 'XM',
    /** DeFiHorse */
    'DFH' = 'DFH',
    /** BALI TOKEN */
    'BLI' = 'BLI',
    /** CollectCoin */
    'CLCT' = 'CLCT',
    /** Nether NFT */
    'NTR' = 'NTR',
    /** Halis */
    'HLS' = 'HLS',
    /** Alita Finance */
    'ALI' = 'ALI',
    /** DogeZilla V2 */
    'DOGEZILLA' = 'DOGEZILLA',
    /** Hare Token */
    'HARE' = 'HARE',
    /** Star Wars Cat */
    'SWCAT' = 'SWCAT',
    /** LaserEyes */
    'LSR' = 'LSR',
    /** Green Shiba Inu (new) */
    'GINUX' = 'GINUX',
    /** Happy Train */
    'HTR' = 'HTR',
    /** Elon Pepe Robot */
    'ELONPEPE' = 'ELONPEPE',
    /** DMEX (Decentralized Mining Exchange) */
    'DMC' = 'DMC',
    /** CTBNETWORK */
    'CTB/WBNB' = 'CTB/WBNB',
    /** Elite Network */
    'ELITE' = 'ELITE',
    /** TIPO Token */
    'TIPO' = 'TIPO',
    /** La Peseta */
    'PTAS' = 'PTAS',
    /** SoccerInu */
    'SOCCER' = 'SOCCER',
    /** Sishi Finance */
    'SISHI' = 'SISHI',
    /** POTTER */
    'POTTER' = 'POTTER',
    /** Smart Donation Coin */
    'SDC' = 'SDC',
    /** Pepe Grow */
    '$PG' = '$PG',
    /** Catena X */
    'CEX' = 'CEX',
    /** SibaInu */
    'SIBA' = 'SIBA',
    /** AmpleSwap (new) */
    'AMPLE' = 'AMPLE',
    /** Red Floki */
    'REDFLOKI' = 'REDFLOKI',
    /** Daddy Doge */
    'DADDYDOGE' = 'DADDYDOGE',
    /** Newsolution2.0 */
    'NSTE' = 'NSTE',
    /** Plant Empires */
    'PEFI' = 'PEFI',
    /** HongKong BTC bank */
    'HKB' = 'HKB',
    /** Yearn Classic Finance */
    'EARN' = 'EARN',
    /** MongBNB */
    'MONGBNB' = 'MONGBNB',
    /** SoIGPT */
    'SGP' = 'SGP',
    /** Minifootball */
    'MINIFOOTBALL' = 'MINIFOOTBALL',
    /** CluCoin */
    'CLU' = 'CLU',
    /** FOX TOKEN */
    'FOX' = 'FOX',
    /** One Basis */
    'OBS' = 'OBS',
    /** MiniDOGE */
    'MINIDOGE' = 'MINIDOGE',
    /** YTOFU */
    'YTOFU' = 'YTOFU',
    /** Melo Token */
    'MELO' = 'MELO',
    /** Rat Protocol */
    'RAT' = 'RAT',
    /** GreenGPT */
    'GGPT' = 'GGPT',
    /** X-Metaverse */
    'XMETA' = 'XMETA',
    /** Super Floki */
    'SLOKI' = 'SLOKI',
    /** Snap Token */
    'SNAP' = 'SNAP',
    /** Web2 Inu */
    'WEB2' = 'WEB2',
    /** Dhabi Coin */
    'DBC' = 'DBC',
    /** Pico Coin */
    'PICO' = 'PICO',
    /** BABYLTC */
    'BLTC' = 'BLTC',
    /** SnowCrash Token */
    'NORA' = 'NORA',
    /** LOL coin */
    'LOL' = 'LOL',
    /** PEPE inu */
    'PEPEINU' = 'PEPEINU',
    /** AquaGoat.Finance */
    'AQUAGOAT' = 'AQUAGOAT',
    /** Baby Shiba Inu */
    'BABYSHIBAINU' = 'BABYSHIBAINU',
    /** ShibCEO */
    'SHIBCEO' = 'SHIBCEO',
    /** BABY DOGE INU */
    '$BABYDOGEINU' = '$BABYDOGEINU',
    /** Simpsons AI */
    'SAI' = 'SAI',
    /** DOJO Coin */
    'DOJO COIN' = 'DOJO COIN',
    /** LBViVi */
    'LBVV' = 'LBVV',
    /** Pi Network DeFi */
    'PI NETWORK DEFI' = 'PI NETWORK DEFI',
    /** SappChat */
    'APP' = 'APP',
    /** Enigma Gaming */
    'ENG' = 'ENG',
    /** FantomPAD */
    'FPAD' = 'FPAD',
    /** ELON BUYS TWITTER */
    'EBT' = 'EBT',
    /** Doge Money */
    'DOGEMONEY' = 'DOGEMONEY',
    /** Floki Cash */
    'FLOKICASH' = 'FLOKICASH',
    /** API INU */
    'API' = 'API',
    /** Wanaka Farm WAIRERE Token */
    'WAI' = 'WAI',
    /** BlueBenx */
    'BENX' = 'BENX',
    /** PulseAI */
    'PULSE' = 'PULSE',
    /** SDAO */
    'SDAO' = 'SDAO',
    /** Online Cold Wallet */
    'OCW' = 'OCW',
    /** Ryoshi Token */
    'RYOSHI' = 'RYOSHI',
    /** BenTen Inu */
    'BENT' = 'BENT',
    /** Layer Network */
    'LAYER' = 'LAYER',
    /** Minelab */
    'MELB' = 'MELB',
    /** CRYPTOFRUITS */
    'FRUIT' = 'FRUIT',
    /** Olive Cash */
    'OLIVE' = 'OLIVE',
    /** AI Floki */
    'AIFLOKI' = 'AIFLOKI',
    /** Cats Coin */
    'CTS' = 'CTS',
    /** Zeppelin DAO */
    'ZEP' = 'ZEP',
    /** SmartFi */
    'SMTF' = 'SMTF',
    /** Frozy Inu */
    'FRZ' = 'FRZ',
    /** INTERSTELLAR DOMAIN ORDER */
    'IDO' = 'IDO',
    /** FirstHare */
    'FirstHare' = 'FirstHare',
    /** BlockAura */
    'TBAC' = 'TBAC',
    /** LUCKY CATS */
    'KATZ' = 'KATZ',
    /** Smart Block Chain City */
    'SBCC' = 'SBCC',
    /** Libra Protocol */
    'LBR' = 'LBR',
    /** BabyApeFunClub */
    'BAFC' = 'BAFC',
    /** White Tiger Pixel */
    'WHTGRPXL' = 'WHTGRPXL',
    /** Surf Live */
    'SURF' = 'SURF',
    /** Stylike Governance */
    'STYL' = 'STYL',
    /** Binance Smart Chain Girl */
    'BSCGIRL' = 'BSCGIRL',
    /** Grapeswap */
    'GRAPE' = 'GRAPE',
    /** Librium Tech */
    'LIB' = 'LIB',
    /** Euler Tools */
    'EULER' = 'EULER',
    /** Elon Cat */
    'ELONCAT' = 'ELONCAT',
    /** LuckyChip */
    'LC' = 'LC',
    /** Feeder.finance */
    'FEED' = 'FEED',
    /** WEDEX TOKEN V2 */
    'DEX' = 'DEX',
    /** Zamzam Token */
    'ZAMZAM' = 'ZAMZAM',
    /** Secured MoonRat Token */
    'SMRAT' = 'SMRAT',
    /** DYZilla */
    'DYZILLA' = 'DYZILLA',
    /** SpaceXDoge */
    'SDOGE' = 'SDOGE',
    /** Crypto News Flash AI */
    'CNF' = 'CNF',
    /** ZUNA */
    'ZUNA' = 'ZUNA',
    /** DORK LORD */
    'DORKL' = 'DORKL',
    /** All In AI */
    'AIAI' = 'AIAI',
    /** HorizonDollar */
    'HZD' = 'HZD',
    /** Rising Sun */
    'SUN' = 'SUN',
    /** ADToken */
    'AD' = 'AD',
    /** Archean */
    'ARCH' = 'ARCH',
    /** CashCow */
    'COW' = 'COW',
    /** LeisurePay */
    'LPY' = 'LPY',
    /** Snaps */
    'SNPS' = 'SNPS',
    /** BABY CRAZY TIGER */
    'BABYCRAZYTIGER' = 'BABYCRAZYTIGER',
    /** SpartaCats */
    'PURR' = 'PURR',
    /** CakeWSwap */
    'CAKEW' = 'CAKEW',
    /** Bonfire */
    'BONFIRE' = 'BONFIRE',
    /** Howl City */
    'HWL' = 'HWL',
    /** Tesla Cars */
    'TECAR' = 'TECAR',
    /** WHITEX */
    'WHX' = 'WHX',
    /** THIS IS FINE 2.0 */
    'FINE 2.0' = 'FINE 2.0',
    /** Waifer */
    'WAIFER' = 'WAIFER',
    /** Kaiken Shiba */
    'KSHIB' = 'KSHIB',
    /** ENEFTIVERSE */
    'EVR' = 'EVR',
    /** Moox Protocol */
    'MOOX' = 'MOOX',
    /** BOHR */
    'BR' = 'BR',
    /** Red Floki CEO */
    'RedFlokiCEO' = 'RedFlokiCEO',
    /** dexSHARE */
    'DEXSHARE' = 'DEXSHARE',
    /** ChainCade */
    'CHAINCADE' = 'CHAINCADE',
    /** Pink Panther Lovers */
    'PPL' = 'PPL',
    /** BabySweep */
    'BABYSWEEP' = 'BABYSWEEP',
    /** Tomcat Inu */
    'TOMC' = 'TOMC',
    /** Predictcoin */
    'PRED' = 'PRED',
    /** wShiba */
    'WSHIB' = 'WSHIB',
    /** FRLONGTOKEN */
    'FRLONG' = 'FRLONG',
    /** MITA */
    'MITA' = 'MITA',
    /** Atlantis */
    'ATLAS' = 'ATLAS',
    /** ACEToken */
    'ACE' = 'ACE',
    /** FADO Go */
    'FADO' = 'FADO',
    /** CryptoHunterTrading */
    'CHT' = 'CHT',
    /** MoonLift Capital */
    'MLTPX' = 'MLTPX',
    /** PepeMo */
    'PEPEMO' = 'PEPEMO',
    /** MyBricks */
    '$BRICKS' = '$BRICKS',
    /** Agricoin */
    'AGX' = 'AGX',
    /** gotEM */
    'GOTEM' = 'GOTEM',
    /** Shib Army */
    'SHIBARMY' = 'SHIBARMY',
    /** TokenFi2.0 */
    'TOKEN2.0' = 'TOKEN2.0',
    /** Lego Coin */
    'LEGO' = 'LEGO',
    /** HeroBook */
    'HBG' = 'HBG',
    /** MetaGear */
    'GEAR' = 'GEAR',
    /** PEPEFLOKI */
    'PEPEF' = 'PEPEF',
    /** SquidGameToken */
    'SGT' = 'SGT',
    /** Tarmex */
    'TARM' = 'TARM',
    /** KungFu Inu */
    'KUNGFU' = 'KUNGFU',
    /** ITSMYNE */
    'MYNE' = 'MYNE',
    /** SafeMoonCash */
    'SAFEMOONCASH' = 'SAFEMOONCASH',
    /** Shiba Inu Empire */
    'SHIBEMP' = 'SHIBEMP',
    /** samoydecoin (BSC) */
    'SAMO' = 'SAMO',
    /** Frz Solar System */
    'FRZSS' = 'FRZSS',
    /** Red Pepe */
    'REDPEPE' = 'REDPEPE',
    /** Pepe 2.0 */
    'PEPE2.0' = 'PEPE2.0',
    /** Dubai Cat */
    'DUBAICAT' = 'DUBAICAT',
    /** Bunny Inu */
    'BUNNY INU' = 'BUNNY INU',
    /** Pepelisa */
    'PEPELISA' = 'PEPELISA',
    /** TosaInu BSC */
    'TOSA' = 'TOSA',
    /** BossBaby */
    'BOSSBABY' = 'BOSSBABY',
    /** BabyPepeEntire */
    'BABYPEPE' = 'BABYPEPE',
    /** Success Kid */
    'SCCS' = 'SCCS',
    /** Pepa Inu 2.0 */
    'PEPA 2.0' = 'PEPA 2.0',
    /** Bob AI */
    'BOBAI' = 'BOBAI',
    /** JNY */
    'JNY' = 'JNY',
    /** MEOW MEOW */
    '$MEOW' = '$MEOW',
    /** BARNEY COIN */
    '$BARNEY' = '$BARNEY',
    /** PEPEZilla */
    'PEPEZILLA' = 'PEPEZILLA',
    /** Maxx BSC */
    'MAXX' = 'MAXX',
    /** Kinder Inu */
    'KINDERINU' = 'KINDERINU',
    /** TED BNB */
    'TED' = 'TED',
    /** Metafury */
    'FURYX' = 'FURYX',
    /** HULKDOGE */
    'HULKDOGE' = 'HULKDOGE',
    /** NOSHIT */
    'NSH' = 'NSH',
    /** Pooh Inu */
    'POOH' = 'POOH',
    /** Incorgnito */
    'INCORGNITO' = 'INCORGNITO',
    /** ULAND */
    'ULAND' = 'ULAND',
    /** Whale Exploder */
    'WHEX' = 'WHEX',
    /** Hydropia */
    'HPIA' = 'HPIA',
    /** MatchNova */
    'MCC' = 'MCC',
    /** Jesus-Raptor */
    'RAPTOR' = 'RAPTOR',
    /** Joe-Yo Coin */
    'JYC' = 'JYC',
    /** Qatar Inu */
    'QATAR' = 'QATAR',
    /** BitOrbit */
    'BITORB' = 'BITORB',
    /** Swinca */
    'SWI' = 'SWI',
    /** Revolution */
    'REV' = 'REV',
    /** CRAZY MUSK */
    'CRAZYMUSK' = 'CRAZYMUSK',
    /** Useless (V3) */
    'USE' = 'USE',
    /** Moonpot */
    'POTS' = 'POTS',
    /** Girlfriend */
    '$GF' = '$GF',
    /** CloudChat */
    'CC' = 'CC',
    /** Power Cash */
    'PRCH' = 'PRCH',
    /** The Simpsons Inu */
    'SIMPSONSINU' = 'SIMPSONSINU',
    /** India Coin */
    'XINDIA' = 'XINDIA',
    /** iOWN Token */
    'iOWN' = 'iOWN',
    /** CashHand */
    'CashHand' = 'CashHand',
    /** GLOBALTRUSTFUND TOKEN */
    'GTF' = 'GTF',
    /** FolgoryUSD */
    'USDF' = 'USDF',
    /** Jointer */
    'JNTR' = 'JNTR',
    /** ACryptoS */
    'ACS' = 'ACS',
    /** Channels */
    'CAN' = 'CAN',
    /** SaltSwap Finance */
    'SALT' = 'SALT',
    /** Jetfuel Finance */
    'FUEL' = 'FUEL',
    /** Bogged */
    'BOG' = 'BOG',
    /** DailySwap Token */
    'DAILYS' = 'DAILYS',
    /** vBSWAP */
    'VBSWAP' = 'VBSWAP',
    /** Daily */
    'DAILY' = 'DAILY',
    /** Blizzard.money */
    'xBLZD' = 'xBLZD',
    /** BerrySwap */
    'BERRY' = 'BERRY',
    /** Realfinance Network */
    'REFI' = 'REFI',
    /** Swampy */
    'SWAMP' = 'SWAMP',
    /** PhoenixDefi.Finance */
    'PNIX' = 'PNIX',
    /** Orakuru */
    'ORK' = 'ORK',
    /** Hungry Bear */
    'HUNGRY' = 'HUNGRY',
    /** Porta */
    'KIAN' = 'KIAN',
    /** SAFESUN */
    'SAFESUN' = 'SAFESUN',
    /** CLIMB TOKEN FINANCE */
    'CLIMB' = 'CLIMB',
    /** CrossFi */
    'CRFI' = 'CRFI',
    /** CroxSwap */
    'CROX' = 'CROX',
    /** Fortress Lending */
    'FTS' = 'FTS',
    /** Safeicarus */
    'SAFEICARUS' = 'SAFEICARUS',
    /** SafeZone */
    'SAFEZONE' = 'SAFEZONE',
    /** EVAI */
    'EV' = 'EV',
    /** GAMERZONE */
    'GAT' = 'GAT',
    /** Chihuahua */
    'HUA' = 'HUA',
    /** Quidax Token */
    'QDX' = 'QDX',
    /** NFT TOKEN PILOT */
    'NFTP' = 'NFTP',
    /** JINDO INU */
    'JIND' = 'JIND',
    /** SIL Finance */
    'SIL' = 'SIL',
    /** Sustainable Energy Token */
    'SET' = 'SET',
    /** SpaceGrime */
    'GRIMEX' = 'GRIMEX',
    /** NFTTONE */
    'TONE' = 'TONE',
    /** UltraSafe Token */
    'ULTRA' = 'ULTRA',
    /** AutoShark */
    'JAWS' = 'JAWS',
    /** Sheesha Finance [BEP20] */
    'SHEESHA' = 'SHEESHA',
    /** Black Eye Galaxy */
    'BYG' = 'BYG',
    /** Lifty */
    'LQT' = 'LQT',
    /** Definix */
    'FINIX' = 'FINIX',
    /** Koji */
    'KOJI' = 'KOJI',
    /** EverMars */
    'EVM' = 'EVM',
    /** CoinSwap Space */
    'CSS' = 'CSS',
    /** Evodefi */
    'GENX' = 'GENX',
    /** Jetswap.finance */
    'WINGS' = 'WINGS',
    /** Yield Parrot */
    'LORY' = 'LORY',
    /** NAFTY */
    'NAFTY' = 'NAFTY',
    /** Rise Of Nebula */
    'RON' = 'RON',
    /** Tokyo AU */
    'TOKAU' = 'TOKAU',
    /** WIZARD */
    'WIZARD' = 'WIZARD',
    /** XXT-Token */
    'XXT' = 'XXT',
    /** BabyBitcoin */
    'BABYBITC' = 'BABYBITC',
    /** RedFEG */
    'REDFEG' = 'REDFEG',
    /** SafeWolf */
    'SW' = 'SW',
    /** Golden Doge */
    'GDOGE' = 'GDOGE',
    /** MaticVerse */
    'Mverse' = 'Mverse',
    /** Binamars */
    'BMARS' = 'BMARS',
    /** Bimp.Finance */
    'BIMP' = 'BIMP',
    /** SoccerHub */
    'SCH' = 'SCH',
    /** Nuketoken */
    'NUKE' = 'NUKE',
    /** Dragon Slayer */
    'DRS' = 'DRS',
    /** RoboDoge Coin */
    'ROBODOGE' = 'ROBODOGE',
    /** Covid Token */
    'COVIDTOKEN' = 'COVIDTOKEN',
    /** Yaki Gold */
    'YAG' = 'YAG',
    /** CryptoCars */
    'CCAR' = 'CCAR',
    /** Starmon Metaverse */
    'SMON' = 'SMON',
    /** WEYU */
    'WEYU' = 'WEYU',
    /** Wault USD */
    'WUSD' = 'WUSD',
    /** Pocoland */
    'POCO' = 'POCO',
    /** Overlord */
    'LORD' = 'LORD',
    /** Sona Network */
    'SONA' = 'SONA',
    /** Scientix */
    'SCIX' = 'SCIX',
    /** Monsters Clan */
    'MONS' = 'MONS',
    /** Meme Lordz */
    '$LORDZ' = '$LORDZ',
    /** Ulti Arena */
    'ULTI' = 'ULTI',
    /** EpicHero 3D NFT */
    'EPICHERO' = 'EPICHERO',
    /** HiFi Gaming Society */
    'HIFI' = 'HIFI',
    /** Phat Doge Givings */
    'GIVING' = 'GIVING',
    /** Diamond Boyz Coin */
    'DBZ' = 'DBZ',
    /** Loki Variants Fan */
    'VARIANTS' = 'VARIANTS',
    /** RugZombie */
    'ZMBE' = 'ZMBE',
    /** DareNFT */
    'DNFT' = 'DNFT',
    /** XTRA Token */
    'XTRA' = 'XTRA',
    /** SPACE SIP */
    'SIP' = 'SIP',
    /** SAVE CARDANO */
    'SADA' = 'SADA',
    /** Lovelace World */
    'LACE' = 'LACE',
    /** LuckyPig */
    'LuckyPig' = 'LuckyPig',
    /** UNITED EMIRATE DECENTRALIZED COIN. */
    'UEDC' = 'UEDC',
    /** Project DogeX */
    '$DOGEX' = '$DOGEX',
    /** PinkSale */
    'PINKSALE' = 'PINKSALE',
    /** AutoShark DEX */
    'FINS' = 'FINS',
    /** Euphoria */
    'EUPH' = 'EUPH',
    /** Wrapped PKT */
    'WPKT' = 'WPKT',
    /** Shakita Inu */
    'SHAK' = 'SHAK',
    /** Ideanet Token */
    'INET' = 'INET',
    /** Piggy Finance */
    'PIGGY' = 'PIGGY',
    /** CRB Coin */
    'CRB' = 'CRB',
    /** Duelist King */
    'DKT' = 'DKT',
    /** PokeDX */
    'PDX' = 'PDX',
    /** Treat DAO [new] */
    'TREAT' = 'TREAT',
    /** PAPPAY */
    'PAPPAY' = 'PAPPAY',
    /** Moby Dick */
    'WOT' = 'WOT',
    /** SatoshiStreetBets Token */
    'SSB' = 'SSB',
    /** Cougar */
    'CGS' = 'CGS',
    /** Atlantis Loans */
    'ATL' = 'ATL',
    /** Flokimooni */
    'FLOKIM' = 'FLOKIM',
    /** Nfans */
    'NFS' = 'NFS',
    /** PancakePoll */
    'PPOLL' = 'PPOLL',
    /** Omni Consumer Protocols */
    'OCP' = 'OCP',
    /** Rolaz Gold */
    'rGLD' = 'rGLD',
    /** HEXAGON Pay */
    'HXP' = 'HXP',
    /** MetaPlay */
    'MPLAY' = 'MPLAY',
    /** Luna-Pad */
    'LUNAPAD' = 'LUNAPAD',
    /** AlgoPad */
    'ALGOPAD' = 'ALGOPAD',
    /** BABY WHITE HAMSTER */
    'BWH' = 'BWH',
    /** GAMINGDOGE */
    'GAMINGDOGE' = 'GAMINGDOGE',
    /** TaiChi */
    'TAC' = 'TAC',
    /** Polygonum Online */
    'POG' = 'POG',
    /** BabyXape */
    'BABYX' = 'BABYX',
    /** Liquid Collectibles */
    'LICO' = 'LICO',
    /** Baby Floki Up */
    'BFU' = 'BFU',
    /** BuffedShiba */
    'BSHIB' = 'BSHIB',
    /** Cockapoo */
    'CPOO' = 'CPOO',
    /** Crypto Classic */
    'CRC' = 'CRC',
    /** Diviner Protocol */
    'DPT' = 'DPT',
    /** Bunscake */
    'BSCAKE' = 'BSCAKE',
    /** Gamma */
    'GAM' = 'GAM',
    /** nSights DeFi Trader */
    'NSI' = 'NSI',
    /** NFTStyle */
    'NFTSTYLE' = 'NFTSTYLE',
    /** BabyFlokiZilla */
    'BABYFLOKIZILLA' = 'BABYFLOKIZILLA',
    /** MetaDoge V2 */
    'METADOGEV2' = 'METADOGEV2',
    /** AVNRich Token */
    'AVN' = 'AVN',
    /** Evagrow Coin */
    'EVA' = 'EVA',
    /** FIA Protocol */
    'FIA' = 'FIA',
    /** Anji */
    'ANJI' = 'ANJI',
    /** POOMOON */
    'POO' = 'POO',
    /** Buff Shiba Inu */
    'BUFFSHIBA' = 'BUFFSHIBA',
    /** DogeBNB.org */
    'DOGEBNB' = 'DOGEBNB',
    /** Shiba Interstellar */
    'SHINT' = 'SHINT',
    /** QuizDrop */
    'QDROP' = 'QDROP',
    /** Dali */
    'DALI' = 'DALI',
    /** ZillaMatrix */
    'ZMAX' = 'ZMAX',
    /** DoKEN */
    'DOKEN' = 'DOKEN',
    /** AxieDoge */
    'AXSD' = 'AXSD',
    /** Refugees Token */
    'RFG' = 'RFG',
    /** ShibaBNB.org */
    'SHIBABNB' = 'SHIBABNB',
    /** Trusted Node */
    'TNODE' = 'TNODE',
    /** NFTPunk */
    'NFTPUNK2.0' = 'NFTPUNK2.0',
    /** Baby Tiger King */
    'BABYTK' = 'BABYTK',
    /** BNB Hero Token */
    'BNBH' = 'BNBH',
    /** Caketools */
    'CKT' = 'CKT',
    /** Chain Wars */
    'CWE' = 'CWE',
    /** Microverse */
    'MVP' = 'MVP',
    /** BrowniesSwap */
    'BROWN' = 'BROWN',
    /** GoldenWspp */
    'GWSPP' = 'GWSPP',
    /** Surviving Soldiers */
    'SSG' = 'SSG',
    /** DART Inu */
    'DART' = 'DART',
    /** SwinCoin */
    'SWIN' = 'SWIN',
    /** Metavice */
    'SERVE' = 'SERVE',
    /** ForthBox */
    'FBX' = 'FBX',
    /** PopDoge */
    'POPDOGE' = 'POPDOGE',
    /** Bloomify */
    'CHARGE' = 'CHARGE',
    /** WoopMoney */
    'WMW' = 'WMW',
    /** Space Crypto */
    'SPG' = 'SPG',
    /** KaraStar UMY */
    'UMY' = 'UMY',
    /** BIZVERSE */
    'BIVE' = 'BIVE',
    /** BlackPoker */
    'BPKR' = 'BPKR',
    /** Energyfi */
    'EFT' = 'EFT',
    /** Meta Rewards Token */
    'MRT' = 'MRT',
    /** Ltradex */
    'LTEX' = 'LTEX',
    /** GFORCE */
    'GFCE' = 'GFCE',
    /** BitGoo */
    'BTG' = 'BTG',
    /** Twelve Legions */
    'CTL' = 'CTL',
    /** LIFEBIRD */
    'LIFEBIRD' = 'LIFEBIRD',
    /** FILIPCOIN */
    'FCP' = 'FCP',
    /** Yearnlab */
    'YLB' = 'YLB',
    /** SIF Token */
    'SIF' = 'SIF',
    /** Bloomify Static */
    'STATIC' = 'STATIC',
    /** UNIVERSE ISLAND */
    'UIM' = 'UIM',
    /** Eterland */
    'ETER' = 'ETER',
    /** Akil Coin */
    'AKL' = 'AKL',
    /** Xpansion Game */
    'XPS' = 'XPS',
    /** Minereum BSC */
    'MNEB' = 'MNEB',
    /** Jigen */
    'JIG' = 'JIG',
    /** Travel Coin */
    'TCOIN' = 'TCOIN',
    /** EarnHub */
    'EHB' = 'EHB',
    /** TravGoPV */
    'TPV' = 'TPV',
    /** CryptoGuards */
    'CGAR' = 'CGAR',
    /** Hakuna Matata */
    'MATATA' = 'MATATA',
    /** REDMARS */
    'RMARS' = 'RMARS',
    /** Meta Decentraland */
    'MDL' = 'MDL',
    /** Meblox Protocol */
    'MEB' = 'MEB',
    /** CakeSwap */
    'CAKESWAP' = 'CAKESWAP',
    /** Conjee */
    'CONJ' = 'CONJ',
    /** Metaxy */
    'MXY' = 'MXY',
    /** Orakler */
    'ORKL' = 'ORKL',
    /** HeroPark */
    'HP' = 'HP',
    /** Exobots */
    'EXOS' = 'EXOS',
    /** NanoMeter Bitcoin */
    'NMBTC' = 'NMBTC',
    /** Fisher Vs Pirate */
    'FVP' = 'FVP',
    /** Singularity */
    'SGLY' = 'SGLY',
    /** ZAT Project */
    'ZPRO' = 'ZPRO',
    /** ANTIS INU */
    'ANTIS' = 'ANTIS',
    /** OUSE Token */
    'OUSE' = 'OUSE',
    /** Milk and Butter */
    'MB' = 'MB',
    /** BAHA */
    'BA' = 'BA',
    /** France REV Finance */
    'FRF' = 'FRF',
    /** DOGEFOOD */
    'DOGEFOOD' = 'DOGEFOOD',
    /** SHIBIC */
    'SHIBIC' = 'SHIBIC',
    /** SHIBA2K22 */
    'SHIBA22' = 'SHIBA22',
    /** Warena */
    'WARE' = 'WARE',
    /** Puli */
    'PULI' = 'PULI',
    /** Moebius */
    'MOBI' = 'MOBI',
    /** METABULLRAGE */
    'BERAGE' = 'BERAGE',
    /** Persia */
    'PERSIA' = 'PERSIA',
    /** EverReflect */
    'EVRF' = 'EVRF',
    /** Eagle Token */
    'EAGLE' = 'EAGLE',
    /** HyperVerse */
    'HVT' = 'HVT',
    /** King Dog Inu */
    'KINGDOG' = 'KINGDOG',
    /** Gunstar Metaverse Currency */
    'GSC' = 'GSC',
    /** Dogecolony */
    'DOGECO' = 'DOGECO',
    /** Galatic Kitty Fighters */
    'GKF' = 'GKF',
    /** Matrix Token */
    'MTIX' = 'MTIX',
    /** CoinRadr */
    'RADR' = 'RADR',
    /** EarthByt */
    'EBYT' = 'EBYT',
    /** Kodi Coin */
    'KODI' = 'KODI',
    /** Dark Land Survival */
    'BIG' = 'BIG',
    /** Robot Warriors */
    'METABOT' = 'METABOT',
    /** TOURIST SHIBA INU */
    'TOURISTS' = 'TOURISTS',
    /** AppleSwap */
    'APPLE' = 'APPLE',
    /** For Meta World */
    '4MW' = '4MW',
    /** Forus */
    'FORS' = 'FORS',
    /** NFT11 */
    'NFT11' = 'NFT11',
    /** MetaSwap */
    'MSC' = 'MSC',
    /** Covid Cutter */
    'CVC' = 'CVC',
    /** Gateway Protocol */
    'GWP' = 'GWP',
    /** Zoro Inu */
    'ZORO' = 'ZORO',
    /** Food Bank */
    'FOOD' = 'FOOD',
    /** ShibaFameV2 */
    'SFV2' = 'SFV2',
    /** Dx Spot */
    'DXS' = 'DXS',
    /** MetaTariffv3 */
    'RIF3' = 'RIF3',
    /** Space Corsair Key */
    'SCK' = 'SCK',
    /** Mooner */
    'MNR' = 'MNR',
    /** Class Coin */
    'CLASS' = 'CLASS',
    /** GoldeFy */
    'GOD' = 'GOD',
    /** Calvex */
    'CLVX' = 'CLVX',
    /** Earncraft */
    'PLOT' = 'PLOT',
    /** CryptoMines Reborn */
    'CRUX' = 'CRUX',
    /** Gafa */
    'GAFA' = 'GAFA',
    /** Wolf Town Wool */
    'WTWOOL' = 'WTWOOL',
    /** TiraVerse */
    'TVRS' = 'TVRS',
    /** Vulcano */
    'VULC' = 'VULC',
    /** Magic Elpis Gem */
    'MEG' = 'MEG',
    /** Vitteey */
    'VITY' = 'VITY',
    /** MetaToll */
    'TAX' = 'TAX',
    /** MetaBomb */
    'MTB' = 'MTB',
    /** Speedex */
    'SPDX' = 'SPDX',
    /** NUDES */
    'NUDES' = 'NUDES',
    /** Planet NFT */
    'PNFT' = 'PNFT',
    /** BABYOKX */
    'BABYOKX' = 'BABYOKX',
    /** Zirve Coin */
    'ZIRVE' = 'ZIRVE',
    /** AlgoBlocks */
    'ALGOBLK' = 'ALGOBLK',
    /** Dog Boss */
    'DOGBOSS' = 'DOGBOSS',
    /** Bridge Network */
    'BRDG' = 'BRDG',
    /** WOLF PUPS */
    'WOLFIES' = 'WOLFIES',
    /** DecentraWorld Ecosystem */
    '$DEWO' = '$DEWO',
    /** Zenc Coin */
    'ZENC' = 'ZENC',
    /** MICROCOSM */
    'MIC' = 'MIC',
    /** World of Masters */
    'WOFM' = 'WOFM',
    /** Buddy DAO */
    'BDY' = 'BDY',
    /** HODLX */
    'HODLX' = 'HODLX',
    /** RUN TOGETHER */
    'RUN' = 'RUN',
    /** Galaxy Heroes */
    'GHC' = 'GHC',
    /** CROC BOY */
    'CROC' = 'CROC',
    /** HotMoon Token */
    'HOTMOON' = 'HOTMOON',
    /** VicMove */
    'VIM' = 'VIM',
    /** APPLECOIN */
    'APPLECOIN' = 'APPLECOIN',
    /** Cashera */
    'CSR' = 'CSR',
    /** panKUKU */
    'KUKU' = 'KUKU',
    /** Numitor */
    'NUMI' = 'NUMI',
    /** Demy Games */
    'DEMY' = 'DEMY',
    /** BelecX Protocol */
    'BEX' = 'BEX',
    /** Sensi */
    'SENSI' = 'SENSI',
    /** Era7: Game of Truth */
    'GOT' = 'GOT',
    /** Green Life Energy */
    'GLE' = 'GLE',
    /** STEPINU */
    'STEPI' = 'STEPI',
    /** StepG */
    'STEPG' = 'STEPG',
    /** Chibi Dinos */
    'HOOP' = 'HOOP',
    /** Ezillion */
    'EZI' = 'EZI',
    /** ShinobiVerse */
    'SHINO' = 'SHINO',
    /** Vegasino */
    'VEGAS' = 'VEGAS',
    /** Coin Gabbar Token */
    'CGT' = 'CGT',
    /** HyperOne */
    'HOT' = 'HOT',
    /** OLOID */
    'OLOID' = 'OLOID',
    /** Kakashi Sensei */
    'KAKASHI' = 'KAKASHI',
    /** StarFish OS */
    'SFO' = 'SFO',
    /** Emit Water Element */
    'WATER' = 'WATER',
    /** Posschain */
    'POSS' = 'POSS',
    /** Metagamble */
    'GAMBL' = 'GAMBL',
    /** Metaegg DeFi */
    'MDEFI' = 'MDEFI',
    /** Source Token */
    'SRCX' = 'SRCX',
    /** LooksCoin */
    'LOOK' = 'LOOK',
    /** Mudra MDR */
    'MDR' = 'MDR',
    /** CZshares */
    'CZSHARES' = 'CZSHARES',
    /** Nowar */
    'NOWAR' = 'NOWAR',
    /** Brayzin Heist */
    'BRZH' = 'BRZH',
    /** Cards of BSC */
    'COB' = 'COB',
    /** Artificial Intelligence Technology Network */
    'AITN' = 'AITN',
    /** Slime Royale Cupid Essence */
    'SCE' = 'SCE',
    /** VALIMARKET */
    'VALI' = 'VALI',
    /** NFTL */
    'NFTL' = 'NFTL',
    /** AveFarm */
    'AVE' = 'AVE',
    /** Fast To Earn */
    'F2E' = 'F2E',
    /** HRC Crypto */
    'HRCC' = 'HRCC',
    /** ALL BEST ICO SATOSHI */
    'SATSALL' = 'SATSALL',
    /** NBOX */
    'NBOX' = 'NBOX',
    /** Meta Apes */
    'SHELL' = 'SHELL',
    /** OSK DAO */
    'OSK-DAO' = 'OSK-DAO',
    /** EcoSmart */
    'ECG' = 'ECG',
    /** Metahamster */
    'MHAM' = 'MHAM',
    /** Scouthub */
    'HUB' = 'HUB',
    /** Betterment Digital */
    'BEMD' = 'BEMD',
    /** 3air */
    '3AIR' = '3AIR',
    /** Future Of Fintech */
    'FOF' = 'FOF',
    /** Shush Club */
    'SHUSH' = 'SHUSH',
    /** Million Pixel */
    'XIX' = 'XIX',
    /** FX Stock Token */
    'FXST' = 'FXST',
    /** 3QT */
    '3QT' = '3QT',
    /** DollarBack */
    'BACK' = 'BACK',
    /** NEO FANTASY */
    'ERT' = 'ERT',
    /** Powabit */
    'POWA' = 'POWA',
    /** Telegram Inu */
    'TINU' = 'TINU',
    /** Okse */
    'OKSE' = 'OKSE',
    /** BeerusCat */
    'BCAT' = 'BCAT',
    /** LOGOS */
    'LOG' = 'LOG',
    /** Shanum */
    'SHAN' = 'SHAN',
    /** NEWTOWNGAMING */
    'NTG' = 'NTG',
    /** NSKSwap */
    'NSK' = 'NSK',
    /** Balance Network */
    'BLN' = 'BLN',
    /** 2G CARBON COIN */
    '2GCC' = '2GCC',
    /** Moonions */
    'MOONION' = 'MOONION',
    /** Project CareCoin */
    'CARESV2' = 'CARESV2',
    /** Halloween Floki */
    'FLOH' = 'FLOH',
    /** ADO Protocol */
    'ADO' = 'ADO',
    /** FRZSwap */
    'FRZW' = 'FRZW',
    /** TYV Coin */
    'TYV' = 'TYV',
    /** Global Innovative Solutions */
    'GSI' = 'GSI',
    /** ECLAT */
    'ELT' = 'ELT',
    /** CrazySharo */
    'SHARO' = 'SHARO',
    /** Maximus Coin */
    'MXZ' = 'MXZ',
    /** Viva Classic (new) */
    'VIVA' = 'VIVA',
    /** France Fan Token */
    'FRA' = 'FRA',
    /** King of Legends */
    'KOL' = 'KOL',
    /** BAO */
    'BAO' = 'BAO',
    /** Hayya Qatar */
    'HQR' = 'HQR',
    /** Winterdog */
    'WDOG' = 'WDOG',
    /** NUTGAIN */
    'NUTGV2' = 'NUTGV2',
    /** KRAKENPAD */
    'KRP' = 'KRP',
    /** Melody */
    'SNS' = 'SNS',
    /** Alcazar */
    'LEO' = 'LEO',
    /** JackpotDoge */
    'JPD' = 'JPD',
    /** Kuwa Oracle */
    'KUOR' = 'KUOR',
    /** RABBIT HALLOWEEN */
    'RH31' = 'RH31',
    /** Upload Token */
    'UPLOAD' = 'UPLOAD',
    /** NFT2STAKE */
    'NFT2$' = 'NFT2$',
    /** ZFMCOIN */
    'ZFM' = 'ZFM',
    /** Football INU */
    'FOOTBALL' = 'FOOTBALL',
    /** Forever Burn */
    'FBURN' = 'FBURN',
    /** Bitratoken */
    'BITRA' = 'BITRA',
    /** CBFINU */
    'CBFINU' = 'CBFINU',
    /** Goal Champion */
    'GC' = 'GC',
    /** Big Crypto Game */
    'CRYPTO' = 'CRYPTO',
    /** NEXUSPAD PROTOCOL */
    'NEXUS' = 'NEXUS',
    /** X-MASK Coin */
    'XMC' = 'XMC',
    /** Catvills Coin */
    'CATVILLS' = 'CATVILLS',
    /** MxmBoxcEus Token */
    'MBE' = 'MBE',
    /** Meta Space 2045 */
    'MTW' = 'MTW',
    /** Xstudio */
    'TXS' = 'TXS',
    /** Fintoken Coin */
    'FTC' = 'FTC',
    /** NirvanaMeta V2 */
    'MNU' = 'MNU',
    /** Genesis Wink */
    'GWINK' = 'GWINK',
    /** Cryptegrity DAO */
    'ESCROW' = 'ESCROW',
    /** Waifu */
    'WAIFU' = 'WAIFU',
    /** BSC PAYMENTS */
    'BSCPAY' = 'BSCPAY',
    /** FLOSHIDO INU */
    'FLOSHIDO' = 'FLOSHIDO',
    /** SHIBCAT */
    'SHIBCAT' = 'SHIBCAT',
    /** FloraChain */
    'FYT' = 'FYT',
    /** Civilization Network */
    'CVL' = 'CVL',
    /** Belifex */
    'BEFX' = 'BEFX',
    /** SwirlToken */
    'SWIRLX' = 'SWIRLX',
    /** kiwi */
    'KIWI' = 'KIWI',
    /** Shira Cat */
    'CATSHIRA' = 'CATSHIRA',
    /** CATCEO */
    'CATCEO' = 'CATCEO',
    /** Maral */
    'MRL' = 'MRL',
    /** Pricetools */
    'PTOOLS' = 'PTOOLS',
    /** DogeArmy */
    'DOGRMY' = 'DOGRMY',
    /** RakeIn */
    'RAKE' = 'RAKE',
    /** FLOKI SHIBA PEPE CEO */
    '3CEO' = '3CEO',
    /** All In GPT */
    'AIGPT' = 'AIGPT',
    /** CCGDS */
    'CCGDS' = 'CCGDS',
    /** Elon Musk CEO */
    'ELONMUSKCEO' = 'ELONMUSKCEO',
    /** MiTellor */
    'ITLR' = 'ITLR',
    /** Kang3n */
    'KANG3N' = 'KANG3N',
    /** URUS */
    'URS' = 'URS',
    /** DuckyCoinAI */
    'DUCKYAI' = 'DUCKYAI',
    /** Avatar Musk Verse */
    'AMV' = 'AMV',
    /** ChainGamingX */
    'CGX' = 'CGX',
    /** HongKongDAO */
    'HKD' = 'HKD',
    /** EuroFootball AI */
    'EFBAI' = 'EFBAI',
    /** TycoonFintech */
    'TFTC' = 'TFTC',
    /** Xtreme Token */
    'XTR' = 'XTR',
    /** Pepito BSC */
    'PEPI' = 'PEPI',
    /** DogeCEO Ai */
    'DOGEAI' = 'DOGEAI',
    /** Baby Pepe */
    'BABY PEPE' = 'BABY PEPE',
    /** PEPE DAO */
    'PEPEDAO' = 'PEPEDAO',
    /** Armour Wallet */
    'ARMOUR' = 'ARMOUR',
    /** CRAZY PEPE */
    'CRAZYPEPE' = 'CRAZYPEPE',
    /** QuokkaSwap */
    'QUOK' = 'QUOK',
    /** PEPE Chain */
    'PEPECHAIN' = 'PEPECHAIN',
    /** TrumpCoin */
    'DTC' = 'DTC',
    /** MigMig Swap */
    'MIGMIG' = 'MIGMIG',
    /** Raichu Inu */
    'RINU' = 'RINU',
    /** MYCOWRIE */
    'COWRIE' = 'COWRIE',
    /** GucciPepe */
    'GUCCIPEPE' = 'GUCCIPEPE',
    /** FROG */
    'FROG' = 'FROG',
    /** PepeDerp */
    'PEPEDERP' = 'PEPEDERP',
    /** Sheikh Pepe */
    'SKPEPE' = 'SKPEPE',
    /** PEPEKING */
    'PEPEKING' = 'PEPEKING',
    /** Inuko Finance */
    'INUKO' = 'INUKO',
    /** Lunasphere */
    'LSPHERE' = 'LSPHERE',
    /** UltramanDoge */
    'UTMDOGE' = 'UTMDOGE',
    /** Diamond Are Forever */
    'DAF' = 'DAF',
    /** $CROOGE */
    '$CROOGE' = '$CROOGE',
    /** UTB.ai */
    'UTBAI' = 'UTBAI',
    /** RollerSwap */
    'ROLS' = 'ROLS',
    /** Puppets Coin */
    'PUPPETS' = 'PUPPETS',
    /** Jasan Wellness */
    'JW' = 'JW',
    /** StarShip BSC */
    'SSHIP' = 'SSHIP',
    /** Quadrillion Coin */
    'QDC' = 'QDC',
    /** pepeX */
    'PEPEX' = 'PEPEX',
    /** RAKHI */
    'RKI' = 'RKI',
    /** ELON CGI */
    'CGI' = 'CGI',
    /** Pepe Rich Club */
    'PRC' = 'PRC',
    /** Pepe Grinch */
    'PEPEGRINCH' = 'PEPEGRINCH',
    /** Zi Network */
    'ZNT' = 'ZNT',
    /** Sloth */
    'SLOTH' = 'SLOTH',
    /** PEPEPi */
    'PEPEPI' = 'PEPEPI',
    /** Disabled Pepe */
    'DISPEPE' = 'DISPEPE',
    /** Spoody Man */
    'SPOODY' = 'SPOODY',
    /** Peperonni */
    'PPRNI' = 'PPRNI',
    /** DataDoge */
    'DATADOGE' = 'DATADOGE',
    /** AI Bob */
    'AIB' = 'AIB',
    /** GGBond */
    'GGBOND' = 'GGBOND',
    /** World Virtual Coin */
    'WVC' = 'WVC',
    /** Aruna */
    'ARU' = 'ARU',
    /** JeromeAndGary */
    'JARY' = 'JARY',
    /** Pepe GEM AI */
    'PEPEGA' = 'PEPEGA',
    /** Secret Gem */
    'SHEEESH' = 'SHEEESH',
    /** BnBdognetwork */
    'BNBDOG$' = 'BNBDOG$',
    /** Global Reserve Coin */
    'GRC' = 'GRC',
    /** Dobby */
    'DOBBY' = 'DOBBY',
    /** HongKong Doge */
    'HKDOGE' = 'HKDOGE',
    /** SafuuGO */
    'SGO' = 'SGO',
    /** Crypto Pepe Mines */
    'CPM' = 'CPM',
    /** FUNCH */
    'FUNCH' = 'FUNCH',
    /** Garuda Coin */
    'GARU' = 'GARU',
    /** King Bean */
    'KINGB' = 'KINGB',
    /** popo */
    'POPO' = 'POPO',
    /** BabyAMA */
    'BAMA' = 'BAMA',
    /** ShenZhou16 */
    'SHENZHOU' = 'SHENZHOU',
    /** X Project */
    'X-TOKEN' = 'X-TOKEN',
    /** LUPIN */
    'LUPIN' = 'LUPIN',
    /** Safari Crush */
    'SFRC' = 'SFRC',
    /** PSYOP */
    'PSYOP' = 'PSYOP',
    /** SMP Finance */
    'SMPF' = 'SMPF',
    /** Cage Match */
    'MUSKVSZUCK' = 'MUSKVSZUCK',
    /** Hello Kitty */
    'KITTY' = 'KITTY',
    /** opZEKND Superchain */
    'OPZEKND' = 'OPZEKND',
    /** Eggman Inu */
    'EGGMAN' = 'EGGMAN',
    /** KinkyMilady */
    'KML' = 'KML',
    /** Price Gap */
    'PGT' = 'PGT',
    /** CuanSwap.com */
    'CUAN' = 'CUAN',
    /** BSCBURN */
    'BSCBURN' = 'BSCBURN',
    /** YOUWHO */
    'YOU' = 'YOU',
    /** Beagle Inu */
    'BEA' = 'BEA',
    /** Botopia.Finance */
    'BTOP' = 'BTOP',
    /** CHAVO */
    'CHA' = 'CHA',
    /** Green Grass Hopper */
    'GGH' = 'GGH',
    /** Tokenemoji */
    '$EMOJI' = '$EMOJI',
    /** MARIO CEO */
    'MARIO' = 'MARIO',
    /** TXGPRO */
    'TXGP' = 'TXGP',
    /** Litecoin */
    'LTC' = 'LTC',
    /** XRP */
    'XRP' = 'XRP',
    /** Ethereum Classic */
    'ETC' = 'ETC',
    /** Zcash */
    'ZEC' = 'ZEC',
    /** Maker */
    'MKR' = 'MKR',
    /** EOS */
    'EOS' = 'EOS',
    /** TRON */
    'TRX' = 'TRX',
    /** Tezos */
    'XTZ' = 'XTZ',
    /** Filecoin */
    'FIL' = 'FIL',
    /** Cosmos */
    'ATOM' = 'ATOM',
    /** Polygon */
    'MATIC' = 'MATIC',
    /** Terra Classic */
    'LUNC' = 'LUNC',
    /** Avalanche */
    'AVAX' = 'AVAX',
    /** NEAR Protocol */
    'NEAR' = 'NEAR',
    /** Polkadot */
    'DOT' = 'DOT',
    /** Axie Infinity */
    'AXS' = 'AXS',
    /** MultiversX */
    'EGLD' = 'EGLD',
    /** Aave */
    'AAVE' = 'AAVE',
    /** Oasis Network */
    'ROSE' = 'ROSE',
    /** Lido DAO */
    'LDO' = 'LDO',
    /** Toncoin */
    'TON' = 'TON',
    /** USDD */
    'USDD' = 'USDD',
    /** First Digital USD */
    'FDUSD' = 'FDUSD',
    /** Beam */
    'BEAM' = 'BEAM',
    /** Basic Attention Token */
    'BAT' = 'BAT',
    /** aelf */
    'ELF' = 'ELF',
    /** Zilliqa */
    'ZIL' = 'ZIL',
    /** Ontology */
    'ONT' = 'ONT',
    /** IoTeX */
    'IOTX' = 'IOTX',
    /** Pax Dollar */
    'USDP' = 'USDP',
    /** Fetch.ai */
    'FET' = 'FET',
    /** Band Protocol */
    'BAND' = 'BAND',
    /** PAX Gold */
    'PAXG' = 'PAXG',
    /** Compound */
    'COMP' = 'COMP',
    /** SushiSwap */
    'SUSHI' = 'SUSHI',
    /** Alchemy Pay */
    'ACH' = 'ACH',
    /** TerraClassicUSD */
    'USTC' = 'USTC',
    /** DeXe */
    'DEXE' = 'DEXE',
    /** 1inch Network */
    '1INCH' = '1INCH',
    /** SuperVerse */
    'SUPER' = 'SUPER',
    /** Mask Network */
    'MASK' = 'MASK',
    /** APENFT */
    'NFT' = 'NFT',
    /** Galxe */
    'GAL' = 'GAL',
    /** STEPN */
    'GMT' = 'GMT',
    /** Bitcoin Palladium */
    'BTCP' = 'BTCP',
    /** Loom Network */
    'LOOM' = 'LOOM',
    /** ShahramCoin */
    'SHC' = 'SHC',
    /** BitTorrent */
    'BTTOLD' = 'BTTOLD',
    /** Celer Network */
    'CELR' = 'CELR',
    /** Beldex */
    'BDX' = 'BDX',
    /** inSure DeFi */
    'SURE' = 'SURE',
    /** Bitcoin Classic */
    'BXC' = 'BXC',
    /** TNC Coin */
    'TNC' = 'TNC',
    /** Smooth Love Potion */
    'SLP' = 'SLP',
    /** Bounce Token */
    'AUCTION' = 'AUCTION',
    /** AIOZ Network */
    'AIOZ' = 'AIOZ',
    /** Baby Doge Coin */
    'BabyDoge' = 'BabyDoge',
    /** Coin98 */
    'C98' = 'C98',
    /** Decimal */
    'DEL' = 'DEL',
    /** SPACE ID */
    'ID' = 'ID',
    /** Wrapped Beacon ETH */
    'WBETH' = 'WBETH',
    /** KWD */
    'KWD' = 'KWD',
    /** Bitcoin Dominica */
    'BTCD' = 'BTCD',
    /** Bluzelle */
    'BLZ' = 'BLZ',
    /** MVL */
    'MVL' = 'MVL',
    /** Neom */
    'NEOM' = 'NEOM',
    /** Prom */
    'PROM' = 'PROM',
    /** WINkLink */
    'WIN' = 'WIN',
    /** Swiss Franc */
    'CHF' = 'CHF',
    /** BitcoinXT */
    'BTCXT' = 'BTCXT',
    /** Stella */
    'ALPHA' = 'ALPHA',
    /** Raydium */
    'RAY' = 'RAY',
    /** MyNeighborAlice */
    'ALICE' = 'ALICE',
    /** Dogelon Mars */
    'ELON' = 'ELON',
    /** Bitgert */
    'BRISE' = 'BRISE',
    /** Highstreet */
    'HIGH' = 'HIGH',
    /** Maverick Protocol */
    'MAV' = 'MAV',
    /** HELLO Labs */
    'HELLO' = 'HELLO',
    /** AllianceBlock Nexera */
    'NXRA' = 'NXRA',
    /** SmarDex */
    'SDEX' = 'SDEX',
    /** CyberConnect */
    'CYBER' = 'CYBER',
    /** PLANET */
    'PLANET' = 'PLANET',
    /** ABBC Coin */
    'ABBC' = 'ABBC',
    /** ThunderCore */
    'TT' = 'TT',
    /** ARPA */
    'ARPA' = 'ARPA',
    /** Shentu */
    'CTK' = 'CTK',
    /** DEXTools */
    'DEXT' = 'DEXT',
    /** DFI.Money */
    'YFII' = 'YFII',
    /** Linear Finance */
    'LINA' = 'LINA',
    /** Polkastarter */
    'POLS' = 'POLS',
    /** Oraichain */
    'ORAI' = 'ORAI',
    /** Mdex */
    'MDX' = 'MDX',
    /** Alien Worlds */
    'TLM' = 'TLM',
    /** XCAD Network */
    'XCAD' = 'XCAD',
    /** Automata Network */
    'ATA' = 'ATA',
    /** DeRace */
    'DERC' = 'DERC',
    /** RACA */
    'RACA' = 'RACA',
    /** Mines of Dalarnia */
    'DAR' = 'DAR',
    /** Synapse */
    'SYN' = 'SYN',
    /** SIDUS */
    'SIDUS' = 'SIDUS',
    /** MARBLEX */
    'MBX' = 'MBX',
    /** Komodo */
    'KMD' = 'KMD',
    /** Measurable Data Token */
    'MDT' = 'MDT',
    /** Phantasma */
    'SOUL' = 'SOUL',
    /** CEEK VR */
    'CEEK' = 'CEEK',
    /** Telos */
    'TLOS' = 'TLOS',
    /** Velas */
    'VLX' = 'VLX',
    /** Frontier */
    'FRONT' = 'FRONT',
    /** Meter Governance */
    'MTRG' = 'MTRG',
    /** DIA */
    'DIA' = 'DIA',
    /** Cream Finance */
    'CREAM' = 'CREAM',
    /** Bella Protocol */
    'BEL' = 'BEL',
    /** Reef */
    'REEF' = 'REEF',
    /** Dego Finance */
    'DEGO' = 'DEGO',
    /** Alpha Quark Token */
    'AQT' = 'AQT',
    /** CLV */
    'CLV' = 'CLV',
    /** Trias Token (New) */
    'TRIAS' = 'TRIAS',
    /** RocketX exchange */
    'RVF' = 'RVF',
    /** Beta Finance */
    'BETA' = 'BETA',
    /** GameZone */
    'GZONE' = 'GZONE',
    /** Phoenix */
    'PHB' = 'PHB',
    /** Katana Inu */
    'KATA' = 'KATA',
    /** Firo */
    'FIRO' = 'FIRO',
    /** AdEx */
    'ADX' = 'ADX',
    /** NULS */
    'NULS' = 'NULS',
    /** Streamr */
    'DATA' = 'DATA',
    /** PARSIQ */
    'PRQ' = 'PRQ',
    /** DEAPcoin */
    'DEP' = 'DEP',
    /** KardiaChain */
    'KAI' = 'KAI',
    /** Orion */
    'ORN' = 'ORN',
    /** Aleph.im */
    'ALEPH' = 'ALEPH',
    /** Harvest Finance */
    'FARM' = 'FARM',
    /** REVV */
    'REVV' = 'REVV',
    /** Wing Finance */
    'WING' = 'WING',
    /** RAMP */
    'RAMP' = 'RAMP',
    /** Rainicorn */
    'RAINI' = 'RAINI',
    /** Ternoa */
    'CAPS' = 'CAPS',
    /** EPIK Prime */
    'EPIK' = 'EPIK',
    /** Polytrade */
    'TRADE' = 'TRADE',
    /** Nine Chronicles */
    'WNCG' = 'WNCG',
    /** ParaSwap */
    'PSP' = 'PSP',
    /** Onyxcoin */
    'XCN' = 'XCN',
    /** Circuits of Value */
    'COVAL' = 'COVAL',
    /** Chrono.tech */
    'TIME' = 'TIME',
    /** Adshares */
    'ADS' = 'ADS',
    /** Bitcoin Unlimited */
    'BTCU' = 'BTCU',
    /** Fusion */
    'FSN' = 'FSN',
    /** VITE */
    'VITE' = 'VITE',
    /** ForTube */
    'FOR' = 'FOR',
    /** MAP Protocol */
    'MAP' = 'MAP',
    /** XPR Network */
    'XPR' = 'XPR',
    /** Hacken Token */
    'HAI' = 'HAI',
    /** pNetwork */
    'PNT' = 'PNT',
    /** MANTRA */
    'OM' = 'OM',
    /** RFOX */
    'RFOX' = 'RFOX',
    /** GAMEE */
    'GMEE' = 'GMEE',
    /** Saito */
    'SAITO' = 'SAITO',
    /** MILC Platform */
    'MLT' = 'MLT',
    /** Impossible Finance Launchpad */
    'IDIA' = 'IDIA',
    /** Stader */
    'SD' = 'SD',
    /** Numbers Protocol */
    'NUM' = 'NUM',
    /** Hillstone Finance */
    'HSF' = 'HSF',
    /** Volt Inu */
    'VOLT' = 'VOLT',
    /** VIDT DAO */
    'VIDT' = 'VIDT',
    /** ERC20 */
    'ERC20' = 'ERC20',
    /** Newscrypto */
    'NWC' = 'NWC',
    /** MATH */
    'MATH' = 'MATH',
    /** renBTC */
    'RENBTC' = 'RENBTC',
    /** NEST Protocol */
    'NEST' = 'NEST',
    /** Serum */
    'SRM' = 'SRM',
    /** UNCX Network */
    'UNCX' = 'UNCX',
    /** PAID Network */
    'PAID' = 'PAID',
    /** Xend Finance */
    'XEND' = 'XEND',
    /** Deeper Network */
    'DPR' = 'DPR',
    /** Zigcoin */
    'ZIG' = 'ZIG',
    /** blockbank */
    'BBANK' = 'BBANK',
    /** Swash */
    'SWASH' = 'SWASH',
    /** Gamium */
    'GMM' = 'GMM',
    /** Vita Inu */
    'VINU' = 'VINU',
    /** NvirWorld */
    'NVIR' = 'NVIR',
    /** Izumi Finance */
    'IZI' = 'IZI',
    /** Grove Coin */
    'GRV' = 'GRV',
    /** AIPAD */
    'AIPAD' = 'AIPAD',
    /** Carbon Protocol */
    'SWTH' = 'SWTH',
    /** QuadrantProtocol */
    'EQUAD' = 'EQUAD',
    /** TOKPIE */
    'TKP' = 'TKP',
    /** apM Coin */
    'APM' = 'APM',
    /** ELYSIA */
    'EL' = 'EL',
    /** Suku */
    'SUKU' = 'SUKU',
    /** UniLend */
    'UFT' = 'UFT',
    /** Dvision Network */
    'DVI' = 'DVI',
    /** Exeedme */
    'XED' = 'XED',
    /** Prosper */
    'PROS' = 'PROS',
    /** HAPI Protocol */
    'HAPI' = 'HAPI',
    /** Cellframe */
    'CELL' = 'CELL',
    /** Cratos */
    'CRTS' = 'CRTS',
    /** TRVL */
    'TRVL' = 'TRVL',
    /** DappRadar */
    'RADAR' = 'RADAR',
    /** EverRise */
    'RISE' = 'RISE',
    /** Kunci Coin */
    'KUNCI' = 'KUNCI',
    /** ColossusXT */
    'COLX' = 'COLX',
    /** SwftCoin */
    'SWFTC' = 'SWFTC',
    /** NewYork Exchange */
    'NYE' = 'NYE',
    /** MixMarvel */
    'MIX' = 'MIX',
    /** Banano */
    'BAN' = 'BAN',
    /** Juggernaut */
    'JGN' = 'JGN',
    /** Stobox Token */
    'STBU' = 'STBU',
    /** Tornado Cash */
    'TORN' = 'TORN',
    /** Dypius */
    'DYP' = 'DYP',
    /** OVR */
    'OVR' = 'OVR',
    /** Electra Protocol */
    'XEP' = 'XEP',
    /** Umbrella Network */
    'UMB' = 'UMB',
    /** ChainGuardians */
    'CGG' = 'CGG',
    /** Ispolink */
    'ISP' = 'ISP',
    /** MARS4 */
    'MARS4' = 'MARS4',
    /** Lovely Inu Finance */
    'LOVELY' = 'LOVELY',
    /** Rainmaker Games */
    'RAIN' = 'RAIN',
    /** StreamCoin */
    'STRM' = 'STRM',
    /** DexCheck */
    'DCK' = 'DCK',
    /** Obyte */
    'GBYTE' = 'GBYTE',
    /** Mysterium */
    'MYST' = 'MYST',
    /** SparkPoint */
    'SRK' = 'SRK',
    /** GNY */
    'GNY' = 'GNY',
    /** PERL.eco */
    'PERL' = 'PERL',
    /** Zynecoin */
    'ZYN' = 'ZYN',
    /** Minter Network */
    'BIP' = 'BIP',
    /** Neutrino Index */
    'XTN' = 'XTN',
    /** GamerCoin */
    'GHX' = 'GHX',
    /** Forj (Bondly) */
    'BONDLY' = 'BONDLY',
    /** PolkaBridge */
    'PBR' = 'PBR',
    /** Aurox */
    'URUS' = 'URUS',
    /** InsurAce */
    'INSUR' = 'INSUR',
    /** Uno Re */
    'UNO' = 'UNO',
    /** Virtue Poker */
    'VPP' = 'VPP',
    /** Dotmoovs */
    'MOOV' = 'MOOV',
    /** HI */
    'HI' = 'HI',
    /** VEMP */
    'VEMP' = 'VEMP',
    /** Arix */
    'ARIX' = 'ARIX',
    /** LABEL Foundation */
    'LBL' = 'LBL',
    /** JumpToken */
    'JMPT' = 'JMPT',
    /** Prime Numbers Labs Ecosystem */
    'PRNT' = 'PRNT',
    /** Wrapped Alvey Chain */
    'WALV' = 'WALV',
    /** BitcoinZ */
    'BTCZ' = 'BTCZ',
    /** Callisto Network */
    'CLO' = 'CLO',
    /** FREEdom Coin */
    'FREE' = 'FREE',
    /** TenUp */
    'TUP' = 'TUP',
    /** suterusu */
    'SUTER' = 'SUTER',
    /** NerveNetwork */
    'NVT' = 'NVT',
    /** ClinTex CTi */
    'CTI' = 'CTI',
    /** iMe Lab */
    'LIME' = 'LIME',
    /** PureFi Protocol */
    'UFI' = 'UFI',
    /** Witch Token */
    'WITCH' = 'WITCH',
    /** Ariva */
    'ARV' = 'ARV',
    /** Cirus Foundation */
    'CIRUS' = 'CIRUS',
    /** VelasPad */
    'VLXPAD' = 'VLXPAD',
    /** DOSE */
    'DOSE' = 'DOSE',
    /** Triall */
    'TRL' = 'TRL',
    /** CheckDot */
    'CDT' = 'CDT',
    /** NanoByte Token */
    'NBT' = 'NBT',
    /** Safe Haven */
    'SHA' = 'SHA',
    /** PEAKDEFI */
    'PEAK' = 'PEAK',
    /** Offshift (old) */
    'XFT' = 'XFT',
    /** ASTA */
    'ASTA' = 'ASTA',
    /** ZeroSwap */
    'ZEE' = 'ZEE',
    /** Carbon */
    'CRBN' = 'CRBN',
    /** Unbound */
    'UNB' = 'UNB',
    /** TOWER */
    'TOWER' = 'TOWER',
    /** Woonkly Power */
    'WOOP' = 'WOOP',
    /** Polychain Monsters */
    'PMON' = 'PMON',
    /** Etherland */
    'ELAND' = 'ELAND',
    /** XCarnival */
    'XCV' = 'XCV',
    /** ETHPad */
    'ETHPAD' = 'ETHPAD',
    /** DEEPSPACE */
    'DPS' = 'DPS',
    /** Adora Token */
    'ARA' = 'ARA',
    /** pSTAKE Finance */
    'PSTAKE' = 'PSTAKE',
    /** Wombat Exchange */
    'WOM' = 'WOM',
    /** Cheems Inu (new) */
    'CINU' = 'CINU',
    /** BitCore */
    'BTX' = 'BTX',
    /** Metrix Coin */
    'MRX' = 'MRX',
    /** Garlicoin */
    'GRLC' = 'GRLC',
    /** Atomic Wallet Coin */
    'AWC' = 'AWC',
    /** Tokoin */
    'TOKO' = 'TOKO',
    /** Bitgesell */
    'BGL' = 'BGL',
    /** Idena */
    'IDNA' = 'IDNA',
    /** Bird.Money */
    'BIRD' = 'BIRD',
    /** Oddz */
    'ODDZ' = 'ODDZ',
    /** Chronicle */
    'XNL' = 'XNL',
    /** UnMarshal */
    'MARSH' = 'MARSH',
    /** Equalizer */
    'EQZ' = 'EQZ',
    /** Pleasure Coin */
    'NSFW' = 'NSFW',
    /** Hot Cross */
    'HOTCROSS' = 'HOTCROSS',
    /** Bistroo */
    'BIST' = 'BIST',
    /** Omchain */
    'OMC' = 'OMC',
    /** ADAPad */
    'ADAPAD' = 'ADAPAD',
    /** Blockchain Monster Hunt */
    'BCMC' = 'BCMC',
    /** Internet of Energy Network */
    'IOEN' = 'IOEN',
    /** Avocado DAO Token */
    'AVG' = 'AVG',
    /** BBS Network */
    'BBS' = 'BBS',
    /** SLG.GAMES */
    'SLG' = 'SLG',
    /** BUX Token */
    'BUX' = 'BUX',
    /** Effect Network */
    'EFX' = 'EFX',
    /** DAPS Coin */
    'DAPS' = 'DAPS',
    /** Etho Protocol */
    'ETHO' = 'ETHO',
    /** Zelwin */
    'ZLW' = 'ZLW',
    /** Arcona */
    'ARCONA' = 'ARCONA',
    /** RioDeFi */
    'RFUEL' = 'RFUEL',
    /** Hakka.Finance */
    'HAKKA' = 'HAKKA',
    /** Gather */
    'GTH' = 'GTH',
    /** Mettalex */
    'MTLX' = 'MTLX',
    /** Zenfuse */
    'ZEFU' = 'ZEFU',
    /** Hyve */
    'HYVE' = 'HYVE',
    /** Freeway Token */
    'FWT' = 'FWT',
    /** Mogul Productions */
    'STARS' = 'STARS',
    /** 8PAY */
    '8PAY' = '8PAY',
    /** Raze Network */
    'RAZE' = 'RAZE',
    /** Nabox */
    'NABOX' = 'NABOX',
    /** Corite */
    'CO' = 'CO',
    /** PulsePad */
    'PLSPAD' = 'PLSPAD',
    /** OneArt */
    '1ART' = '1ART',
    /** XY Finance */
    'XY' = 'XY',
    /** Bismuth */
    'BIS' = 'BIS',
    /** Zap */
    'ZAP' = 'ZAP',
    /** PhoenixDAO */
    'PHNX' = 'PHNX',
    /** CyberFi Token */
    'CFi' = 'CFi',
    /** Glitch */
    'GLCH' = 'GLCH',
    /** Signata */
    'SATA' = 'SATA',
    /** PERI Finance */
    'PERI' = 'PERI',
    /** Coldstack */
    'CLS' = 'CLS',
    /** Don-key */
    'DON' = 'DON',
    /** Loser Coin */
    'LOWB' = 'LOWB',
    /** Tiger King Coin */
    'TKING' = 'TKING',
    /** IOI Token */
    'IOI' = 'IOI',
    /** KamPay */
    'KAMPAY' = 'KAMPAY',
    /** Life Crypto */
    'LIFE' = 'LIFE',
    /** NFTrade */
    'NFTD' = 'NFTD',
    /** NFTY Token */
    'NFTY' = 'NFTY',
    /** OLD DOVU */
    'DOV' = 'DOV',
    /** CREDIT */
    'CREDIT' = 'CREDIT',
    /** Autobahn Network */
    'TXL' = 'TXL',
    /** EasyFi */
    'EZ' = 'EZ',
    /** ACoconut */
    'AC' = 'AC',
    /** Coreto */
    'COR' = 'COR',
    /** Curate */
    'XCUR' = 'XCUR',
    /** Unido EP */
    'UDO' = 'UDO',
    /** disBalancer */
    'DDOS' = 'DDOS',
    /** Tycoon */
    'TYC' = 'TYC',
    /** Brokoli Network */
    'BRKL' = 'BRKL',
    /** DinoX */
    'DNXC' = 'DNXC',
    /** The HUSL */
    'HUSL' = 'HUSL',
    /** Shirtum */
    'SHI' = 'SHI',
    /** Kaizen Finance */
    'KZEN' = 'KZEN',
    /** S4FE */
    'S4F' = 'S4F',
    /** CoTrader */
    'COT' = 'COT',
    /** Aryacoin */
    'AYA' = 'AYA',
    /** ZUM TOKEN */
    'ZUM' = 'ZUM',
    /** AtromG8 */
    'AG8' = 'AG8',
    /** DuckDaoDime */
    'DDIM' = 'DDIM',
    /** SaTT */
    'SATT' = 'SATT',
    /** Royale Finance */
    'ROYA' = 'ROYA',
    /** Finxflo */
    'FXF' = 'FXF',
    /** Peanut */
    'NUX' = 'NUX',
    /** Kangal */
    'KANGAL' = 'KANGAL',
    /** Ares Protocol */
    'ARES' = 'ARES',
    /** DeHive */
    'DHV' = 'DHV',
    /** EVRYNET */
    'EVRY' = 'EVRY',
    /** ChronoBase */
    'TIK' = 'TIK',
    /** SPORT */
    'SPORT' = 'SPORT',
    /** NevaCoin */
    'NEVA' = 'NEVA',
    /** Jetcoin */
    'JET' = 'JET',
    /** ALL BEST ICO */
    'ALLBI' = 'ALLBI',
    /** OIN Finance */
    'OIN' = 'OIN',
    /** KIRA */
    'KEX' = 'KEX',
    /** Lead Wallet */
    'LEAD' = 'LEAD',
    /** SakeToken */
    'SAKE' = 'SAKE',
    /** Golff */
    'GOF' = 'GOF',
    /** Spaceswap MILK2 */
    'MILK2' = 'MILK2',
    /** Duck DAO (DLP Duck Token) */
    'DUCK' = 'DUCK',
    /** APYSwap */
    'APYS' = 'APYS',
    /** PRivaCY Coin */
    'PRCY' = 'PRCY',
    /** Shadows */
    'DOWS' = 'DOWS',
    /** Playcent */
    'PCNT' = 'PCNT',
    /** Genesis Shards */
    'GS' = 'GS',
    /** Mochi Market */
    'MOMA' = 'MOMA',
    /** Formation Fi */
    'FORM' = 'FORM',
    /** Impossible Finance */
    'IF' = 'IF',
    /** WagyuSwap */
    'WAG' = 'WAG',
    /** Orion Money */
    'ORION' = 'ORION',
    /** Polis */
    'POLIS' = 'POLIS',
    /** Shard */
    'SHARD' = 'SHARD',
    /** Gem Exchange and Trading */
    'GXT' = 'GXT',
    /** YVS.Finance */
    'YVS' = 'YVS',
    /** AnRKey X */
    '$ANRX' = '$ANRX',
    /** Gourmet Galaxy */
    'GUM' = 'GUM',
    /** KeyFi */
    'KEYFI' = 'KEYFI',
    /** TotemFi */
    'TOTM' = 'TOTM',
    /** Rage Fan */
    'RAGE' = 'RAGE',
    /** GamyFi Platform */
    'GFX' = 'GFX',
    /** WorkQuest Token */
    'WQT' = 'WQT',
    /** UniFarm */
    'UFARM' = 'UFARM',
    /** GTONCapital */
    'GTON' = 'GTON',
    /** Ekta */
    'EKTA' = 'EKTA',
    /** ShoeFy */
    'SHOE' = 'SHOE',
    /** ZCore (old) */
    'ZCR' = 'ZCR',
    /** ECOSC */
    'ECU' = 'ECU',
    /** SeChain */
    'SNN' = 'SNN',
    /** extraDNA */
    'XDNA' = 'XDNA',
    /** Spaceswap SHAKE */
    'SHAKE' = 'SHAKE',
    /** BonFi */
    'BNF' = 'BNF',
    /** CorionX */
    'CORX' = 'CORX',
    /** Wrapped Matic */
    'WMATIC' = 'WMATIC',
    /** 1MillionNFTs */
    '1MIL' = '1MIL',
    /** Knit Finance */
    'KFT' = 'KFT',
    /** Kwikswap Protocol */
    'KWIK' = 'KWIK',
    /** 2crazyNFT */
    '2CRZ' = '2CRZ',
    /** KingDeFi */
    'KRW' = 'KRW',
    /** Mind Music */
    'MND' = 'MND',
    /** Wrapped Solana */
    'SOL' = 'SOL',
    /** ℓUSD */
    'LUSD' = 'LUSD',
    /** XEN Crypto */
    'XEN' = 'XEN',
    /** LayerAI */
    'LAI' = 'LAI',
    /** Zeebu */
    'ZBU' = 'ZBU',
    /** Shrapnel */
    'SHRAP' = 'SHRAP',
    /** SIX */
    'SIX' = 'SIX',
    /** QANplatform */
    'QANX' = 'QANX',
    /** WadzPay Token */
    'WTK' = 'WTK',
    /** XANA */
    'XETA' = 'XETA',
    /** ZEDXION */
    'USDZ' = 'USDZ',
    /** Hippo Wallet Token (HPO) */
    'HPO' = 'HPO',
    /** CashBackPro */
    'CBP' = 'CBP',
    /** Gold Fever */
    'NGL' = 'NGL',
    /** ZEDXION */
    'ZEDXION' = 'ZEDXION',
    /** Domi Online */
    'DOMI' = 'DOMI',
    /** Parex */
    'PRX' = 'PRX',
    /** Brickken */
    'BKN' = 'BKN',
    /** OHO */
    'OHO' = 'OHO',
    /** Degen Zoo */
    'DZOO' = 'DZOO',
    /** Bitcoiva */
    'BCA' = 'BCA',
    /** EscoinToken */
    'ELG' = 'ELG',
    /** Enjinstarter */
    'EJS' = 'EJS',
    /** Squid Game */
    'SQUID' = 'SQUID',
    /** MetaGaming Guild */
    'MGG' = 'MGG',
    /** Digital Financial Exchange */
    'DIFX' = 'DIFX',
    /** HistoryDAO */
    'HAO' = 'HAO',
    /** Virtual Versions */
    'VV' = 'VV',
    /** FEG Token [NEW] */
    'FEG' = 'FEG',
    /** Polkacity */
    'POLC' = 'POLC',
    /** Privapp Network */
    'bPRIVA' = 'bPRIVA',
    /** TribeOne */
    'HAKA' = 'HAKA',
    /** Ridotto */
    'RDT' = 'RDT',
    /** Credefi */
    'CREDI' = 'CREDI',
    /** VLaunch */
    'VPAD' = 'VPAD',
    /** Wrapped Kaspa */
    'WKAS' = 'WKAS',
    /** Chintai */
    'CHEX' = 'CHEX',
    /** MContent */
    'MCONTENT' = 'MCONTENT',
    /** Allbridge */
    'ABR' = 'ABR',
    /** FODL Finance */
    'FODL' = 'FODL',
    /** Melos Studio */
    'MELOS' = 'MELOS',
    /** Realm */
    'REALM' = 'REALM',
    /** Vera */
    'VERA' = 'VERA',
    /** Celestial */
    'CELT' = 'CELT',
    /** DEUS Finance */
    'DEUS' = 'DEUS',
    /** Animal Concerts */
    'ANML' = 'ANML',
    /** Loop Network */
    'LOOP' = 'LOOP',
    /** GOLCOIN */
    'GOLC' = 'GOLC',
    /** SophiaVerse */
    'SOPH' = 'SOPH',
    /** Homeros */
    'HMR' = 'HMR',
    /** Occam.Fi */
    'OCC' = 'OCC',
    /** BoringDAO */
    'BORING' = 'BORING',
    /** Wrapped Harmony */
    'WONE' = 'WONE',
    /** NASDEX */
    'NSDX' = 'NSDX',
    /** Zamio */
    'ZAM' = 'ZAM',
    /** HyperCycle */
    'HYPC' = 'HYPC',
    /** Dark Energy Crystals */
    'DEC' = 'DEC',
    /** Gera Coin */
    'GERA' = 'GERA',
    /** EQIFI */
    'EQX' = 'EQX',
    /** Pancake Games */
    'GCAKE' = 'GCAKE',
    /** Mean DAO */
    'MEAN' = 'MEAN',
    /** Trace Network Labs */
    'TRACE' = 'TRACE',
    /** Zeniq */
    'ZENIQ' = 'ZENIQ',
    /** Popsicle Finance */
    'ICE' = 'ICE',
    /** bloXmove Token */
    'BLXM' = 'BLXM',
    /** Catena */
    'CMCX' = 'CMCX',
    /** MetaVPad */
    'METAV' = 'METAV',
    /** ClearDAO */
    'CLH' = 'CLH',
    /** Xodex */
    'XODEX' = 'XODEX',
    /** Zombie Inu(New) */
    'ZINU' = 'ZINU',
    /** CateCoin */
    'CATE' = 'CATE',
    /** TCGCoin 2.0 */
    'TCG2' = 'TCG2',
    /** Bit2Me */
    'B2M' = 'B2M',
    /** BlockchainSpace */
    'GUILD' = 'GUILD',
    /** Betswap.gg */
    'BSGG' = 'BSGG',
    /** Spellfire */
    'SPELLFIRE' = 'SPELLFIRE',
    /** QMALL TOKEN */
    'QMALL' = 'QMALL',
    /** HalfPizza */
    'PIZA' = 'PIZA',
    /** Lunr Token */
    'LUNR' = 'LUNR',
    /** Nunu Spirits */
    'NNT' = 'NNT',
    /** SpaceMine */
    'MINE' = 'MINE',
    /** OGzClub */
    'OGz' = 'OGz',
    /** Chain Games */
    'CHAIN' = 'CHAIN',
    /** Launchpool */
    'LPOOL' = 'LPOOL',
    /** Freela */
    'FREL' = 'FREL',
    /** Centaurify */
    'CENT' = 'CENT',
    /** Frontrow */
    'FRR' = 'FRR',
    /** iDypius */
    'IDYP' = 'IDYP',
    /** Galeon */
    'GALEON' = 'GALEON',
    /** Jupiter */
    'JUP' = 'JUP',
    /** 4P FOUR */
    'FOUR' = 'FOUR',
    /** NAOS Finance */
    'NAOS' = 'NAOS',
    /** WELD */
    'WELD' = 'WELD',
    /** Poken */
    'PKN' = 'PKN',
    /** Kiba Inu */
    'KIBA' = 'KIBA',
    /** ShibElon */
    'SHIBELON' = 'SHIBELON',
    /** The Winkyverse */
    'WNK' = 'WNK',
    /** Fusotao Protocol */
    'TAO' = 'TAO',
    /** Ultimate Champions */
    'CHAMP' = 'CHAMP',
    /** ProximaX */
    'XPX' = 'XPX',
    /** YoloCash */
    'YLC' = 'YLC',
    /** HALO network */
    'HO' = 'HO',
    /** Zenith Coin */
    'ZENITH' = 'ZENITH',
    /** Kudoe */
    'KDOE' = 'KDOE',
    /** Astropup coin */
    'ASPC' = 'ASPC',
    /** MN Bridge */
    'MNB' = 'MNB',
    /** Navcoin */
    'NAV' = 'NAV',
    /** Nexalt */
    'XLT' = 'XLT',
    /** DataHighway */
    'DHX' = 'DHX',
    /** Blind Boxes */
    'BLES' = 'BLES',
    /** Phantom Protocol */
    'PHM' = 'PHM',
    /** AmazingTeamDAO */
    'AMAZINGTEAM' = 'AMAZINGTEAM',
    /** XENO Governance */
    'GXE' = 'GXE',
    /** Dynamite */
    'DYNMT' = 'DYNMT',
    /** TruePNL */
    'PNL' = 'PNL',
    /** BOY X HIGHSPEED */
    'BXH' = 'BXH',
    /** Vent Finance */
    'VENT' = 'VENT',
    /** Moon Rabbit */
    'AAA' = 'AAA',
    /** Mimir Token */
    'MIMIR' = 'MIMIR',
    /** ReSource Protocol */
    'SOURCE' = 'SOURCE',
    /** SafeMoon V2 */
    'SFM' = 'SFM',
    /** Luffy */
    'LUFFY' = 'LUFFY',
    /** Archethic */
    'UCO' = 'UCO',
    /** BambooDeFi */
    'BAMBOO' = 'BAMBOO',
    /** PixelVerse */
    'PIXEL' = 'PIXEL',
    /** SportsIcon */
    'ICONS' = 'ICONS',
    /** Nexum */
    'NEXM' = 'NEXM',
    /** Ignore Fud */
    '4TOKEN' = '4TOKEN',
    /** Envoy */
    'ENV' = 'ENV',
    /** ChainPort */
    'PORTX' = 'PORTX',
    /** Colizeum */
    'ZEUM' = 'ZEUM',
    /** Omnisea */
    'OSEA' = 'OSEA',
    /** TokenClub */
    'TCT' = 'TCT',
    /** Paralink Network */
    'PARA' = 'PARA',
    /** DeSpace Protocol */
    'DES' = 'DES',
    /** Creator Platform */
    'CTR' = 'CTR',
    /** Asia Coin */
    'ASIA' = 'ASIA',
    /** Yoshi.exchange */
    'YOSHI' = 'YOSHI',
    /** TFS Token */
    'TFS' = 'TFS',
    /** Okuru */
    'XOT' = 'XOT',
    /** Bluelight */
    'KALE' = 'KALE',
    /** Jarvis Synthetic Euro */
    'JEUR' = 'JEUR',
    /** Zugacoin */
    'SZCB' = 'SZCB',
    /** Zipmex */
    'ZMT' = 'ZMT',
    /** Gains */
    'GAINS' = 'GAINS',
    /** Flurry Finance */
    'FLURRY' = 'FLURRY',
    /** SpaceDawgs */
    'DAWGS' = 'DAWGS',
    /** 99Starz */
    'STZ' = 'STZ',
    /** DogCoin */
    'DOGS' = 'DOGS',
    /** MaziMatic */
    'MAZI' = 'MAZI',
    /** Doge KaKi */
    'KAKI' = 'KAKI',
    /** Wistaverse */
    'WISTA' = 'WISTA',
    /** Tidex Token */
    'TDX' = 'TDX',
    /** VNDC */
    'VNDC' = 'VNDC',
    /** Lever Token */
    'LEV' = 'LEV',
    /** NEXTYPE */
    'NT' = 'NT',
    /** Brewlabs */
    'BREWLABS' = 'BREWLABS',
    /** ELYFI */
    'ELFI' = 'ELFI',
    /** Quidd */
    'QUIDD' = 'QUIDD',
    /** Oobit */
    'OBT' = 'OBT',
    /** BlazeX */
    'BLAZEX' = 'BLAZEX',
    /** Noku */
    'NOKU' = 'NOKU',
    /** Cloudbric */
    'CLBK' = 'CLBK',
    /** The Recharge */
    'RCG' = 'RCG',
    /** Entropy */
    'ENT' = 'ENT',
    /** BiLira */
    'TRYB' = 'TRYB',
    /** Pontoon */
    'TOON' = 'TOON',
    /** dHealth */
    'DHP' = 'DHP',
    /** Ethereum Meta */
    'ETHM' = 'ETHM',
    /** TeraBlock */
    'TBC' = 'TBC',
    /** TTcoin */
    'TC' = 'TC',
    /** Secret */
    'SIE' = 'SIE',
    /** Acria.AI */
    'ACRIA' = 'ACRIA',
    /** DeFinity */
    'DEFX' = 'DEFX',
    /** Synapse Network */
    'SNP' = 'SNP',
    /** Betero */
    'BTE' = 'BTE',
    /** BUILD */
    'BUILD' = 'BUILD',
    /** Genius */
    'GENI' = 'GENI',
    /** Burnny Inu */
    'BURNNY' = 'BURNNY',
    /** PirateCash */
    'PIRATE' = 'PIRATE',
    /** Anime Token */
    'ANI' = 'ANI',
    /** Luxurious Pro Network Token */
    'LPNT' = 'LPNT',
    /** ONUS */
    'ONUS' = 'ONUS',
    /** Froyo Games */
    'FROYO' = 'FROYO',
    /** PayBolt */
    'PAY' = 'PAY',
    /** DRAC Network */
    'DRAC' = 'DRAC',
    /** CrowdSwap */
    'CROWD' = 'CROWD',
    /** YEL.Finance */
    'YEL' = 'YEL',
    /** PlayNity */
    'PLY' = 'PLY',
    /** A4 Finance */
    'A4' = 'A4',
    /** Coin98 Dollar */
    'CUSD' = 'CUSD',
    /** DeFiato */
    'DFIAT' = 'DFIAT',
    /** FQSwap V2 */
    'FQS' = 'FQS',
    /** Hanu Yokia */
    'HANU' = 'HANU',
    /** OxAI.com */
    'OXAI' = 'OXAI',
    /** Cogecoin */
    'COGE' = 'COGE',
    /** RBX */
    'RBX' = 'RBX',
    /** MetalSwap */
    'XMT' = 'XMT',
    /** VCGamers */
    'VCG' = 'VCG',
    /** Primate */
    'PRIMATE' = 'PRIMATE',
    /** Dohrnii */
    'DHN' = 'DHN',
    /** Ankr Staked MATIC */
    'ankrMATIC' = 'ankrMATIC',
    /** Talent Token */
    'TTX' = 'TTX',
    /** HashBit BlockChain */
    'HBIT' = 'HBIT',
    /** Uplift */
    'LIFT' = 'LIFT',
    /** Iclick inu */
    'ICLICK' = 'ICLICK',
    /** Giftedhands */
    'GHD' = 'GHD',
    /** Obortech */
    'OBOT' = 'OBOT',
    /** GogolCoin */
    'GOL' = 'GOL',
    /** Dinger Token */
    'DINGER' = 'DINGER',
    /** Crown */
    'CRW' = 'CRW',
    /** XIDO FINANCE */
    'XIDO' = 'XIDO',
    /** Pige */
    'PIGE' = 'PIGE',
    /** Zeedex */
    'ZDEX' = 'ZDEX',
    /** Medicalveda */
    'MVEDA' = 'MVEDA',
    /** Safle */
    'SAFLE' = 'SAFLE',
    /** Hector Network */
    'HEC' = 'HEC',
    /** Golden Inu */
    'GOLDEN' = 'GOLDEN',
    /** HUNDRED */
    'HUNDRED' = 'HUNDRED',
    /** Kambria */
    'KAT' = 'KAT',
    /** EnreachDAO */
    'NRCH' = 'NRCH',
    /** Duet Protocol */
    'DUET' = 'DUET',
    /** NFT STARS */
    'NFTS' = 'NFTS',
    /** PolkaFantasy */
    'XP' = 'XP',
    /** bobcoin */
    'BOBC' = 'BOBC',
    /** BlackCoin */
    'BLK' = 'BLK',
    /** AMMYI Coin */
    'AMI' = 'AMI',
    /** Pussy Financial */
    'PUSSY' = 'PUSSY',
    /** Exohood */
    'EXO' = 'EXO',
    /** Identity */
    'IDTT' = 'IDTT',
    /** Wakanda Inu */
    'WKD' = 'WKD',
    /** Mainstream For The Underground */
    'MFTU' = 'MFTU',
    /** Voltz */
    'VOLTZ' = 'VOLTZ',
    /** Reflex */
    'RFX' = 'RFX',
    /** Starter */
    'START' = 'START',
    /** American Shiba */
    'USHIBA' = 'USHIBA',
    /** Relay Token */
    'RELAY' = 'RELAY',
    /** DBX */
    'DBX' = 'DBX',
    /** BitcoinBR */
    'BTCBR' = 'BTCBR',
    /** Micromines */
    'MICRO' = 'MICRO',
    /** Anyswap */
    'ANY' = 'ANY',
    /** Rentible */
    'RNB' = 'RNB',
    /** TORG */
    'TORG' = 'TORG',
    /** Lightcoin */
    'LHC' = 'LHC',
    /** GoCryptoMe */
    'GCME' = 'GCME',
    /** MAIN */
    'MAIN' = 'MAIN',
    /** Zoo Token */
    'ZOOT' = 'ZOOT',
    /** Crosschain IOTX */
    'CIOTX' = 'CIOTX',
    /** HappyFans */
    'HAPPY' = 'HAPPY',
    /** CUBE */
    'ITAMCUBE' = 'ITAMCUBE',
    /** Novacoin */
    'NVC' = 'NVC',
    /** Barter */
    'BRTR' = 'BRTR',
    /** Quarashi */
    'QUA' = 'QUA',
    /** Jax.Network */
    'WJXN' = 'WJXN',
    /** HUH Token */
    'HUH' = 'HUH',
    /** TOR */
    'TOR' = 'TOR',
    /** Salmonation */
    'SUI' = 'SUI',
    /** Goldex Token */
    'GLDX' = 'GLDX',
    /** Crypto Global United */
    'CGU' = 'CGU',
    /** StrongHands Finance */
    'ISHND' = 'ISHND',
    /** Xave Coin */
    'XVC' = 'XVC',
    /** Shido (Old) */
    'SHIDO' = 'SHIDO',
    /** SpiceUSD */
    'USDS' = 'USDS',
    /** DigitalNote */
    'XDN' = 'XDN',
    /** ITAM Games */
    'ITAM' = 'ITAM',
    /** TOZEX */
    'TOZ' = 'TOZ',
    /** LunaChow */
    'LUCHOW' = 'LUCHOW',
    /** Cross-Chain Bridge Token */
    'BRIDGE' = 'BRIDGE',
    /** StaFi Staked MATIC */
    'RMATIC' = 'RMATIC',
    /** INT */
    'INT' = 'INT',
    /** Merge */
    'MERGE' = 'MERGE',
    /** YFIONE */
    'YFO' = 'YFO',
    /** Pirate Coin Games */
    'PirateCoin☠' = 'PirateCoin☠',
    /** Matrix Labs */
    'MATRIX' = 'MATRIX',
    /** CRYSTAL STONES */
    'CRYSTAL STONES' = 'CRYSTAL STONES',
    /** Aircoins */
    'AIRX' = 'AIRX',
    /** PayAccept */
    'PAYT' = 'PAYT',
    /** Vidiachange */
    'VIDA' = 'VIDA',
    /** GovWorld */
    'GOV' = 'GOV',
    /** Gemlink */
    'GLINK' = 'GLINK',
    /** MusicAI */
    'MUSICAI' = 'MUSICAI',
    /** Switch */
    'ESH' = 'ESH',
    /** SokuSwap */
    'SOKU' = 'SOKU',
    /** Envelop */
    'NIFTSY' = 'NIFTSY',
    /** GalaxyGoggle DAO */
    'GG' = 'GG',
    /** 42-coin */
    '#42' = '42',
    /** Kalkulus */
    'KLKS' = 'KLKS',
    /** Scaleswap */
    'SCA' = 'SCA',
    /** Raptor Finance */
    'RPTR' = 'RPTR',
    /** Shih Tzu */
    'SHIH' = 'SHIH',
    /** bePAY Finance */
    'BECOIN' = 'BECOIN',
    /** Atlantis Coin */
    'ATC' = 'ATC',
    /** SF Capital */
    'SFCP' = 'SFCP',
    /** Shibaken Finance */
    'SHIBAKEN' = 'SHIBAKEN',
    /** FOHO Coin */
    'FOHO' = 'FOHO',
    /** Centurion */
    'CNT' = 'CNT',
    /** Ormeus Coin */
    'ORMEUS' = 'ORMEUS',
    /** Lamden */
    'TAU' = 'TAU',
    /** Covesting */
    'COV' = 'COV',
    /** FantasyGold */
    'FGC' = 'FGC',
    /** Beetlecoin */
    'BEET' = 'BEET',
    /** Escroco Emerald */
    'ESCE' = 'ESCE',
    /** xRhodium */
    'XRC' = 'XRC',
    /** Levolution */
    'LEVL' = 'LEVL',
    /** HEdpAY */
    'HDP.ф' = 'HDP.ф',
    /** JBOX */
    'JBX' = 'JBX',
    /** DxSale.Network */
    'SALE' = 'SALE',
    /** Kirobo */
    'KIRO' = 'KIRO',
    /** UNCL */
    'UNCL' = 'UNCL',
    /** DexKit */
    'KIT' = 'KIT',
    /** Action Coin */
    'ACTN' = 'ACTN',
    /** Trism */
    'TRISM' = 'TRISM',
    /** ebox */
    'EBOX' = 'EBOX',
    /** HOKK Finance */
    'HOKK' = 'HOKK',
    /** UMI */
    'UMI' = 'UMI',
    /** Upfire */
    'UPR' = 'UPR',
    /** Sensitrust */
    'SETS' = 'SETS',
    /** PalGold */
    'PALG' = 'PALG',
    /** Iron */
    'IRON' = 'IRON',
    /** CoinsPaid */
    'CPD' = 'CPD',
    /** Hibiki Finance */
    'HIBIKI' = 'HIBIKI',
    /** NFTmall */
    'GEM' = 'GEM',
    /** IjasCoin */
    'IJC' = 'IJC',
    /** Starchi */
    'ELIXIR' = 'ELIXIR',
    /** Ponyo Impact */
    'PONYO' = 'PONYO',
    /** Adonis */
    'ADON' = 'ADON',
    /** MEDIA EYE NFT Portal */
    'eYe' = 'eYe',
    /** MetaFabric */
    'FABRIC' = 'FABRIC',
    /** Restore Truth Token */
    'RTT' = 'RTT',
    /** DigiMetaverse */
    'DGMV' = 'DGMV',
    /** MondayClub */
    'MONDAY' = 'MONDAY',
    /** POLYSPORTS */
    'PS1' = 'PS1',
    /** Kyuubi */
    '$KYUB' = '$KYUB',
    /** Universal Store of Value */
    'USV' = 'USV',
    /** Shack Token */
    'SHACK' = 'SHACK',
    /** Saudi Shiba Inu */
    'SAUDISHIB' = 'SAUDISHIB',
    /** Champion */
    'CHAM' = 'CHAM',
    /** Collie Inu */
    'COLLIE' = 'COLLIE',
    /** UpBots Token */
    'UBXN' = 'UBXN',
    /** Shibnobi(New) */
    'SHINJA' = 'SHINJA',
    /** Shorter Finance */
    'IPISTR' = 'IPISTR',
    /** X Social Network */
    'X-AI' = 'X-AI',
    /** MultiBTC */
    'MULTIBTC' = 'MULTIBTC',
    /** Popecoin */
    'POPE' = 'POPE',
    /** Oshi */
    'OSHI' = 'OSHI',
    /** Celo */
    'CELO' = 'CELO',
    /** Celo Euro */
    'CEUR' = 'CEUR',
    /** Plastiks */
    'PLASTIK' = 'PLASTIK',
    /** Moola Celo USD */
    'mCUSD' = 'mCUSD',
    /** Celo Brazilian Real */
    'CREAL' = 'CREAL',
    /** Moola Celo EUR */
    'mCEUR' = 'mCEUR',
    /** Ubeswap */
    'UBE' = 'UBE',
    /** impactMarket */
    'PACT' = 'PACT',
    /** Moola Celo */
    'mCELO' = 'mCELO',
    /** ImmortalDAO Finance */
    'IMMO' = 'IMMO',
    /** Poof.cash */
    'POOF' = 'POOF',
    /** Ari Swap */
    'ARI' = 'ARI',
    /** TrueFeedBack */
    'TFBX' = 'TFBX',
    /** Moss Carbon Credit */
    'MCO2' = 'MCO2',
    /** Glo Dollar */
    'USDGLO' = 'USDGLO',
    /** GoodDollar */
    'G$' = 'G$',
    /** EthicHub */
    'ETHIX' = 'ETHIX',
    /** KnoxDAO */
    'KNOX' = 'KNOX',
    /** Symmetric */
    'SYMM' = 'SYMM',
    /** Doge Eat Doge */
    'OMNOM' = 'OMNOM',
    /** Wrapped WDOGE */
    'WWDOGE' = 'WWDOGE',
    /** YodeSwap */
    'YODE' = 'YODE',
    /** Doge Pup Token */
    'DOGEPUP' = 'DOGEPUP',
    /** Poo Doge */
    'POO DOGE' = 'POO DOGE',
    /** Dogechain */
    'DC' = 'DC',
    /** Duckereum */
    'DUCKER' = 'DUCKER',
    /** Dogira */
    'DOGIRA' = 'DOGIRA',
    /** holoride */
    'RIDE' = 'RIDE',
    /** Hatom */
    'HTM' = 'HTM',
    /** ZoidPay */
    'ZPAY' = 'ZPAY',
    /** AshSwap */
    'ASH' = 'ASH',
    /** BH Network */
    'BHAT' = 'BHAT',
    /** Itheum */
    'ITHEUM' = 'ITHEUM',
    /** Cantina Royale */
    'CRT' = 'CRT',
    /** Moove Protocol */
    'MOOVE' = 'MOOVE',
    /** Proteo DeFi */
    'PROTEO' = 'PROTEO',
    /** xExchange */
    'MEX' = 'MEX',
    /** QoWatt */
    'QWT' = 'QWT',
    /** Sense4FIT */
    'SFIT' = 'SFIT',
    /** GoCharge Tech */
    'CHARGED' = 'CHARGED',
    /** Aerovek Aviation */
    'AERO' = 'AERO',
    /** Ofero */
    'OFE' = 'OFE',
    /** Effort Economy */
    'EFFORT' = 'EFFORT',
    /** Krogan */
    'KRO' = 'KRO',
    /** Plata Network */
    'PLATA' = 'PLATA',
    /** Vital Network */
    'VITAL' = 'VITAL',
    /** Beskar */
    'BSK-BAA025' = 'BSK-BAA025',
    /** CheckerChain */
    'CHECKR' = 'CHECKR',
    /** xMoney */
    'UTK' = 'UTK',
    /** Wrapped EGLD */
    'WEGLD' = 'WEGLD',
    /** Shiba Inu */
    'SHIB' = 'SHIB',
    /** OKB */
    'OKB' = 'OKB',
    /** Immutable */
    'IMX' = 'IMX',
    /** Mantle */
    'MNT' = 'MNT',
    /** Render */
    'RNDR' = 'RNDR',
    /** Quant */
    'QNT' = 'QNT',
    /** The Sandbox */
    'SAND' = 'SAND',
    /** KuCoin Token */
    'KCS' = 'KCS',
    /** Decentraland */
    'MANA' = 'MANA',
    /** Gala */
    'GALA' = 'GALA',
    /** Bitget Token */
    'BGB' = 'BGB',
    /** Rocket Pool */
    'RPL' = 'RPL',
    /** Blur */
    'BLUR' = 'BLUR',
    /** Curve DAO Token */
    'CRV' = 'CRV',
    /** dYdX (ethDYDX) */
    'ETHDYDX' = 'ETHDYDX',
    /** Tether Gold */
    'XAUt' = 'XAUt',
    /** Huobi Token */
    'HT' = 'HT',
    /** GateToken */
    'GT' = 'GT',
    /** Illuvium */
    'ILV' = 'ILV',
    /** Convex Finance */
    'CVX' = 'CVX',
    /** 0x Protocol */
    'ZRX' = 'ZRX',
    /** Enjin Coin */
    'ENJ' = 'ENJ',
    /** Loopring */
    'LRC' = 'LRC',
    /** Storj */
    'STORJ' = 'STORJ',
    /** MX TOKEN */
    'MX' = 'MX',
    /** Worldcoin */
    'WLD' = 'WLD',
    /** Ocean Protocol */
    'OCEAN' = 'OCEAN',
    /** JasmyCoin */
    'JASMY' = 'JASMY',
    /** ssv.network */
    'SSV' = 'SSV',
    /** Ethereum Name Service */
    'ENS' = 'ENS',
    /** Threshold */
    'T' = 'T',
    /** Audius */
    'AUDIO' = 'AUDIO',
    /** Golem */
    'GLM' = 'GLM',
    /** Biconomy */
    'BICO' = 'BICO',
    /** SKALE */
    'SKL' = 'SKL',
    /** Echelon Prime */
    'PRIME' = 'PRIME',
    /** Vulcan Forged PYR */
    'PYR' = 'PYR',
    /** Livepeer */
    'LPT' = 'LPT',
    /** Ribbon Finance */
    'RBN' = 'RBN',
    /** Status */
    'SNT' = 'SNT',
    /** Aurora */
    'AURORA' = 'AURORA',
    /** Powerledger */
    'POWR' = 'POWR',
    /** UMA */
    'UMA' = 'UMA',
    /** Reserve Rights */
    'RSR' = 'RSR',
    /** Lido Staked ETH */
    'stETH' = 'stETH',
    /** Rocket Pool ETH */
    'RETH' = 'RETH',
    /** HEX */
    'HEX' = 'HEX',
    /** Rollbit Coin */
    'RLB' = 'RLB',
    /** Coinbase Wrapped Staked ETH */
    'CBETH' = 'CBETH',
    /** Huobi BTC */
    'HBTC' = 'HBTC',
    /** Orbler */
    'ORBR' = 'ORBR',
    /** Valobit */
    'VBIT' = 'VBIT',
    /** OriginTrail */
    'TRAC' = 'TRAC',
    /** Big Time */
    'BIGTIME' = 'BIGTIME',
    /** SwissBorg */
    'BORG' = 'BORG',
    /** Tribe */
    'TRIBE' = 'TRIBE',
    /** Telcoin */
    'TEL' = 'TEL',
    /** PayPal USD */
    'PYUSD' = 'PYUSD',
    /** LUKSO (Old) */
    'LYXe' = 'LYXe',
    /** Gemini Dollar */
    'GUSD' = 'GUSD',
    /** Covalent */
    'CQT' = 'CQT',
    /** VVS Finance */
    'VVS' = 'VVS',
    /** Liquity */
    'LQTY' = 'LQTY',
    /** tomiNet */
    'TOMI' = 'TOMI',
    /** Agoras: Currency of Tau */
    'AGRS' = 'AGRS',
    /** STASIS EURO */
    'EURS' = 'EURS',
    /** API3 */
    'API3' = 'API3',
    /** Pundi X (New) */
    'PUNDIX' = 'PUNDIX',
    /** Braintrust */
    'BTRST' = 'BTRST',
    /** STP */
    'STPT' = 'STPT',
    /** dKargo */
    'DKA' = 'DKA',
    /** PlayDapp */
    'PLA' = 'PLA',
    /** PAAL AI */
    'PAAL' = 'PAAL',
    /** Keep Network */
    'KEEP' = 'KEEP',
    /** Gods Unchained */
    'GODS' = 'GODS',
    /** Yield Guild Games */
    'YGG' = 'YGG',
    /** Metal DAO */
    'MTL' = 'MTL',
    /** Amp */
    'AMP' = 'AMP',
    /** iExec RLC */
    'RLC' = 'RLC',
    /** Bancor */
    'BNT' = 'BNT',
    /** LooksRare */
    'LOOKS' = 'LOOKS',
    /** MetisDAO */
    'METIS' = 'METIS',
    /** Marlin */
    'POND' = 'POND',
    /** Astrafer */
    'ASTRAFER' = 'ASTRAFER',
    /** NYM */
    'NYM' = 'NYM',
    /** Numeraire */
    'NMR' = 'NMR',
    /** Lyra */
    'LYRA' = 'LYRA',
    /** OMG Network */
    'OMG' = 'OMG',
    /** Adventure Gold */
    'AGLD' = 'AGLD',
    /** 0x0.ai */
    '#0x0' = '0x0',
    /** Wrapped NXM */
    'WNXM' = 'WNXM',
    /** Dent */
    'DENT' = 'DENT',
    /** Multibit */
    'MUBI' = 'MUBI',
    /** Request */
    'REQ' = 'REQ',
    /** Hashflow */
    'HFT' = 'HFT',
    /** Verasity */
    'VRA' = 'VRA',
    /** XYO */
    'XYO' = 'XYO',
    /** Uquid Coin */
    'UQC' = 'UQC',
    /** SuperRare */
    'RARE' = 'RARE',
    /** Arkham */
    'ARKM' = 'ARKM',
    /** StormX */
    'STMX' = 'STMX',
    /** Maple */
    'MPL' = 'MPL',
    /** QuarkChain */
    'QKC' = 'QKC',
    /** Vanar Chain */
    'VANRY' = 'VANRY',
    /** Gitcoin */
    'GTC' = 'GTC',
    /** ATOR Protocol */
    'ATOR' = 'ATOR',
    /** Origin Protocol */
    'OGN' = 'OGN',
    /** Orchid */
    'OXT' = 'OXT',
    /** ConstitutionDAO */
    'PEOPLE' = 'PEOPLE',
    /** Ultra */
    'UOS' = 'UOS',
    /** Vega Protocol */
    'VEGA' = 'VEGA',
    /** GuildFi */
    'GF' = 'GF',
    /** Bifrost */
    'BFC' = 'BFC',
    /** Cobak Token */
    'CBK' = 'CBK',
    /** Sweat Economy */
    'SWEAT' = 'SWEAT',
    /** Myria */
    'MYRIA' = 'MYRIA',
    /** RSS3 */
    'RSS3' = 'RSS3',
    /** Ren */
    'REN' = 'REN',
    /** Spell Token */
    'SPELL' = 'SPELL',
    /** FLEX */
    'FLEX' = 'FLEX',
    /** UniBot */
    'UNIBOT' = 'UNIBOT',
    /** Tokenlon Network Token */
    'LON' = 'LON',
    /** Efinity Token */
    'EFI' = 'EFI',
    /** TrueFi */
    'TRU' = 'TRU',
    /** Function X */
    'FX' = 'FX',
    /** Morpheus.Network */
    'MNW' = 'MNW',
    /** WAGMI Games */
    'WAGMIGAMES' = 'WAGMIGAMES',
    /** Celsius */
    'CEL' = 'CEL',
    /** Router Protocol */
    'ROUTE' = 'ROUTE',
    /** Strike */
    'STRK' = 'STRK',
    /** EURC */
    'EURC' = 'EURC',
    /** Carry */
    'CRE' = 'CRE',
    /** KRYZA Exchange */
    'KRX' = 'KRX',
    /** Polygon Ecosystem Token */
    'POL' = 'POL',
    /** LCX */
    'LCX' = 'LCX',
    /** COMBO */
    'COMBO' = 'COMBO',
    /** Ampleforth Governance Token */
    'FORTH' = 'FORTH',
    /** sUSD */
    'SUSD' = 'SUSD',
    /** FirmaChain */
    'FCT' = 'FCT',
    /** FUNToken */
    'FUN' = 'FUN',
    /** DeFi Pulse Index */
    'DPI' = 'DPI',
    /** HUNT */
    'HUNT' = 'HUNT',
    /** Tectum */
    'TET' = 'TET',
    /** Guild of Guardians */
    'GOG' = 'GOG',
    /** Niza Global */
    'NIZA' = 'NIZA',
    /** NFT Worlds */
    'WRLD' = 'WRLD',
    /** Aavegotchi */
    'GHST' = 'GHST',
    /** Goldfinch */
    'GFI' = 'GFI',
    /** Orbit Chain */
    'ORC' = 'ORC',
    /** Assemble Protocol */
    'ASM' = 'ASM',
    /** Wall Street Memes */
    'WSM' = 'WSM',
    /** Perpetual Protocol */
    'PERP' = 'PERP',
    /** Propy */
    'PRO' = 'PRO',
    /** Aura Finance */
    'AURA' = 'AURA',
    /** UFO Gaming */
    'UFO' = 'UFO',
    /** IDEX */
    'IDEX' = 'IDEX',
    /** Ampleforth */
    'AMPL' = 'AMPL',
    /** LeverFi */
    'LEVER' = 'LEVER',
    /** Voyager Token */
    'VGX' = 'VGX',
    /** Moss Coin */
    'MOC' = 'MOC',
    /** Reserve */
    'RSV' = 'RSV',
    /** Gelato */
    'GEL' = 'GEL',
    /** Tether EURt */
    'EURt' = 'EURt',
    /** Alchemix */
    'ALCX' = 'ALCX',
    /** Rarible */
    'RARI' = 'RARI',
    /** Ethernity */
    'ERN' = 'ERN',
    /** Enzyme */
    'MLN' = 'MLN',
    /** e-Radix */
    'EXRD' = 'EXRD',
    /** Image Generation AI */
    'IMGNAI' = 'IMGNAI',
    /** Sentinel Protocol */
    'UPP' = 'UPP',
    /** Marinade Staked SOL */
    'MSOL' = 'MSOL',
    /** AVINOC */
    'AVINOC' = 'AVINOC',
    /** Dora Factory */
    'DORA' = 'DORA',
    /** Qredo */
    'QRDO' = 'QRDO',
    /** Rakon */
    'RKN' = 'RKN',
    /** Vectorspace AI */
    'VXV' = 'VXV',
    /** Whiteheart */
    'WHITE' = 'WHITE',
    /** ZEON */
    'ZEON' = 'ZEON',
    /** Doge Killer */
    'LEASH' = 'LEASH',
    /** Fei USD */
    'FEI' = 'FEI',
    /** CUDOS */
    'CUDOS' = 'CUDOS',
    /** Starlink */
    'STARL' = 'STARL',
    /** Dione Protocol */
    'DIONE' = 'DIONE',
    /** Locus Chain */
    'LOCUS' = 'LOCUS',
    /** Clearpool */
    'CPOOL' = 'CPOOL',
    /** Boson Protocol */
    'BOSON' = 'BOSON',
    /** Keep3rV1 */
    'KP3R' = 'KP3R',
    /** NEOPIN */
    'NPT' = 'NPT',
    /** Ultiledger */
    'ULT' = 'ULT',
    /** ASD */
    'ASD' = 'ASD',
    /** SENATE */
    'SENATE' = 'SENATE',
    /** BarnBridge */
    'BOND' = 'BOND',
    /** Rally */
    'RLY' = 'RLY',
    /** Optimus AI */
    'OPTI' = 'OPTI',
    /** Dejitaru Tsuka */
    'TSUKA' = 'TSUKA',
    /** BitMart Token */
    'BMX' = 'BMX',
    /** Syntropy */
    'NOIA' = 'NOIA',
    /** RMRK */
    'RMRK' = 'RMRK',
    /** Kishu Inu */
    'KISHU' = 'KISHU',
    /** Akropolis */
    'AKRO' = 'AKRO',
    /** QuickSwap [Old] */
    'QUICK' = 'QUICK',
    /** USDK */
    'USDK' = 'USDK',
    /** CONUN */
    'CON' = 'CON',
    /** ParagonsDAO */
    'PDT' = 'PDT',
    /** XSGD */
    'XSGD' = 'XSGD',
    /** Castello Coin */
    'CAST' = 'CAST',
    /** MASQ */
    'MASQ' = 'MASQ',
    /** Propchain */
    'PROPC' = 'PROPC',
    /** Kava Lend */
    'HARD' = 'HARD',
    /** League of Kingdoms Arena */
    'LOKA' = 'LOKA',
    /** Coinweb */
    'CWEB' = 'CWEB',
    /** StaFi */
    'FIS' = 'FIS',
    /** Across Protocol */
    'ACX' = 'ACX',
    /** ZKSpace */
    'ZKS' = 'ZKS',
    /** Swarm */
    'BZZ' = 'BZZ',
    /** Inverse Finance */
    'INV' = 'INV',
    /** ONBUFF */
    'ONIT' = 'ONIT',
    /** Victoria VR */
    'VR' = 'VR',
    /** Bad Idea AI */
    'BAD' = 'BAD',
    /** Metacade */
    'MCADE' = 'MCADE',
    /** Decentral Games */
    'DG' = 'DG',
    /** Angola */
    'AGLA' = 'AGLA',
    /** Bubblefong */
    'BBF' = 'BBF',
    /** Sovryn */
    'SOV' = 'SOV',
    /** Yield App */
    'YLD' = 'YLD',
    /** Hermez Network */
    'HEZ' = 'HEZ',
    /** Hoge Finance */
    'HOGE' = 'HOGE',
    /** NFTX */
    'NFTX' = 'NFTX',
    /** LATOKEN */
    'LA' = 'LA',
    /** Netvrk */
    'NTVRK' = 'NTVRK',
    /** Stratos */
    'STOS' = 'STOS',
    /** Dimitra */
    'DMTR' = 'DMTR',
    /** SOLVE */
    'SOLVE' = 'SOLVE',
    /** Unibright */
    'UBT' = 'UBT',
    /** Kleros */
    'PNK' = 'PNK',
    /** Viberate */
    'VIB' = 'VIB',
    /** Origin Dollar */
    'OUSD' = 'OUSD',
    /** Red Kite */
    'PKF' = 'PKF',
    /** Unisocks */
    'SOCKS' = 'SOCKS',
    /** HOPR */
    'HOPR' = 'HOPR',
    /** Shiba Predator */
    'QOM' = 'QOM',
    /** LimeWire */
    'LMWR' = 'LMWR',
    /** rhino.fi */
    'DVF' = 'DVF',
    /** OAX */
    'OAX' = 'OAX',
    /** Crust Network */
    'CRU' = 'CRU',
    /** Blocksquare Token */
    'BST' = 'BST',
    /** Tamadoge */
    'TAMA' = 'TAMA',
    /** Drep [new] */
    'DREP' = 'DREP',
    /** Botto */
    'BOTTO' = 'BOTTO',
    /** Orbcity */
    'ORB' = 'ORB',
    /** Presearch */
    'PRE' = 'PRE',
    /** STFX */
    'STFX' = 'STFX',
    /** XELS */
    'XELS' = 'XELS',
    /** Throne */
    'THN' = 'THN',
    /** BABB */
    'BAX' = 'BAX',
    /** XDEFI Wallet */
    'XDEFI' = 'XDEFI',
    /** Kryll */
    'KRL' = 'KRL',
    /** Somnium Space Cubes */
    'CUBE' = 'CUBE',
    /** Pluton */
    'PLU' = 'PLU',
    /** Cult DAO */
    'CULT' = 'CULT',
    /** DOGAMÍ */
    'DOGA' = 'DOGA',
    /** TriumphX */
    'TRIX' = 'TRIX',
    /** Dust Protocol */
    'DUST' = 'DUST',
    /** Arcblock */
    'ABT' = 'ABT',
    /** ISKRA Token */
    'ISK' = 'ISK',
    /** PowerPool */
    'CVP' = 'CVP',
    /** Bepro */
    'BEPRO' = 'BEPRO',
    /** HUSD */
    'HUSD' = 'HUSD',
    /** Hourglass */
    'WAIT' = 'WAIT',
    /** Lossless */
    'LSS' = 'LSS',
    /** HAVAH */
    'HVH' = 'HVH',
    /** Spheroid Universe */
    'SPH' = 'SPH',
    /** Ooki Protocol */
    'OOKI' = 'OOKI',
    /** BitKan */
    'KAN' = 'KAN',
    /** Push Protocol */
    'PUSH' = 'PUSH',
    /** SHPING */
    'SHPING' = 'SHPING',
    /** Swarm Markets */
    'SMT' = 'SMT',
    /** Arianee */
    'ARIA20' = 'ARIA20',
    /** GET Protocol */
    'GET' = 'GET',
    /** Cryptex Finance */
    'CTX' = 'CTX',
    /** Dacxi */
    'DACXI' = 'DACXI',
    /** Bitrue Coin */
    'BTR' = 'BTR',
    /** Quiztok */
    'QTCON' = 'QTCON',
    /** Govi */
    'GOVI' = 'GOVI',
    /** Cere Network */
    'CERE' = 'CERE',
    /** Unicly CryptoPunks Collection */
    'UPUNK' = 'UPUNK',
    /** Augur */
    'REP' = 'REP',
    /** Big Data Protocol */
    'BDP' = 'BDP',
    /** Verse */
    'VERSE' = 'VERSE',
    /** Aventus */
    'AVT' = 'AVT',
    /** Origin DeFi Governance */
    'OGV' = 'OGV',
    /** King DAG */
    'KDAG' = 'KDAG',
    /** Grid+ */
    'GRID' = 'GRID',
    /** Akita Inu */
    'AKITA' = 'AKITA',
    /** Rari Governance Token */
    'RGT' = 'RGT',
    /** Ferro */
    'FER' = 'FER',
    /** Sylo */
    'SYLO' = 'SYLO',
    /** Polkadex */
    'PDEX' = 'PDEX',
    /** GensoKishi Metaverse */
    'MV' = 'MV',
    /** Unification */
    'FUND' = 'FUND',
    /** RAI Finance */
    'SOFI' = 'SOFI',
    /** Project WITH */
    'WIKEN' = 'WIKEN',
    /** Nuco.cloud */
    'NCDT' = 'NCDT',
    /** Handy */
    'HANDY' = 'HANDY',
    /** Pintu Token */
    'PTU' = 'PTU',
    /** SPX6900 */
    'SPX' = 'SPX',
    /** Valor Token */
    'VALOR' = 'VALOR',
    /** Receive Access Ecosystem */
    'RAE' = 'RAE',
    /** Darwinia Network */
    'RING' = 'RING',
    /** SpookySwap */
    'BOO' = 'BOO',
    /** Linker Coin */
    'LNC' = 'LNC',
    /** LeisureMeta */
    'LM' = 'LM',
    /** Emirex Token */
    'EMRX' = 'EMRX',
    /** Chainbing */
    'CBG' = 'CBG',
    /** Minted */
    'MTD' = 'MTD',
    /** WHALE */
    'WHALE' = 'WHALE',
    /** Gorilla */
    'GORILLA' = 'GORILLA',
    /** DAD */
    'DAD' = 'DAD',
    /** GPEX */
    'GPX' = 'GPX',
    /** IMPT */
    'IMPT' = 'IMPT',
    /** Era Swap */
    'ES' = 'ES',
    /** SENSO */
    'SENSO' = 'SENSO',
    /** MongCoin */
    '$MONG' = '$MONG',
    /** BOLT */
    'BOLT' = 'BOLT',
    /** Mancium */
    'MANC' = 'MANC',
    /** Tap */
    'XTP' = 'XTP',
    /** BioPassport Token */
    'BIOT' = 'BIOT',
    /** Polaris Share */
    'POLA' = 'POLA',
    /** CropBytes */
    'CBX' = 'CBX',
    /** Shiryo */
    'SHIRYO' = 'SHIRYO',
    /** Rai Reflex Index */
    'RAI' = 'RAI',
    /** DxChain Token */
    'DX' = 'DX',
    /** LootBot */
    'LOOT' = 'LOOT',
    /** Strips Finance */
    'STRP' = 'STRP',
    /** DeFine */
    'DFA' = 'DFA',
    /** Gameswap */
    'GSWAP' = 'GSWAP',
    /** Realio Network */
    'RIO' = 'RIO',
    /** Blockchain Brawlers */
    'BRWL' = 'BRWL',
    /** Monavale */
    'MONA' = 'MONA',
    /** Shiba Saga */
    'SHIA' = 'SHIA',
    /** Crypterium */
    'CRPT' = 'CRPT',
    /** Etherisc DIP Token */
    'DIP' = 'DIP',
    /** Polkamarkets */
    'POLK' = 'POLK',
    /** Dragonchain */
    'DRGN' = 'DRGN',
    /** Vesper */
    'VSP' = 'VSP',
    /** Dfyn Network */
    'DFYN' = 'DFYN',
    /** Stronghold Token */
    'SHX' = 'SHX',
    /** dHedge DAO */
    'DHT' = 'DHT',
    /** DAFI Protocol */
    'DAFI' = 'DAFI',
    /** BitForex Token */
    'BF' = 'BF',
    /** Robonomics.network */
    'XRT' = 'XRT',
    /** Efforce */
    'WOZX' = 'WOZX',
    /** BTSE Token */
    'BTSE' = 'BTSE',
    /** Muse */
    'MUSE' = 'MUSE',
    /** Geeq */
    'GEEQ' = 'GEEQ',
    /** MurAll */
    'PAINT' = 'PAINT',
    /** Neighbourhoods */
    'NHT' = 'NHT',
    /** Blocery */
    'BLY' = 'BLY',
    /** TomTomCoin */
    'TOMS' = 'TOMS',
    /** Lambda */
    'LAMB' = 'LAMB',
    /** Waltonchain */
    'WTC' = 'WTC',
    /** Lithium */
    'LITH' = 'LITH',
    /** Geojam Token */
    'JAM' = 'JAM',
    /** Morpheus Labs */
    'MITX' = 'MITX',
    /** Konomi Network */
    'KONO' = 'KONO',
    /** KINE */
    'KINE' = 'KINE',
    /** Quantstamp */
    'QSP' = 'QSP',
    /** BOSagora */
    'BOA' = 'BOA',
    /** VIXCO */
    'VIX' = 'VIX',
    /** Adappter Token */
    'ADP' = 'ADP',
    /** SHOPX */
    'SHOPX' = 'SHOPX',
    /** Skey Network */
    'SKEY' = 'SKEY',
    /** Lattice Token */
    'LTX' = 'LTX',
    /** Vidya */
    'VIDYA' = 'VIDYA',
    /** ReapChain */
    'REAP' = 'REAP',
    /** Onooks */
    'OOKS' = 'OOKS',
    /** Civilization */
    'CIV' = 'CIV',
    /** K21 */
    'K21' = 'K21',
    /** STAT */
    'STAT' = 'STAT',
    /** TOP */
    'TOP' = 'TOP',
    /** Abyss */
    'ABYSS' = 'ABYSS',
    /** Electric Vehicle Zone */
    'EVZ' = 'EVZ',
    /** BaaSid */
    'BAAS' = 'BAAS',
    /** OpenDAO */
    'SOS' = 'SOS',
    /** Kylin */
    'KYL' = 'KYL',
    /** DerivaDAO */
    'DDX' = 'DDX',
    /** saffron.finance */
    'SFI' = 'SFI',
    /** SunContract */
    'SNC' = 'SNC',
    /** PointPay */
    'PXP' = 'PXP',
    /** DEXGame */
    'DXGM' = 'DXGM',
    /** Fringe Finance */
    'FRIN' = 'FRIN',
    /** ArdCoin */
    'ARDX' = 'ARDX',
    /** Gracy */
    'GRACY' = 'GRACY',
    /** CEREAL */
    'CEP' = 'CEP',
    /** MAPS */
    'MAPS' = 'MAPS',
    /** yAxis */
    'YAXIS' = 'YAXIS',
    /** AmonD */
    'AMON' = 'AMON',
    /** Integral */
    'ITGR' = 'ITGR',
    /** XMON */
    'XMON' = 'XMON',
    /** Props Token */
    'PROPS' = 'PROPS',
    /** Niftyx Protocol */
    'SHROOM' = 'SHROOM',
    /** Idle */
    'IDLE' = 'IDLE',
    /** Monetha */
    'MTH' = 'MTH',
    /** AXIS Token */
    'AXIS' = 'AXIS',
    /** mStable Governance Token: Meta (MTA) */
    'MTA' = 'MTA',
    /** Fenerbahçe Token */
    'FB' = 'FB',
    /** KOK */
    'KOK' = 'KOK',
    /** PlayFuel */
    'PLF' = 'PLF',
    /** CONTRACOIN */
    'CTCN' = 'CTCN',
    /** Idavoll DAO */
    'IDV' = 'IDV',
    /** B-cube.ai */
    'BCUBE' = 'BCUBE',
    /** Ojamu */
    'OJA' = 'OJA',
    /** Choise.com */
    'CHO' = 'CHO',
    /** Thorstarter */
    'XRUNE' = 'XRUNE',
    /** Aurora */
    'AOA' = 'AOA',
    /** Skeb Coin */
    'SKEB' = 'SKEB',
    /** SORA */
    'XOR' = 'XOR',
    /** Neutrino Token */
    'NSBT' = 'NSBT',
    /** Razor Network */
    'RAZOR' = 'RAZOR',
    /** YAM V3 */
    'YAM' = 'YAM',
    /** Attila */
    'ATT' = 'ATT',
    /** ALLY */
    'ALY' = 'ALY',
    /** LGCY Network */
    'LGCY' = 'LGCY',
    /** Baanx */
    'BXX' = 'BXX',
    /** Jobchain */
    'JOB' = 'JOB',
    /** Nord Finance */
    'NORD' = 'NORD',
    /** Pillar */
    'PLR' = 'PLR',
    /** ELMOERC */
    'ELMO' = 'ELMO',
    /** Lympo */
    'LYM' = 'LYM',
    /** Eminer */
    'EM' = 'EM',
    /** 0xBitcoin */
    '0xBTC' = '0xBTC',
    /** Convergence */
    'CONV' = 'CONV',
    /** Donut */
    'DONUT' = 'DONUT',
    /** Pickle Finance */
    'PICKLE' = 'PICKLE',
    /** TitanSwap */
    'TITAN' = 'TITAN',
    /** Isiklar Coin */
    'ISIKC' = 'ISIKC',
    /** BlockWallet */
    'BLANK' = 'BLANK',
    /** Elitium */
    'EUM' = 'EUM',
    /** 1irstcoin */
    'FST' = 'FST',
    /** Unistake */
    'UNISTAKE' = 'UNISTAKE',
    /** SafeMoon Inu */
    'SMI' = 'SMI',
    /** Vabble */
    'VAB' = 'VAB',
    /** Ideaology */
    'IDEA' = 'IDEA',
    /** hiDOODLES */
    'HIDOODLES' = 'HIDOODLES',
    /** All In */
    'ALLIN' = 'ALLIN',
    /** LINKA */
    'LINKA' = 'LINKA',
    /** Brother Music Platform */
    'BMP' = 'BMP',
    /** Crust Shadow */
    'CSM' = 'CSM',
    /** Construct */
    'STANDARD' = 'STANDARD',
    /** Oxygen */
    'OXY' = 'OXY',
    /** STAKE */
    'STAKE' = 'STAKE',
    /** Ziktalk */
    'ZIK' = 'ZIK',
    /** hiPENGUINS */
    'HIPENGUINS' = 'HIPENGUINS',
    /** EXMO Coin */
    'EXM' = 'EXM',
    /** Humaniq */
    'HMQ' = 'HMQ',
    /** Shopping.io */
    'SHOP' = 'SHOP',
    /** Dentacoin */
    'DCN' = 'DCN',
    /** Acquire.Fi */
    'ACQ' = 'ACQ',
    /** Olyverse */
    'OLY' = 'OLY',
    /** CRYPTO20 */
    'C20' = 'C20',
    /** MetamonkeyAi */
    'MMAI' = 'MMAI',
    /** Tranche Finance */
    'SLICE' = 'SLICE',
    /** Breezecoin */
    'BRZE' = 'BRZE',
    /** PUMLx */
    'PUMLX' = 'PUMLX',
    /** Leverj Gluon */
    'L2' = 'L2',
    /** FidexToken */
    'FEX' = 'FEX',
    /** Voxel X Network */
    'VXL' = 'VXL',
    /** hiENS3 */
    'HIENS3' = 'HIENS3',
    /** KEK */
    'KEKE' = 'KEKE',
    /** Strong */
    'STRONG' = 'STRONG',
    /** HanChain */
    'HAN' = 'HAN',
    /** Salad */
    'SALD' = 'SALD',
    /** Wombat Web 3 Gaming Platform */
    'WOMBAT' = 'WOMBAT',
    /** unFederalReserve */
    'eRSDL' = 'eRSDL',
    /** DIGG */
    'DIGG' = 'DIGG',
    /** Chimpion */
    'BNANA' = 'BNANA',
    /** dAppstore */
    'DAPPX' = 'DAPPX',
    /** Coinsbit Token */
    'CNB' = 'CNB',
    /** Genaro Network */
    'GNX' = 'GNX',
    /** AurusX */
    'AX' = 'AX',
    /** AGA Token */
    'AGA' = 'AGA',
    /** bAlpha */
    'BALPHA' = 'BALPHA',
    /** YFDAI.FINANCE */
    'YF-DAI' = 'YF-DAI',
    /** PowerTrade Fuel */
    'PTF' = 'PTF',
    /** Parachute */
    'PAR' = 'PAR',
    /** Base Protocol */
    'BASE' = 'BASE',
    /** OctoFi */
    'OCTO' = 'OCTO',
    /** The QWAN */
    'QWAN' = 'QWAN',
    /** Traxx */
    'TRAXX' = 'TRAXX',
    /** DEXA COIN */
    'DEXA' = 'DEXA',
    /** hiMAYC */
    'HIMAYC' = 'HIMAYC',
    /** SynchroBitcoin */
    'SNB' = 'SNB',
    /** Opium */
    'OPIUM' = 'OPIUM',
    /** hiCOOLCATS */
    'HICOOLCATS' = 'HICOOLCATS',
    /** CyberVein */
    'CVT' = 'CVT',
    /** hiMFERS */
    'HIMFERS' = 'HIMFERS',
    /** OnGO */
    'FTG' = 'FTG',
    /** RUSH COIN */
    'RUSH' = 'RUSH',
    /** hiBAYC */
    'HIBAYC' = 'HIBAYC',
    /** hiFIDENZA */
    'HIFIDENZA' = 'HIFIDENZA',
    /** hiSQUIGGLE */
    'HISQUIGGLE' = 'HISQUIGGLE',
    /** Swerve */
    'SWRV' = 'SWRV',
    /** hiPUNKS */
    'HIPUNKS' = 'HIPUNKS',
    /** Hedget */
    'HGET' = 'HGET',
    /** Refereum */
    'RFR' = 'RFR',
    /** hiAZUKI */
    'HIAZUKI' = 'HIAZUKI',
    /** Digital Reserve Currency */
    'DRC' = 'DRC',
    /** PymeDAO */
    'PYME' = 'PYME',
    /** WaifuAI */
    'WFAI' = 'WFAI',
    /** Bridge Mutual */
    'BMI' = 'BMI',
    /** Databroker */
    'DTX' = 'DTX',
    /** Launchblock.com */
    'LBP' = 'LBP',
    /** Rook */
    'ROOK' = 'ROOK',
    /** Ruff */
    'RUFF' = 'RUFF',
    /** Blockzero Labs */
    'XIO' = 'XIO',
    /** Eden */
    'EDEN' = 'EDEN',
    /** LOCGame */
    'LOCG' = 'LOCG',
    /** Tidal Finance */
    'TIDAL' = 'TIDAL',
    /** 88mph */
    'MPH' = 'MPH',
    /** hiFLUF */
    'HIFLUF' = 'HIFLUF',
    /** Fractal */
    'FCL' = 'FCL',
    /** DomRaider */
    'DRT' = 'DRT',
    /** BitBall */
    'BTB' = 'BTB',
    /** Papi */
    'PAPI' = 'PAPI',
    /** Darwinia Commitment Token */
    'KTON' = 'KTON',
    /** Standard */
    'STND' = 'STND',
    /** Inex Project */
    'INEX' = 'INEX',
    /** TigerCash */
    'TCH' = 'TCH',
    /** EarthFund */
    '1EARTH' = '1EARTH',
    /** hiMEEBITS */
    'HIMEEBITS' = 'HIMEEBITS',
    /** Jarvis Network */
    'JRT' = 'JRT',
    /** e-Money */
    'NGM' = 'NGM',
    /** HollyGold */
    'HGOLD' = 'HGOLD',
    /** hiGAZERS */
    'HIGAZERS' = 'HIGAZERS',
    /** ROCKI */
    'ROCKI' = 'ROCKI',
    /** Lokr */
    'LKR' = 'LKR',
    /** Seigniorage Shares */
    'SHARE' = 'SHARE',
    /** hiMOONBIRDS */
    'HIMOONBIRDS' = 'HIMOONBIRDS',
    /** Pawtocol */
    'UPI' = 'UPI',
    /** DecentraWeb */
    'DWEB' = 'DWEB',
    /** Dawn Protocol */
    'DAWN' = 'DAWN',
    /** SnowSwap */
    'SNOW' = 'SNOW',
    /** hiFRIENDS */
    'HIFRIENDS' = 'HIFRIENDS',
    /** hiUNDEAD */
    'HIUNDEAD' = 'HIUNDEAD',
    /** hiCLONEX */
    'HICLONEX' = 'HICLONEX',
    /** NuriFootBall */
    'NRFB' = 'NRFB',
    /** Governor DAO */
    'GDAO' = 'GDAO',
    /** Xeno Token */
    'XNO' = 'XNO',
    /** Empty Set Dollar */
    'ESD' = 'ESD',
    /** renDOGE */
    'RENDOGE' = 'RENDOGE',
    /** hiOD */
    'HIOD' = 'HIOD',
    /** hiBEANZ */
    'HIBEANZ' = 'HIBEANZ',
    /** Misbloc */
    'MSB' = 'MSB',
    /** Evedo */
    'EVED' = 'EVED',
    /** Basis Cash */
    'BAC' = 'BAC',
    /** Jigstack */
    'STAK' = 'STAK',
    /** ZELIX */
    'ZELIX' = 'ZELIX',
    /** VNX */
    'VNXLU' = 'VNXLU',
    /** hiSAND33 */
    'HISAND33' = 'HISAND33',
    /** Cyclub */
    'CYCLUB' = 'CYCLUB',
    /** XcelToken Plus */
    'XLAB' = 'XLAB',
    /** hiRENGA */
    'HIRENGA' = 'HIRENGA',
    /** TokenBot */
    'TKB' = 'TKB',
    /** hiODBS */
    'HIODBS' = 'HIODBS',
    /** hiENS4 */
    'HIENS4' = 'HIENS4',
    /** ORAO Network */
    'ORAO' = 'ORAO',
    /** hiVALHALLA */
    'HIVALHALLA' = 'HIVALHALLA',
    /** HairyPlotterFTX */
    'FTX' = 'FTX',
    /** Cook Finance */
    'COOK' = 'COOK',
    /** HXAcoin */
    'HXA' = 'HXA',
    /** MAX Exchange Token */
    'MAX' = 'MAX',
    /** Internxt */
    'INXT' = 'INXT',
    /** Hashgard */
    'GARD' = 'GARD',
    /** Xiotri */
    'XIOT' = 'XIOT',
    /** Blockchain Cuties Universe Governance */
    'BCUG' = 'BCUG',
    /** GeoDB */
    'GEO' = 'GEO',
    /** LuaSwap */
    'LUA' = 'LUA',
    /** IG Gold */
    'IGG' = 'IGG',
    /** Ubex */
    'UBEX' = 'UBEX',
    /** SORA Validator Token */
    'VAL' = 'VAL',
    /** Rapidz */
    'RPZX' = 'RPZX',
    /** Creaticles */
    'CRE8' = 'CRE8',
    /** Flixxo */
    'FLIXX' = 'FLIXX',
    /** Celeum */
    'CLX' = 'CLX',
    /** APY.Finance */
    'APY' = 'APY',
    /** Opacity */
    'OPCT' = 'OPCT',
    /** Dogeswap */
    'DOGES' = 'DOGES',
    /** Spume Protocol */
    'SPUME' = 'SPUME',
    /** Drops Ownership Power */
    'DOP' = 'DOP',
    /** Rublix */
    'RBLX' = 'RBLX',
    /** Alpha Token */
    'A' = 'A',
    /** Phoenix Token */
    'PHX' = 'PHX',
    /** ETHA Lend */
    'ETHA' = 'ETHA',
    /** Polinate */
    'POLI' = 'POLI',
    /** TrustVerse */
    'TRV' = 'TRV',
    /** DecentralZone */
    'DZ' = 'DZ',
    /** Yield Protocol */
    'YIELD' = 'YIELD',
    /** DeFiner */
    'FIN' = 'FIN',
    /** Bitball Treasure */
    'BTRS' = 'BTRS',
    /** EveryCoin  */
    'EVY' = 'EVY',
    /** Doki Doki Finance */
    'DOKI' = 'DOKI',
    /** hiBAKC */
    'HIBAKC' = 'HIBAKC',
    /** LiquidApps */
    'DAPP' = 'DAPP',
    /** ArGo */
    'ARGO' = 'ARGO',
    /** Sentinel Chain */
    'SENC' = 'SENC',
    /** hiSEALS */
    'HISEALS' = 'HISEALS',
    /** Blockburn */
    'BURN' = 'BURN',
    /** RED TOKEN */
    'RED' = 'RED',
    /** Vodra */
    'VDR' = 'VDR',
    /** Kcash */
    'KCASH' = 'KCASH',
    /** Uptrennd */
    '1UP' = '1UP',
    /** Skyrim Finance */
    'SKYRIM' = 'SKYRIM',
    /** UREEQA */
    'URQA' = 'URQA',
    /** Zoracles */
    'ZORA' = 'ZORA',
    /** NBX */
    'BYN' = 'BYN',
    /** POLKARARE */
    'PRARE' = 'PRARE',
    /** Nsure.Network */
    'NSURE' = 'NSURE',
    /** Azuki */
    'AZUKI' = 'AZUKI',
    /** Curio */
    'CUR' = 'CUR',
    /** MELX */
    'MEL' = 'MEL',
    /** Elementeum */
    'ELET' = 'ELET',
    /** Niftify */
    'NIFT' = 'NIFT',
    /** BoutsPro */
    'BOUTS' = 'BOUTS',
    /** Bitspawn */
    'SPWN' = 'SPWN',
    /** PYRO Network */
    'PYRO' = 'PYRO',
    /** Kuverit */
    'KUV' = 'KUV',
    /** Tokenbox */
    'TBX' = 'TBX',
    /** Alphr finance */
    'ALPHR' = 'ALPHR',
    /** Guider */
    'GDR' = 'GDR',
    /** Innovative Bioresearch Coin */
    'INNBC' = 'INNBC',
    /** Feellike */
    'FLL' = 'FLL',
    /** CoverCompared */
    'CVR' = 'CVR',
    /** Indexed Finance */
    'NDX' = 'NDX',
    /** CareCoin */
    'CARE' = 'CARE',
    /** Mesefa */
    'SEFA' = 'SEFA',
    /** Rewardiqa */
    'REW' = 'REW',
    /** FUZE Token */
    'FUZE' = 'FUZE',
    /** Connectome */
    'CNTM' = 'CNTM',
    /** CRDT */
    'CRDT' = 'CRDT',
    /** Crystal Token */
    'CYL' = 'CYL',
    /** Diligence */
    'IRA' = 'IRA',
    /** Storeum */
    'STO' = 'STO',
    /** PREMA */
    'PRMX' = 'PRMX',
    /** ASKO */
    'ASKO' = 'ASKO',
    /** S.Finance */
    'SFG' = 'SFG',
    /** HaloDAO */
    'RNBW' = 'RNBW',
    /** Crypto Asset Governance Alliance */
    'CAGA' = 'CAGA',
    /** crvUSD */
    'CRVUSD' = 'CRVUSD',
    /** Chainflip */
    'FLIP' = 'FLIP',
    /** Structure finance */
    'STF' = 'STF',
    /** 5ire */
    '5IRE' = '5IRE',
    /** Autonolas */
    'OLAS' = 'OLAS',
    /** Flooring Lab Credit */
    'FLC' = 'FLC',
    /** MMX */
    'MMX' = 'MMX',
    /** WhiteBIT Coin */
    'WBT' = 'WBT',
    /** Fellaz */
    'FLZ' = 'FLZ',
    /** XT.com Token */
    'XT' = 'XT',
    /** Wrapped TAO */
    'WTAO' = 'WTAO',
    /** SWIM - Spread Wisdom */
    'SWIM' = 'SWIM',
    /** AzukiDAO */
    'BEAN' = 'BEAN',
    /** Wojak */
    'WOJAK' = 'WOJAK',
    /** P2P Solutions foundation */
    'P2PS' = 'P2PS',
    /** Open Exchange Token */
    'OX' = 'OX',
    /** 4-CHAN */
    '4CHAN' = '4CHAN',
    /** BlackPearl Token */
    'BPLC' = 'BPLC',
    /** FUTUREXCRYPTO */
    'FXC' = 'FXC',
    /** Coupon Assets */
    'CA' = 'CA',
    /** Landwolf */
    'WOLF' = 'WOLF',
    /** HarryPotterObamaWallStreetBets10Inu */
    'STONKS' = 'STONKS',
    /** Octopus Network */
    'OCT' = 'OCT',
    /** Prisma Finance */
    'PRISMA' = 'PRISMA',
    /** Bitcoin 2.0 */
    'BTC2.0' = 'BTC2.0',
    /** spurdo */
    'SPURDO' = 'SPURDO',
    /** Edelcoin */
    'EDLC' = 'EDLC',
    /** Not Financial Advice */
    'NFAI' = 'NFAI',
    /** LBK */
    'LBK' = 'LBK',
    /** HarryPotterObamaInu */
    'INU' = 'INU',
    /** Ben */
    'BEN' = 'BEN',
    /** Prisma mkUSD */
    'MKUSD' = 'MKUSD',
    /** ResearchCoin */
    'RSC' = 'RSC',
    /** MEMEVENGERS */
    'MMVG' = 'MMVG',
    /** ordinex */
    'ORD' = 'ORD',
    /** DigiFinexToken */
    'DFT' = 'DFT',
    /** XSale */
    'XS' = 'XS',
    /** Benji Bananas */
    'BENJI' = 'BENJI',
    /** Mintlayer */
    'ML' = 'ML',
    /** RepubliK */
    'RPK' = 'RPK',
    /** The Root Network */
    'ROOT' = 'ROOT',
    /** Deesse */
    'LOVE' = 'LOVE',
    /** Prometheum Prodigy */
    'PMPY' = 'PMPY',
    /** Stader MaticX */
    'MATICX' = 'MATICX',
    /** MOG Coin */
    'MOG' = 'MOG',
    /** HYTOPIA */
    'TOPIA' = 'TOPIA',
    /** Unizen */
    'ZCX' = 'ZCX',
    /** Love Hate Inu */
    'LHINU' = 'LHINU',
    /** ALTAVA */
    'TAVA' = 'TAVA',
    /** Stox */
    'STX' = 'STX',
    /** El Hippo */
    'HIPP' = 'HIPP',
    /** VitaDAO */
    'VITA' = 'VITA',
    /** Amino */
    'AMO' = 'AMO',
    /** PepeCoin Cryptocurrency */
    'pepecoin' = 'pepecoin',
    /** SIGN */
    'SIGN' = 'SIGN',
    /** Joseon Mun */
    'JSM' = 'JSM',
    /** Crypto Carbon Energy */
    'CYCE' = 'CYCE',
    /** Australian Dollar Token */
    'AUDT' = 'AUDT',
    /** Convex CRV */
    'CVXCRV' = 'CVXCRV',
    /** Charged Particles */
    'IONX' = 'IONX',
    /** RefundCoin */
    'RFD' = 'RFD',
    /** Ethereum Express */
    'ETE' = 'ETE',
    /** Advanced Project */
    'AUC' = 'AUC',
    /** GHO */
    'GHO' = 'GHO',
    /** FairERC20 */
    'ferc' = 'ferc',
    /** QToken */
    'QTO' = 'QTO',
    /** Business Platform Tomato Coin */
    'BPTC' = 'BPTC',
    /** Alkimi */
    '$ADS' = '$ADS',
    /** Lido Staked Matic */
    'stMATIC' = 'stMATIC',
    /** JFIN Coin */
    'JFIN' = 'JFIN',
    /** Angle Protocol */
    'AGEUR' = 'AGEUR',
    /** INOFI */
    'FON' = 'FON',
    /** Pond Coin */
    'PNDC' = 'PNDC',
    /** Kondux */
    'KNDX' = 'KNDX',
    /** Instadapp */
    'INST' = 'INST',
    /** sudoswap */
    'SUDO' = 'SUDO',
    /** USK */
    'USK' = 'USK',
    /** LiquidLayer */
    'LILA' = 'LILA',
    /** stoicDAO */
    'ZETA' = 'ZETA',
    /** WoofWork.io */
    'WOOF' = 'WOOF',
    /** DongCoin */
    'DONG' = 'DONG',
    /** Revest Finance */
    'RVST' = 'RVST',
    /** ECOMI */
    'OMI' = 'OMI',
    /** Euler */
    'EUL' = 'EUL',
    /** CAW(A Hunters Dream) */
    'CAW' = 'CAW',
    /** ENTERBUTTON */
    'ENTC' = 'ENTC',
    /** PANDA */
    'PANDA' = 'PANDA',
    /** Aegis Ai */
    'AEGIS' = 'AEGIS',
    /** Bitrock */
    'BROCK' = 'BROCK',
    /** Bazaars */
    'BZR' = 'BZR',
    /** Phemex Token */
    'PT' = 'PT',
    /** THORSwap */
    'THOR' = 'THOR',
    /** Blockchain Bets */
    'BCB' = 'BCB',
    /** BreederDAO */
    'BREED' = 'BREED',
    /** Sommelier */
    'SOMM' = 'SOMM',
    /** Medifakt */
    'FAKT' = 'FAKT',
    /** Sponge */
    '$SPONGE' = '$SPONGE',
    /** GROKX */
    'GROKX' = 'GROKX',
    /** ZED Token */
    'ZED' = 'ZED',
    /** X2Y2 */
    'X2Y2' = 'X2Y2',
    /** Shib2.0 */
    'SHIB2.0' = 'SHIB2.0',
    /** Wasder */
    'WAS' = 'WAS',
    /** My Crypto Heroes */
    'MCHC' = 'MCHC',
    /** LORDS */
    'LORDS' = 'LORDS',
    /** BOBO */
    'BOBO' = 'BOBO',
    /** Dubbz */
    'DUBBZ' = 'DUBBZ',
    /** BMAX */
    'BMAX' = 'BMAX',
    /** Vow */
    'VOW' = 'VOW',
    /** Alien Milady Fumo */
    'FUMO' = 'FUMO',
    /** sETH2 */
    'SETH2' = 'SETH2',
    /** Aurix */
    'AUR' = 'AUR',
    /** Altered State Token */
    'ASTO' = 'ASTO',
    /** AI Network */
    'AIN' = 'AIN',
    /** HILO */
    'HILO' = 'HILO',
    /** wrapped POKT */
    'WPOKT' = 'WPOKT',
    /** Fileshare Platform */
    'FSC' = 'FSC',
    /** TokenAsset */
    'NTB' = 'NTB',
    /** EML Protocol */
    'EML' = 'EML',
    /** fanC */
    'FANC' = 'FANC',
    /** OTSea */
    'OTSEA' = 'OTSEA',
    /** PowBlocks */
    'XPB' = 'XPB',
    /** Hikari Protocol */
    'HIKARI' = 'HIKARI',
    /** Curve.fi FRAX/USDC */
    'CRVFRAX' = 'CRVFRAX',
    /** 00 Token */
    '#00' = '00',
    /** Nerd Bot */
    'NERD' = 'NERD',
    /** Musk Dao */
    'MUSK' = 'MUSK',
    /** Feisty Doge NFT */
    'NFD' = 'NFD',
    /** LinkEye */
    'LET' = 'LET',
    /** Pando */
    'PANDO' = 'PANDO',
    /** WiBX */
    'WBX' = 'WBX',
    /** MultiPlanetary Inus */
    'INUS' = 'INUS',
    /** Monte */
    'MONTE' = 'MONTE',
    /** Medicle */
    'MDI' = 'MDI',
    /** Defactor */
    'FACTR' = 'FACTR',
    /** TokenSight */
    'TKST' = 'TKST',
    /** Tidalflats */
    'TIDE' = 'TIDE',
    /** Raft */
    'RAFT' = 'RAFT',
    /** Neo Tokyo */
    'BYTES' = 'BYTES',
    /** Apes Go Bananas */
    'AGB' = 'AGB',
    /** APF coin */
    'APFC' = 'APFC',
    /** MeGods */
    'MEGODS' = 'MEGODS',
    /** UnleashClub */
    'UNLEASH' = 'UNLEASH',
    /** Homer */
    'SIMPSON' = 'SIMPSON',
    /** Karate Combat */
    'KARATE' = 'KARATE',
    /** ROVI */
    'ROVI' = 'ROVI',
    /** HXRO */
    'HXRO' = 'HXRO',
    /** BlueSparrow Token */
    'BlueSparrow' = 'BlueSparrow',
    /** APED */
    'APED' = 'APED',
    /** Wrapped Centrifuge */
    'WCFG' = 'WCFG',
    /** CryptMi */
    'CYMI' = 'CYMI',
    /** IX Swap */
    'IXS' = 'IXS',
    /** AimBot */
    'AIMBOT' = 'AIMBOT',
    /** Wagie Bot */
    'WAGIEBOT' = 'WAGIEBOT',
    /** DOS Network */
    'DOS' = 'DOS',
    /** Blocksport */
    'BSPT' = 'BSPT',
    /** CoFiX */
    'COFI' = 'COFI',
    /** JPEG'd */
    'JPEG' = 'JPEG',
    /** L */
    '$L' = '$L',
    /** Nova */
    'NOVA' = 'NOVA',
    /** Silent Notary */
    'UBSN' = 'UBSN',
    /** LuckysLeprecoin */
    'LUCKYSLP' = 'LUCKYSLP',
    /** Bank */
    '$BANK' = '$BANK',
    /** Olympus v2 */
    'OHM' = 'OHM',
    /** KIZUNA */
    'KIZUNA' = 'KIZUNA',
    /** Faith Tribe */
    'FTRB' = 'FTRB',
    /** WAXE */
    'WAXE' = 'WAXE',
    /** Dash 2 Trade */
    'D2T' = 'D2T',
    /** IPVERSE (ETH) */
    'IPV' = 'IPV',
    /** noiseGPT */
    'NOISEGPT' = 'NOISEGPT',
    /** Humans.ai */
    'HEART' = 'HEART',
    /** Winnerz */
    'WNZ' = 'WNZ',
    /** Doge 2.0 */
    'DOGE2.0' = 'DOGE2.0',
    /** VEROX */
    'VRX' = 'VRX',
    /** Wojak 2.69 */
    'WOJAK2.69' = 'WOJAK2.69',
    /** C-cash */
    'CCASH' = 'CCASH',
    /** Bware */
    'INFRA' = 'INFRA',
    /** Roko */
    'ROKO' = 'ROKO',
    /** LEOX */
    'LEOX' = 'LEOX',
    /** AssetMantle */
    'MNTL' = 'MNTL',
    /** Orbofi AI */
    'OBI' = 'OBI',
    /** DinoLFG */
    'DINO' = 'DINO',
    /** ZigZag */
    'ZZ' = 'ZZ',
    /** Divergence */
    'DIVER' = 'DIVER',
    /** Metanept */
    'NEPT' = 'NEPT',
    /** Gravita Protocol */
    'GRAI' = 'GRAI',
    /** Metano */
    'METANO' = 'METANO',
    /** Uhive */
    'HVE2' = 'HVE2',
    /** Dejitaru Hoshi */
    'HOSHI' = 'HOSHI',
    /** MOROS NET */
    'MOROS' = 'MOROS',
    /** Magical Blocks */
    'MBLK' = 'MBLK',
    /** ecoterra */
    'ECOTERRA' = 'ECOTERRA',
    /** XRUN */
    'XRUN' = 'XRUN',
    /** Chainback */
    'ARCHIVE' = 'ARCHIVE',
    /** GreenWorld */
    'GWD' = 'GWD',
    /** Sipher */
    'SIPHER' = 'SIPHER',
    /** swETH */
    'SWETH' = 'SWETH',
    /** HitBTC Token */
    'HIT' = 'HIT',
    /** Vault Tech */
    'VAULT' = 'VAULT',
    /** THORWallet */
    'TGT' = 'TGT',
    /** Mute */
    'MUTE' = 'MUTE',
    /** Renq Finance */
    'RENQ' = 'RENQ',
    /** SO-COL */
    'SIMP' = 'SIMP',
    /** FXDX */
    'FXDX' = 'FXDX',
    /** Global Human Community Coin */
    'GHCC' = 'GHCC',
    /** Hypr Network */
    'HYPR' = 'HYPR',
    /** ETH 2x Flexible Leverage Index */
    'ETH2X-FLI' = 'ETH2X-FLI',
    /** Onchain AI */
    'OCAI' = 'OCAI',
    /** Dinerobet */
    'DINERO' = 'DINERO',
    /** Baby Shiba Inu */
    'BABYSHIB' = 'BABYSHIB',
    /** Manifold Finance */
    'FOLD' = 'FOLD',
    /** BarbieCrashBandicootRFK888Inu */
    'SOLANA' = 'SOLANA',
    /** Perion */
    'PERC' = 'PERC',
    /** Cappasity */
    'CAPP' = 'CAPP',
    /** FractonX */
    'FT' = 'FT',
    /** NAGA */
    'NGC' = 'NGC',
    /** CHARTAI */
    'CX' = 'CX',
    /** 0 Knowledge Network */
    '0KN' = '0KN',
    /** Morra */
    'MORRA' = 'MORRA',
    /** DEDPRZ */
    'USA' = 'USA',
    /** Pacific */
    'PAF' = 'PAF',
    /** SILK */
    'SILK' = 'SILK',
    /** Jeff World */
    'JEFF' = 'JEFF',
    /** Energreen */
    'EGRN' = 'EGRN',
    /** ANIVERSE */
    'ANV' = 'ANV',
    /** KOMPETE */
    'KOMPETE' = 'KOMPETE',
    /** ShibaDoge */
    'SHIBDOGE' = 'SHIBDOGE',
    /** Jinbi Token */
    'JNB' = 'JNB',
    /** The Nemesis */
    'NEMS' = 'NEMS',
    /** Wojak 2.0 Coin */
    'WOJAK2.0' = 'WOJAK2.0',
    /** Redacted */
    'BTRFLY' = 'BTRFLY',
    /** AQTIS */
    'AQTIS' = 'AQTIS',
    /** Elan */
    'ELAN' = 'ELAN',
    /** Index Cooperative */
    'INDEX' = 'INDEX',
    /** Stader ETHx */
    'ETHX' = 'ETHX',
    /** ADDAMS AI */
    'ADDAMS' = 'ADDAMS',
    /** CaliCoin */
    'CALI' = 'CALI',
    /** Data Lake */
    'LAKE' = 'LAKE',
    /** Meta Masters Guild */
    'MEMAG' = 'MEMAG',
    /** LP 3pool Curve */
    '3CRV' = '3CRV',
    /** VNX Gold */
    'VNXAU' = 'VNXAU',
    /** Tribal Finance */
    'TRIBL' = 'TRIBL',
    /** UniX Gaming */
    'UNIX' = 'UNIX',
    /** Newsly */
    'NEWS' = 'NEWS',
    /** Veil */
    'VEIL' = 'VEIL',
    /** CRYN */
    'CRYN' = 'CRYN',
    /** Unicly */
    'UNIC' = 'UNIC',
    /** Minds */
    'MINDS' = 'MINDS',
    /** Angle */
    'ANGLE' = 'ANGLE',
    /** Human */
    'HMT' = 'HMT',
    /** EthXY */
    'SEXY' = 'SEXY',
    /** MoonDAO */
    'MOONEY' = 'MOONEY',
    /** MAGA */
    'TRUMP' = 'TRUMP',
    /** SymVerse */
    'SYM' = 'SYM',
    /** Kusunoki Samurai */
    'KUSUNOKI' = 'KUSUNOKI',
    /** SocialGood */
    'SG' = 'SG',
    /** Derp */
    'DERP' = 'DERP',
    /** Fair BERC20 */
    'BERC' = 'BERC',
    /** MEMEME */
    'MEMEME' = 'MEMEME',
    /** BitMEX Token */
    'BMEX' = 'BMEX',
    /** USP Token */
    'USP' = 'USP',
    /** 0x Leverage */
    '0XL' = '0XL',
    /** Undead Blocks */
    'UNDEAD' = 'UNDEAD',
    /** Materium */
    'MTRM' = 'MTRM',
    /** NPICK BLOCK */
    'NPICK' = 'NPICK',
    /** Clips */
    'CLIPS' = 'CLIPS',
    /** Morpheus Labs */
    'MIND' = 'MIND',
    /** Aureus Nummus Gold */
    'ANG' = 'ANG',
    /** Web-x-ai */
    'WEB' = 'WEB',
    /** MicroMoney */
    'AMM' = 'AMM',
    /** FOOM */
    'FOOM' = 'FOOM',
    /** Exen Coin */
    'EXEN' = 'EXEN',
    /** VNX EURO */
    'VEUR' = 'VEUR',
    /** Akt.io */
    'AKTIO' = 'AKTIO',
    /** Moonsama */
    'SAMA' = 'SAMA',
    /** MyToken */
    'MT' = 'MT',
    /** MoonBot */
    'MBOT' = 'MBOT',
    /** Switch */
    'SWITCH' = 'SWITCH',
    /** Railgun */
    'RAIL' = 'RAIL',
    /** Panther Protocol */
    'ZKP' = 'ZKP',
    /** Hasaki */
    'HAHA' = 'HAHA',
    /** GROK 2.0 */
    'GROK2.0' = 'GROK2.0',
    /** AAG */
    'AAG' = 'AAG',
    /** Hop Protocol */
    'HOP' = 'HOP',
    /** WATTTON */
    'WATT' = 'WATT',
    /** XShiba Inu */
    'XSHIBA' = 'XSHIBA',
    /** Devour */
    'DPAY' = 'DPAY',
    /** LuckyToad */
    'TOAD' = 'TOAD',
    /** Relation Labs */
    'REL' = 'REL',
    /** Super Athletes Token */
    'SAT' = 'SAT',
    /** ContentBox */
    'BOX' = 'BOX',
    /** OmniaVerse */
    'OMNIA' = 'OMNIA',
    /** Atlas Navi */
    'NAVI' = 'NAVI',
    /** Pear Swap */
    'PEAR' = 'PEAR',
    /** JRVGCUPVSC */
    'PVFYBO' = 'PVFYBO',
    /** G Revolution */
    'G' = 'G',
    /** Ethlas */
    'ELS' = 'ELS',
    /** Satoshis Vision */
    'SATS' = 'SATS',
    /** SoonVerse */
    'SOON' = 'SOON',
    /** StakeWise */
    'SWISE' = 'SWISE',
    /** StarkMeta */
    'SMETA' = 'SMETA',
    /** Pikaboss */
    'PIKA' = 'PIKA',
    /** On-Chain Dynamics */
    'OCD' = 'OCD',
    /** DEVAI */
    '0XDEV' = '0XDEV',
    /** Smart Game Finance */
    'SMART' = 'SMART',
    /** Loyalty Labs */
    'LOYAL' = 'LOYAL',
    /** Made In Real Life */
    'MIRL' = 'MIRL',
    /** Crown Token */
    'CROWN' = 'CROWN',
    /** Talken */
    'TALK' = 'TALK',
    /** Wrapped Ampleforth */
    'WAMPL' = 'WAMPL',
    /** Wrapped Dogecoin */
    'WDOGE' = 'WDOGE',
    /** PlasticHero */
    'PTH' = 'PTH',
    /** DAM Finance */
    'D2O' = 'D2O',
    /** Metavisa Protocol */
    'MESA' = 'MESA',
    /** Bear Inu */
    'BEAR' = 'BEAR',
    /** Decentralized Vulnerability Platform */
    'DVP' = 'DVP',
    /** Gekko HQ */
    'GEKKO' = 'GEKKO',
    /** peg-eUSD */
    'PEUSD' = 'PEUSD',
    /** Minebase */
    'MBASE' = 'MBASE',
    /** wooonen */
    'WOOO' = 'WOOO',
    /** Mandox Token */
    'MANDOX' = 'MANDOX',
    /** Groooook */
    'GROOOOOK' = 'GROOOOOK',
    /** LynKey */
    'LYNK' = 'LYNK',
    /** Invitoken */
    'INVI' = 'INVI',
    /** poundtoken */
    'GBPT' = 'GBPT',
    /** PEEPO */
    'PEEPO' = 'PEEPO',
    /** FansTime */
    'FTI' = 'FTI',
    /** McPepe's */
    'PEPES' = 'PEPES',
    /** AladdinDAO */
    'ALD' = 'ALD',
    /** ROGin AI */
    'ROG' = 'ROG',
    /** Pollchain */
    'POLL' = 'POLL',
    /** Konnect */
    'KCT' = 'KCT',
    /** Kryxivia */
    'KXA' = 'KXA',
    /** SaitaRealty */
    'SRLTY' = 'SRLTY',
    /** Exorde */
    'EXD' = 'EXD',
    /** MixToEarn */
    'MTE' = 'MTE',
    /** Modex */
    'MODEX' = 'MODEX',
    /** ETFETH */
    'ETFETH' = 'ETFETH',
    /** Ethereans */
    'OS' = 'OS',
    /** Xi Token */
    'XI' = 'XI',
    /** ESG */
    'ESG' = 'ESG',
    /** FrontFanz */
    'FANX' = 'FANX',
    /** JPY Coin */
    'JPYC' = 'JPYC',
    /** Got Guaranteed */
    'GOTG' = 'GOTG',
    /** Lillian Token */
    'LYF' = 'LYF',
    /** Brillion */
    'DUA' = 'DUA',
    /** LunchDAO */
    'LUNCH' = 'LUNCH',
    /** SAUDI RAPTOR */
    'SAUDIRAPTOR' = 'SAUDIRAPTOR',
    /** XFLOKI */
    'XFLOKI' = 'XFLOKI',
    /** Qlindo */
    'QLINDO' = 'QLINDO',
    /** ECOx */
    'ECOX' = 'ECOX',
    /** Fable Of The Dragon */
    'TYRANT' = 'TYRANT',
    /** Icy */
    'IC' = 'IC',
    /** ScamFari token */
    'SCM' = 'SCM',
    /** CumInu */
    'CUMINU' = 'CUMINU',
    /** The BLOX Project */
    'BLOX' = 'BLOX',
    /** ALLPAYCOIN */
    'APCG' = 'APCG',
    /** tehBag */
    'BAG' = 'BAG',
    /** CargoX */
    'CXO' = 'CXO',
    /** Champignons of Arborethia */
    'CHAMPZ' = 'CHAMPZ',
    /** Nutcoin */
    'NUT' = 'NUT',
    /** AngelBlock */
    'THOL' = 'THOL',
    /** Changex */
    'CHANGE' = 'CHANGE',
    /** MOBLAND */
    'SYNR' = 'SYNR',
    /** KlubCoin */
    'KLUB' = 'KLUB',
    /** Zoomer Coin */
    'ZOOMER' = 'ZOOMER',
    /** Collateral Network */
    'COLT' = 'COLT',
    /** The Paradox Metaverse */
    'PARADOX' = 'PARADOX',
    /** KleeKai */
    'KLEE' = 'KLEE',
    /** Odyssey */
    'OCN' = 'OCN',
    /** Protectorate Protocol */
    'PRTC' = 'PRTC',
    /** Cindrum */
    'CIND' = 'CIND',
    /** UNIUM */
    'UNM' = 'UNM',
    /** SNKRZ */
    'FRC' = 'FRC',
    /** ExenPay Token */
    'XNP' = 'XNP',
    /** Wrapped LUNA Classic */
    'WLUNC' = 'WLUNC',
    /** Sparko */
    'SPARKO' = 'SPARKO',
    /** Crafting Finance */
    'CRF' = 'CRF',
    /** Jim */
    'JIM' = 'JIM',
    /** Moon Tropica */
    'CAH' = 'CAH',
    /** CPUcoin */
    'CPU' = 'CPU',
    /** Convex FXS */
    'CVXFXS' = 'CVXFXS',
    /** DogPad Finance */
    'DOGPAD' = 'DOGPAD',
    /** KStarCoin */
    'KSC' = 'KSC',
    /** APWine Finance */
    'APW' = 'APW',
    /** EpiK Protocol */
    'AIEPK' = 'AIEPK',
    /** Bugs Bunny */
    'BUGS' = 'BUGS',
    /** UGAS */
    'UGAS' = 'UGAS',
    /** Domani Protocol */
    'DEXTF' = 'DEXTF',
    /** FOGNET */
    'FOG' = 'FOG',
    /** CFX Quantum */
    'CFXQ' = 'CFXQ',
    /** Dreamverse */
    'DV' = 'DV',
    /** The Secret Coin */
    'TSC' = 'TSC',
    /** Opyn Squeeth */
    'OSQTH' = 'OSQTH',
    /** Tyrion */
    'TYRION' = 'TYRION',
    /** SX Network */
    'SX' = 'SX',
    /** VNX Swiss Franc */
    'VCHF' = 'VCHF',
    /** Megabot */
    'MEGABOT' = 'MEGABOT',
    /** battle esports coin */
    'BES' = 'BES',
    /** Damex Token */
    'DAMEX' = 'DAMEX',
    /** Elyssa AR */
    '$ELY' = '$ELY',
    /** GoldFinX */
    'G1X' = 'G1X',
    /** Friendz */
    'FDZ' = 'FDZ',
    /** EVERY GAME */
    'EGAME' = 'EGAME',
    /** PhotoChromic */
    'PHCR' = 'PHCR',
    /** Don't Buy Inu */
    'DBI' = 'DBI',
    /** Paypolitan Token */
    'EPAN' = 'EPAN',
    /** Centurion Invest */
    'CIX' = 'CIX',
    /** Osaka Protocol */
    'OSAK' = 'OSAK',
    /** Mythos */
    'MYTH' = 'MYTH',
    /** DeeLance */
    'DLANCE' = 'DLANCE',
    /** Saltmarble */
    'SML' = 'SML',
    /** I LOVE SNOOPY */
    'LOVESNOOPY' = 'LOVESNOOPY',
    /** Nitro League */
    'NITRO' = 'NITRO',
    /** Conic Finance */
    'CNC' = 'CNC',
    /** Taxa Token */
    'TXT' = 'TXT',
    /** Non-Playable Coin */
    'NPC' = 'NPC',
    /** CoinBot */
    'COINBT' = 'COINBT',
    /** SPECTRE AI */
    'SPECTRE' = 'SPECTRE',
    /** DORK */
    'DORK' = 'DORK',
    /** Trace AI */
    'TAI' = 'TAI',
    /** QASH */
    'QASH' = 'QASH',
    /** Ludena Protocol */
    'LDN' = 'LDN',
    /** Trillant */
    'TRI' = 'TRI',
    /** Potentiam */
    'PTM' = 'PTM',
    /** Akita DAO */
    'HACHI' = 'HACHI',
    /** R-DEE Protocol */
    'RDGX' = 'RDGX',
    /** Raiden Network Token */
    'RDN' = 'RDN',
    /** SPACE-iZ */
    'SPIZ' = 'SPIZ',
    /** Side.xyz */
    'SIDE' = 'SIDE',
    /** Flappymoonbird */
    'FMB' = 'FMB',
    /** dFund */
    'DFND' = 'DFND',
    /** InfinityBit Token */
    'IBIT' = 'IBIT',
    /** Lotty */
    'LOTTY' = 'LOTTY',
    /** Calvaria: Duels of Eternity */
    'RIA' = 'RIA',
    /** Empire Token */
    'EMPIRE' = 'EMPIRE',
    /** Essentia */
    'ESS' = 'ESS',
    /** Olea Token */
    'OLEA' = 'OLEA',
    /** Veritaseum */
    'VERI' = 'VERI',
    /** OnX Finance */
    'ONX' = 'ONX',
    /** Ribbit */
    'RIBBIT' = 'RIBBIT',
    /** Globe Derivative Exchange */
    'GDT' = 'GDT',
    /** MODA DAO */
    'MODA' = 'MODA',
    /** IQ Protocol */
    'IQT' = 'IQT',
    /** ETF Ethereum */
    'ETF-ETH' = 'ETF-ETH',
    /** Pine */
    'PINE' = 'PINE',
    /** Wrapped Minima */
    'WMINIMA' = 'WMINIMA',
    /** Zus */
    'ZCN' = 'ZCN',
    /** XMax */
    'XMX' = 'XMX',
    /** Epiko */
    'EPIKO' = 'EPIKO',
    /** Gas DAO */
    'GAS' = 'GAS',
    /** BlockCDN */
    'BCDN' = 'BCDN',
    /** The Habibiz */
    'HABIBI' = 'HABIBI',
    /** UCX */
    'UCX' = 'UCX',
    /** ETF */
    'ETF' = 'ETF',
    /** CryptoArt.Ai */
    'CART' = 'CART',
    /** BEG */
    'BEG' = 'BEG',
    /** FIDO */
    'FIDO' = 'FIDO',
    /** BTC 2x Flexible Leverage Index */
    'BTC2X-FLI' = 'BTC2X-FLI',
    /** Naka Bodhi Token */
    'NBOT' = 'NBOT',
    /** Vader Protocol */
    'VADER' = 'VADER',
    /** reBaked */
    'BAKED' = 'BAKED',
    /** Bidao */
    'BID' = 'BID',
    /** Remme */
    'REM' = 'REM',
    /** Bounty0x */
    'BNTY' = 'BNTY',
    /** Molecules of Korolchuk IP-NFT */
    'VITA-FAST' = 'VITA-FAST',
    /** Cred */
    'LBA' = 'LBA',
    /** Cardiocoin */
    'CRDC' = 'CRDC',
    /** DeepFakeAI */
    'FAKEAI' = 'FAKEAI',
    /** WASSIE */
    'WASSIE' = 'WASSIE',
    /** ZENEX */
    'ZNX' = 'ZNX',
    /** Lemond */
    'LEMD' = 'LEMD',
    /** Artizen */
    'ATNT' = 'ATNT',
    /** Global Digital Content */
    'GDC' = 'GDC',
    /** Jerome Powell */
    'POWELL' = 'POWELL',
    /** BABYTRUMP */
    'BABYTRUMP' = 'BABYTRUMP',
    /** Fire Protocol */
    'FIRE' = 'FIRE',
    /** Akropolis Delphi */
    'ADEL' = 'ADEL',
    /** Phuture */
    'PHTR' = 'PHTR',
    /** CNNS */
    'CNNS' = 'CNNS',
    /** Alpaca City */
    'ALPA' = 'ALPA',
    /** X7R */
    'X7R' = 'X7R',
    /** ZERO */
    'MEOW' = 'MEOW',
    /** MiniSwap */
    'MINI' = 'MINI',
    /** MixTrust */
    'MXT' = 'MXT',
    /** One Share */
    'ONS' = 'ONS',
    /** NFTMart Token */
    'NMT' = 'NMT',
    /** KittenFinance */
    'KIF' = 'KIF',
    /** Sashimi */
    'SASHIMI' = 'SASHIMI',
    /** Lien */
    'LIEN' = 'LIEN',
    /** Monkeys Token */
    'MONKEYS' = 'MONKEYS',
    /** IHT Real Estate Protocol */
    'IHT' = 'IHT',
    /** Delphy */
    'DPY' = 'DPY',
    /** pBTC35A */
    'pBTC35A' = 'pBTC35A',
    /** Galvan */
    'IZE' = 'IZE',
    /** LemoChain */
    'LEMO' = 'LEMO',
    /** Ripio Credit Network */
    'RCN' = 'RCN',
    /** Hati */
    'HATI' = 'HATI',
    /** HYPERCOMIC */
    'HYCO' = 'HYCO',
    /** Moeda Loyalty Points */
    'MDA' = 'MDA',
    /** Open Platform */
    'OPEN' = 'OPEN',
    /** Rake Finance */
    'RAK' = 'RAK',
    /** Quantfury Token */
    'QTF' = 'QTF',
    /** TurboPepe */
    'VROOM' = 'VROOM',
    /** Privatix */
    'PRIX' = 'PRIX',
    /** VetMe */
    'VETME' = 'VETME',
    /** VeraOne */
    'VRO' = 'VRO',
    /** Artem Coin */
    'ARTEM' = 'ARTEM',
    /** Electrify.Asia */
    'ELEC' = 'ELEC',
    /** Jungle Labz */
    'JNGL' = 'JNGL',
    /** ZURRENCY */
    'ZURR' = 'ZURR',
    /** Havoc */
    'HAVOC' = 'HAVOC',
    /** Shita-kiri Suzume */
    'SUZUME' = 'SUZUME',
    /** GroupDao */
    'GDO' = 'GDO',
    /** One Cash */
    'ONC' = 'ONC',
    /** Liquid Staked ETH */
    'LSETH' = 'LSETH',
    /** Voxto Amplify */
    'VXT' = 'VXT',
    /** AssangeDAO */
    'JUSTICE' = 'JUSTICE',
    /** Wrapped Pepe */
    'WPEPE' = 'WPEPE',
    /** ChilliSwap */
    'CHLI' = 'CHLI',
    /** Mithril Share */
    'MIS' = 'MIS',
    /** GoMeat */
    'GOMT' = 'GOMT',
    /** MatrixETF */
    'MDF' = 'MDF',
    /** Meta MVRS */
    'MVRS' = 'MVRS',
    /** EHash */
    'EHASH' = 'EHASH',
    /** Bumper */
    'BUMP' = 'BUMP',
    /** Liquid Protocol */
    'LP' = 'LP',
    /** AMATERASU OMIKAMI */
    'OMIKAMI' = 'OMIKAMI',
    /** Nchart Token */
    'CHART' = 'CHART',
    /** Hypersign identity */
    'HID' = 'HID',
    /** SimpleHub */
    'SHUB' = 'SHUB',
    /** DAOSquare */
    'RICE' = 'RICE',
    /** Ben's Finale */
    'FINALE' = 'FINALE',
    /** Woozoo Music */
    'WZM' = 'WZM',
    /** Yield Magnet */
    'MAGNET' = 'MAGNET',
    /** Mechanium */
    'MECHA' = 'MECHA',
    /** MediShares */
    'MDS' = 'MDS',
    /** Sienna (ERC20) */
    'wSIENNA' = 'wSIENNA',
    /** Sphere */
    'SXS' = 'SXS',
    /** NightVerse Game */
    'NVG' = 'NVG',
    /** xSUSHI */
    'XSUSHI' = 'XSUSHI',
    /** Luna Inu */
    'LINU' = 'LINU',
    /** Homer */
    'SIMPSON 3.0' = 'SIMPSON 3.0',
    /** VIDY */
    'VIDY' = 'VIDY',
    /** HashCoin */
    'HSC' = 'HSC',
    /** UNION Protocol Governance Token */
    'UNN' = 'UNN',
    /** NexAI */
    'NEX' = 'NEX',
    /** DPRating */
    'RATING' = 'RATING',
    /** XGPT */
    'XGPT' = 'XGPT',
    /** eosDAC */
    'EOSDAC' = 'EOSDAC',
    /** tGOLD */
    'TXAU' = 'TXAU',
    /** Mobius Finance */
    'MOT' = 'MOT',
    /** DogeGF */
    'DOGEGF' = 'DOGEGF',
    /** Medicalchain */
    'MTN' = 'MTN',
    /** DeFIL */
    'DFL' = 'DFL',
    /** Sanin Inu */
    'SANI' = 'SANI',
    /** KiboShib */
    'KIBSHI' = 'KIBSHI',
    /** MerchDAO */
    'MRCH' = 'MRCH',
    /** Yesports */
    'YESP' = 'YESP',
    /** Aradena */
    'AG' = 'AG',
    /** Filecoin Standard Hashrate Token */
    'FILST' = 'FILST',
    /** Nahmii */
    'NII' = 'NII',
    /** BitoPro Exchange Token */
    'BITO' = 'BITO',
    /** POW */
    'POW' = 'POW',
    /** THE9 */
    'THE9' = 'THE9',
    /** METANOA */
    'NOA' = 'NOA',
    /** Primas */
    'PST' = 'PST',
    /** MiraQle */
    'MQL' = 'MQL',
    /** Autonio */
    'NIOX' = 'NIOX',
    /** EdgeSwap */
    'EGS' = 'EGS',
    /** Matching Game */
    'MATCH' = 'MATCH',
    /** LINQ */
    'LINQ' = 'LINQ',
    /** Krypton Galaxy Coin */
    'KGC' = 'KGC',
    /** Kuma Inu */
    'KUMA' = 'KUMA',
    /** Bincentive */
    'BCNT' = 'BCNT',
    /** Snetwork */
    'SNET' = 'SNET',
    /** Chaintools */
    'CTLS' = 'CTLS',
    /** Mimo Governance Token */
    'MIMO' = 'MIMO',
    /** Chicken */
    'KFC' = 'KFC',
    /** NKCL Classic */
    'NKCLC' = 'NKCLC',
    /** Xaurum */
    'XAUR' = 'XAUR',
    /** Neuroni AI */
    'NEURONI' = 'NEURONI',
    /** OST */
    'OST' = 'OST',
    /** Unlock Protocol */
    'UDT' = 'UDT',
    /** Quick Intel */
    'QKNTL' = 'QKNTL',
    /** Marnotaur */
    'TAUR' = 'TAUR',
    /** FunFi */
    'FNF' = 'FNF',
    /** Crypto Villains */
    'CV' = 'CV',
    /** D Community */
    'DILI' = 'DILI',
    /** Crypto Holding Frank Token */
    'CHFT' = 'CHFT',
    /** UGOLD Inc. */
    'UGOLD' = 'UGOLD',
    /** Qrolli */
    'QR' = 'QR',
    /** BaconDAO */
    'BACON' = 'BACON',
    /** MetaOctagon */
    'MOTG' = 'MOTG',
    /** MobiFi */
    'MoFi' = 'MoFi',
    /** SoPay */
    'SOP' = 'SOP',
    /** Dexioprotocol */
    'DEXIO' = 'DEXIO',
    /** Send */
    'SEND' = 'SEND',
    /** Patientory */
    'PTOY' = 'PTOY',
    /** stake.link */
    'SDL' = 'SDL',
    /** GMFAM */
    'GMFAM' = 'GMFAM',
    /** GSENetwork */
    'GSE' = 'GSE',
    /** EmiSwap */
    'ESW' = 'ESW',
    /** Cope */
    'COPE' = 'COPE',
    /** Staked ETH */
    'OSETH' = 'OSETH',
    /** PLEBToken */
    'PLEB' = 'PLEB',
    /** MCFinance */
    'MCF' = 'MCF',
    /** CYBRIA */
    'CYBA' = 'CYBA',
    /** ZkTsunami */
    ':ZKT:' = ':ZKT:',
    /** Cramer Coin */
    'CRAMER' = 'CRAMER',
    /** CouponBay */
    'CUP' = 'CUP',
    /** MetaRare */
    'MTRA' = 'MTRA',
    /** Skrumble Network */
    'SKM' = 'SKM',
    /** EverMoon */
    'EVERMOON' = 'EVERMOON',
    /** MESSIER */
    'M87' = 'M87',
    /** ELTCOIN */
    'ELTCOIN' = 'ELTCOIN',
    /** Ethermon */
    'EMON' = 'EMON',
    /** DogeBoy */
    'DOGB' = 'DOGB',
    /** Jaypeggers */
    'JAY' = 'JAY',
    /** Burp */
    'BURP' = 'BURP',
    /** SpillWays */
    'SPILLWAYS' = 'SPILLWAYS',
    /** Covenant */
    'COVN' = 'COVN',
    /** Value Liquidity */
    'VALUE' = 'VALUE',
    /** MaidSafeCoin */
    'EMAID' = 'EMAID',
    /** Verge (ETH) */
    'XVG' = 'XVG',
    /** Espresso Bot */
    'ESPR' = 'ESPR',
    /** DBXen */
    'DXN' = 'DXN',
    /** DOLZ */
    'DOLZ' = 'DOLZ',
    /** Notional Finance */
    'NOTE' = 'NOTE',
    /** MEME KONG */
    '$MKONG' = '$MKONG',
    /** BlockGPT */
    'BGPT' = 'BGPT',
    /** MetaUnit */
    'MEU' = 'MEU',
    /** Zeusshield */
    'ZSC' = 'ZSC',
    /** Kitty Inu */
    'kitty' = 'kitty',
    /** 0xS */
    '$0XS' = '$0XS',
    /** HZM Coin */
    'HZM' = 'HZM',
    /** Origin Ether */
    'OETH' = 'OETH',
    /** PL^Gnet */
    'PLUG' = 'PLUG',
    /** The Rug Game */
    'TRG' = 'TRG',
    /** Hoppy */
    'HOPPY' = 'HOPPY',
    /** Javelin */
    'JVL' = 'JVL',
    /** STIMA */
    'STIMA' = 'STIMA',
    /** Gemach */
    'GMAC' = 'GMAC',
    /** Toad Killer */
    '$TOAD' = '$TOAD',
    /** Decentralized Eternal Virtual Traveller */
    'DEVT' = 'DEVT',
    /** Tether MXNt */
    'MXNt' = 'MXNt',
    /** eBlockStock */
    'EBSO' = 'EBSO',
    /** Luna 2.0 */
    'LUNA2.0' = 'LUNA2.0',
    /** StakeWise */
    'RETH2' = 'RETH2',
    /** SaitaBit */
    'SAITABIT' = 'SAITABIT',
    /** Theopetra */
    'THEO' = 'THEO',
    /** Blacksmith Token */
    'BS' = 'BS',
    /** VoldemortTrumpRobotnik-10Neko */
    'ETHEREUM' = 'ETHEREUM',
    /** NiiFi */
    'NIIFI' = 'NIIFI',
    /** GUISE */
    'GUISE' = 'GUISE',
    /** Metronome */
    'MET' = 'MET',
    /** Arowana Token */
    'ARW' = 'ARW',
    /** XDoge */
    'XD' = 'XD',
    /** Kanagawa Nami */
    'OKINAMI' = 'OKINAMI',
    /** Dream */
    'DREAM' = 'DREAM',
    /** Shibarium Perpetuals */
    'SERP' = 'SERP',
    /** SnailBrook */
    'SNAIL' = 'SNAIL',
    /** Memecoin 2.0 */
    'MEME 2.0' = 'MEME 2.0',
    /** OGSMINEM */
    'OGSM' = 'OGSM',
    /** The Camel */
    'CAMEL' = 'CAMEL',
    /** eUSD */
    'EUSD' = 'EUSD',
    /** Theos */
    'THEOS' = 'THEOS',
    /** 12Ships */
    'TSHP' = 'TSHP',
    /** Streamer Inu */
    'STREAMERINU' = 'STREAMERINU',
    /** Donald Trump */
    'TRUMP2024' = 'TRUMP2024',
    /** Busy DAO */
    'BUSY' = 'BUSY',
    /** Pokemon */
    'POKEMON' = 'POKEMON',
    /** Gatsby Inu */
    'GATSBY' = 'GATSBY',
    /** TrueDeck */
    'TDP' = 'TDP',
    /** PEIPEI */
    'PEIPEI' = 'PEIPEI',
    /** Chad Index */
    'CHAD' = 'CHAD',
    /** 1Sol */
    '1SOL' = '1SOL',
    /** Bitpanda Ecosystem Token */
    'BEST' = 'BEST',
    /** BlackPool */
    'BPT' = 'BPT',
    /** Universe.XYZ */
    'XYZ' = 'XYZ',
    /** NCOP */
    'NCOP' = 'NCOP',
    /** Boost */
    'BOOST' = 'BOOST',
    /** Everex */
    'EVX' = 'EVX',
    /** Tr3zor */
    'TR3' = 'TR3',
    /** Shezmu */
    'SHEZMU' = 'SHEZMU',
    /** Asymetrix */
    'ASX' = 'ASX',
    /** Sekuritance */
    'SKRT' = 'SKRT',
    /** Vocare ex Machina */
    'VOCARE' = 'VOCARE',
    /** Wolf Game Wool */
    'WOOL' = 'WOOL',
    /** X7DAO */
    'X7DAO' = 'X7DAO',
    /** STREETH */
    'STREETH' = 'STREETH',
    /** Arro Social */
    'ARRO' = 'ARRO',
    /** Winter */
    'WINTER' = 'WINTER',
    /** MOE */
    'MOE' = 'MOE',
    /** Hedron */
    'HDRN' = 'HDRN',
    /** Zenith Wallet */
    'ZW' = 'ZW',
    /** Autumn */
    'AUTUMN' = 'AUTUMN',
    /** Rizz Token */
    '$RIZZ' = '$RIZZ',
    /** VARC */
    'VARC' = 'VARC',
    /** Dog Collar */
    'COLLAR' = 'COLLAR',
    /** ProBit Token */
    'PROB' = 'PROB',
    /** Advantis */
    'ADVT' = 'ADVT',
    /** Wise Token */
    'WISE' = 'WISE',
    /** Reserve */
    'RSRV' = 'RSRV',
    /** Serum SER */
    'SER' = 'SER',
    /** HarryPotterObamaSonic11Inu */
    'BITCOIN1' = 'BITCOIN1',
    /** Spring */
    'SPRING' = 'SPRING',
    /** SHIKOKU */
    'SHIK' = 'SHIK',
    /** DumbMoney */
    'GME' = 'GME',
    /** Koyo */
    'KOY' = 'KOY',
    /** Spot */
    'SPOT' = 'SPOT',
    /** Marvin Inu */
    'MARVIN' = 'MARVIN',
    /** bitcastle */
    'CASTLE' = 'CASTLE',
    /** 2DAI.io */
    '2DAI' = '2DAI',
    /** Robo Inu Finance */
    'RBIF' = 'RBIF',
    /** CryptoFranc */
    'XCHF' = 'XCHF',
    /** Mumu */
    'MUMU' = 'MUMU',
    /** ProjectDojo */
    'DOJO' = 'DOJO',
    /** EventChain */
    'EVC' = 'EVC',
    /** Deepcave */
    'CAVE' = 'CAVE',
    /** mStable USD */
    'MUSD' = 'MUSD',
    /** Summer */
    'SUMMER' = 'SUMMER',
    /** met a meta metameme */
    'METAMEME' = 'METAMEME',
    /** SHIB2 */
    'SHIB2' = 'SHIB2',
    /** Airbloc */
    'ABL' = 'ABL',
    /** Adana Demirspor Token */
    'DEMIR' = 'DEMIR',
    /** Vehicle Mining System */
    'VMS' = 'VMS',
    /** Bezoge Earth */
    'BEZOGE' = 'BEZOGE',
    /** Sybulls */
    'SYBL' = 'SYBL',
    /** Icosa */
    'ICSA' = 'ICSA',
    /** Ness LAB */
    'NESS' = 'NESS',
    /** Chooky V2 */
    '$CHOO' = '$CHOO',
    /** Neural Radiance Field */
    'NERF' = 'NERF',
    /** Hoichi */
    'HOICHI' = 'HOICHI',
    /** MetaZooMee */
    'MZM' = 'MZM',
    /** Metaverse Index */
    'MVI' = 'MVI',
    /** Reddit */
    'REDDIT' = 'REDDIT',
    /** Stronger */
    'STRNGR' = 'STRNGR',
    /** AIBabyDoge */
    'AIBABYDOGE' = 'AIBABYDOGE',
    /** MuzzleToken */
    'MUZZ' = 'MUZZ',
    /** Goerli ETH */
    'GETH' = 'GETH',
    /** New Order */
    'NEWO' = 'NEWO',
    /** Pepechain */
    'PC' = 'PC',
    /** Escrowed Illuvium 2 */
    'SILV2' = 'SILV2',
    /** SafeStake */
    'DVT' = 'DVT',
    /** Liza */
    'LIZA' = 'LIZA',
    /** Moxy */
    'WMOXY' = 'WMOXY',
    /** Viralsniper */
    'VIRAL' = 'VIRAL',
    /** IMVU */
    'VCORE' = 'VCORE',
    /** BuildUp */
    'BUP' = 'BUP',
    /** X.COM */
    'XCOM' = 'XCOM',
    /** EdFi */
    'EDFI' = 'EDFI',
    /** TiUSD */
    'TIUSD' = 'TIUSD',
    /** tSILVER */
    'TXAG' = 'TXAG',
    /** VKENAF */
    'VKNF' = 'VKNF',
    /** Uniqly */
    'UNIQ' = 'UNIQ',
    /** Cajutel */
    'CAJ' = 'CAJ',
    /** Duzce Token */
    'DUZCE' = 'DUZCE',
    /** BlackSwan AI */
    'BLACKSWAN' = 'BLACKSWAN',
    /** Hermes DAO */
    'HMX' = 'HMX',
    /** Regen Network */
    'REGEN' = 'REGEN',
    /** Gains Farm */
    'GFARM2' = 'GFARM2',
    /** Squid Game 2.0 */
    'SQUID2' = 'SQUID2',
    /** Stake DAO CRV */
    'SDCRV' = 'SDCRV',
    /** sETH */
    'SETH' = 'SETH',
    /** Curve Inu */
    'CRVY' = 'CRVY',
    /** LinkCoin Token */
    'LKN' = 'LKN',
    /** TRYC */
    'TRYC' = 'TRYC',
    /** NuCypher */
    'NU' = 'NU',
    /** Half Shiba Inu */
    'SHIB0.5' = 'SHIB0.5',
    /** JDB */
    'JDB' = 'JDB',
    /** Capybara */
    'BARA' = 'BARA',
    /** Osmo Bot */
    'OSMO' = 'OSMO',
    /** BLAZE TOKEN */
    'BLZE' = 'BLZE',
    /** Alpha Bot Calls */
    'ABC' = 'ABC',
    /** MEZZ Token */
    'MEZZ' = 'MEZZ',
    /** Tether CNHt */
    'CNHt' = 'CNHt',
    /** Neos Credits */
    'NCR' = 'NCR',
    /** Boop */
    'BOOP' = 'BOOP',
    /** AGOV (ANSWER Governance) */
    'AGOV' = 'AGOV',
    /** Catgirl Optimus */
    'OPTIG' = 'OPTIG',
    /** Mononoke Inu */
    'Mononoke-Inu' = 'Mononoke-Inu',
    /** Tempus */
    'TEMP' = 'TEMP',
    /** BOB 2.0 */
    'BOB 2.0' = 'BOB 2.0',
    /** Neton */
    'NTO' = 'NTO',
    /** IPOR */
    'IPOR' = 'IPOR',
    /** Marble Bet */
    'MARBLE' = 'MARBLE',
    /** Betrock */
    'BETROCK' = 'BETROCK',
    /** SILVER */
    'SILVER' = 'SILVER',
    /** OUCHI */
    'OUCHI' = 'OUCHI',
    /** PEPE 3.0 */
    'PEPE 3.0' = 'PEPE 3.0',
    /** Frens */
    'FRENS' = 'FRENS',
    /** Shanghai Inu */
    'SHANG' = 'SHANG',
    /** Balıkesirspor Token */
    'BLKS' = 'BLKS',
    /** Jared From Subway */
    'JARED' = 'JARED',
    /** BOBO 2.0 */
    'BOBO 2.0' = 'BOBO 2.0',
    /** SORA Synthetic USD */
    'XSTUSD' = 'XSTUSD',
    /** TUF Token */
    'TUF' = 'TUF',
    /** XBOT */
    'XBOT' = 'XBOT',
    /** Dogeclub */
    'DOGC' = 'DOGC',
    /** Cryptomeda */
    'TECH' = 'TECH',
    /** Sivasspor Token */
    'SIV' = 'SIV',
    /** Rizespor Token */
    'RIZE' = 'RIZE',
    /** Icel Idman Yurdu Token */
    'MIY' = 'MIY',
    /** BlockRemit */
    'REMIT' = 'REMIT',
    /** Bananatok */
    'BNA' = 'BNA',
    /** Hobbes */
    'HOBBES' = 'HOBBES',
    /** zkSync Labs */
    'ZKLAB' = 'ZKLAB',
    /** Gravitas */
    'GRAVITAS' = 'GRAVITAS',
    /** BarbieCrashBandicootRFK777Inu */
    'SOLANA 2.0' = 'SOLANA 2.0',
    /** LockTrip */
    'LOC' = 'LOC',
    /** EYES Protocol */
    'EYES' = 'EYES',
    /** PEPEYE */
    'PEPEYE' = 'PEPEYE',
    /** Reality Metaverse */
    'RMV' = 'RMV',
    /** Fisco Coin */
    'FSCC' = 'FSCC',
    /** BLOCKS */
    'BLOCKS' = 'BLOCKS',
    /** Aigang */
    'AIX' = 'AIX',
    /** Ethane */
    'C2H6' = 'C2H6',
    /** DEFY */
    'DEFY' = 'DEFY',
    /** Kermit */
    'KERMIT' = 'KERMIT',
    /** Fight Out */
    'FGHT' = 'FGHT',
    /** EDDASwap */
    'EDDA' = 'EDDA',
    /** Coinmetro Token */
    'XCM' = 'XCM',
    /** LakeViewMeta */
    'LVM' = 'LVM',
    /** MemeDAO */
    'MEMD' = 'MEMD',
    /** BigShortBets */
    'BIGSB' = 'BIGSB',
    /** HARAM */
    'HARAM' = 'HARAM',
    /** DubX */
    'DUB' = 'DUB',
    /** Antalyaspor Token */
    'AKREP' = 'AKREP',
    /** Asap Sniper Bot */
    'ASAP' = 'ASAP',
    /** Apollo Crypto DAO */
    'APOLLO' = 'APOLLO',
    /** GigaSwap */
    'GIGA' = 'GIGA',
    /** kendoll janner */
    'KEN' = 'KEN',
    /** CoinMerge OS */
    'CMOS' = 'CMOS',
    /** Ante Casino */
    'CHANCE' = 'CHANCE',
    /** WEN Token */
    'WEN' = 'WEN',
    /** Edge */
    'EDGE' = 'EDGE',
    /** PLOUTΩS */
    'PLOUTΩS' = 'PLOUTΩS',
    /** Open Proprietary Protocol */
    'OPP' = 'OPP',
    /** CARD.STARTER */
    'CARDS' = 'CARDS',
    /** BitSong */
    'BTSG' = 'BTSG',
    /** Hatayspor Token */
    'HATAY' = 'HATAY',
    /** Shiba V Pepe */
    'SHEPE' = 'SHEPE',
    /** ETH 2.0 */
    'ETH 2.0' = 'ETH 2.0',
    /** StealthPad */
    'STEALTH' = 'STEALTH',
    /** Aviator */
    'AVI' = 'AVI',
    /** Tate */
    'TATE' = 'TATE',
    /** 3d3d */
    '3D3D' = '3D3D',
    /** $LAMBO */
    'LAMBO' = 'LAMBO',
    /** Friends With Benefits Pro */
    'FWB' = 'FWB',
    /** Scooby Doo */
    'SODO' = 'SODO',
    /** Cuckadoodledoo */
    'CUCK' = 'CUCK',
    /** STCoin */
    'ST' = 'ST',
    /** Homer */
    'SIMPSON 2.0' = 'SIMPSON 2.0',
    /** BetaCarbon */
    'BCAU' = 'BCAU',
    /** Tail */
    'TAIL' = 'TAIL',
    /** A2A */
    'A2A' = 'A2A',
    /** Fuck Gary Gensler */
    'FKGARY' = 'FKGARY',
    /** Creation Energy Join International */
    'CEJI' = 'CEJI',
    /** Pantos */
    'PAN' = 'PAN',
    /** JungleKing TigerCoin */
    'TIGER' = 'TIGER',
    /** P Pizza */
    'PPIZZA' = 'PPIZZA',
    /** GGDApp */
    'GGTK' = 'GGTK',
    /** Fuck Pepe */
    'FKPEPE' = 'FKPEPE',
    /** humanDAO */
    'HDAO' = 'HDAO',
    /** SHILLD */
    'SHILLD' = 'SHILLD',
    /** Archive AI */
    'ARCAI' = 'ARCAI',
    /** ELIS */
    'XLS' = 'XLS',
    /** Continuum World */
    'UM' = 'UM',
    /** Erugo World Coin */
    'EWC' = 'EWC',
    /** Qawalla */
    'QWLA' = 'QWLA',
    /** HNB Protocol */
    'HNB' = 'HNB',
    /** greg */
    'GREG' = 'GREG',
    /** Stablz */
    'STABLZ' = 'STABLZ',
    /** IYKYK */
    'IYKYK' = 'IYKYK',
    /** Universal Basic Income */
    'UBI' = 'UBI',
    /** PREME Token */
    'PREME' = 'PREME',
    /** Z-Cubed */
    'Z3' = 'Z3',
    /** Kawakami */
    'KAWA' = 'KAWA',
    /** TCG Verse */
    'TCGC' = 'TCGC',
    /** Floor Cheese Burger */
    'FLRBRG' = 'FLRBRG',
    /** Cindicator */
    'CND' = 'CND',
    /** Linework Coin */
    'LWC' = 'LWC',
    /** Orbeon Protocol */
    'ORBN' = 'ORBN',
    /** The SkyToken */
    'SKY' = 'SKY',
    /** PEPURAI */
    'PEPURAI' = 'PEPURAI',
    /** Beauty Bakery Linked Operation Transaction Technology */
    'LOTT' = 'LOTT',
    /** PepeGains */
    'PEPEGAINS' = 'PEPEGAINS',
    /** Echain Network */
    'ECT' = 'ECT',
    /** Financie Token */
    'FNCT' = 'FNCT',
    /** B.Protocol */
    'BPRO' = 'BPRO',
    /** Pepe Girl */
    'PEPEG' = 'PEPEG',
    /** Shuggi */
    'SHUGGI' = 'SHUGGI',
    /** Star Wars */
    'STARWARS' = 'STARWARS',
    /** QUARTZ */
    'QUARTZ' = 'QUARTZ',
    /** Cat-in-a-Box Ether */
    'BOXETH' = 'BOXETH',
    /** Emerging Assets Group */
    'EAG' = 'EAG',
    /** 0xCoco */
    'COCO' = 'COCO',
    /** Magic Shiba Starter */
    'MSHIB' = 'MSHIB',
    /** Happy Birthday Coin */
    'HBDC' = 'HBDC',
    /** SPGBB */
    'SPGBB' = 'SPGBB',
    /** OpenAI ERC */
    'OPENAI ERC' = 'OPENAI ERC',
    /** EVEAI */
    'EVEAI' = 'EVEAI',
    /** XINU */
    'XINU' = 'XINU',
    /** FX1Sports */
    'FX1' = 'FX1',
    /** Dede */
    'DEDE' = 'DEDE',
    /** Experty Wisdom Token */
    'WIS' = 'WIS',
    /** Shila Inu */
    'SHIL' = 'SHIL',
    /** SALAMANDER */
    'SALLY' = 'SALLY',
    /** HarryPotterRussellSonic1Inu */
    'SAITAMA' = 'SAITAMA',
    /** Darüşşafaka Spor Kulübü Token */
    'DSK' = 'DSK',
    /** 50x.com */
    '50X' = '50X',
    /** Bankera V2 */
    'BNK' = 'BNK',
    /** XRP20 */
    'XRP20' = 'XRP20',
    /** PepeXL */
    'PEPEXL' = 'PEPEXL',
    /** APY Vision */
    'VISION' = 'VISION',
    /** K-Tune */
    'KTT' = 'KTT',
    /** Flute */
    'FLUT' = 'FLUT',
    /** X8X Token */
    'X8X' = 'X8X',
    /** Merchant Token */
    'MTO' = 'MTO',
    /** FOFO Token */
    'FOFO' = 'FOFO',
    /** Oasis Metaverse */
    'OASIS' = 'OASIS',
    /** MyBit */
    'MYB' = 'MYB',
    /** Giresunspor Token */
    'GRS' = 'GRS',
    /** Hola Token */
    '$HOLA' = '$HOLA',
    /** Blockster */
    'BXR' = 'BXR',
    /** R */
    'R' = 'R',
    /** Changer */
    'CNG' = 'CNG',
    /** Lets Go Brandon */
    'LETSGO' = 'LETSGO',
    /** BuckHathCoin */
    'BHIG' = 'BHIG',
    /** Kayserispor Token */
    'KYSR' = 'KYSR',
    /** Evil Pepe */
    'EVILPEPE' = 'EVILPEPE',
    /** Pac Man */
    'PACMAN' = 'PACMAN',
    /** Generational Wealth */
    'GEN' = 'GEN',
    /** Internet Money */
    'IM' = 'IM',
    /** Old Bitcoin */
    'BC' = 'BC',
    /** JEFE TOKEN */
    'JEFE' = 'JEFE',
    /** BlitzPick */
    'XBP' = 'XBP',
    /** Pre-retogeum */
    'PRTG' = 'PRTG',
    /** Vanspor Token */
    'VAN' = 'VAN',
    /** Algory Project */
    'ALG' = 'ALG',
    /** LarryCoin */
    'LARRY' = 'LARRY',
    /** Empower */
    'MPWR' = 'MPWR',
    /** New World Order */
    'STATE' = 'STATE',
    /** Element */
    'ELMT' = 'ELMT',
    /** Savings Dai */
    'SDAI' = 'SDAI',
    /** Bambi */
    'BAM' = 'BAM',
    /** Simpson6900 */
    'Simpson6900' = 'Simpson6900',
    /** AMLT */
    'AMLT' = 'AMLT',
    /** Cardstack */
    'CARD' = 'CARD',
    /** Delta */
    'DELTA' = 'DELTA',
    /** BNS Token */
    'BNS' = 'BNS',
    /** Blui */
    'BLUI' = 'BLUI',
    /** Scarab Tools */
    'DUNG' = 'DUNG',
    /** Shiba Girlfriend */
    'SHIBGF' = 'SHIBGF',
    /** 0x1.tools: AI Multi-tool Plaform */
    '#0X1' = '0X1',
    /** Eggs */
    'EGGS' = 'EGGS',
    /** VERSACE */
    'VERSACE' = 'VERSACE',
    /** EYE */
    'EYE' = 'EYE',
    /** Hamsters */
    'HAMS' = 'HAMS',
    /** Earn Defi Coin */
    'EDC' = 'EDC',
    /** MeetPle */
    'MPT' = 'MPT',
    /** MOMO v2 */
    'MOMO V2' = 'MOMO V2',
    /** Elon GOAT */
    'EGT' = 'EGT',
    /** Mongoose */
    'MONGOOSE' = 'MONGOOSE',
    /** A Fund Baby */
    'AFB' = 'AFB',
    /** KEVIN */
    'KEVIN' = 'KEVIN',
    /** Urfaspor Token */
    'URFA' = 'URFA',
    /** Kento */
    'KNTO' = 'KNTO',
    /** Santiment Network Token */
    'SAN' = 'SAN',
    /** AstroElon */
    'ELONONE' = 'ELONONE',
    /** Solidly */
    'SOLID' = 'SOLID',
    /** LUXO */
    'LUXO' = 'LUXO',
    /** Erzurumspor Token */
    'ERZ' = 'ERZ',
    /** Pumbaa */
    'PUMBAA' = 'PUMBAA',
    /** StartupersCoin */
    'STAR' = 'STAR',
    /** Marginswap */
    'MFI' = 'MFI',
    /** GREENETH */
    'GRE' = 'GRE',
    /** Sakaryaspor Token */
    'SKRY' = 'SKRY',
    /** SPINDLE */
    'SPD' = 'SPD',
    /** Seele-N */
    'SEELE' = 'SEELE',
    /** SEC */
    'SEC' = 'SEC',
    /** SASQUATCH */
    'SQUATCH' = 'SQUATCH',
    /** Hydra Coin */
    'HYDRA' = 'HYDRA',
    /** Alpha Impact */
    'FOLO' = 'FOLO',
    /** YAMA Inu */
    'YAMA' = 'YAMA',
    /** Giveth */
    'GIV' = 'GIV',
    /** StrongX */
    'STRONGX' = 'STRONGX',
    /** GreenTek */
    'GTE' = 'GTE',
    /** Falcon 9 */
    'F9' = 'F9',
    /** EVE Token */
    'EVE' = 'EVE',
    /** ImpulseVen */
    'VEN' = 'VEN',
    /** Hamster Coin */
    '$HAMSTR' = '$HAMSTR',
    /** Formula Inu */
    'FINU' = 'FINU',
    /** Tuzlaspor Token */
    'TUZLA' = 'TUZLA',
    /** Shiba 2.0 */
    'Shiba 2.0' = 'Shiba 2.0',
    /** XRP2.0 */
    'XRP2' = 'XRP2',
    /** Yeni Malatyaspor Token */
    'YMS' = 'YMS',
    /** Talent */
    'TNT' = 'TNT',
    /** Pepe Le Pew Coin */
    '$PLPC' = '$PLPC',
    /** Decentral Games Governance (xDG) */
    'xDG' = 'xDG',
    /** TILWIKI */
    'TLW' = 'TLW',
    /** CoinLoan */
    'CLT' = 'CLT',
    /** LinkPool */
    'LPL' = 'LPL',
    /** Mondo Community Coin */
    'MNDCC' = 'MNDCC',
    /** VINLINK */
    'VNLNK' = 'VNLNK',
    /** Snek on Ethereum */
    'SNEKE' = 'SNEKE',
    /** GenieBot */
    'GENIE' = 'GENIE',
    /** NeoBot */
    'NEOBOT' = 'NEOBOT',
    /** Arbidex */
    'ABX' = 'ABX',
    /** MemeStation */
    'MEMES' = 'MEMES',
    /** SmartCredit Token */
    'SMARTCREDIT' = 'SMARTCREDIT',
    /** SMILEY */
    'SMILEY' = 'SMILEY',
    /** ARTi Project */
    'ARTI' = 'ARTI',
    /** BeforeCoinMarketCap */
    'BCMC1' = 'BCMC1',
    /** NFTDAO */
    'NAO' = 'NAO',
    /** Ccore */
    'CCO' = 'CCO',
    /** Dymmax */
    'DMX' = 'DMX',
    /** renZEC */
    'RENZEC' = 'RENZEC',
    /** Diyarbekirspor Token */
    'DIYAR' = 'DIYAR',
    /** Datarius Credit */
    'DTRC' = 'DTRC',
    /** Thug Life */
    'THUG' = 'THUG',
    /** Laika */
    'LAIKA' = 'LAIKA',
    /** Cometh */
    'MUST' = 'MUST',
    /** MDsquare */
    'TMED' = 'TMED',
    /** DXdao */
    'DXD' = 'DXD',
    /** SIRIN LABS Token */
    'SRN' = 'SRN',
    /** MMAON */
    'MMAON' = 'MMAON',
    /** ArbiSmart */
    'RBIS' = 'RBIS',
    /** Fancy Games */
    'FNC' = 'FNC',
    /** MONNOS */
    'MNS' = 'MNS',
    /** PepeGPT */
    'PEPEGPT' = 'PEPEGPT',
    /** Waygate */
    'WAY' = 'WAY',
    /** Bibox Token */
    'BIX' = 'BIX',
    /** Waste Digital Coin */
    'WACO' = 'WACO',
    /** BNSD Finance */
    'BNSD' = 'BNSD',
    /** Apple Network */
    'ANK' = 'ANK',
    /** Aardvark */
    'ARDVRK' = 'ARDVRK',
    /** Maximus Token */
    'MAXI' = 'MAXI',
    /** BlockEscrow */
    'BET' = 'BET',
    /** StarterCoin */
    'STAC' = 'STAC',
    /** SHEESH */
    'SHS' = 'SHS',
    /** Riot Racers */
    'RIOT' = 'RIOT',
    /** Crypto Accept */
    'ACPT' = 'ACPT',
    /** HedgeTrade */
    'HEDG' = 'HEDG',
    /** BUGATTI */
    'BUGATTI' = 'BUGATTI',
    /** Bancor Governance Token */
    'VBNT' = 'VBNT',
    /** iEthereum */
    'IETH' = 'IETH',
    /** WePower */
    'WPR' = 'WPR',
    /** Digex */
    'DIGEX' = 'DIGEX',
    /** Cultiplan(CTPL) */
    'CTPL' = 'CTPL',
    /** IMPACTXPRIME */
    'IXP' = 'IXP',
    /** Telefy */
    'TELE' = 'TELE',
    /** Crypviser */
    'CVNX' = 'CVNX',
    /** Aloha */
    'ALOHA' = 'ALOHA',
    /** Bestay */
    'BSY' = 'BSY',
    /** Neural Protocol */
    'NRP' = 'NRP',
    /** Butterfly Protocol */
    'BFLY' = 'BFLY',
    /** Pax Unitas */
    'PAXU' = 'PAXU',
    /** SKINCHAIN */
    'SKC' = 'SKC',
    /** USD Bancor */
    'USDB' = 'USDB',
    /** Yobit Token */
    'YO' = 'YO',
    /** SESTERCE MONEY */
    'SES' = 'SES',
    /** Freight Trust & Clearing Network */
    'EDI' = 'EDI',
    /** Dextoken */
    'DEXG' = 'DEXG',
    /** Memecoin */
    'MEM' = 'MEM',
    /** Smart MFG */
    'MFG' = 'MFG',
    /** Metropoly */
    'METRO' = 'METRO',
    /** Amon */
    'AMN' = 'AMN',
    /** iCommunity Labs */
    'ICOM' = 'ICOM',
    /** FLAMA */
    'FMA' = 'FMA',
    /** XRP 2.0 */
    'XRP 2.0' = 'XRP 2.0',
    /** Intexcoin */
    'INTX' = 'INTX',
    /** Tronipay */
    'TRP' = 'TRP',
    /** Daisy Launch Pad */
    'DAISY' = 'DAISY',
    /** Mainframe */
    'MFT' = 'MFT',
    /** Zippie */
    'ZIPT' = 'ZIPT',
    /** Transcodium */
    'TNS' = 'TNS',
    /** VINU Network */
    'VNN' = 'VNN',
    /** WhaleRoom */
    'WHL' = 'WHL',
    /** Crypto Village Accelerator */
    'CVAG' = 'CVAG',
    /** Scanetchain */
    'SWC' = 'SWC',
    /** Satopay Network */
    'STOP' = 'STOP',
    /** ODEM */
    'ODE' = 'ODE',
    /** Buzzshow */
    'GLDY' = 'GLDY',
    /** WeTrust */
    'TRST' = 'TRST',
    /** Apu */
    'APU' = 'APU',
    /** Ecoreal Estate */
    'ECOREAL' = 'ECOREAL',
    /** Skillchain */
    'SKI' = 'SKI',
    /** Limestone Network */
    'LIMEX' = 'LIMEX',
    /** Camp */
    'CAMP' = 'CAMP',
    /** CitiOs */
    'R2R' = 'R2R',
    /** Libfx */
    'LIBFX' = 'LIBFX',
    /** VouchForMe */
    'IPL' = 'IPL',
    /** Vendit */
    'VNDT' = 'VNDT',
    /** Korea Entertainment Education & Shopping */
    'KEES' = 'KEES',
    /** indaHash */
    'IDH' = 'IDH',
    /** Maya Preferred */
    'MAYP' = 'MAYP',
    /** Demeter Chain */
    'DMTC' = 'DMTC',
    /** U Network */
    'UUU' = 'UUU',
    /** Lunyr */
    'LUN' = 'LUN',
    /** Axia Protocol */
    'AXIA' = 'AXIA',
    /** Huobi Pool Token */
    'HPT' = 'HPT',
    /** Substratum */
    'SUB' = 'SUB',
    /** Community Business Token */
    'CBT' = 'CBT',
    /** Pundi X (Old) */
    'NPXS' = 'NPXS',
    /** AgaveCoin */
    'AGVC' = 'AGVC',
    /** TideBit Token */
    'TBT' = 'TBT',
    /** Grom */
    'GR' = 'GR',
    /** Colibri Protocol */
    'CLBR' = 'CLBR',
    /** Global Gaming */
    'GMNG' = 'GMNG',
    /** Xank */
    'XANK' = 'XANK',
    /** Medium */
    'MDM' = 'MDM',
    /** SAUDI PEPE */
    'SAUDIPEPE' = 'SAUDIPEPE',
    /** Prostarter */
    'PROT' = 'PROT',
    /** SPRINK */
    'SPRINK' = 'SPRINK',
    /** FlypMe */
    'FYP' = 'FYP',
    /** Rivetz */
    'RVT' = 'RVT',
    /** Incube Chain */
    'ICB' = 'ICB',
    /** shares.finance */
    'SHARES' = 'SHARES',
    /** Hiveterminal Token */
    'HVN' = 'HVN',
    /** United Traders Token */
    'UTT' = 'UTT',
    /** RUGAME */
    'RUG' = 'RUG',
    /** GoldeNugget */
    'GNTO' = 'GNTO',
    /** Mission Helios */
    'HELIOS' = 'HELIOS',
    /** Tradix */
    'TX' = 'TX',
    /** Monolith */
    'TKN' = 'TKN',
    /** PumaPay */
    'PMA' = 'PMA',
    /** JPGold Coin */
    'JPGC' = 'JPGC',
    /** CDbio */
    'MCD' = 'MCD',
    /** Fat Cat Killer */
    'KUSDC' = 'KUSDC',
    /** ZINC */
    'ZINC' = 'ZINC',
    /** DigixDAO */
    'DGD' = 'DGD',
    /** Swarm City */
    'SWT' = 'SWT',
    /** SpeedCash */
    'SCS' = 'SCS',
    /** Polybius */
    'PLBT' = 'PLBT',
    /** SkinCoin */
    'SKIN' = 'SKIN',
    /** InvestFeed */
    'IFT' = 'IFT',
    /** Po.et */
    'POE' = 'POE',
    /** Agrello */
    'DLT' = 'DLT',
    /** XPA */
    'XPA' = 'XPA',
    /** Kyber Network Crystal Legacy */
    'KNCL' = 'KNCL',
    /** VIBE */
    'VIBE' = 'VIBE',
    /** EXRNchain */
    'EXRN' = 'EXRN',
    /** Exchange Union */
    'XUC' = 'XUC',
    /** JavaScript Token */
    'JS' = 'JS',
    /** Upfiring */
    'UFR' = 'UFR',
    /** Paypex */
    'PAYX' = 'PAYX',
    /** BLOCKv */
    'VEE' = 'VEE',
    /** Eroscoin */
    'ERO' = 'ERO',
    /** Bonpay */
    'BON' = 'BON',
    /** Worldcore */
    'WRC' = 'WRC',
    /** AppCoins */
    'APPC' = 'APPC',
    /** Mixin */
    'XIN' = 'XIN',
    /** EchoLink */
    'EKO' = 'EKO',
    /** ArbitrageCT */
    'ARCT' = 'ARCT',
    /** Double-A Chain */
    'AAC' = 'AAC',
    /** AidCoin */
    'AID' = 'AID',
    /** Moola */
    'AXPR' = 'AXPR',
    /** Neurotoken */
    'NTK' = 'NTK',
    /** Tokenomy */
    'TEN' = 'TEN',
    /** Banca */
    'BANCA' = 'BANCA',
    /** NANJCOIN */
    'NANJ' = 'NANJ',
    /** DAEX */
    'DAX' = 'DAX',
    /** FLIP */
    'FLP' = 'FLP',
    /** Digix Gold Token */
    'DGX' = 'DGX',
    /** Sakura Bloom */
    'SKB' = 'SKB',
    /** Joint Ventures */
    'JOINT' = 'JOINT',
    /** Smartshare */
    'SSP' = 'SSP',
    /** KanadeCoin */
    'KNDC' = 'KNDC',
    /** OTCBTC Token */
    'OTB' = 'OTB',
    /** Karatgold Coin */
    'KBC' = 'KBC',
    /** Tourist Token */
    'TOTO' = 'TOTO',
    /** VikkyToken */
    'VIKKY' = 'VIKKY',
    /** WABnetwork */
    'WAB' = 'WAB',
    /** DACC */
    'DACC' = 'DACC',
    /** XOVBank */
    'XOV' = 'XOV',
    /** PKG Token */
    'PKG' = 'PKG',
    /** IGToken */
    'IG' = 'IG',
    /** SiaCashCoin */
    'SCC' = 'SCC',
    /** Blockpass */
    'PASS' = 'PASS',
    /** Obitan Chain */
    'OBTC' = 'OBTC',
    /** HeartBout */
    'HB' = 'HB',
    /** ShowHand */
    'HAND' = 'HAND',
    /** KingXChain */
    'KXC' = 'KXC',
    /** VeriDocGlobal */
    'VDG' = 'VDG',
    /** YUKI */
    'YUKI' = 'YUKI',
    /** FUTURAX */
    'FTXT' = 'FTXT',
    /** DAV Coin */
    'DAV' = 'DAV',
    /** ABCC Token */
    'AT' = 'AT',
    /** Fire Lotto */
    'FLOT' = 'FLOT',
    /** CyberMusic */
    'CYMT' = 'CYMT',
    /** EvenCoin */
    'EVN' = 'EVN',
    /** Havy */
    'HAVY' = 'HAVY',
    /** Rotharium */
    'RTH' = 'RTH',
    /** MobilinkToken */
    'MOLK' = 'MOLK',
    /** Cryptrust */
    'CTRT' = 'CTRT',
    /** Robotina */
    'ROX' = 'ROX',
    /** CMITCOIN */
    'CMIT' = 'CMIT',
    /** QChi */
    'QCH' = 'QCH',
    /** MidasProtocol */
    'MAS' = 'MAS',
    /** TRONCLASSIC */
    'TRXC' = 'TRXC',
    /** Knekted */
    'KNT' = 'KNT',
    /** Wixlar */
    'WIX' = 'WIX',
    /** Future1coin */
    'F1C' = 'F1C',
    /** Decentralized Asset Trading Platform */
    'DATP' = 'DATP',
    /** MODEL-X-coin */
    'MODX' = 'MODX',
    /** Alpha Coin */
    'APC' = 'APC',
    /** PlayGame */
    'PXG' = 'PXG',
    /** TravelNote */
    'TVNT' = 'TVNT',
    /** Shivers */
    'SHVR' = 'SHVR',
    /** Herbalist Token */
    'HERB' = 'HERB',
    /** QUINADS */
    'QUIN' = 'QUIN',
    /** The Currency Analytics */
    'TCAT' = 'TCAT',
    /** BTU Protocol */
    'BTU' = 'BTU',
    /** Decentralized Crypto Token */
    'DCTO' = 'DCTO',
    /** CustomContractNetwork */
    'CCN' = 'CCN',
    /** BoatPilot Token */
    'NAVY' = 'NAVY',
    /** LitecoinToken */
    'LTK' = 'LTK',
    /** Ethereum Gold Project */
    'ETGP' = 'ETGP',
    /** botXcoin */
    'BOTX' = 'BOTX',
    /** Tratok */
    'TRAT' = 'TRAT',
    /** MenaPay */
    'MPAY' = 'MPAY',
    /** ETHplode */
    'ETHPLO' = 'ETHPLO',
    /** EOS TRUST */
    'EOST' = 'EOST',
    /** GLOBEX */
    'GEX' = 'GEX',
    /** BitcoinRegular */
    'BTRL' = 'BTRL',
    /** Defi */
    'DEFI' = 'DEFI',
    /** BSOV Token */
    'BSOV' = 'BSOV',
    /** Tepleton */
    'TEP' = 'TEP',
    /** Tokenize Xchange */
    'TKX' = 'TKX',
    /** 1irstGold */
    '1GOLD' = '1GOLD',
    /** FinexboxToken */
    'FNB' = 'FNB',
    /** MeconCash */
    'MCH' = 'MCH',
    /** Bitcoin & Company Network */
    'BITN' = 'BITN',
    /** AfroDex */
    'AfroX' = 'AfroX',
    /** QURAS */
    'XQC' = 'XQC',
    /** Compound Dai */
    'CDAI' = 'CDAI',
    /** Compound USD Coin */
    'CUSDC' = 'CUSDC',
    /** GoalTime N */
    'GTX' = 'GTX',
    /** B ONE PAYMENT */
    'B1P' = 'B1P',
    /** Bali Coin */
    'BALI' = 'BALI',
    /** Jack Token */
    'JACK' = 'JACK',
    /** REBIT */
    'KEYT' = 'KEYT',
    /** Mt Pelerin Shares */
    'MPS' = 'MPS',
    /** Idea Chain Coin */
    'ICH' = 'ICH',
    /** Litecoin SV */
    'LSV' = 'LSV',
    /** BlockNoteX */
    'BNOX' = 'BNOX',
    /** CryptoBet */
    'CBET' = 'CBET',
    /** DMM: Governance */
    'DMG' = 'DMG',
    /** sBTC */
    'SBTC' = 'SBTC',
    /** STPAY */
    'STP' = 'STP',
    /** 4ART Coin */
    '4ART' = '4ART',
    /** CoinZoom */
    'ZOOM' = 'ZOOM',
    /** FME */
    'FME' = 'FME',
    /** DigiMax DGMT */
    'DGMT' = 'DGMT',
    /** DeFiPie */
    'PIE' = 'PIE',
    /** Jubi Token */
    'JT' = 'JT',
    /** Newtonium */
    'NEWTON' = 'NEWTON',
    /** Upper Euro */
    'EURU' = 'EURU',
    /** Upper Pound */
    'GBPU' = 'GBPU',
    /** Upper Dollar */
    'USDU' = 'USDU',
    /** Betherchip */
    'BEC' = 'BEC',
    /** Consensus Cell Network */
    'ECELL' = 'ECELL',
    /** cVault.finance */
    'CORE' = 'CORE',
    /** HLP Token */
    'HLP' = 'HLP',
    /** LCG */
    'LCG' = 'LCG',
    /** DeFi Gold */
    'DFGL' = 'DFGL',
    /** Mars Network */
    'MARS' = 'MARS',
    /** Auric Network */
    'AUSCM' = 'AUSCM',
    /** DefiDollar DAO */
    'DFD' = 'DFD',
    /** Zuplo */
    'ZLP' = 'ZLP',
    /** Libartysharetoken */
    'LST' = 'LST',
    /** GYSR */
    'GYSR' = 'GYSR',
    /** Folder Protocol */
    'FOL' = 'FOL',
    /** TOM Finance */
    'TOM' = 'TOM',
    /** BIOKKOIN */
    'BKKG' = 'BKKG',
    /** Unicap.finance */
    'UCAP' = 'UCAP',
    /** DGPayment */
    'DGP' = 'DGP',
    /** YFIDapp */
    'YFID' = 'YFID',
    /** Alpha5 */
    'A5T' = 'A5T',
    /** SpiderDAO */
    'SPDR' = 'SPDR',
    /** Cybertronchain */
    'CTC' = 'CTC',
    /** Dexfin */
    'DXF' = 'DXF',
    /** XCF Token */
    'XCF' = 'XCF',
    /** PoolTogether */
    'POOL' = 'POOL',
    /** BT.Finance */
    'BT' = 'BT',
    /** Ballswap */
    'BSP' = 'BSP',
    /** Advertise Coin */
    'ADCO' = 'ADCO',
    /** CAD Coin */
    'CADC' = 'CADC',
    /** DAOhaus */
    'HAUS' = 'HAUS',
    /** Stone DeFi */
    'STN' = 'STN',
    /** XFai */
    'XFIT' = 'XFIT',
    /** Wrapped Statera */
    'WSTA' = 'WSTA',
    /** Pinknode */
    'PNODE' = 'PNODE',
    /** Sanshu Inu */
    'SANSHU' = 'SANSHU',
    /** Hina Inu */
    'HINA' = 'HINA',
    /** Keanu Inu */
    'KEANU' = 'KEANU',
    /** Star Foxx */
    'FOXX' = 'FOXX',
    /** Xiglute Coin */
    'XGC' = 'XGC',
    /** Curio Stable Coin */
    'CSC' = 'CSC',
    /** Liti Capital */
    'WLITI' = 'WLITI',
    /** BabyDoge ETH */
    'BABYDOGE' = 'BABYDOGE',
    /** A51 Finance */
    'PILOT' = 'PILOT',
    /** Bright Union */
    'BRIGHT' = 'BRIGHT',
    /** Unreal Finance */
    'UGT' = 'UGT',
    /** Beast Token */
    'BEAST' = 'BEAST',
    /** CWallet */
    'CW' = 'CW',
    /** YDragon */
    'YDR' = 'YDR',
    /** Jenny Metaverse DAO Token */
    'UJENNY' = 'UJENNY',
    /** Entropyfi */
    'ERP' = 'ERP',
    /** Raid Token */
    'RAID' = 'RAID',
    /** Punk Vault (NFTX) */
    'PUNK' = 'PUNK',
    /** Gro DAO Token */
    'GRO' = 'GRO',
    /** Doren */
    'DRE' = 'DRE',
    /** ArchAngel Token */
    'ARCHA' = 'ARCHA',
    /** Balkari */
    'BKR' = 'BKR',
    /** Mainston */
    'STON' = 'STON',
    /** Sewer Rat Social Club CHIZ Token */
    'CHIZ' = 'CHIZ',
    /** Wrapped Curio Ferrari F12tdf */
    'WCT1' = 'WCT1',
    /** Chellitcoin */
    'CHLT' = 'CHLT',
    /** DotOracle */
    'DTO' = 'DTO',
    /** Etherrock#72 */
    'PEBBLE' = 'PEBBLE',
    /** No one */
    'NOONE' = 'NOONE',
    /** Winry Inu */
    'WINRY' = 'WINRY',
    /** Jacy */
    'JACY' = 'JACY',
    /** Kaiba DeFi */
    'KAIBA' = 'KAIBA',
    /** Upper Swiss Franc */
    'CHFU' = 'CHFU',
    /** Spike Inu */
    'SPKI' = 'SPKI',
    /** Amun Polygon Ecosystem Index */
    'PECO' = 'PECO',
    /** Mochi Inu */
    'MOCHI' = 'MOCHI',
    /** Zuki */
    'ZUKI' = 'ZUKI',
    /** Ridge */
    'RIDGE' = 'RIDGE',
    /** KEYS */
    'KEYS' = 'KEYS',
    /** MADworld */
    'UMAD' = 'UMAD',
    /** XIDR */
    'XIDR' = 'XIDR',
    /** Firulais */
    'FIRU' = 'FIRU',
    /** Studio Shibli */
    'SHIBLI' = 'SHIBLI',
    /** PathDAO */
    'PATH' = 'PATH',
    /** MetaBrands */
    'MAGE' = 'MAGE',
    /** Kounotori */
    'KTO' = 'KTO',
    /** IDEAS */
    'IDEAS' = 'IDEAS',
    /** KunoichiX */
    'KUNO' = 'KUNO',
    /** Ghost Trader */
    'GTR' = 'GTR',
    /** 1eco */
    '1ECO' = '1ECO',
    /** Doge Protocol */
    'DOGEP' = 'DOGEP',
    /** 1peco */
    '1PECO' = '1PECO',
    /** Meta Capital */
    'MCAP' = 'MCAP',
    /** MetaBET */
    'MBET' = 'MBET',
    /** MetaRuffy */
    'MR' = 'MR',
    /** THIS */
    'THIS' = 'THIS',
    /** Envision */
    'VIS' = 'VIS',
    /** Bitazza */
    'BTZ' = 'BTZ',
    /** Lyfe Silver */
    'LSILVER' = 'LSILVER',
    /** Tiamonds */
    'TIA' = 'TIA',
    /** FloorDAO */
    'FLOOR' = 'FLOOR',
    /** inheritance Art */
    'IAI' = 'IAI',
    /** .Alpha */
    '.ALPHA' = '.ALPHA',
    /** Kyoko */
    'KYOKO' = 'KYOKO',
    /** LYFE GOLD */
    'LGOLD' = 'LGOLD',
    /** Shiba Hunter */
    'FALQOM' = 'FALQOM',
    /** BlockBlend */
    'BBL' = 'BBL',
    /** VIKTAMA */
    'VIK' = 'VIK',
    /** Stone */
    '0NE' = '0NE',
    /** Dexpools */
    'DXP' = 'DXP',
    /** HITOP */
    'HITOP' = 'HITOP',
    /** MyOwnItem */
    'MOI' = 'MOI',
    /** 7Pixels */
    '7PXS' = '7PXS',
    /** Crypto International */
    'CRI' = 'CRI',
    /** Inu Inu */
    'INUINU' = 'INUINU',
    /** Gravitationally Bound AURA */
    'GRAVIAURA' = 'GRAVIAURA',
    /** Anarchy */
    'ANARCHY' = 'ANARCHY',
    /** KumaDex Token */
    'dKUMA' = 'dKUMA',
    /** MicroChains Gov Token */
    'MCG' = 'MCG',
    /** PulseDogecoin */
    'PLSD' = 'PLSD',
    /** BuyMORE */
    'MORE' = 'MORE',
    /** STEED */
    'STD' = 'STD',
    /** Good Dog */
    'HEEL' = 'HEEL',
    /** ZARP Stablecoin */
    'ZARP' = 'ZARP',
    /** MUU */
    'MUU' = 'MUU',
    /** LUSD Chicken Bonds */
    'BLUSD' = 'BLUSD',
    /** Maximus DECI */
    'DECI' = 'DECI',
    /** DeFi Franc */
    'DCHF' = 'DCHF',
    /** Ushi */
    'USHI' = 'USHI',
    /** Marosca Inu */
    'MAROSCA' = 'MAROSCA',
    /** ZudgeZury */
    'ZZC' = 'ZZC',
    /** Shinigami */
    '$DEATH' = '$DEATH',
    /** NutsDAO */
    'NUTS' = 'NUTS',
    /** Morphswap */
    'MS' = 'MS',
    /** Cydotori */
    'DOTR' = 'DOTR',
    /** Black Dragon Society */
    'BDS' = 'BDS',
    /** Electronic USD */
    'eUSD' = 'eUSD',
    /** MegaWorld */
    'MEGA' = 'MEGA',
    /** Dogens */
    'DOGENS' = 'DOGENS',
    /** Protocol Zero */
    'ZRO' = 'ZRO',
    /** LYFE */
    'LYFE' = 'LYFE',
    /** One Hundred Million Inu */
    'OHMI' = 'OHMI',
    /** Shibosu */
    'SHIBO' = 'SHIBO',
    /** CNHC */
    'CNHC' = 'CNHC',
    /** PulseBitcoin */
    'PLSB' = 'PLSB',
    /** Communis */
    'COM' = 'COM',
    /** Bermuda */
    'BMDA' = 'BMDA',
    /** MarvelDoge */
    'MADOG' = 'MADOG',
    /** Mouseworm */
    'MOUSEWORM' = 'MOUSEWORM',
    /** COTI Governance Token */
    'gCOTI' = 'gCOTI',
    /** Toshi Tools */
    'TOSHI' = 'TOSHI',
    /** Chin */
    'CHIN' = 'CHIN',
    /** Zeni */
    'ZENI' = 'ZENI',
    /** Alpha Intelligence */
    '$AI' = '$AI',
    /** Doke Inu */
    'DOKE' = 'DOKE',
    /** DOPE Coin */
    'DOPE' = 'DOPE',
    /** Universal ETH */
    'UNIETH' = 'UNIETH',
    /** AliceNet */
    'ALCA' = 'ALCA',
    /** Fetch */
    'FETCH' = 'FETCH',
    /** Rinia Inu */
    'RINIA' = 'RINIA',
    /** Zero Liquid */
    'ZERO' = 'ZERO',
    /** MEVFree */
    'MEVFREE' = 'MEVFREE',
    /** Millix */
    'WMLX' = 'WMLX',
    /** Meeds */
    'MEED' = 'MEED',
    /** Pool Party */
    'PARTY' = 'PARTY',
    /** Phiat.io */
    'ePhiat' = 'ePhiat',
    /** ShibLa */
    'SHIBL' = 'SHIBL',
    /** Crypto SDG */
    'SDG' = 'SDG',
    /** Simps AI */
    'SIMPAI' = 'SIMPAI',
    /** Shinjarium */
    'SJM' = 'SJM',
    /** Clown Pepe */
    'HONK' = 'HONK',
    /** Good Gensler */
    'GENSLR' = 'GENSLR',
    /** Baby Pepe */
    'BBPP' = 'BBPP',
    /** Laelaps */
    'LAELAPS' = 'LAELAPS',
    /** Vision City */
    'VIZ' = 'VIZ',
    /** Text2VidAI */
    'T2V' = 'T2V',
    /** Mrs Pepe */
    'MRSPEPE' = 'MRSPEPE',
    /** Foxe */
    'FOXE' = 'FOXE',
    /** Capybara */
    'CAPY' = 'CAPY',
    /** CryptoTwitter */
    'CT' = 'CT',
    /** Gooch */
    'GOOCH' = 'GOOCH',
    /** Smudge Lord */
    'SMUDGE' = 'SMUDGE',
    /** FuckJared */
    'FJARED' = 'FJARED',
    /** SHROOMS AI */
    'SHROOMS' = 'SHROOMS',
    /** Esco Coin */
    'ESCO' = 'ESCO',
    /** Marshall Inu */
    'MRI' = 'MRI',
    /** 100xgems */
    '100XGEMS' = '100XGEMS',
    /** CyberTruck */
    'TRUCK' = 'TRUCK',
    /** WEWE */
    'WEWE' = 'WEWE',
    /** Simple Asymmetry ETH */
    'SAFETH' = 'SAFETH',
    /** NGMI Coin */
    'NGMI' = 'NGMI',
    /** MASYA */
    'MASYA' = 'MASYA',
    /** Copium */
    'COPIUM' = 'COPIUM',
    /** YUKKY */
    'YUKKY' = 'YUKKY',
    /** Chorizo */
    'CHORIZO' = 'CHORIZO',
    /** Mr F was here */
    'MRF' = 'MRF',
    /** BEEP coin */
    'BEEP' = 'BEEP',
    /** Denky Inu */
    'DNKY' = 'DNKY',
    /** Biaocoin */
    'BIAO' = 'BIAO',
    /** ぺぺ */
    'ぺぺ' = 'ぺぺ',
    /** KIKO */
    'KIKO' = 'KIKO',
    /** Mr. Narco */
    'NARCO' = 'NARCO',
    /** NiHao */
    'NIHAO' = 'NIHAO',
    /** GenieCoin */
    'GNC' = 'GNC',
    /** Star Token */
    'STK' = 'STK',
    /** Swipe Bot */
    'SWIPE' = 'SWIPE',
    /** Mr Potato Token */
    '$MRPOTATO' = '$MRPOTATO',
    /** Rin Tin Tin */
    'RIN' = 'RIN',
    /** Florky */
    'FLORKY' = 'FLORKY',
    /** HONGKONG INU */
    'HONG' = 'HONG',
    /** Fur Ball */
    'FUR' = 'FUR',
    /** Pick Or Rick */
    'RICK' = 'RICK',
    /** Dummy */
    'DUMMY' = 'DUMMY',
    /** ChadGPT */
    'CHADGPT' = 'CHADGPT',
    /** alphAI */
    'ΑAI' = 'ΑAI',
    /** DAVE */
    'DAVE' = 'DAVE',
    /** Lisa Simpson */
    'LISA' = 'LISA',
    /** Jotaro Token */
    'JOTARO' = 'JOTARO',
    /** DracoMalfoyDeSantisMario420Inu */
    'ZCASH' = 'ZCASH',
    /** INEDIBLE */
    'INEDIBLE' = 'INEDIBLE',
    /** KONG */
    'KONG' = 'KONG',
    /** CopyCat DAO */
    'CCD' = 'CCD',
    /** ROBO PEPE */
    'ROBOPEPE' = 'ROBOPEPE',
    /** 0xGasless */
    '0XGAS' = '0XGAS',
    /** BUBU */
    'BUBU' = 'BUBU',
    /** ENIGMA */
    'ENIGMA' = 'ENIGMA',
    /** Tweety */
    'TWEETY' = 'TWEETY',
    /** 9GAG */
    '9GAG' = '9GAG',
    /** DIVA Protocol */
    'DIVA' = 'DIVA',
    /** HarryPotterObamaSonic10Inu2.0 */
    'BITCOIN2.0' = 'BITCOIN2.0',
    /** SHIBADOGEPEPE */
    '$SDOPE' = '$SDOPE',
    /** Harbinger */
    'CHAOS' = 'CHAOS',
    /** ShitGPT */
    'SGPT' = 'SGPT',
    /** LiquiShield */
    'LIQS' = 'LIQS',
    /** SafeMoon 2.0 */
    'SAFEMOON2.0' = 'SAFEMOON2.0',
    /** Wrapped XRP */
    'WXRP' = 'WXRP',
    /** TruthGPT (ETH) */
    '$TRUTH' = '$TRUTH',
    /** Dogelon Mars 2.0 */
    'ELON2.0' = 'ELON2.0',
    /** Echoes */
    'ECHOES' = 'ECHOES',
    /** Ethereum 2.0 */
    'ETH2.0' = 'ETH2.0',
    /** LTC 2.0 */
    'LTC 2.0' = 'LTC 2.0',
    /** Opera Protocol */
    'OPERA' = 'OPERA',
    /** BetBot */
    'BBOT' = 'BBOT',
    /** FusionBot */
    'FUSION' = 'FUSION',
    /** Ascend */
    'ASC' = 'ASC',
    /** DGEN */
    'DGEN' = 'DGEN',
    /** XRPCHAIN */
    'RIPPLE CHAIN' = 'RIPPLE CHAIN',
    /** Test */
    'TEST' = 'TEST',
    /** None Trading */
    'NONE' = 'NONE',
    /** PengyX */
    'PENGYX' = 'PENGYX',
    /** Beautiful Princess Disorder */
    'BPD' = 'BPD',
    /** Navy seal */
    'NAVY SEAL' = 'NAVY SEAL',
    /** ECHO BOT */
    'ECHO' = 'ECHO',
    /** Mixaverse */
    'MIXCOIN' = 'MIXCOIN',
    /** Ultimate Tipbot */
    'ULTIMATEBOT' = 'ULTIMATEBOT',
    /** Xccelerate */
    'XLRT' = 'XLRT',
    /** PEPECASH */
    'PEPECASH' = 'PEPECASH',
    /** BREPE */
    'BREPE' = 'BREPE',
    /** SonicBOT */
    'SONICBOT' = 'SONICBOT',
    /** 28 */
    '#28' = '28',
    /** Short-term T-Bill Token */
    'STBT' = 'STBT',
    /** Hpohs888inu */
    'TETHER' = 'TETHER',
    /** WhaleWatch */
    'WBOT' = 'WBOT',
    /** RouletteBot */
    'ROULETTEBO' = 'ROULETTEBO',
    /** BobaCat */
    'PSPS' = 'PSPS',
    /** Unibets.AI */
    '$BETS' = '$BETS',
    /** Ethereum Gold Mining Company */
    'EGMC' = 'EGMC',
    /** Feels Good Man */
    'GOOD' = 'GOOD',
    /** JOEBIDEN2024 */
    'JOEBIDEN2024' = 'JOEBIDEN2024',
    /** BABY WALL STREET MEMES */
    'BWSM' = 'BWSM',
    /** EmotiCoin */
    'EMOTI' = 'EMOTI',
    /** Refund */
    'REFUND' = 'REFUND',
    /** XRP 2 */
    'XRP 2' = 'XRP 2',
    /** Alphas */
    'ALPHAS' = 'ALPHAS',
    /** SafeLunar */
    'SAFELUNAR' = 'SAFELUNAR',
    /** GUCCI */
    'GUCCI' = 'GUCCI',
    /** Baby X 2.0 */
    'BABYX2' = 'BABYX2',
    /** Elonium */
    'ELONIUM' = 'ELONIUM',
    /** VZZN */
    'VZZN' = 'VZZN',
    /** Bull Moon */
    'BULLMOON' = 'BULLMOON',
    /** BTEX */
    'BTEX' = 'BTEX',
    /** XNOVA */
    'XNOVA' = 'XNOVA',
    /** Cronos */
    'CRO' = 'CRO',
    /** Flow */
    'FLOW' = 'FLOW',
    /** Fasttoken */
    'FTN' = 'FTN',
    /** Radworks */
    'RAD' = 'RAD',
    /** Wirex Token */
    'WXT' = 'WXT',
    /** Phala Network */
    'PHA' = 'PHA',
    /** Forta */
    'FORT' = 'FORT',
    /** IRISnet */
    'IRIS' = 'IRIS',
    /** Elastos */
    'ELA' = 'ELA',
    /** CoinEx Token */
    'CET' = 'CET',
    /** cheqd */
    'CHEQ' = 'CHEQ',
    /** Boba Network */
    'BOBA' = 'BOBA',
    /** Alephium */
    'ALPH' = 'ALPH',
    /** DIMO */
    'DIMO' = 'DIMO',
    /** Tokemak */
    'TOKE' = 'TOKE',
    /** Veloce */
    'VEXT' = 'VEXT',
    /** Energi */
    'NRG' = 'NRG',
    /** Divi */
    'DIVI' = 'DIVI',
    /** Zenon */
    'ZNN' = 'ZNN',
    /** Pip */
    'PIP' = 'PIP',
    /** Sentinel */
    'DVPN' = 'DVPN',
    /** Shyft Network */
    'SHFT' = 'SHFT',
    /** MEVerse */
    'MEV' = 'MEV',
    /** Anchor Protocol */
    'ANC' = 'ANC',
    /** Graphlinq Chain */
    'GLQ' = 'GLQ',
    /** UBIX.Network */
    'UBX' = 'UBX',
    /** SifChain */
    'erowan' = 'erowan',
    /** Centaur */
    'CNTR' = 'CNTR',
    /** Sator */
    'SAO' = 'SAO',
    /** x42 Protocol */
    'X42' = 'X42',
    /** ION */
    'ION' = 'ION',
    /** Gaj Finance */
    'GAJ' = 'GAJ',
    /** Moneta Digital */
    'MMXN' = 'MMXN',
    /** Wrapped CANTO */
    'WCANTO' = 'WCANTO',
    /** TENET */
    'TENET' = 'TENET',
    /** Bitkub Coin */
    'KUB' = 'KUB',
    /** Evmos */
    'EVMOS' = 'EVMOS',
    /** CorgiAI */
    'CORGIAI' = 'CORGIAI',
    /** ROND */
    'ROND' = 'ROND',
    /** Paycoin */
    'PCI' = 'PCI',
    /** JUNO */
    'JUNO' = 'JUNO',
    /** Ambire Wallet */
    'WALLET' = 'WALLET',
    /** MinePlex */
    'PLEX' = 'PLEX',
    /** JD Coin */
    'JDC' = 'JDC',
    /** xFund */
    'XFUND' = 'XFUND',
    /** Polkaswap */
    'PSWAP' = 'PSWAP',
    /** BTC Proxy */
    'BTCPX' = 'BTCPX',
    /** e-Money EUR */
    'EEUR' = 'EEUR',
    /** MaxxChain */
    'PWR' = 'PWR',
    /** BitCanna */
    'BCNA' = 'BCNA',
    /** Aelin */
    'AELIN' = 'AELIN',
    /** GNFT */
    'GNFT' = 'GNFT',
    /** Polygen */
    'PGEN' = 'PGEN',
    /** Starname */
    'IOV' = 'IOV',
    /** Manna */
    'MANNA' = 'MANNA',
    /** Sovi Finance */
    'SOVI' = 'SOVI',
    /** POA Network */
    'POA' = 'POA',
    /** GoldMint */
    'MNTP' = 'MNTP',
    /** Charg Coin */
    'CHG' = 'CHG',
    /** SonoCoin */
    'SONO' = 'SONO',
    /** Easticoin */
    'ESTI' = 'ESTI',
    /** Polylauncher */
    'ANGEL' = 'ANGEL',
    /** Everscale */
    'EVER' = 'EVER',
    /** Wrapped Everscale */
    'WEVER' = 'WEVER',
    /** FlatQube */
    'QUBE' = 'QUBE',
    /** Kaspa */
    'KAS' = 'KAS',
    /** MojitoSwap */
    'MJT' = 'MJT',
    /** KuSwap */
    'KUS' = 'KUS',
    /** Wrapped KuCoin Token */
    'WKCS' = 'WKCS',
    /** Klaytn */
    'KLAY' = 'KLAY',
    /** BORA */
    'BORA' = 'BORA',
    /** SOMESING */
    'SSX' = 'SSX',
    /** KLAYswap Protocol */
    'KSP' = 'KSP',
    /** DeFi Kingdoms */
    'JEWEL' = 'JEWEL',
    /** GALAXIA */
    'GXA' = 'GXA',
    /** SuperWalk */
    'GRND' = 'GRND',
    /** Observer */
    'OBSR' = 'OBSR',
    /** TEMCO */
    'TEMCO' = 'TEMCO',
    /** Azit */
    'AZIT' = 'AZIT',
    /** Klaydice */
    'DICE' = 'DICE',
    /** SELO+ */
    'SELO' = 'SELO',
    /** Hiblocks */
    'HIBS' = 'HIBS',
    /** Drawshop Kingdom Reverse */
    'JOY' = 'JOY',
    /** Payment Swap Utility Board */
    'PSUB' = 'PSUB',
    /** GemHUB */
    'GHUB' = 'GHUB',
    /** Orbit Bridge Klaytn USDC */
    'OUSDC' = 'OUSDC',
    /** Treecle */
    'TRCL' = 'TRCL',
    /** Orbit Bridge Klaytn Orbit Chain */
    'OORC' = 'OORC',
    /** LEMON */
    'LEMN' = 'LEMN',
    /** Insureum */
    'ISR' = 'ISR',
    /** Round X */
    'RNDX' = 'RNDX',
    /** Tari World */
    'TARI' = 'TARI',
    /** Orbit Bridge Klaytn Handy */
    'OHANDY' = 'OHANDY',
    /** FAVOR */
    'FAVR' = 'FAVR',
    /** Gametree */
    'GTCOIN' = 'GTCOIN',
    /** KLEVA Protocol */
    'KLEVA' = 'KLEVA',
    /** MetaMAFIA */
    'MAF' = 'MAF',
    /** Walk Token */
    'WALK' = 'WALK',
    /** Hello Art */
    'HTT' = 'HTT',
    /** D-SHOP */
    'DP' = 'DP',
    /** Another World */
    'AWM' = 'AWM',
    /** 3 Kingdoms Multiverse */
    '3KM' = '3KM',
    /** LEMONCHAIN */
    'LEMC' = 'LEMC',
    /** Perproject */
    'PER' = 'PER',
    /** ASAN VERSE */
    'ASAN' = 'ASAN',
    /** KlayFi Finance */
    'KFI' = 'KFI',
    /** Meta Kongz */
    'MKC' = 'MKC',
    /** CashZone */
    'CASHZ' = 'CASHZ',
    /** NOX */
    'NOX' = 'NOX',
    /** MEDIEUS */
    'MDUS' = 'MDUS',
    /** CRYPTOKKI */
    'TOKKI' = 'TOKKI',
    /** Ducato Finance Token */
    'DUCATO' = 'DUCATO',
    /** Klaymore Stakehouse */
    'HOUSE' = 'HOUSE',
    /** ClaimSwap */
    'CLA' = 'CLA',
    /** NIKPLACE */
    'NIK' = 'NIK',
    /** Hintchain */
    'HINT' = 'HINT',
    /** CryptoBank */
    'CBANK' = 'CBANK',
    /** KROME Shares */
    'KROME' = 'KROME',
    /** Decentralized Liquidity Program */
    'DLP' = 'DLP',
    /** Wrapped Klaytn */
    'WKLAY' = 'WKLAY',
    /** Nakamoto Games */
    'NAKA' = 'NAKA',
    /** Battle World */
    'BWO' = 'BWO',
    /** Voxies */
    'VOXEL' = 'VOXEL',
    /** Captain Tsubasa */
    'TSUGT' = 'TSUGT',
    /** Carrieverse */
    'CVTX' = 'CVTX',
    /** Algebra */
    'ALGB' = 'ALGB',
    /** Kasta */
    'KASTA' = 'KASTA',
    /** Permission Coin */
    'ASK' = 'ASK',
    /** Wicrypt */
    'WNT' = 'WNT',
    /** WiFi Map */
    'WIFI' = 'WIFI',
    /** Rainbow Token */
    'RBW' = 'RBW',
    /** Affyn */
    'FYN' = 'FYN',
    /** Cipher */
    'CPR' = 'CPR',
    /** MetaTrace */
    'TRC' = 'TRC',
    /** Rebel Bots */
    'RBLS' = 'RBLS',
    /** Digital Fitness */
    'DEFIT' = 'DEFIT',
    /** Polylastic */
    'POLX' = 'POLX',
    /** MetaBeat */
    'BEAT' = 'BEAT',
    /** POLKER */
    'PKR' = 'PKR',
    /** ISLAMICOIN */
    'ISLAMI' = 'ISLAMI',
    /** Revolt 2 Earn */
    'RVLT' = 'RVLT',
    /** Artisse */
    'CATHEON' = 'CATHEON',
    /** Galaxy Fight Club */
    'GCOIN' = 'GCOIN',
    /** SolarX */
    'SOLX' = 'SOLX',
    /** The Unfettered */
    'SOULS' = 'SOULS',
    /** Playermon */
    'PYM' = 'PYM',
    /** Duckies, the canary network for Yellow */
    'DUCKIES' = 'DUCKIES',
    /** OneRare */
    'ORARE' = 'ORARE',
    /** SKYPlay */
    'SKP' = 'SKP',
    /** Genesis Worlds */
    'GENESIS' = 'GENESIS',
    /** Crypto Sports Network */
    'CSPN' = 'CSPN',
    /** Lithosphere */
    'LITHO' = 'LITHO',
    /** cLFi */
    'CLFI' = 'CLFI',
    /** SwissCheese */
    'SWCH' = 'SWCH',
    /** UpOnly */
    'UPO' = 'UPO',
    /** Art de Finance */
    'ADF' = 'ADF',
    /** Enegra (EGX) */
    'EGX' = 'EGX',
    /** XActRewards */
    'XACT' = 'XACT',
    /** DPEX */
    'DPEX' = 'DPEX',
    /** Ommniverse */
    'OMMI' = 'OMMI',
    /** MSQUARE GLOBAL */
    'MSQ' = 'MSQ',
    /** PolyDoge */
    'POLYDOGE' = 'POLYDOGE',
    /** Creta World */
    'CRETA' = 'CRETA',
    /** Leandro Lopes */
    'LOPES' = 'LOPES',
    /** Ethernity CLOUD */
    'ECLD' = 'ECLD',
    /** Medieval Empires */
    'MEE' = 'MEE',
    /** Floxypay */
    'FXY' = 'FXY',
    /** Planet IX(formerly IX token) */
    'IXT' = 'IXT',
    /** Epillo */
    'EPILLO' = 'EPILLO',
    /** Decentrawood */
    'DEOD' = 'DEOD',
    /** Metavault Trade */
    'MVX' = 'MVX',
    /** FireStarter */
    'FLAME' = 'FLAME',
    /** Farmsent */
    'FARMS' = 'FARMS',
    /** BitCone */
    'CONE' = 'CONE',
    /** KlimaDAO */
    'KLIMA' = 'KLIMA',
    /** World$tateCoin */
    'W$C' = 'W$C',
    /** Fidira */
    'FID' = 'FID',
    /** Soil */
    'SOIL' = 'SOIL',
    /** IPMB */
    'IPMB' = 'IPMB',
    /** CLIMBERS */
    'MITHRIL' = 'MITHRIL',
    /** Bullieverse */
    '$BULL' = '$BULL',
    /** KridaFans */
    'KRIDA' = 'KRIDA',
    /** Nexus Dubai */
    'NXD' = 'NXD',
    /** EnviDa */
    'EDAT' = 'EDAT',
    /** ZEXICON */
    'ZEXI' = 'ZEXI',
    /** GeniuX */
    'IUX' = 'IUX',
    /** Mars Token */
    'MRST' = 'MRST',
    /** Hibiki Run */
    'HUT' = 'HUT',
    /** POPKON */
    'POPK' = 'POPK',
    /** Aree Shards */
    'AES' = 'AES',
    /** Floyx */
    'FLOYX' = 'FLOYX',
    /** Digital Swiss Franc */
    'DSFR' = 'DSFR',
    /** ZIZY */
    'ZIZY' = 'ZIZY',
    /** GameStation */
    'GAMER' = 'GAMER',
    /** Carbify */
    'CBY' = 'CBY',
    /** Attack Wagon */
    'ATK' = 'ATK',
    /** Crepe Coin */
    'CREPE' = 'CREPE',
    /** LUXY */
    'LUXY' = 'LUXY',
    /** AETERNUS */
    'ATRNO' = 'ATRNO',
    /** KOLnet */
    'KOLNET' = 'KOLNET',
    /** Giddy */
    'GDDY' = 'GDDY',
    /** iNFTspace */
    'INS' = 'INS',
    /** Proxy */
    'PRXY' = 'PRXY',
    /** PIVN */
    'PIVN' = 'PIVN',
    /** Pegaxy */
    'PGX' = 'PGX',
    /** Map Node */
    'MNI' = 'MNI',
    /** ITSBLOC */
    'ITSB' = 'ITSB',
    /** Karmaverse */
    'KNOT' = 'KNOT',
    /** Carnomaly */
    'CARR' = 'CARR',
    /** o-mee */
    'OME' = 'OME',
    /** Torah Network */
    'VP' = 'VP',
    /** Infinity Skies */
    'ISKY' = 'ISKY',
    /** UMMA Token */
    'UMMA' = 'UMMA',
    /** Sway Protocol */
    'SWAY' = 'SWAY',
    /** Sunflower Land */
    'SFL' = 'SFL',
    /** GEODNET */
    'GEOD' = 'GEOD',
    /** Snook */
    'SNK' = 'SNK',
    /** Artmeta */
    'MART' = 'MART',
    /** JennyCo */
    'JCO' = 'JCO',
    /** BIM */
    'BIM' = 'BIM',
    /** Croatian FF Fan Token */
    'VATRENI' = 'VATRENI',
    /** EMG SuperApp */
    'EMGS' = 'EMGS',
    /** OkLetsPlay */
    'OKLP' = 'OKLP',
    /** Crypto Raiders */
    'RAIDER' = 'RAIDER',
    /** BundlesBets V2 */
    'BUND' = 'BUND',
    /** NextEarth */
    'NXTT' = 'NXTT',
    /** Pearl */
    'PEARL' = 'PEARL',
    /** Tutellus */
    'TUT' = 'TUT',
    /** Amber Soul Stone */
    'AMBER' = 'AMBER',
    /** Lucidao */
    'LCD' = 'LCD',
    /** NFTBooks */
    'NFTBS' = 'NFTBS',
    /** Tangible */
    'TNGBL' = 'TNGBL',
    /** Fitmint */
    'FITT' = 'FITT',
    /** Sphere Finance */
    'SPHERE' = 'SPHERE',
    /** Captain Tsubasa -RIVALS */
    'TSUBASAUT' = 'TSUBASAUT',
    /** Axion */
    'AXN' = 'AXN',
    /** Kitsumon */
    '$KMC' = '$KMC',
    /** KRED */
    'KRED' = 'KRED',
    /** Unicorn Milk */
    'UNIM' = 'UNIM',
    /** Cookies Protocol */
    'CP' = 'CP',
    /** Runy */
    'RUNY' = 'RUNY',
    /** CRI3X */
    'CRI3X' = 'CRI3X',
    /** Aventis Metaverse */
    'AVTM' = 'AVTM',
    /** VersaGames */
    'VERSA' = 'VERSA',
    /** CRISP Scored Mangroves */
    'CRISP-M' = 'CRISP-M',
    /** Dragon Soul Token */
    'DST' = 'DST',
    /** Sheesha Finance Polygon */
    'MSHEESHA' = 'MSHEESHA',
    /** TETU */
    'TETU' = 'TETU',
    /** Stasis Network */
    'STS' = 'STS',
    /** Homeety */
    'HOM' = 'HOM',
    /** Nurcoin */
    'NUR' = 'NUR',
    /** Flare Token */
    '1FLR' = '1FLR',
    /** MoonEdge */
    'MOONED' = 'MOONED',
    /** QiDao */
    'QI' = 'QI',
    /** DUX */
    'DUX' = 'DUX',
    /** Dystopia */
    'DYST' = 'DYST',
    /** DDAO Hunters */
    'DDAO' = 'DDAO',
    /** teleBTC */
    'TELEBTC' = 'TELEBTC',
    /** Polycat Finance */
    'FISH' = 'FISH',
    /** Honey */
    'HNY' = 'HNY',
    /** OvalPixel */
    'OPAIG' = 'OPAIG',
    /** Aavegotchi FOMO */
    'FOMO' = 'FOMO',
    /** CoinPoker */
    'CHP' = 'CHP',
    /** Karmaverse Zombie */
    'SERUM' = 'SERUM',
    /** CoinCollect */
    'COLLECT' = 'COLLECT',
    /** Exchange Genesis Ethlas Medium */
    'XGEM' = 'XGEM',
    /** Vault Hill City */
    'VHC' = 'VHC',
    /** EQ9 */
    'EQ9' = 'EQ9',
    /** Aavegotchi KEK */
    'KEK' = 'KEK',
    /** Rise of Elves */
    'ROE' = 'ROE',
    /** Saiyan PEPE */
    'SPEPE' = 'SPEPE',
    /** Aavegotchi FUD */
    'FUD' = 'FUD',
    /** StrongNode Edge */
    'SNE' = 'SNE',
    /** Adamant */
    'ADDY' = 'ADDY',
    /** Xion Finance */
    'XGT' = 'XGT',
    /** PolyCub */
    'POLYCUB' = 'POLYCUB',
    /** Cresio */
    'XCRE' = 'XCRE',
    /** Time Raiders */
    'XPND' = 'XPND',
    /** GoldPesa Option */
    'GPO' = 'GPO',
    /** LunaFi */
    'LFI' = 'LFI',
    /** SafeSwap */
    'SSGTX' = 'SSGTX',
    /** WaultSwap Polygon */
    'WEXPOLY' = 'WEXPOLY',
    /** Geopoly */
    'GEO$' = 'GEO$',
    /** Energy8 */
    'E8' = 'E8',
    /** AI PEPE KING */
    'AIPEPE' = 'AIPEPE',
    /** NFT Pussies */
    'PUSSIES' = 'PUSSIES',
    /** PolygonFarm Finance */
    'SPADE' = 'SPADE',
    /** KogeCoin.io */
    'KOGECOIN' = 'KOGECOIN',
    /** Dragoma */
    'DMA' = 'DMA',
    /** Ocavu Network Token */
    'OCAVU' = 'OCAVU',
    /** IBF Net */
    'IBFN' = 'IBFN',
    /** Coinerr */
    'ERR' = 'ERR',
    /** TradingDAO */
    'TDAO' = 'TDAO',
    /** Polygon HBD */
    'pHBD' = 'pHBD',
    /** Power Of Deep Ocean */
    'PODO' = 'PODO',
    /** GOGOcoin */
    'GOGO' = 'GOGO',
    /** KPAPA */
    'KPAPA' = 'KPAPA',
    /** Virtu */
    'VTU' = 'VTU',
    /** Gooeys */
    'GOO' = 'GOO',
    /** POLYDeFI */
    'OCTAGON' = 'OCTAGON',
    /** Greenhouse */
    'GREEN' = 'GREEN',
    /** H3RO3S */
    'H3RO3S' = 'H3RO3S',
    /** Daily COP */
    'DLYCOP' = 'DLYCOP',
    /** JetSwap pWings */
    'pWINGS' = 'pWINGS',
    /** Sunflower Farm */
    'SFF' = 'SFF',
    /** SingularFarm */
    'SING' = 'SING',
    /** FibSWAP DEx */
    'FIBO' = 'FIBO',
    /** SPORTZCHAIN */
    'SPN' = 'SPN',
    /** SupremeX */
    'SXC' = 'SXC',
    /** FarmHero */
    'HONOR' = 'HONOR',
    /** Crystl Finance */
    'CRYSTL' = 'CRYSTL',
    /** Firebird Finance */
    'HOPE' = 'HOPE',
    /** PolyDragon */
    'DGOLD' = 'DGOLD',
    /** Navigator DAO */
    'NTTC' = 'NTTC',
    /** xDollar */
    'XDO' = 'XDO',
    /** ETH 2x Flexible Leverage Index (Polygon) */
    'ETH2X-FLI-P' = 'ETH2X-FLI-P',
    /** Atlantis Loans Polygon */
    'ATLX' = 'ATLX',
    /** Chain Lords */
    'GLORY' = 'GLORY',
    /** SelfBar */
    'SBAR' = 'SBAR',
    /** PAXW */
    'PAXW' = 'PAXW',
    /** Ecowatt */
    'EWT' = 'EWT',
    /** Alpha Petto Shells */
    'APES' = 'APES',
    /** PDDOLLAR */
    'PDD' = 'PDD',
    /** Poriverse */
    'RIKEN' = 'RIKEN',
    /** Cyber City */
    'CYBR' = 'CYBR',
    /** Meshswap Protocol */
    'MESH' = 'MESH',
    /** DuDe */
    'DUDE' = 'DUDE',
    /** Movn */
    'MOV' = 'MOV',
    /** NZD Stablecoin */
    'NZDS' = 'NZDS',
    /** Ripae pMATIC */
    'PMATIC' = 'PMATIC',
    /** Branaverse */
    'BRANA' = 'BRANA',
    /** Lumiii */
    'LUMIII' = 'LUMIII',
    /** PartyFi */
    'PFI' = 'PFI',
    /** Reel Token */
    'Reelt' = 'Reelt',
    /** HYBRID TOKEN */
    'HBD' = 'HBD',
    /** Balancer Tetu Boosted Pool (USDC) */
    'bb-t-USDC' = 'bb-t-USDC',
    /** MonoLend */
    'MLD' = 'MLD',
    /** TrotelCoin */
    'TROTEL' = 'TROTEL',
    /** IDRX */
    'IDRX' = 'IDRX',
    /** EDNS Token */
    'EDNS' = 'EDNS',
    /** Shrike */
    'SHK' = 'SHK',
    /** Retreeb */
    'TREEB' = 'TREEB',
    /** Ubiq */
    'UBQ' = 'UBQ',
    /** X-CASH */
    'XCASH' = 'XCASH',
    /** TAKI */
    'TAKI' = 'TAKI',
    /** Crypto Royale */
    'ROY' = 'ROY',
    /** Kassandra */
    'KACY' = 'KACY',
    /** Minerva Wallet */
    'MIVA' = 'MIVA',
    /** INTDESTCOIN */
    'INTD' = 'INTD',
    /** Optimism */
    'OP' = 'OP',
    /** Kwenta */
    'KWENTA' = 'KWENTA',
    /** Sonne Finance */
    'SONNE' = 'SONNE',
    /** Collab.Land */
    'COLLAB' = 'COLLAB',
    /** Extra Finance */
    'EXTRA' = 'EXTRA',
    /** Exactly Protocol */
    'EXA' = 'EXA',
    /** Overnight */
    'OVN' = 'OVN',
    /** OpenSwap Optimism Token */
    'OPENX' = 'OPENX',
    /** OPX Finance */
    'OPX' = 'OPX',
    /** OpenX Locked Velo */
    'OPXVEVELO' = 'OPXVEVELO',
    /** Defira */
    'FIRA' = 'FIRA',
    /** Speed Star JOC */
    'JOC' = 'JOC',
    /** Speed Star SPEED */
    'SPEED' = 'SPEED',
    /** Tranquil Finance */
    'TRANQ' = 'TRANQ',
    /** Tranquil Staked ONE */
    'STONE' = 'STONE',
    /** MarsColony */
    'CLNY' = 'CLNY',
    /** Viper Protocol */
    'VIPER' = 'VIPER',
    /** Bonk */
    'BONK' = 'BONK',
    /** Solend */
    'SLND' = 'SLND',
    /** Hivemapper */
    'HONEY' = 'HONEY',
    /** Myro */
    'MYRO' = 'MYRO',
    /** Bonfida */
    'FIDA' = 'FIDA',
    /** Decimated */
    'DIO' = 'DIO',
    /** Mango */
    'MNGO' = 'MNGO',
    /** ROA CORE */
    'ROA' = 'ROA',
    /** Solanium */
    'SLIM' = 'SLIM',
    /** Gari Network */
    'GARI' = 'GARI',
    /** Only1 */
    'LIKE' = 'LIKE',
    /** UNKJD */
    'MBS' = 'MBS',
    /** Hawksight */
    'HAWK' = 'HAWK',
    /** Crowny */
    'CRWNY' = 'CRWNY',
    /** Walken */
    'WLKN' = 'WLKN',
    /** Prism */
    'PRISM' = 'PRISM',
    /** Octokn */
    'OTK' = 'OTK',
    /** xHashtag */
    'XTAG' = 'XTAG',
    /** Tulip Protocol */
    'TULIP' = 'TULIP',
    /** All.Art Protocol */
    'AART' = 'AART',
    /** Cheems */
    'CHEEMS' = 'CHEEMS',
    /** SpaceFalcon */
    'FCON' = 'FCON',
    /** FRAKT Token */
    'FRKT' = 'FRKT',
    /** Dexlab */
    'DXL' = 'DXL',
    /** RaceFi */
    'RACEFI' = 'RACEFI',
    /** CropperFinance */
    'CRP' = 'CRP',
    /** Sypool */
    'SYP' = 'SYP',
    /** SolChicks Token */
    'CHICKS' = 'CHICKS',
    /** Synthetify */
    'SNY' = 'SNY',
    /** Pyth Network */
    'PYTH' = 'PYTH',
    /** Jito Staked SOL */
    'JITOSOL' = 'JITOSOL',
    /** Guacamole */
    'GUAC' = 'GUAC',
    /** Elumia Crowns */
    'ELU' = 'ELU',
    /** Marinade */
    'MNDE' = 'MNDE',
    /** Nosana */
    'NOS' = 'NOS',
    /** Lido Staked SOL */
    'stSOL' = 'stSOL',
    /** USD Coin (Wormhole) */
    'USDC(WormHole)' = 'USDC(WormHole)',
    /** Orca */
    'ORCA' = 'ORCA',
    /** Shadow Token */
    'SHDW' = 'SHDW',
    /** Peepo (SOL) */
    '$PEEP' = '$PEEP',
    /** Metaplex */
    'MPLX' = 'MPLX',
    /** UXD Stablecoin */
    'UXD' = 'UXD',
    /** Leia */
    'LEIA' = 'LEIA',
    /** Helium Mobile */
    'MOBILE' = 'MOBILE',
    /** Staika */
    'STIK' = 'STIK',
    /** Hubble Protocol */
    'HBB' = 'HBB',
    /** Atlas DEX */
    'ATS' = 'ATS',
    /** Honeyland */
    'HXD' = 'HXD',
    /** Fronk */
    'FRONK' = 'FRONK',
    /** PsyOptions */
    'PSY' = 'PSY',
    /** Media Network */
    'MEDIA' = 'MEDIA',
    /** Graphite Protocol */
    'GP' = 'GP',
    /** Port Finance */
    'PORT' = 'PORT',
    /** Fluxbot */
    'FLUXB' = 'FLUXB',
    /** Whole Earth Coin */
    'WEC' = 'WEC',
    /** Cryowar */
    'CWAR' = 'CWAR',
    /** Parrot USD */
    'PAI' = 'PAI',
    /** EFK Token */
    'EFK' = 'EFK',
    /** SHIBONK */
    'SBONK' = 'SBONK',
    /** Solice */
    'SLC' = 'SLC',
    /** Bonk Earn */
    'BERN' = 'BERN',
    /** Hadeswap */
    'HADES' = 'HADES',
    /** RazrFi */
    'SOLR' = 'SOLR',
    /** Kurobi */
    'KURO' = 'KURO',
    /** GooseFX */
    'GOFX' = 'GOFX',
    /** Alfprotocol */
    'ALF' = 'ALF',
    /** SonarWatch */
    'SONAR' = 'SONAR',
    /** Sunny Aggregator */
    'SUNNY' = 'SUNNY',
    /** Larix */
    'LARIX' = 'LARIX',
    /** UXD Protocol */
    'UXP' = 'UXP',
    /** Ratio Finance */
    'RATIO' = 'RATIO',
    /** Kineko */
    'KNK' = 'KNK',
    /** Lifinity */
    'LFNTY' = 'LFNTY',
    /** Apricot Finance */
    'APT' = 'APT',
    /** Parrot Protocol */
    'PRT' = 'PRT',
    /** AtPay */
    'ATPAY' = 'ATPAY',
    /** Unique Venture Clubs */
    'UNQ' = 'UNQ',
    /** CATO */
    'CATO' = 'CATO',
    /** Socean Staked Sol */
    'SCNSOL' = 'SCNSOL',
    /** Sentre Protocol */
    'SNTR' = 'SNTR',
    /** Cykura */
    'CYS' = 'CYS',
    /** CoreStarter */
    'CSTR' = 'CSTR',
    /** Ethrix */
    'ETX' = 'ETX',
    /** Blocksmith Labs Forge */
    '$FORGE' = '$FORGE',
    /** DOGGO */
    'DOGGO' = 'DOGGO',
    /** TabTrader Token */
    'TTT' = 'TTT',
    /** TheSolanDAO */
    'SDO' = 'SDO',
    /** Compendium */
    'CMFI' = 'CMFI',
    /** Solar */
    'SOLAR' = 'SOLAR',
    /** Flowmatic */
    '$FM' = '$FM',
    /** DeFi Land Gold */
    'GOLDY' = 'GOLDY',
    /** Style */
    'STYLE' = 'STYLE',
    /** DeltaFi */
    'DELFI' = 'DELFI',
    /** Famous Fox Federation */
    'FOXY' = 'FOXY',
    /** PUG AI */
    'PUGAI' = 'PUGAI',
    /** SolAPE Token */
    'SOLAPE' = 'SOLAPE',
    /** Moonlana */
    'MOLA' = 'MOLA',
    /** Puff */
    'PUFF' = 'PUFF',
    /** Lambda Markets */
    'LMDA' = 'LMDA',
    /** basis.markets */
    'BASIS' = 'BASIS',
    /** Leancoin */
    'LEAN' = 'LEAN',
    /** PlutusFi */
    'PLUT' = 'PLUT',
    /** Secret Skellies Society */
    '$CRYPT' = '$CRYPT',
    /** Bamboo Coin */
    'BMBO' = 'BMBO',
    /** CHILI */
    'CHILI' = 'CHILI',
    /** Solpad Finance */
    'SOLPAD' = 'SOLPAD',
    /** Plutonians */
    'PLD' = 'PLD',
    /** Heavenland */
    'HTO' = 'HTO',
    /** DAWG */
    'DAWG' = 'DAWG',
    /** Genopets KI */
    'KI' = 'KI',
    /** Theca */
    'THECA' = 'THECA',
    /** FLOOF */
    'FLOOF' = 'FLOOF',
    /** Jelly eSports */
    'JELLY' = 'JELLY',
    /** Charactbit */
    'CHB' = 'CHB',
    /** Solcial */
    'SLCL' = 'SLCL',
    /** SWEETS */
    'SWTS' = 'SWTS',
    /** Gary */
    'GARY' = 'GARY',
    /** Boring Protocol */
    'BOP' = 'BOP',
    /** GhostKidDao */
    '$BOO' = '$BOO',
    /** Aevum */
    'AEVUM' = 'AEVUM',
    /** FONE */
    'FONE' = 'FONE',
    /** Enrex */
    'ENRX' = 'ENRX',
    /** MonkeDAO */
    'DAOSOL' = 'DAOSOL',
    /** Wrapped ALGO */
    'XALGO' = 'XALGO',
    /** Solareum Wallet */
    'XSB' = 'XSB',
    /** Yawww */
    'YAW' = 'YAW',
    /** Potato */
    'POTATO' = 'POTATO',
    /** Swole Doge */
    'SWOLE' = 'SWOLE',
    /** Dogelana */
    'DGLN' = 'DGLN',
    /** Yaku */
    '$YAKU' = '$YAKU',
    /** Scrap */
    'SCRAP' = 'SCRAP',
    /** Solberg */
    'SLB' = 'SLB',
    /** JPool Staked SOL (JSOL) */
    'JSOL' = 'JSOL',
    /** Voodoo Token */
    'LDZ' = 'LDZ',
    /** TinyBits */
    'TINY' = 'TINY',
    /** Galaxy */
    'GLXY' = 'GLXY',
    /** SolanaSail */
    'SAIL' = 'SAIL',
    /** Rally (Solana) */
    'SRLY' = 'SRLY',
    /** Mad Bears Club */
    'MBC' = 'MBC',
    /** KITTI TOKEN */
    'KITTI' = 'KITTI',
    /** SolChicks Shards */
    'SHARDS' = 'SHARDS',
    /** ChikinCoin */
    'CKC' = 'CKC',
    /** Plutonians */
    'RPC' = 'RPC',
    /** Ninja Protocol */
    'NINJA' = 'NINJA',
    /** GENZ Token */
    'GENZ' = 'GENZ',
    /** OOGI */
    'OOGI' = 'OOGI',
    /** SouloCoin */
    'SOULO' = 'SOULO',
    /** ROPE Token */
    'ROPE' = 'ROPE',
    /** Learning Star */
    'LSTAR' = 'LSTAR',
    /** YOM */
    'YOM' = 'YOM',
    /** Metamall */
    'MALL' = 'MALL',
    /** HolyGrails.io */
    'HOLY' = 'HOLY',
    /** Sunny Side up */
    'SSU' = 'SSU',
    /** Boryoku Dragonz */
    'BOKU' = 'BOKU',
    /** Raindrops Protocol */
    '$RAIN' = '$RAIN',
    /** Nirvana NIRV */
    'NIRV' = 'NIRV',
    /** Bruv */
    'BRUV' = 'BRUV',
    /** Elumia Krystal Shards */
    'EKS' = 'EKS',
    /** Equinox */
    'ENX' = 'ENX',
    /** Investin */
    'IVN' = 'IVN',
    /** Mindfolk Wood */
    '$WOOD' = '$WOOD',
    /** UPFI Network */
    'UPS' = 'UPS',
    /** Hedge */
    'HDG' = 'HDG',
    /** LadderCaster */
    'LADA' = 'LADA',
    /** YoloNolo */
    'YOLO' = 'YOLO',
    /** yOUcash */
    'YOUC' = 'YOUC',
    /** Solanax */
    'SOLD' = 'SOLD',
    /** Future */
    'FTR' = 'FTR',
    /** Turtles Token */
    'TRTLS' = 'TRTLS',
    /** Solvent */
    'SVT' = 'SVT',
    /** AvocadoCoin */
    'AVDO' = 'AVDO',
    /** Stoned Ape Crew Index */
    'SAC' = 'SAC',
    /** Pollen Coin */
    'PCN' = 'PCN',
    /** Degenerate Ape Academy Floor Index */
    'DAPE' = 'DAPE',
    /** Catalina Whales Index */
    'WHALES' = 'WHALES',
    /** Burrito Boyz Floor Index */
    'BURR' = 'BURR',
    /** Okay Bears Floor Index */
    'OKAYB' = 'OKAYB',
    /** Metaweds */
    'MWEDS' = 'MWEDS',
    /** Sollama Utilities */
    'SOLLAMA' = 'SOLLAMA',
    /** Dual Finance */
    'DUAL' = 'DUAL',
    /** Helium */
    'HNT' = 'HNT',
    /** Saber */
    'SBR' = 'SBR',
    /** Wrapped Neon EVM */
    'WNEON' = 'WNEON',
    /** USDH */
    'USDH' = 'USDH',
    /** Fanzee */
    'FNZ' = 'FNZ',
    /** Lavandos */
    'LAVE' = 'LAVE',
    /** TON Bridged USDT */
    'JUSDT' = 'JUSDT',
    /** Glint Coin */
    'GLINT' = 'GLINT',
    /** Scaleton */
    'SCALE' = 'SCALE',
    /** Margaritis */
    'MARGA' = 'MARGA',
    /** JetTon Game */
    'JETTON' = 'JETTON',
    /** GEMSTON */
    'GEMSTON' = 'GEMSTON',
    /** TON Bridged USDC */
    'JUSDC' = 'JUSDC',
    /** KINGYTON */
    'KINGY' = 'KINGY',
    /** Find & Check */
    'FCK' = 'FCK',
    /** Tonex */
    'TNX' = 'TNX',
    /** Ambra */
    'AMBR' = 'AMBR',
    /** JUST */
    'JST' = 'JST',
    /** USDJ */
    'USDJ' = 'USDJ',
    /** BitGuild PLAT */
    'PLAT' = 'PLAT',
    /** wstUSDT */
    'WSTUSDT' = 'WSTUSDT',
    /** Bitcoin TRC20 */
    'BTCT' = 'BTCT',
    /** TrueCNH */
    'TCNH' = 'TCNH',
    /** Metaworld */
    'MWCC' = 'MWCC',
    /** Guarantee */
    'TEE' = 'TEE',
    /** Degree Crypto Token */
    'DCT' = 'DCT',
    /** Kyrrex */
    'KRRX' = 'KRRX',
    /** X7 Coin */
    'X7C' = 'X7C',
    /** Fuji */
    'FJT' = 'FJT',
    /** Pollux Coin */
    'POX' = 'POX',
    /** JackPool.finance */
    'JFI' = 'JFI',
    /** FIX00 */
    'FIX00' = 'FIX00',
    /** CORN */
    'CORN' = 'CORN',
    /** SalmonSwap */
    'SAL' = 'SAL',
    /** Devikins */
    'DVK' = 'DVK',
    /** UpStableToken */
    'USTX' = 'USTX',
    /** BemChain */
    'BCN' = 'BCN',
    /** GreenZoneX */
    'GZX' = 'GZX',
    /** Litecoin TRC20 */
    'LTCT' = 'LTCT',
    /** PayNet Coin */
    'PAYN' = 'PAYN',
    /** Tourism Industry Metaverse */
    'TIM' = 'TIM',
    /** Social Swap Token */
    'SST' = 'SST',
    /** ITC */
    'ITC' = 'ITC',
    /** CryptoBharatCoin */
    'CBC' = 'CBC',
    /** 888tron */
    '#888' = '888',
    /** MUSE ENT NFT */
    'MSCT' = 'MSCT',
    /** Jot Art */
    'JOT' = 'JOT',
    /** TamaKitty */
    'TITTY' = 'TITTY',
    /** Bankroll Network */
    'BNKR' = 'BNKR',
    /** Cofinex */
    'CNX' = 'CNX',
    /** Chainpay */
    'CPAY' = 'CPAY',
    /** BIZZCOIN */
    'BIZZ' = 'BIZZ',
    /** TRONbetLive */
    'LIVE' = 'LIVE',
    /** BitOnyx */
    'BTNYX' = 'BTNYX',
    /** Rank Token */
    'RANK' = 'RANK',
    /** SHD CASH */
    'SHDC' = 'SHDC',
    /** Rumito */
    'RUTC' = 'RUTC',
    /** Extraterrestrial Token */
    'ET' = 'ET',
    /** Healium */
    'HLM' = 'HLM',
    /** uShark */
    'USHA' = 'USHA',
    /** Wrapped TRON */
    'WTRX' = 'WTRX',
    /** Venom */
    'VENOM' = 'VENOM',
    /** VeChain */
    'VET' = 'VET',
    /** VeThor Token */
    'VTHO' = 'VTHO',
    /** OceanEx Token */
    'OCE' = 'OCE',
    /** Jur */
    'JUR' = 'JUR',
    /** Wrapped VeChain */
    'WVET' = 'WVET',
    /** XDC Network */
    'XDC' = 'XDC',
    /** Comtech Gold */
    'CGO' = 'CGO',
    /** Plugin */
    'PLI' = 'PLI',
    /** StorX Network */
    'SRX' = 'SRX',
    /** Globiance Exchange Token */
    'GBEX' = 'GBEX',
    /** Fathom Dollar */
    'FXD' = 'FXD',
    /** Law Blocks */
    'LBT' = 'LBT',
    /** Fluent Finance */
    'USPLUS' = 'USPLUS',
    /** XSwap Treasure */
    'XTT' = 'XTT',
    /** Stellar */
    'XLM' = 'XLM',
    /** Edgecoin */
    'EDGT' = 'EDGT',
    /** Lumenswap */
    'LSP' = 'LSP',
    /** TheFutbolCoin */
    'TFC' = 'TFC',
    /** Rabet */
    'RBT' = 'RBT',
    /** Scopuly */
    'SCOP' = 'SCOP',
    /** Afreum */
    'AFR' = 'AFR',
    /** Equilibrium Games */
    'EQ' = 'EQ',
    /** XRPayNet */
    'XRPAYNET' = 'XRPAYNET',
    /** xSPECTAR */
    'XSPECTAR' = 'XSPECTAR',
    /** XRP Healthcare */
    'XRPH' = 'XRPH',
    /** Galaxy Coin */
    'GALAXY' = 'GALAXY',
    /** Xrp Classic (new) */
    'XRPC' = 'XRPC',
    /** The Reaper */
    'RPR' = 'RPR',
    /** Greyhound */
    'GREYHOUND' = 'GREYHOUND',
    /** ChorusX */
    'CX1' = 'CX1',
    /** XList */
    'XLIST' = 'XLIST',
    /** SmartNFT */
    'SMARTNFT' = 'SMARTNFT',
    /** SmartLOX */
    'SMARTLOX' = 'SMARTLOX',
    /** XGOLD COIN */
    'XGOLD' = 'XGOLD',
    /** MotionWreck Games */
    'MWG' = 'MWG',
    /** Moon Light Night */
    'MLNT' = 'MLNT',
}
