import { gql } from '@apollo/client';

import type { ExchangeCurrency } from '@smartfolly/backend.market-cap';

// Public constants

// GraphQL

export const historicalCollection = 'historical';

function quote(currency: ExchangeCurrency): string {
    const quoteFields = '{ price high low }';
    return `${currency} ${quoteFields}`;
}

const tokenHistoricalDataFields = `token date quote {
    ${quote('USD')}
    ${quote('EUR')}
    ${quote('CNY')}
    ${quote('GBP')}
    ${quote('JPY')}
    ${quote('AED')}
    ${quote('BRL')}
}`;

const historicalDataRecordFields = `token data { ${tokenHistoricalDataFields} }`;

export const historicalQuery = gql`
    query GetHistoricalData($filter: HistoricalFilter!) {
        ${historicalCollection}(filter: $filter) { ${historicalDataRecordFields} }
    }
`;

export const historicalSubscription = gql`
    subscription ($token: String!) {
        ${historicalCollection}(token: $token) { ${tokenHistoricalDataFields} }
    }
`;
