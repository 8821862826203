import { BaseMessageSignerWalletAdapter, WalletReadyState } from '@solana/wallet-adapter-base';
import type { PublicKey } from '@solana/web3.js';

import { solanaMainnetChain, ProvidersError } from '../constants';

import type {
    GetAccountsResponse,
    IProvider,
    ProviderInfo,
    SignMessageOptions,
    SignMessageResponse,
} from '../types';

import type { SolanaProviderName } from './types';

export class SolanaProvider<
    Name extends SolanaProviderName,
    Adapter extends BaseMessageSignerWalletAdapter,
> implements IProvider<Name>
{
    // Properties

    /**
     * A private instance of Solana wallet provider adapter.
     */
    private adapter: Adapter;

    /**
     * Name of the provider.
     */
    private name: Name;

    /**
     * A private flag either we are "ready-to-go" with Solana wallet provider adapter.
     */
    private readyToGo: boolean = false;

    // Constructor
    public constructor(name: Name, adapter: Adapter) {
        this.name = name;
        this.adapter = adapter;
    }

    // Interface

    public async checkIfAvailable(): Promise<boolean> {
        try {
            const provider = await this.getProvider();
            return !!provider;
        } catch (error) {
            if (error === ProvidersError.Solana.NoProviderFound) {
                return false;
            }

            throw error;
        }
    }

    public async disconnect(): Promise<void> {
        // Get the provider
        const provider = await this.getProvider();

        // Check if the provider is connected
        if (provider.connected) {
            // Disconnect if connected
            await provider.disconnect();
        } else {
            // Do nothing if no
        }
    }

    public async getAccounts(): Promise<GetAccountsResponse> {
        // Get the provider
        const provider = await this.getProvider();

        // Prepare the connection status processing function
        async function processConnectedPublicKey(
            publicKey: PublicKey | null,
        ): Promise<GetAccountsResponse> {
            // Check if an account is found
            if (!publicKey) {
                // Throw if not
                throw ProvidersError.Solana.NoAccountFound;
            }

            // Return the found accounts
            return [
                {
                    address: publicKey.toString(), // Solana's public key in "base-58" is an address
                    // Set the chain as Solana Mainnet taken from the WalletConnect related constants
                    // since this chain ID is general and can be used anywhere as CAIP-2 standard value
                    chainId: solanaMainnetChain.id,
                },
            ];
        }

        // Check if we are already connected;
        if (provider.connected) {
            // Process the connected public key
            return processConnectedPublicKey(provider.publicKey);
        }

        // Connect to the provider
        await provider.connect();
        if (provider.connected) {
            // Process the connected public key
            return processConnectedPublicKey(provider.publicKey);
        }

        // Throw if failed to process the connection
        throw ProvidersError.Solana.NoAccountFound;
    }

    public async getInfo(): Promise<ProviderInfo<Name>> {
        // Get the provider
        const provider = await this.getProvider();

        // Build-up the provider info
        const providerInfo: ProviderInfo<Name> = { name: this.name };

        // Get the peered wallet info
        if (provider.connected) {
            providerInfo.peer = {
                description: '', // Empty
                icons: [], // Maybe use the provider.icon which is in "base64"?
                name: provider.name,
                url: provider.url,
            };
        }

        return providerInfo;
    }

    // eslint-disable-next-line class-methods-use-this
    public async signMessage(_options: SignMessageOptions): Promise<SignMessageResponse> {
        // Throw an error as message signing is not supported for any Solana provider by us
        throw ProvidersError.Solana.NoSignatureMade;
    }

    /**
     * A lazy getter of Solana adapter.
     */
    private async getProvider(): Promise<BaseMessageSignerWalletAdapter> {
        // Return an already initialized if any
        if (this.readyToGo) {
            return this.adapter;
        }

        // Wait for the provider becomes "ready-to-go"
        await new Promise<void>((resolve, reject) => {
            // Prepare the list of disposers in order to listen to the provider state
            const disposers: (() => void)[] = [];
            const disposeAll = () => disposers.forEach(disposer => disposer());

            // Create a method to handle the provider state
            const handleReadyStateChange = (readyState: WalletReadyState): boolean => {
                // Check if the provider is unsupported
                if (this.adapter.readyState === WalletReadyState.Unsupported) {
                    reject(ProvidersError.Solana.NoProviderFound);
                    disposeAll();

                    // Return the flag that state is handled
                    return true;
                }

                // Check if the provider installed or loadable, i.e. ready to work with
                if (
                    readyState === WalletReadyState.Installed ||
                    readyState === WalletReadyState.Loadable
                ) {
                    resolve();
                    disposeAll();

                    // Return the flag that state is handled
                    return true;
                }

                // Ensure the only left state is "NotDetected"
                if (this.adapter.readyState !== WalletReadyState.NotDetected) {
                    throw new Error('New unprocessed adapter "readyState" detected!');
                }

                // Do nothing and wait until the state is changed!

                // Return the flag that state is not yet handled
                return false;
            };

            // Try to handle the provider state ASAP
            if (handleReadyStateChange(this.adapter.readyState)) {
                // Return since the state is handled
                return;
            }

            // Subscribe on the "readyState" changes
            this.adapter.on('readyStateChange', handleReadyStateChange);
            // Push the disposer to unsubscribe
            disposers.push(() => this.adapter.off('readyStateChange', handleReadyStateChange));

            // Wait for a sec to ensure the provider is not detected during this time
            const timeout = setTimeout(() => {
                if (this.adapter.readyState === WalletReadyState.NotDetected) {
                    reject(ProvidersError.Solana.NoProviderFound);
                    disposeAll();
                }
            }, 1000);
            // Push the disposer to stop waiting
            disposers.push(() => clearTimeout(timeout));
        });

        // Mark the provider as "ready-to-go"
        this.readyToGo = true;

        // Return a ready to go provider
        return this.adapter;
    }
}
