import {
    GetAccountsResponse,
    PhantomProvider as Provider,
    ProviderInfo,
    ProvidersError,
} from '@smartfolly/common.providers';

import { AssetsManagerError } from '../../../constants';

import type { IAddressProvider } from './types';

type Func<T, P> = (...args: P[]) => Promise<T>;
type WrappedFunc<T, P> = Func<T, P>;

const withErrorsMapperWrapper =
    <T, P, C>(fn: Func<T, P>, context: C): WrappedFunc<T, P> =>
    async (...args: P[]) => {
        try {
            // Run the original function
            return await fn.bind(context)(...args);
        } catch (error) {
            // Map the errors
            if (error === ProvidersError.Solana.NoAccountFound) {
                throw AssetsManagerError.Providers.Phantom.NoAccountFound;
            } else if (error === ProvidersError.Solana.NoProviderFound) {
                throw AssetsManagerError.Providers.Phantom.NoProviderFound;
            } else {
                throw error;
            }
        }
    };

class PhantomProvider implements IAddressProvider<'Phantom'> {
    // Properties

    /**
     * A private instance of Phantom provider
     */
    private phantomProvider = new Provider();

    // Interface

    public async checkIfAvailable(): Promise<boolean> {
        return withErrorsMapperWrapper(
            this.phantomProvider.checkIfAvailable,
            this.phantomProvider,
        )();
    }

    public async disconnect(): Promise<void> {
        return withErrorsMapperWrapper(this.phantomProvider.disconnect, this.phantomProvider)();
    }

    public async getAccounts(): Promise<GetAccountsResponse> {
        return withErrorsMapperWrapper(this.phantomProvider.getAccounts, this.phantomProvider)();
    }

    public async getInfo(): Promise<ProviderInfo<'Phantom'>> {
        return withErrorsMapperWrapper(this.phantomProvider.getInfo, this.phantomProvider)();
    }
}

export const phantomProvider = new PhantomProvider();
