// Inspired by https://github.com/christsim/multicoin-address-validator

import base58 from 'bs58';
// @ts-ignore Add TS-annotation if needed
import cbor from 'cbor-js';
import CRC from 'crc';

import { Blockchains } from '../../constants';

import { isValidBIP173Address } from './validators';

import { validatingBlockchains } from './validatingBlockchains';

function decodeAddress(address: string) {
    try {
        const decoded = base58.decode(address);
        return cbor.decode(decoded.buffer);
    } catch (e) {
        // If decoding fails assume it's an invalid address
        return null;
    }
}

/**
 * Check for Byron (Base58) address format.
 * @param address - an address to check.
 * @returns a result of the check.
 */
function isValidAddressByron(address: string) {
    const decoded = decodeAddress(address);

    if (!decoded || !Array.isArray(decoded) || decoded.length !== 2) {
        return false;
    }

    const tagged = decoded[0];
    const validCrc = decoded[1];
    if (typeof validCrc !== 'number') {
        return false;
    }

    // get crc of the payload
    const crc = CRC.crc32(tagged);

    return crc === validCrc;
}

/**
 * Check for Shelly (Bech32) address format.
 * @param address - an address to check.
 * @returns a result of the check.
 */
function isValidAddressShelley(address: string) {
    // Shelley address are just bip 173 - bech32 addresses (https://cips.cardano.org/cips/cip4/)
    return isValidBIP173Address(address, validatingBlockchains[Blockchains.ADA]);
}

/**
 * Check if the given address is valid for ADA blockchain.
 * @param address - an address to check.
 * @returns a result of the check.
 */
export function isValidAdaAddress(address: string) {
    return isValidAddressByron(address) || isValidAddressShelley(address);
}
