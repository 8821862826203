/* eslint-disable max-lines */
import { Blockchains } from './Blockchains';

type Contracts = {
    [address: string]: {
        decimals: number;
    };
};

type Chain = {
    contracts: Contracts;
};

// Add decimals here if impossible to get it by API
export const decimals: { [chain: string]: Chain } = {
    [Blockchains.ALGO]: {
        contracts: {},
    },
    [Blockchains.EGLD]: {
        contracts: {},
    },
    [Blockchains.KCS]: {
        contracts: {},
    },
    [Blockchains.KLAY]: {
        contracts: {},
    },
    [Blockchains.ONE]: {
        contracts: {},
    },
    [Blockchains.TRX]: {
        contracts: {},
    },
    [Blockchains.XDC]: {
        contracts: {},
    },
    [Blockchains.OP]: {
        contracts: {},
    },
    [Blockchains.ARB]: {
        contracts: {},
    },
};
