import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';

import { SolanaProvider } from '../helpers';

import type { IProvider } from '../types';

export class PhantomProvider
    extends SolanaProvider<'Phantom', PhantomWalletAdapter>
    implements IProvider<'Phantom'>
{
    public constructor() {
        super('Phantom', new PhantomWalletAdapter());
    }
}
