import type { Tokens } from '@smartfolly/common.currencies';

import {
    appStorage,
    buildStorageKeyForUser,
    Log,
    MemoDocumentLoader,
} from '@smartfolly/common.utilities';
import type { DocumentLoaderListener } from '@smartfolly/common.utilities';

import type {
    TokenDataQueryParameters,
    TokenDataQueryResponse,
    TokenDataSubscriptionResponse,
} from '@smartfolly/server';

import { serverConnector } from '@smartfolly/middleware.server-connector';
import type { DataListenerDisposer } from '@smartfolly/middleware.server-connector';

import { tokenDataCollection, tokenDataQuery, tokenDataSubscription } from '../constants';

import type { IMarketCap, MarketCapData, MarketCapDisposer, MarketCapListener } from '../types';

const log = new Log('MarketCap');

const MARKET_CAP_DATA_KEY = 'MarketCap:Data';

type MarketCapOptions = {
    /**
     * An optional ID of the user to work with when dealing with the market cap data.
     */
    userId?: string;
};

export class MarketCap implements IMarketCap {
    /**
     * Options the MarketCap module is created with.
     */
    private options: MarketCapOptions;

    /**
     * Currently active subscriptions.
     */
    private subscriptions: MarketCapListener[] = [];

    /**
     * Market Cap Data Loader instance.
     */
    private marketCapDataLoader?: MemoDocumentLoader<MarketCapData>;

    /**
     * Currently known market cap data.
     */
    private marketCapData?: MarketCapData;

    /**
     * An array of GraphQL Market Cap Data subscriptions disposers.
     */
    private disposers?: DataListenerDisposer[];

    // Constructor

    public constructor(options: MarketCapOptions) {
        this.options = options;
    }

    // Interface

    public get(): MarketCapData | undefined {
        return this.marketCapData;
    }

    public subscribe(tokens: Tokens[], marketCapListener: MarketCapListener): MarketCapDisposer {
        // Add the listener to active subscriptions
        this.subscriptions.push(marketCapListener);

        // Ensure to create a market cap loader
        if (!this.marketCapDataLoader) {
            this.marketCapDataLoader = new MemoDocumentLoader<MarketCapData>({
                key: this.options.userId
                    ? buildStorageKeyForUser(MARKET_CAP_DATA_KEY, this.options.userId)
                    : MARKET_CAP_DATA_KEY,
                storage: appStorage,
                documentFetcher: (listener: DocumentLoaderListener<MarketCapData>) => {
                    // Create a function to fetch the market cap data
                    const fetchMarketCapData = async () => {
                        try {
                            // Get the Market Cap Data for the specified tokens via GraphQL query
                            const response = await serverConnector.query<
                                TokenDataQueryParameters,
                                TokenDataQueryResponse
                            >({
                                collection: tokenDataCollection,
                                query: tokenDataQuery,
                                variables: {
                                    tokens,
                                },
                            });

                            // Check for errors in response
                            if ('error' in response) {
                                const { error } = response;

                                // Create a error instance
                                const fetchError = new Error(error);

                                // Return the error to the listener
                                listener(fetchError);

                                // And throw it to catch and log bellow
                                throw fetchError;
                            }

                            // Process and resolve the market cap data
                            const data = response.reduce<MarketCapData>((acc, item) => {
                                acc[item.name] = item;
                                return acc;
                            }, {});

                            listener(undefined, data);
                        } catch (error) {
                            log.error('Failed to fetch market cap data with error:', error);

                            // Return the subscription error to the listener
                            listener(error);
                        }
                    };

                    // Fetch market cap data
                    fetchMarketCapData();

                    // Create Market Cap Data subscriptions and store them in the disposers array
                    this.disposers = tokens.map(token => {
                        return serverConnector.subscribe<
                            { token: string },
                            TokenDataSubscriptionResponse
                        >(
                            {
                                collection: tokenDataCollection,
                                query: tokenDataSubscription,
                                variables: {
                                    token,
                                },
                            },
                            (error?: Error, data?: TokenDataSubscriptionResponse | null) => {
                                if (error) {
                                    log.error(
                                        `Failed to get ${token} data by subscription with error:`,
                                        error,
                                    );
                                    listener(error);
                                }

                                if (data) {
                                    // Update and resolve the market cap data
                                    const updatedData = {
                                        ...this.marketCapData,
                                        [data.name]: data,
                                    };
                                    listener(undefined, updatedData);
                                }
                            },
                        );
                    });
                },
                stopDocumentFetching: () => {
                    // Dispose all the created Market Cap Data subscriptions
                    if (this.disposers) {
                        this.disposers.forEach(disposer => disposer());
                        delete this.disposers;
                    }
                },
            });

            // Subscribe on the market cap data
            this.marketCapDataLoader.subscribe((error: Error | undefined, data?: MarketCapData) => {
                if (error) {
                    log.error('Failed to fetch market cap data with error:', error);
                    this.broadcastDataToListeners(error);
                    return;
                }

                if (!data) {
                    const noDataError = new Error('No market cap data has been loaded');
                    log.error(noDataError);
                    this.broadcastDataToListeners(noDataError);
                    return;
                }

                this.marketCapData = data;
                this.broadcastDataToListeners(undefined, data);
            });
        }

        // Return a disposer
        return () => {
            // Remove the current listener from the subscriptions
            const index = this.subscriptions.indexOf(marketCapListener);
            if (index !== 1) {
                this.subscriptions.splice(index, 1);
            } else {
                // Normally should never happen, since the listener must be removed only once
                log.error('Failed to remove an already removed listener from the subscriptions');
            }

            // Check if the subscriptions are present
            // Stop listening to the market cap data if no
            if (!this.subscriptions.length) {
                if (this.marketCapDataLoader) {
                    this.marketCapDataLoader.unsubscribe();
                    delete this.marketCapDataLoader;
                } else {
                    // Normally should never happen, since the loader should be destroyed only once
                    // when the last subscription is removed
                    log.error('Failed to unsubscribe from already removed market cap loader');
                }
            }
        };
    }

    // Internals

    /**
     * Method to broadcast the data changes from the Market Cap loader to the subscribed listeners.
     * @param error - a error to broadcast if any.
     * @param data - a market cap data to broadcast if any.
     */
    private broadcastDataToListeners(error: Error | undefined, data?: MarketCapData) {
        this.subscriptions.forEach(listener => listener(error, data));
    }
}
