/* eslint-disable class-methods-use-this */
import { computed, makeObservable, observable, runInAction } from 'mobx';

import { Log } from '@smartfolly/common.utilities';
import { tokensData } from '@smartfolly/common.currencies';

import { serverConnector } from '@smartfolly/middleware.server-connector';

import type { ITokensDataStore, TokensDataMap } from '../types';

const log = new Log('TokensDataStore');

class TokensDataStore implements ITokensDataStore {
    private loadedValue: TokensDataMap | undefined = undefined;

    public constructor() {
        makeObservable<TokensDataStore, 'loadedValue'>(this, {
            loadedValue: observable,
            tokensData: computed,
        });
    }

    public get tokensData(): TokensDataMap {
        return this.loadedValue ?? tokensData;
    }

    public async load() {
        try {
            const data = await serverConnector.sendGETRequest<TokensDataMap>({
                path: 'currencies/tokensData',
            });

            // Check for errors in response
            if ('error' in data) {
                // Ensure the data belongs to the ResponseError type or continue processing
                const { error, errorCode } = data;
                if (typeof error === 'string' && typeof errorCode === 'number') {
                    // TODO: process `errorCode` if needed
                    throw new Error(error);
                }
            }

            runInAction(() => {
                this.loadedValue = data as TokensDataMap;
            });
        } catch (error) {
            log.error('Failed to load stablecoins data form server with error', error);
        }
    }
}

export const tokensDataStore = new TokensDataStore();
