/* eslint-disable class-methods-use-this */
import { ProviderRpcClient } from '@eversurf/surfkeeper-provider';
import type { ConnectResponse } from '@eversurf/surfkeeper-provider';

import { everscaleMainnetChain, ProvidersError } from '../constants';

import type {
    GetAccountsResponse,
    IProvider,
    ProviderInfo,
    SignMessageOptions,
    SignMessageResponse,
} from '../types';

export class SurfKeeperExtProvider implements IProvider<'SurfKeeperExt'> {
    // Properties

    /**
     * A private instance of SurfKeeper's provider Client.
     */
    private providerClient?: ProviderRpcClient;

    // Interface

    public async checkIfAvailable(): Promise<boolean> {
        try {
            const provider = await this.getProvider();
            return !!provider;
        } catch (error) {
            if (error === ProvidersError.SurfKeeperExt.NoProviderFound) {
                return false;
            }

            throw error;
        }
    }

    public async disconnect(): Promise<void> {
        // Get the provider
        const provider = await this.getProvider();

        // Check if the provider is connected
        const connectionStatus = (await provider.connectStatus()) as ConnectResponse;
        if (connectionStatus.isConnected) {
            // Disconnect if connected
            await provider.disconnect();
        } else {
            // Do nothing if no
            // Note: at the moment SurfKeeper provider cannot disconnect if it wasn't connected!!!
        }
    }

    public async getAccounts(): Promise<GetAccountsResponse> {
        // Get the provider
        const provider = await this.getProvider();

        // Prepare the connection status processing function
        async function processConnection(
            connectionStatus: ConnectResponse,
        ): Promise<GetAccountsResponse> {
            // Get the main address of the current session
            const { address } = connectionStatus;

            // Check if an address is found
            if (!address) {
                // Throw if not
                throw ProvidersError.SurfKeeperExt.NoAccountFound;
            }

            // Return the found accounts
            return [
                {
                    address,
                    // Set the chain as Everscale Mainnet taken from the WalletConnect related constants
                    // since this chain ID is general and can be used anywhere as CAIP-2 standard value
                    chainId: everscaleMainnetChain.id,
                },
            ];
        }

        // Check if we are already connected
        const connectionStatus = (await provider.connectStatus()) as ConnectResponse;
        if (connectionStatus.isConnected) {
            // Process the connection status
            return processConnection(connectionStatus);
        }

        // Connect to SurfKeeper provider
        const connectionResponse = (await provider.connect()) as ConnectResponse;
        if (connectionResponse.isConnected) {
            // Process the connection response
            return processConnection(connectionResponse);
        }

        // Throw if failed to process the connection
        throw ProvidersError.SurfKeeperExt.NoAccountFound;
    }

    public async getInfo(): Promise<ProviderInfo<'SurfKeeperExt'>> {
        return {
            name: 'SurfKeeperExt',
        };
    }

    public async signMessage(_options: SignMessageOptions): Promise<SignMessageResponse> {
        // Throw an error as message signing is not supported for SurfKeeper Extension by us
        throw ProvidersError.SurfKeeperExt.NoSignatureMade;
    }

    /**
     * A lazy getter of SurfKeeper's provider Client.
     */
    private async getProvider(): Promise<ProviderRpcClient> {
        // Return an already initialized provider if any
        if (this.providerClient) {
            return this.providerClient;
        }

        // Check if provider exists
        const providerClient = new ProviderRpcClient();
        const hasProvider = await providerClient.hasProvider();
        if (!hasProvider) {
            throw ProvidersError.SurfKeeperExt.NoProviderFound;
        }

        // Save an initialized provider client and return
        this.providerClient = providerClient;
        return this.providerClient;
    }
}
