type NamespaceName =
    | 'cip-34'
    | 'eip155'
    | 'elrond'
    | 'flow'
    | 'solana'
    | 'stellar'
    | 'tron'
    | 'tvm'
    | 'xrpl';

type Chain<T extends NamespaceName> = {
    /**
     * A name of the chain.
     */
    name: string;

    /**
     * An ID of the chain as per CAIP-2.
     * @see {@link https://chainagnostic.org/CAIPs/caip-2}.
     */
    id: `${T}:${string}`;
};

type Namespace<T extends NamespaceName = NamespaceName> = {
    /**
     * A name of the namespace.
     */
    name: T;

    /**
     * A list of supported chains on this namespace;
     */
    chains: Chain<T>[];
};

/**
 * Supported `cip-34` chains when connecting via WalletConnect for now.
 */
const cip34Namespace: Namespace<'cip-34'> = {
    name: 'cip-34',
    chains: [
        {
            name: 'Cardano Mainnet',
            id: 'cip-34:1-764824073',
        },
    ],
};

/**
 * Ethereum Mainnet chain.
 * Note: used when dealing with Ethereum related features,
 * e.g. signing with SECP256k1 keys (used by Ethereum).
 */
export const ethereumMainnetChain: Chain<'eip155'> = {
    name: 'Ethereum Mainnet',
    id: 'eip155:1',
};

/**
 * Supported `eip155` chains when connecting via WalletConnect for now.
 */
const eip155Namespace: Namespace<'eip155'> = {
    name: 'eip155',
    chains: [
        ethereumMainnetChain,
        {
            name: 'Arbitrum One',
            id: 'eip155:42161',
        },
        {
            name: 'Optimistic Ethereum',
            id: 'eip155:10',
        },
        {
            name: 'Binance Smart Chain Mainnet',
            id: 'eip155:56',
        },
        {
            name: 'Celo Mainnet',
            id: 'eip155:42220',
        },
        {
            name: 'Harmony Mainnet Shard 0',
            id: 'eip155:1666600000',
        },
        {
            name: 'Harmony Mainnet Shard 1',
            id: 'eip155:1666600001',
        },
        {
            name: 'Harmony Mainnet Shard 2',
            id: 'eip155:1666600002',
        },
        {
            name: 'Harmony Mainnet Shard 3',
            id: 'eip155:1666600003',
        },
        {
            name: 'Klaytn Mainnet',
            id: 'eip155:8217',
        },
        {
            name: 'Polygon Mainnet',
            id: 'eip155:137',
        },
        {
            name: 'XinFin Network Mainnet',
            id: 'eip155:50',
        },
    ],
};

/**
 * Supported `elrond` chains when connecting via WalletConnect for now.
 */
const elrondNamespace: Namespace<'elrond'> = {
    name: 'elrond',
    chains: [
        {
            name: 'Elrond Mainnet',
            // Note: probably not supported by WalletConnect anymore, since missing in docs.
            id: 'elrond:1',
        },
    ],
};

/**
 * Supported `flow` chains when connecting via WalletConnect for now.
 */
const flowNamespace: Namespace<'flow'> = {
    name: 'flow',
    chains: [
        {
            name: 'Flow Mainnet',
            id: 'flow:mainnet',
        },
    ],
};

/**
 * Solana Mainnet chain.
 * Note: used when dealing with Solana related features.
 */
export const solanaMainnetChain: Chain<'solana'> = {
    name: 'Solana Mainnet',
    id: 'solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ',
};

/**
 * Supported `solana` chains when connecting via WalletConnect for now.
 */
const solanaNamespace: Namespace<'solana'> = {
    name: 'solana',
    chains: [solanaMainnetChain],
};

/**
 * Supported `stellar` chains when connecting via WalletConnect for now.
 */
const stellarNamespace: Namespace<'stellar'> = {
    name: 'stellar',
    chains: [
        {
            name: 'Stellar PubNet',
            id: 'stellar:pubnet',
        },
    ],
};

/**
 * Supported `tron` chains when connecting via WalletConnect for now.
 */
const tronNamespace: Namespace<'tron'> = {
    name: 'tron',
    chains: [
        {
            name: 'Tron Mainnet',
            // Note: probably not supported by WalletConnect anymore, since missing in docs.
            // Nevertheless this one works in Trust Wallet.
            id: 'tron:0x2b6653dc',
        },
    ],
};

/**
 * Everscale Mainnet chain.
 * Note: used when dealing with Everscale related features.
 */
export const everscaleMainnetChain: Chain<'tvm'> = {
    name: 'Everscale Mainnet',
    id: 'tvm:42',
};

/**
 * Toncoin Mainnet chain.
 * Note: used when dealing with Toncoin related features.
 */
export const tonMainnetChain: Chain<'tvm'> = {
    name: 'Toncoin Mainnet',
    /**
     * Note: not supported by WalletConnect yet, but supported by TonConnect.
     */
    id: 'tvm:-239',
};

/**
 * Supported `tvm` chains when connecting via WalletConnect for now.
 */
const tvmNamespace: Namespace<'tvm'> = {
    name: 'tvm',
    chains: [everscaleMainnetChain, tonMainnetChain],
};

/**
 * Supported `xrpl` chains when connecting via WalletConnect for now.
 */
const xrplNamespace: Namespace<'xrpl'> = {
    name: 'xrpl',
    chains: [
        {
            name: 'XRP Ledger Mainnet',
            id: 'xrpl:0',
        },
    ],
};

/**
 * Supported namespaces with their chains when connecting via WalletConnect for now.
 */
export const namespaces: Namespace[] = [
    cip34Namespace,
    eip155Namespace,
    elrondNamespace,
    flowNamespace,
    solanaNamespace,
    stellarNamespace,
    tronNamespace,
    tvmNamespace,
    xrplNamespace,
];

/**
 * Supported chain IDs when connecting via WalletConnect for now.
 */
export const chainIDs = namespaces.map(({ chains }) => chains.map(({ id }) => id)).flat();
