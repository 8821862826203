// Public constants

import type { GraphQLErrorCodesType } from '@smartfolly/server';

/**
 * Request protocols
 */
export enum Protocols {
    Http = 'http://',
    Https = 'https://',
    Ws = 'ws://',
    Wss = 'wss://',
}

// Errors

export const ServerConnectorError = {
    NoConfig: new Error('No config set'),
};

// GraphQL

// copy from @smartfolly/server
export const GraphQLErrorCodes: GraphQLErrorCodesType = {
    // unknown graphQL error
    UNKNOWN_ERROR: 1000,
    // built-in errors by https://www.apollographql.com/docs/apollo-server/data/errors
    GRAPHQL_PARSE_FAILED: 1001, // The GraphQL operation string contains a syntax error.
    GRAPHQL_VALIDATION_FAILED: 1002, // The GraphQL operation is not valid against the server's schema.
    BAD_USER_INPUT: 1003, // The GraphQL operation includes an invalid value for a field argument.
    PERSISTED_QUERY_NOT_FOUND: 1004, // A client sent the hash of a query string to execute via automatic persisted queries, but the query was not in the APQ cache.
    PERSISTED_QUERY_NOT_SUPPORTED: 1005, // A client sent the hash of a query string to execute via automatic persisted queries, but the server has disabled APQ.
    OPERATION_RESOLUTION_FAILURE: 1006, // The request was parsed successfully and is valid against the server's schema, but the server couldn't resolve which operation to run.
    BAD_REQUEST: 1007, // An error occurred before your server could attempt to parse the given GraphQL operation.
    INTERNAL_SERVER_ERROR: 1008, // An unspecified error occurred. When Apollo Server formats an error in a response, it sets the code extension to this value if no other code is set.
    // add custom errors below
    READING_DB_ERROR: 1009,
    INCORRECT_FILTER: 1010,
};
