import { CommonStore } from '@smartfolly/common.utilities';
import type { Token } from '@smartfolly/frontend.assets-service';
import { RatesStore } from '@smartfolly/frontend.assets-service/src/module/stores';
import type { Tokens } from '@smartfolly/frontend.currencies-service';
import { computed, makeObservable } from 'mobx';

export class TokenPriceStore extends CommonStore {
    private readonly token: Tokens;

    private readonly ratesStore: RatesStore;

    public constructor(token: Tokens) {
        super();

        if (!token) {
            throw new Error('Token is required');
        }
        this.token = token;
        this.ratesStore = new RatesStore({ userId: 'TOKEN_PRICE_STORE_USER_ID' });
        this.ratesStore.selectListenedTokens([token]);
        this.ratesStore.selectExchangeCurrency('USD');

        makeObservable(this, { info: computed });
    }

    protected onLoad = async () => {
        return this.ratesStore.load();
    };

    protected onUnload = async () => {
        return this.ratesStore.unload();
    };

    public get info(): Token | undefined {
        return this.ratesStore.enrichTokens([this.token])[0];
    }
}
