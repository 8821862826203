import {
    ETHKeys,
    generateETHKeys,
    publicKeyToAddress,
    signMessageWithKey,
} from '@smartfolly/common.utilities';

import { ethereumMainnetChain, ProvidersError } from '../constants';

import type {
    GetAccountsResponse,
    IProvider,
    ProviderInfo,
    SignMessageOptions,
    SignMessageResponse,
} from '../types';

/**
 * Helper to generate the link to Telegram bot with a start parameter.
 * @param start - a start parameter to open the bot with.
 */
export function generateLinkToTelegramBot(start: string): string {
    return `http://t.me/Mooli_App_Bot?start=${start}`;
}

export class TelegramBotProvider implements IProvider<'TelegramBot'> {
    // Properties

    /**
     * A private ETH key pair used to validate the auth requests to Telegram and the server.
     */
    private keyPair?: ETHKeys;

    // Interface

    // eslint-disable-next-line class-methods-use-this
    public async checkIfAvailable(): Promise<boolean> {
        // Consider it to be always available, since the bot should be always available
        return true;
    }

    public async disconnect(): Promise<void> {
        // Note: there is no real way to disconnect from the client side
        // Instead we just clean the keyPair if it exists

        if (this.keyPair) {
            delete this.keyPair;
        }
    }

    public async getAccounts(): Promise<GetAccountsResponse> {
        // Check for the keypair
        if (!this.keyPair) {
            this.keyPair = await generateETHKeys();
        }

        // Get the dummy ETH-accounts from the temporary keypair
        // Note: it will be used on a backend side to validate the requests
        const accounts = [
            {
                address: await publicKeyToAddress(this.keyPair.publicKey),
                // Set the chain as Ethereum Mainnet taken from the WalletConnect related constants
                // since this chain ID is general and can be used anywhere as CAIP-2 standard value
                chainId: ethereumMainnetChain.id,
            },
        ];

        // Return the accounts
        return accounts;
    }

    // eslint-disable-next-line class-methods-use-this
    public async getInfo(): Promise<ProviderInfo<'TelegramBot'>> {
        return {
            name: 'TelegramBot',
        };
    }

    public async signMessage({
        message,
        ethAddress,
    }: SignMessageOptions): Promise<SignMessageResponse> {
        // Check for the keypair which must be present
        if (!this.keyPair) {
            throw ProvidersError.TelegramBot.NoCryptoKeys;
        }

        // Ensure the ETH-address correspond to the keypair
        if (ethAddress !== (await publicKeyToAddress(this.keyPair.publicKey))) {
            throw ProvidersError.TelegramBot.WrongCryptoKeys;
        }

        // Sign the message with the given ethAddress
        const { signature } = signMessageWithKey({
            message,
            privateKey: this.keyPair.secretKey,
        });

        // Check for a signature presence
        if (!signature) {
            throw ProvidersError.TelegramBot.NoSignatureMade;
        }

        // Return if it's there
        return { signature };
    }
}
