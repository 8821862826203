// Inspired by https://github.com/christsim/multicoin-address-validator

import { Blockchains } from '../../constants';

import type { ValidatingBlockchain } from './validators';

type ValidatingBlockchainName = Extract<
    keyof typeof Blockchains,
    'ADA' | 'BTC' | 'DOGE' | 'LTC' | 'SOL'
>;

export type ValidatingBlockchains = Record<ValidatingBlockchainName, ValidatingBlockchain>;

export const validatingBlockchains: ValidatingBlockchains = {
    [Blockchains.ADA]: {
        bech32Hrp: ['addr'],
    },
    [Blockchains.BTC]: {
        addressTypes: ['00', '05'],
        bech32Hrp: ['bc'],
    },
    [Blockchains.DOGE]: {
        addressTypes: ['1e', '16'],
    },
    [Blockchains.LTC]: {
        addressTypes: ['30', '05', '32'],
        bech32Hrp: ['ltc'],
    },
    [Blockchains.SOL]: {},
};
