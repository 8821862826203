import { Protocols, serverConnector } from '@smartfolly/sdk';
import { setupLogs } from './helpers';

class ServicesLauncher {
    /**
     * A single method to start all services once at app launch.
     */
    // eslint-disable-next-line class-methods-use-this
    public async start() {
        // Setup the logs behaviour
        setupLogs();

        // Configure the server connector singleton
        serverConnector.configure({
            host: 'mooli.app:30001', // or '0.0.0.0:30000',
            queryProtocol: Protocols.Https, // or Protocols.Http,
            subscriptionProtocol: Protocols.Wss, // or Protocols.Ws,
            // Note: to work with 'http://0.0.0.0:30000' (local version of the server)
            // just run `yarn server:build` once (in order to setup MongoDB instance)
            // and then run `yarn server:launch && yarn server:local` in the repo root
        });
    }
}

export const servicesLauncher = new ServicesLauncher();
