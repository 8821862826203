import { Log } from '@smartfolly/common.utilities';

import type {
    Asset,
    AssetListener,
    AssetListenerDisposer,
    IAssetsManager,
} from '@smartfolly/middleware.assets-manager';

import type { IAssetScanner } from '../types';

const log = new Log('AssetScanner');

type AssetSubscription = {
    /**
     * A disposer created for a reaction subscribed on the asset changes.
     */
    disposer: () => void;

    /**
     * Listeners which listen to the asset changes.
     */
    listeners: AssetListener[];
};

export type AssetScannerOptions = {
    /**
     * An instance of AssetsManager to deal with the assets.
     */
    assetsManager: IAssetsManager;
};

export class AssetScanner implements IAssetScanner {
    // Properties

    /**
     * An instance of the AssetsManager to work with when scanning assets.
     */
    private assetsManager: IAssetsManager;

    // Constructor

    public constructor({ assetsManager }: AssetScannerOptions) {
        this.assetsManager = assetsManager;
    }

    // Interface

    public get(assetId: string): Asset | undefined {
        return this.assetsManager.allAssets[assetId];
    }

    /**
     * Currently active subscriptions.
     */
    private subscriptions: { [assetId: string]: AssetSubscription } = {};

    public subscribe(assetId: string, listener: AssetListener): AssetListenerDisposer {
        // Create a subscription to the asset or add a listener to a currently active subscription
        if (!this.subscriptions[assetId]) {
            // Make a list of listeners which should belong to the asset subscription
            const listeners = [listener];
            // Create a subscription to the specified asset
            const disposer = this.assetsManager.subscribeToAsset(assetId, (...args) => {
                // Broadcast the data to all listeners
                listeners.forEach(callback => callback(...args));
            });
            // Save the subscription disposer and related listers
            this.subscriptions[assetId] = { disposer, listeners };
        } else {
            // Add a new listener to the subscription
            this.subscriptions[assetId]!.listeners.push(listener);
        }

        // Make a disposer to remove the subscription only when it's needed
        return () => {
            if (!this.subscriptions[assetId]) {
                // Normally should never happen, since the subscription must
                // be removed only once all the listeners are removed from it
                log.error(
                    'Failed to dispose a subscription with a listener due to missing subscription',
                );
                return;
            }

            // Remove a listener from the subscription first
            const index = this.subscriptions[assetId]!.listeners.indexOf(listener);
            if (index !== -1) {
                this.subscriptions[assetId]!.listeners.splice(index, 1);
            } else {
                // Normally should never happen, since the listener must be removed only once
                log.error('Failed to remove an already removed listener from the subscription');
            }

            // Check if there are listeners still present for the subscription
            if (!this.subscriptions[assetId]!.listeners.length) {
                // Dispose the reaction if no listeners left
                const { disposer } = this.subscriptions[assetId]!;
                disposer();

                // And remove the subscription itself since nobody listens to it
                delete this.subscriptions[assetId];
            }
        };
    }
}
