import 'bootstrap/dist/css/bootstrap.min.css';
import '@smartfolly/frontend.web-ui/assets/styles/index.scss';

import './assets/styles/common.scss';
import { currenciesService } from '@smartfolly/frontend.currencies-service';
import type { Tokens } from '@smartfolly/frontend.currencies-service';
import { useScript } from '@smartfolly/frontend.web-ui';

import { Landing } from './pages/Landing';
import { TokenPriceStore } from './stores/TokenStore';

import { config } from './config';
import { pirschCode } from '../configs';

const tokenPriceStore = new TokenPriceStore(
    currenciesService.tokens[config.token as keyof typeof Tokens],
);

tokenPriceStore.load();

const pirschScriptOptions = {
    defer: true,
    id: 'pirschjs',
    dataCode: pirschCode[config.blockchain as keyof typeof pirschCode],
};

function App() {
    useScript('https://api.pirsch.io/pirsch.js', pirschScriptOptions);

    return <Landing token={tokenPriceStore.info} />;
}

export default App;
