import {
    GetAccountsResponse,
    SolflareProvider as Provider,
    ProviderInfo,
    ProvidersError,
} from '@smartfolly/common.providers';

import { AssetsManagerError } from '../../../constants';

import type { IAddressProvider } from './types';

type Func<T, P> = (...args: P[]) => Promise<T>;
type WrappedFunc<T, P> = Func<T, P>;

const withErrorsMapperWrapper =
    <T, P, C>(fn: Func<T, P>, context: C): WrappedFunc<T, P> =>
    async (...args: P[]) => {
        try {
            // Run the original function
            return await fn.bind(context)(...args);
        } catch (error) {
            // Map the errors
            if (error === ProvidersError.Solana.NoAccountFound) {
                throw AssetsManagerError.Providers.Solflare.NoAccountFound;
            } else if (error === ProvidersError.Solana.NoProviderFound) {
                throw AssetsManagerError.Providers.Solflare.NoProviderFound;
            } else {
                throw error;
            }
        }
    };

class SolflareProvider implements IAddressProvider<'Solflare'> {
    // Properties

    /**
     * A private instance of Solflare provider
     */
    private solflareProvider = new Provider();

    // Interface

    public async checkIfAvailable(): Promise<boolean> {
        return withErrorsMapperWrapper(
            this.solflareProvider.checkIfAvailable,
            this.solflareProvider,
        )();
    }

    public async disconnect(): Promise<void> {
        return withErrorsMapperWrapper(this.solflareProvider.disconnect, this.solflareProvider)();
    }

    public async getAccounts(): Promise<GetAccountsResponse> {
        return withErrorsMapperWrapper(this.solflareProvider.getAccounts, this.solflareProvider)();
    }

    public async getInfo(): Promise<ProviderInfo<'Solflare'>> {
        return withErrorsMapperWrapper(this.solflareProvider.getInfo, this.solflareProvider)();
    }
}

export const solflareProvider = new SolflareProvider();
