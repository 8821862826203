import type { UsersQueryParameters, UsersQueryResponse } from '@smartfolly/server';

import { Log } from '@smartfolly/common.utilities';

import { serverConnector } from '@smartfolly/middleware.server-connector';

import { usersAddressesQuery, usersCollection } from '../../constants';

import type { ProvidedAddresses } from '../../types';

const log = new Log('user-manager:queryProvidedAddresses');

/**
 * A function to query all provided addresses by the user.
 *  @param userId - an ID of the user to query the provided addresses for.
 * @returns the map of all provided address.
 */
export async function queryProvidedAddresses(userId: string): Promise<ProvidedAddresses> {
    const response = await serverConnector.query<
        UsersQueryParameters,
        UsersQueryResponse<'providedAddresses'>
    >({
        collection: usersCollection,
        query: usersAddressesQuery,
        variables: {
            users: [userId],
        },
    });

    // Check for errors in response
    if ('error' in response) {
        const { error } = response;
        // TODO: process `errorCode` if needed
        log.error('Failed to get the provided addresses:', response);
        throw new Error(error);
    }

    // Check if the response has users
    if (response.length === 0) {
        const error = new Error('No user found');
        log.error('Failed to get the provided addresses:', error);
        throw error;
    }

    // Pick the first user (should be the only one for the "userId")
    const user = response[0]!;

    // Return the found user addresses converting the response into the map
    return user.providedAddresses.reduce<ProvidedAddresses>((acc, { address, ...rest }) => {
        acc[address] = rest;
        return acc;
    }, {});
}
