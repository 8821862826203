import { computed, makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

import {
    appStorage,
    buildStorageKeyForUser,
    CommonStore,
    DocumentLoaderListener,
    Log,
    MemoDocumentLoader,
} from '@smartfolly/common.utilities';

import {
    ProvidedExchange,
    ProvidedExchanges,
    ProvidedExchangeSourceData,
    ProvidedExchangeSourceInfo,
    Asset,
    UserExchangesListenerDisposer,
    IUserManager,
    UserManager,
} from '@smartfolly/sdk';

import type { IAuthService } from '@smartfolly/frontend.auth-service';
import type { Blockchains } from '@smartfolly/frontend.currencies-service';

import type { ExchangeAssetWithExchangeData, IExchangesStore } from '../types';

const log = new Log('AssetsService:Exchanges');

const ALL_EXCHANGES_KEY = 'AssetsService:AllExchanges';

type ExchangesStoreOptions =
    | {
          /**
           * An instance of AuthService with the user to work with when dealing with the assets.
           * Note: the passed service MUST be loaded and initialized, i.e. be ready to work with.
           */
          authService: IAuthService;
      }
    | {
          /**
           * An ID of the user to work with when dealing with the assets.
           */
          userId: string;
      };

export class ExchangesStore extends CommonStore implements IExchangesStore {
    // Properties

    /**
     * Options the store is created with.
     */
    private options: ExchangesStoreOptions;

    /**
     * An instance of the UserManager to work with when dealing with the user exchanges.
     */
    private userManager: IUserManager;

    /**
     * A private observable map of all user exchanges provided by {@link userManager}.
     */
    private allExchangesMap: ProvidedExchanges = {};

    /**
     * A private observable instance of the `isEditingExchangeInfo` flag.
     */
    private isEditingExchangeInfoFlag: boolean = false;

    /**
     * A memo loader of all user exchanges.
     */
    private memoExchangesLoader?: MemoDocumentLoader<ProvidedExchanges>;

    /**
     * A listener to update all user exchanges.
     */
    private exchangesLoaderListener?: DocumentLoaderListener<ProvidedExchanges>;

    /**
     * A GraphQL User Exchanges subscription disposer.
     */
    private subscriptionDisposer?: UserExchangesListenerDisposer;

    // Constructor

    public constructor(options: ExchangesStoreOptions) {
        super();

        this.options = options;

        this.userManager = new UserManager(
            'authService' in options
                ? { userAuth: options.authService.userAuth }
                : { userId: options.userId },
        );

        makeObservable<
            ExchangesStore,
            'allExchanges' | 'allExchangesMap' | 'isEditingExchangeInfoFlag'
        >(this, {
            allExchanges: computed,
            allExchangesMap: observable,
            isEditingExchangeInfo: computed,
            isEditingExchangeInfoFlag: observable,
            providedExchanges: computed,
        });
    }

    // Getters & Setters

    /**
     * A computed map of all user exchanges.
     */
    private get allExchanges(): ProvidedExchanges {
        return this.allExchangesMap;
    }

    /**
     * Set the map of all user exchanges.
     */
    private set allExchanges(allExchanges: ProvidedExchanges) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.allExchangesMap = allExchanges;
    }

    // Interface

    protected onLoad = async () => {
        // Load the user exchanges
        this.loadExchanges();
    };

    protected onUnload = async () => {
        // Unload the user exchanges
        this.unloadExchanges();
    };

    public get providedExchanges(): ProvidedExchange[] {
        return Object.keys(this.allExchanges).map(sourceId => {
            const data = this.allExchanges[sourceId]!;

            return { sourceId, ...data };
        });
    }

    public get isEditingExchangeInfo(): boolean {
        return this.isEditingExchangeInfoFlag;
    }

    private set isEditingExchangeInfo(isEditingExchangeInfo: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.isEditingExchangeInfoFlag = isEditingExchangeInfo;
    }

    public enrichAssets = computedFn(
        (
            assets: Asset[],
        ): (Extract<Asset, { blockchain: Blockchains }> | ExchangeAssetWithExchangeData)[] => {
            return assets.map<
                Extract<Asset, { blockchain: Blockchains }> | ExchangeAssetWithExchangeData
            >(asset => {
                // Enrich the asset with the exchange data if it's present
                const exchangeData = 'sourceId' in asset && this.allExchanges[asset.sourceId];
                if (exchangeData) {
                    return {
                        ...asset,
                        exchangeData,
                    };
                }

                // Return the asset unchanged if there is no exchange data
                return asset;
            });
        },
    );

    public addExchangeData = (
        sourceId: string,
        { exchange, info, keys }: Pick<ProvidedExchangeSourceData, 'exchange' | 'info' | 'keys'>,
    ) => {
        // Check if the exchanges are still listened to by the loader
        if (!this.exchangesLoaderListener) {
            // No way to update the exchanges
            return;
        }

        // Add and exchange with the info to local user exchanges
        const updatedExchanges = { ...this.allExchanges }; // copy

        // Add the exchange with the info
        updatedExchanges[sourceId] = {
            created: Date.now(), // this timestamp will be taken from the backend later
            exchange,
            keys: { apiKey: keys.apiKey }, // store only the "apiKey" with no secrets
            ...(info ? { info } : {}),
        };

        // Update all user exchanges via the listener
        this.exchangesLoaderListener(undefined, updatedExchanges);
    };

    public editExchangeInfo = async (
        sourceId: string,
        info: ProvidedExchangeSourceInfo,
    ): Promise<boolean> => {
        // Mark an exchange info is being edited
        this.isEditingExchangeInfo = true;
        try {
            // Edit an exchange info
            const edited = await this.userManager.editExchangeInfo(sourceId, info);
            log.debug('Editing result:', edited);

            if (!edited) {
                // Return a negative result of the operation
                return false;
            }

            // Check if the exchanges are still listened to by the loader
            if (!this.exchangesLoaderListener) {
                // Return a positive result of the operation, but do not update the exchanges
                return true;
            }

            // Edit info of the corresponding local user exchange
            const updatedExchanges = { ...this.allExchanges }; // copy
            const exchangeToUpdate = updatedExchanges[sourceId];
            if (!exchangeToUpdate) {
                // Return a positive result of the operation, but do not update the exchanges,
                // as there is nothing to update
                return true;
            }

            // Edit the exchange info
            exchangeToUpdate.info = info;

            // Update all user exchanges via the listener
            this.exchangesLoaderListener(undefined, updatedExchanges);

            // Return the positive result of the operation
            return true;
        } catch (error) {
            log.error('Failed to remove an exchange with error:', error);

            throw error;
        } finally {
            // Mark an exchange is not being edited anymore
            this.isEditingExchangeInfo = false;
        }
    };

    public removeExchangeData = (sourceId: string): void => {
        // Check if the exchanges are still listened to by the loader
        if (!this.exchangesLoaderListener) {
            // No way to update the exchanges
            return;
        }

        // Exclude the corresponding local user exchange
        const updatedExchanges = { ...this.allExchanges }; // copy
        const exchangeToUpdate = updatedExchanges[sourceId];
        if (!exchangeToUpdate) {
            // Do nothing as there is nothing to update
            return;
        }

        // Remove the exchange
        delete updatedExchanges[sourceId];

        // Update all user exchanges via the listener
        this.exchangesLoaderListener(undefined, updatedExchanges);
    };

    // Internals

    /**
     * Method to load user exchanges using memo loader.
     * @throws a variety of errors if the user is not authorized or exchanges are already loading.
     */
    private loadExchanges() {
        // Check if the exchanges loader already exist to avoid loading the store twice
        if (this.memoExchangesLoader) {
            throw new Error('Exchanges are already loading');
        }

        // Get a storage key to memo the exchanges
        // Note: could throw if the user is not authorized
        const storageKey = this.getStorageKey(ALL_EXCHANGES_KEY);

        // Get all user exchanges via memo loader
        this.memoExchangesLoader = new MemoDocumentLoader<ProvidedExchanges>({
            key: storageKey,
            storage: appStorage,
            documentFetcher: (listener: DocumentLoaderListener<ProvidedExchanges>) => {
                // Save the listener in order to reflect exchanges changes made by other methods
                this.exchangesLoaderListener = listener;

                // Get all the user exchanges from the backend
                (async () => {
                    try {
                        const allExchanges = await this.userManager.getProvidedExchanges();
                        listener(undefined, allExchanges);
                    } catch (error) {
                        listener(error);
                    }
                })();

                // Subscribe to the server to update the provided exchanges
                this.subscriptionDisposer = this.userManager.subscribeToExchanges(
                    (error: Error | undefined, exchanges?: ProvidedExchanges | null) => {
                        if (error) {
                            listener(error);
                        }

                        if (exchanges) {
                            listener(undefined, exchanges);
                        }
                    },
                );
            },
            stopDocumentFetching: () => {
                // Dispose the subscription if any
                if (this.subscriptionDisposer) {
                    this.subscriptionDisposer();
                    delete this.subscriptionDisposer;
                }

                // Delete the exchanges listener
                delete this.exchangesLoaderListener;
            },
        });

        // Listen to all user exchanges changes
        this.memoExchangesLoader.subscribe(
            (error: Error | undefined, allExchanges?: ProvidedExchanges) => {
                if (error) {
                    log.error('Failed to fetch all user exchanges with error:', error);
                    return;
                }

                if (!allExchanges) {
                    log.error('No exchanges have been loaded');
                    return;
                }

                this.allExchanges = allExchanges;
            },
        );
    }

    /**
     * Methods to unload user exchanges with the memo loader.
     */
    private unloadExchanges() {
        // Unsubscribe from the memo exchanges loader
        if (this.memoExchangesLoader) {
            this.memoExchangesLoader.unsubscribe();

            // And delete the loader itself
            delete this.memoExchangesLoader;
        }

        // Remove all exchanges
        this.allExchanges = {};
    }

    /**
     * Method to get a storage key for the current user and a specified key.
     * @param key - a provided key to get the storage key for.
     * @returns the key bound to the current user.
     */
    private getStorageKey(key: string): string {
        if ('userId' in this.options) {
            return buildStorageKeyForUser(key, this.options.userId);
        }

        return this.options.authService.getStorageKey(key);
    }
}
