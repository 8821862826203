export const ProvidersError = {
    MetaMaskExt: {
        NoAccountFound: new Error('No account provided by MetaMask extension'),
        NoProviderFound: new Error('No MetaMask Extension provider available'),
        NoSignatureMade: new Error('No signature made by MetaMask extension'),
    },
    Solana: {
        NoAccountFound: new Error('No account provided by this Solana providerr'),
        NoProviderFound: new Error('No this Solana provider available'),
        NoSignatureMade: new Error('No signature made by this Solana provider'), // not supported
    },
    SurfKeeperExt: {
        NoAccountFound: new Error('No account provided by SurfKeeper extension'),
        NoProviderFound: new Error('No SurfKeeper Extension provider available'),
        NoSignatureMade: new Error('No signature made by SurfKeeper extension'), // not supported
    },
    TonConnect: {
        NoAccountFound: new Error('No account provided by TonConnect'),
        NoSignatureMade: new Error('No signature made by Ton Connect'), // not supported
        NotReadyToInitialize: new Error('Not ready to initialize the TonConnect client'),
        QRCodeClosed: new Error('QRCode modal has been closed for TonConnect'),
    },
    WalletConnect: {
        AccountUnrecognized: new Error('Account is unrecognized and belongs to unknown chain ID'),
        NoAccountFound: new Error('No account provided by WalletConnect'),
        NoSignatureMade: new Error('No signature made by WalletConnect'),
        NotConnected: new Error('Not connected via WalletConnect'),
        QRCodeClosed: new Error('QRCode modal has been closed for WalletConnect'),
    },
    TelegramBot: {
        NoCryptoKeys: new Error('No crypto keys to work with'),
        NoSignatureMade: new Error('No signature made for Telegram Bot'),
        WrongCryptoKeys: new Error('Wrong crypto keys to work with'),
    },
};
