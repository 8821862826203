import { Button, Flex, FlexContainer, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';
import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback, useRef } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import BigNumber from 'bignumber.js';

import type { Token } from '@smartfolly/frontend.assets-service';

import { Header, Showcase } from '../components';

import { config } from '../config';

import styles from './Landing.module.scss';
import { BlockchainLandingIcon } from '../components/BlockchainLandingIcon/BlockchainLandingIcon';

const cnb = classnames.bind(styles);

const iconRight: IButtonIcon = {
    icon: <Icon icon="arrow-right-mini" />,
    animation: 'none',
};

const inputIconLeft: IButtonIcon = {
    icon: <Icon icon="arrow-right" />,
    animation: 'none',
};

const openApp = () => {
    window.open('//beta.mooli.app', '_blank');
};

const LINKS = {
    discord: '//discord.com/invite/dRJczHKmVB',
    twitter: '//twitter.com/mooli_app',
    telegram: '//t.me/mooli_app',
    medium: '//mooliapp.medium.com',
    linkedin: '//www.linkedin.com/company/mooliapp',
    github: '//github.com/mooliapp',
};

export const Landing = observer(function Landing({ token }: { token: Token | undefined }) {
    const change24h = token?.price.percentChange24h.value?.gt(new BigNumber(0))
        ? token?.price.percentChange24h.string
        : 'Daily';
    const price = token?.price.string ?? '';

    const inputRef = useRef<HTMLInputElement>(null);
    const addAddress = useCallback(() => {
        const inputValue = inputRef.current?.value;
        if (inputValue) {
            window.open(`https://beta.mooli.app/join/${inputValue}`, '_blank');
        }
    }, []);

    const onInputKeyUp = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                addAddress();
            }
        },
        [addAddress],
    );

    return (
        <>
            <Header openApp={openApp} />
            <div className={cnb('slide', 'first')}>
                <div className="container">
                    <FlexContainer direction="column" justify="center" align="center">
                        <Flex className="headline headline-biggest text-center position-relative m-t-2">
                            Stress-free <sup className={cnb('change-24h')}>{change24h}</sup>
                            <br />
                            tool to gain insight
                            <br /> into your assets
                        </Flex>
                        <Flex className="m-t-3 title title-large text-center">
                            Just unite{' '}
                            <BlockchainLandingIcon
                                icon={`${config.blockchain}-colorful`}
                                className="m-l-0.25 m-r-0.25 color-blockchain"
                            />{' '}
                            {config.tokenName} with everything
                        </Flex>
                        <Flex className={cnb('back-text')}>{price}</Flex>
                        <Flex className="m-t-8">
                            <InputGroup>
                                <Form.Control
                                    ref={inputRef}
                                    className={cnb('input-component')}
                                    type="text"
                                    onKeyUp={onInputKeyUp}
                                    placeholder={`Paste & track ${config.name} address`}
                                />
                                <Button
                                    iconLeft={inputIconLeft}
                                    onClick={addAddress}
                                    className="p-r-1"
                                />
                            </InputGroup>
                        </Flex>
                        <Flex className="paragraph paragraph-small color-text-secondary m-t-1">
                            We only request your public wallet address
                        </Flex>
                    </FlexContainer>
                </div>
            </div>
            <div className={cnb('slide')}>
                <div className="container">
                    <FlexContainer direction="column" justify="center" align="center">
                        <Flex className="title title-normal text-center m-b-2">
                            Still have doubts?
                        </Flex>
                        <Flex className="headline headline-little text-center m-b-4">
                            OK, let&#39;s look
                            <br /> inside the box
                        </Flex>
                        <Flex className={`text-center ${cnb('showcase-wrapper')}`}>
                            <Showcase
                                blockchain={config.blockchain}
                                name={config.name}
                                tokenName={config.tokenName}
                            />
                        </Flex>
                    </FlexContainer>
                </div>
            </div>
            <div className={cnb('slide')}>
                <div className="container">
                    <FlexContainer direction="column" justify="center" align="center">
                        <Flex className="title title-normal text-center m-b-2">
                            What are you waiting for?
                        </Flex>
                        <Flex className="headline headline-big text-center m-b-4">
                            Track your{' '}
                            <BlockchainLandingIcon
                                icon={`${config.blockchain}-big`}
                                className="color-blockchain m-l-0.5 m-r-0.5"
                            />{' '}
                            {config.tokenName}
                            <br /> and other cryptos
                            <br /> in one place
                        </Flex>
                        <Flex>
                            <Button
                                className="background-blockchain hover:background-blockchain b-r-6"
                                iconRight={iconRight}
                                onClick={openApp}
                            >
                                Try Mooli now
                            </Button>
                        </Flex>
                    </FlexContainer>
                </div>
            </div>
            <div className={cnb('slide')}>
                <div className="container">
                    <FlexContainer
                        direction="row"
                        justify="center"
                        align="center"
                        className={cnb('options')}
                    >
                        <Flex className={cnb('option')}>
                            <FlexContainer
                                className="position-relative"
                                direction="column"
                                justify="stretch"
                            >
                                <Flex className="title title-normal">Almost forgot!</Flex>
                                <Flex grow={1} className="headline headline-little m-t-2 m-b-2">
                                    It’s free — $0.00
                                </Flex>
                                <Flex>
                                    <div className="paragraph paragraph-small float-end back-primary p-l-1 p-r-1 p-t-1 p-b-1 b-r-3">
                                        Because this is an early beta stage.
                                        <br /> We appreciate your feedback to make
                                        <br /> Mooli more valuable.
                                    </div>
                                </Flex>
                            </FlexContainer>
                        </Flex>
                        <Flex className={cnb('option', 'selected')}>
                            <FlexContainer direction="column" justify="center" align="start">
                                <Flex>
                                    <ul className="paragraph paragraph-large">
                                        <li>Unlimited wallets and boards</li>
                                        <li>20+ blockchains</li>
                                        <li>8K+ tokens</li>
                                        <li>{config.name} & Bitcoin on-board</li>
                                        <li>WalletConnect 2.0</li>
                                        <li>Anonymous sessions</li>
                                        <li>Key statistics</li>
                                    </ul>
                                </Flex>
                                <Flex>
                                    <Button
                                        className="btn-link m-t-1"
                                        iconRight={iconRight}
                                        onClick={openApp}
                                    >
                                        Start for free
                                    </Button>
                                </Flex>
                            </FlexContainer>
                        </Flex>
                    </FlexContainer>
                </div>
            </div>
            <div className={cnb('slide', 'last')}>
                <div className="container">
                    <FlexContainer direction="column" justify="center" align="center">
                        <Flex grow={1} className="m-b-10 headline headline-little text-center">
                            Your experience
                            <br /> matters to us,
                            <br /> so let us know
                            <br /> what you think
                            <br /> in our{' '}
                            <a href={LINKS.discord} className="position-relative">
                                Discord{' '}
                                <Icon icon="discord-big" className="position-absolute m-l-0.5" />
                            </a>
                        </Flex>
                        <Flex className="m-b-2">
                            <FlexContainer direction="row" justify="center" align="center">
                                <Flex className="m-r-1">
                                    <a
                                        href={LINKS.discord}
                                        className="text-white hover:color-blockchain"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Icon icon="discord" />
                                    </a>
                                </Flex>
                                <Flex className="m-r-1 m-l-1">
                                    <a
                                        href={LINKS.telegram}
                                        className="text-white hover:color-blockchain"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Icon icon="telegram" />
                                    </a>
                                </Flex>
                                <Flex className="m-r-1 m-l-1">
                                    <a
                                        href={LINKS.twitter}
                                        className="text-white hover:color-blockchain"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Icon icon="twitter" />
                                    </a>
                                </Flex>
                                <Flex className="m-r-1 m-l-1">
                                    <a
                                        href={LINKS.medium}
                                        className="text-white hover:color-blockchain"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Icon icon="medium" />
                                    </a>
                                </Flex>
                                <Flex className="m-r-1 m-l-1">
                                    <a
                                        href={LINKS.linkedin}
                                        className="text-white hover:color-blockchain"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Icon icon="linkedin" />
                                    </a>
                                </Flex>
                                <Flex className="m-l-1">
                                    <a
                                        href={LINKS.github}
                                        className="text-white hover:color-blockchain"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Icon icon="github" />
                                    </a>
                                </Flex>
                            </FlexContainer>
                        </Flex>
                        <Flex className="m-b-2 action action-normal">
                            or say, <a href="mailto:hello@mooli.app">hello@mooli.app</a>
                        </Flex>
                        <Flex className="paragraph paragraph-small m-b-1">
                            © {new Date().getFullYear()}
                        </Flex>
                    </FlexContainer>
                </div>
            </div>
        </>
    );
});
