import { action, computed, makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { appStorage, buildStorageKeyForUser, CommonStore, Log } from '@smartfolly/common.utilities';

import type { IAuthService } from '@smartfolly/frontend.auth-service';
import { currenciesService } from '@smartfolly/frontend.currencies-service';

import {
    areAllAssetsWithLowPrice,
    doesAssetCorrespondsToFilteringOptions,
    filterGroupsExcludingAssetsWithLowPrice,
    groupAssetsByBlockchains,
    groupAssetsByExchanges,
    groupAssetsByTokens,
    groupAssetsByWallets,
    sortGroupsByTotalPrice,
} from '../../utils';

import type {
    Asset,
    BlockchainGroup,
    ExchangeGroup,
    FilteringOptions,
    Group,
    GroupedAssets,
    TokenGroup,
    WalletGroup,
} from '../../types';

import type { IFiltersStore } from '../types';

const log = new Log('AssetsService:Filters');

const FILTERS_KEY = 'AssetsService:Filters';

const ARE_LOW_PRICES_HIDDEN_KEY = 'AssetsService:AreLowPricesHidden';

/**
 * A default `areLowPricesHidden` flag value.
 * Note: it's `true` by the design.
 */
const DEFAULT_ARE_LOW_PRICES_HIDDEN_VALUE: boolean = true;

type FiltersStoreOptions =
    | {
          /**
           * An instance of AuthService with the user to work with when dealing with the assets.
           * Note: the passed service MUST be loaded and initialized, i.e. be ready to work with.
           */
          authService: IAuthService;
      }
    | {
          /**
           * An ID of the user to work with when dealing with the assets.
           */
          userId: string;
      };

export class FiltersStore extends CommonStore implements IFiltersStore {
    // Properties

    /**
     * Options the store is created with.
     */
    private options: FiltersStoreOptions;

    /**
     * A private observable variable of applied filtering options to filter assets.
     */
    private filteringOptions: FilteringOptions = {};

    /**
     * A private observable instance of the `areLowPricesHidden` flag.
     */
    private areLowPricesHiddenFlag: boolean = DEFAULT_ARE_LOW_PRICES_HIDDEN_VALUE;

    // Constructor

    public constructor(options: FiltersStoreOptions) {
        super();

        this.options = options;

        makeObservable<
            FiltersStore,
            'filteringOptions' | 'applyFilters' | 'areLowPricesHiddenFlag'
        >(this, {
            filteringOptions: observable,
            applyFilters: action,
            appliedFilters: computed,
            areLowPricesHidden: computed,
            areLowPricesHiddenFlag: observable,
        });
    }

    // Interface

    protected onLoad = async () => {
        await Promise.all([
            // Try to load saved assets filters.
            this.loadSavedFilters().catch(error => {
                // Note: This method should never fail to avoid failing the assets service loading,
                // since it's OK to fail loading filters as it doesn't affect the service workflow.
                log.error('Failed to load saved filters with error:', error);
            }),

            // Load the `areLowPricesHidden` flag from the storage
            this.loadAreLowPricesHiddenFlag().catch(error => {
                // Note: This method should never fail to avoid failing the assets service loading,
                // since it's OK to fail loading filters as it doesn't affect the service workflow.
                log.error('Failed to load `areLowPricesHidden` flag with error:', error);
            }),
        ]);
    };

    protected onUnload = async () => {
        // Reset filters
        this.resetFilters();

        // Unload the `areLowPricesHidden` flag
        this.unloadAreLowPricesHiddenFlag();
    };

    public filter = async (options: FilteringOptions) => {
        // Apply MobX action to filter assets
        this.applyFilters(options);

        // Save filters
        try {
            await this.saveFilters(options);
        } catch (error) {
            // Note: it's OK to fail saving filters as it doesn't affect the service workflow.
            log.error('Failed to save filters with error:', error);
        }
    };

    public filterTokens = computedFn(
        (assets: Asset[]): GroupedAssets<TokenGroup> =>
            // Group the assets by tokens checking if the asset should be included in such groups
            this.groupTokens(this.filterAssets(assets)),
    );

    public filterBlockchains = computedFn(
        (assets: Asset[]): GroupedAssets<BlockchainGroup> =>
            // Group the assets by blockchains checking if the asset should be included in such groups
            this.groupBlockchains(this.filterAssets(assets)),
    );

    public filterExchanges = computedFn(
        (assets: Asset[]): GroupedAssets<ExchangeGroup> =>
            // Group the assets by exchanges checking if the asset should be included in such groups
            this.groupExchanges(this.filterAssets(assets)),
    );

    public filterWallets = computedFn(
        (assets: Asset[]): GroupedAssets<WalletGroup> =>
            // Group the assets by wallets checking if the asset should be included in such groups
            this.groupWallets(this.filterAssets(assets)),
    );

    public filterAssets = computedFn((assets: Asset[]) =>
        // Check if should include an asset
        assets.filter(asset =>
            doesAssetCorrespondsToFilteringOptions(
                asset,
                this.appliedFilters,
                currenciesService.stablecoins,
            ),
        ),
    );

    public groupTokens = computedFn(
        (assets: Asset[]): GroupedAssets<TokenGroup> =>
            // Group the assets by tokens
            // and filter by excluding assets with the low price if needed
            this.filterGroupsExcludingAssetsWithLowPriceIfNeeded(groupAssetsByTokens(assets)),
    );

    public groupBlockchains = computedFn(
        (assets: Asset[]): GroupedAssets<BlockchainGroup> =>
            // Group the assets by blockchains
            // and filter by excluding assets with the low price if needed
            this.filterGroupsExcludingAssetsWithLowPriceIfNeeded(groupAssetsByBlockchains(assets)),
    );

    public groupExchanges = computedFn(
        (assets: Asset[]): GroupedAssets<ExchangeGroup> =>
            // Group the assets by exchanges
            // and filter by excluding assets with the low price if needed
            this.filterGroupsExcludingAssetsWithLowPriceIfNeeded(groupAssetsByExchanges(assets)),
    );

    public groupWallets = computedFn(
        (assets: Asset[]): GroupedAssets<WalletGroup> =>
            // Group the assets by wallets
            // and filter by excluding assets with the low price if needed
            this.filterGroupsExcludingAssetsWithLowPriceIfNeeded(groupAssetsByWallets(assets)),
    );

    public get appliedFilters(): FilteringOptions {
        return this.filteringOptions;
    }

    public toggleHidingLowPrices = async (hideLowPrices: boolean) => {
        // Apply MobX action to set the corresponding flag
        this.areLowPricesHidden = hideLowPrices;

        // Save it to the storage
        try {
            await this.saveAreLowPricesHiddenFlag(hideLowPrices);
        } catch (error) {
            // Note: it's OK to fail saving this flag
            // as it doesn't affect the service workflow.
            log.error('Failed to save the `areLowPricesHidden` flag with error:', error);
        }
    };

    public get areLowPricesHidden(): boolean {
        return this.areLowPricesHiddenFlag;
    }

    private set areLowPricesHidden(areLowPricesHidden: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.areLowPricesHiddenFlag = areLowPricesHidden;
    }

    // Internals

    /**
     * Method to filter groups by excluding assets with the low price if needed.
     * Note: the method also sorts the resulted groups by their total price value.
     */
    private filterGroupsExcludingAssetsWithLowPriceIfNeeded = computedFn(
        <T extends Group>(groups: T[]): GroupedAssets<T> => {
            // Check if need to filter the groups
            if (
                // Check the flag if we need to hide asset with the low price
                !this.areLowPricesHidden ||
                // Also do not hide assets with the low price if all of them have the low price
                areAllAssetsWithLowPrice(groups.flatMap(({ assets }) => assets))
            ) {
                // No need to filter, just sort the groups
                return {
                    groups: sortGroupsByTotalPrice(groups),
                };
            }

            // Filter the groups
            const { filteredGroups, excludedGroups } =
                filterGroupsExcludingAssetsWithLowPrice(groups);

            // Sort the filtered groups
            return {
                groups: sortGroupsByTotalPrice(filteredGroups),
                ...(excludedGroups ? { hiddenGroups: sortGroupsByTotalPrice(excludedGroups) } : {}),
            };
        },
    );

    /**
     * Method to load the `areLowPricesHidden` flag from the storage.
     */
    private async loadAreLowPricesHiddenFlag() {
        // Get a storage key to keep the `areLowPricesHidden` flag
        // Note: could throw if the user is not authorized
        const storageKey = this.getStorageKey(ARE_LOW_PRICES_HIDDEN_KEY);

        // Get a stored `areLowPricesHidden` flag value
        const areLowPricesHidden = await appStorage.getItem(storageKey);

        // Set the `areLowPricesHidden` flag to the stored one if present.
        if (areLowPricesHidden) {
            this.areLowPricesHidden = JSON.parse(areLowPricesHidden) as boolean;
        }
    }

    /**
     * Method to unload the `areLowPricesHidden` flag  to its default state.
     */
    private unloadAreLowPricesHiddenFlag() {
        this.areLowPricesHidden = DEFAULT_ARE_LOW_PRICES_HIDDEN_VALUE;
    }

    /**
     * Method to save the `areLowPricesHidden` flag value in the storage.
     * @param areLowPricesHidden - the `areLowPricesHidden` flag value value to store.
     */
    // eslint-disable-next-line class-methods-use-this
    private async saveAreLowPricesHiddenFlag(areLowPricesHidden: boolean) {
        // Get a storage key to keep the `areLowPricesHidden` flag
        // Note: could throw if the user is not authorized
        const storageKey = this.getStorageKey(ARE_LOW_PRICES_HIDDEN_KEY);

        // Save the selected exchange currency into the storage
        await appStorage.setItem(storageKey, areLowPricesHidden.toString());
    }

    /**
     * Method to apply filtering options in order to filter asset groups.
     * Note: it's a MobX action method.
     * @param options - filtering options to apply.
     */
    private applyFilters(options: FilteringOptions): void {
        this.filteringOptions = options;
    }

    /**
     * Method to save filtering options.
     * @param options - filtering options to save.
     */
    // eslint-disable-next-line class-methods-use-this
    private async saveFilters(options: FilteringOptions): Promise<void> {
        // Get a storage key to store the filters
        // Note: could throw if the user is not authorized
        const key = this.getStorageKey(FILTERS_KEY);

        // Save the filters into the storage
        await appStorage.setItem(key, JSON.stringify(options));
    }

    /**
     * Method to reset applied filters.
     */
    private resetFilters() {
        this.applyFilters({});
    }

    /**
     * Method to load saved filters from the storage.
     */
    private async loadSavedFilters() {
        // Get a storage key to load the filters
        // Note: could throw if the user is not authorized
        const key = this.getStorageKey(FILTERS_KEY);

        // Get the filters from the storage
        const filters = await appStorage.getItem(key);

        // Parse them and apply if present
        if (filters) {
            const options = JSON.parse(filters) as FilteringOptions;
            this.applyFilters(options); // Note: do not use `filter()` to avoid re-saving them
        }
    }

    /**
     * Method to get a storage key for the current user and a specified key.
     * @param key - a provided key to get the storage key for.
     * @returns the key bound to the current user.
     */
    private getStorageKey(key: string): string {
        if ('userId' in this.options) {
            return buildStorageKeyForUser(key, this.options.userId);
        }

        return this.options.authService.getStorageKey(key);
    }
}
