import { gql } from '@apollo/client';

// Public constants

// Enums

/**
 * Kinds of board templates.
 * Note: same enum as on the backend.
 */
export enum BoardTemplates {
    /**
     * The board kind should include all user assets,
     * i.e. no filters are applied.
     * Note: this board kind may not be created on the backend side
     * and moreover MUST be present only in one instance by design.
     */
    Total = 'total',

    /**
     * This board kind should include only some "Core" tokens,
     * e.g. `[BTC,ETH,USDT,USDC].includes(token)`
     */
    CoreCrypto = 'coreCrypto',

    /**
     * This board kind should include only Stablecoins tokens,
     * i.e. `tokenType === "Stablecoin"`.
     */
    Stablecoins = 'stablecoins',

    /**
     * This board kind should include only Staked tokens,
     * i.e. `contractType === "StakingPool"`.
     */
    StakedTokens = 'stakedTokens',
}

// Errors

export const BoardsManagerError = {
    NoUser: new Error('No User to get the boards for'),
    NotAuthorizedToManage: new Error('User is not authorized to manage boards'),
};

// GraphQL

export const boardsCollection = 'boards';

const custodianWalletFields = 'sourceId exchange';
const nonCustodianWalletFields = 'address blockchain';
const priceFields = 'min max currency';
const filtersFields = `
    tokens
    blockchains
    exchanges
    wallets {
        __typename
        ... on CustodianWallet {
            ${custodianWalletFields}
        }
        ... on NonCustodianWallet {
            ${nonCustodianWalletFields}
        }
    }
    prices {
        ${priceFields}
    } 
    tokenTypes
    contractTypes
`;
const templateFields = 'kind';
const boardsFields = `name description filters { ${filtersFields} } selectedAssets notSelectedAssets created updated sortingIndex userId boardId template { ${templateFields} } `;

export const boardsQuery = gql`
    query GetBoard($filter: BoardFilter!) {
        ${boardsCollection}(filter: $filter) { ${boardsFields} }
    }
`;

export const boardSubscription = gql`
    subscription ($boardId: String!) {
        ${boardsCollection}(boardId: $boardId) { ${boardsFields} }
    }
`;
