import source from '../assets/symbolTokens.json';

import type { Symbols } from './Symbols';

import type { Tokens } from './Tokens';

type SymbolTokens = {
    /**
     * Key as token Symbol
     */
    [item in Symbols]: Tokens[];
};

export const symbolTokens = source as SymbolTokens;
