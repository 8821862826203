import { Log } from '@smartfolly/common.utilities';

import type {
    ServiceWhalesRequestParameters,
    ServiceWhalesResponse,
    UsersEditAddressRequestParameters,
    UsersEditAddressRequestResponse,
    UsersEditExchangeRequestParameters,
    UsersEditExchangeRequestResponse,
} from '@smartfolly/server';

import type { IUserAuth } from '@smartfolly/middleware.user-auth';

import { UserManagerError } from '../constants';

import type {
    IUserManager,
    ProvidedAddressInfo,
    ProvidedAddresses,
    ProvidedExchangeSourceInfo,
    ProvidedExchanges,
    UserAddressesListener,
    UserAddressesListenerDisposer,
    UserInfo,
    UserInfoListener,
    UserInfoListenerDisposer,
    UserExchangesListener,
    UserExchangesListenerDisposer,
} from '../types';

import { ServicesRequestPath, UsersRequestPath } from './constants';

import { queryProvidedAddresses, queryProvidedExchanges, queryUserInfo } from './queries';

import { subscribeToAddresses, subscribeToExchanges, subscribeToUserInfo } from './subscriptions';

const log = new Log('UserManager');

export type UserManagerOptions =
    | {
          /**
           * An instance of the UserAuth to work with when dealing with user data in "Write" mode.
           * Note: the passed module MUST be configured, i.e. be ready to work with.
           */
          userAuth: IUserAuth;
      }
    | {
          /**
           * An ID of the user to work with when dealing with the user data in "Read" mode.
           */
          userId: string;
      };

export class UserManager implements IUserManager {
    // Properties

    /**
     * Options the UserManager module is created with.
     */
    private options: UserManagerOptions;

    // Constructor

    public constructor(options: UserManagerOptions) {
        this.options = options;
    }

    // Interface

    public async getUserInfo(): Promise<UserInfo> {
        const { userId } = this;
        if (!userId) {
            throw UserManagerError.NoUser;
        }

        return queryUserInfo(userId);
    }

    public subscribeToUserInfo(listener: UserInfoListener): UserInfoListenerDisposer {
        const { userId } = this;
        if (!userId) {
            throw UserManagerError.NoUser;
        }

        return subscribeToUserInfo(userId, listener);
    }

    // eslint-disable-next-line class-methods-use-this
    public async createWhalesSubscription(): Promise<boolean> {
        // Check if the module can manage subscriptions
        if (!this.userAuth) {
            throw UserManagerError.NotAuthorizedToManage;
        }

        // Make a request to activate the subscription
        const response = await this.userAuth.sendRequest<
            ServiceWhalesRequestParameters,
            ServiceWhalesResponse
        >({
            path: ServicesRequestPath.Whales,
            params: {
                action: 'activate',
            },
        });

        // Check for errors in response
        if ('error' in response) {
            const { error } = response;
            // TODO: process `errorCode` if needed
            log.error('Failed to create a whales subscription:', response);
            throw new Error(error);
        }

        // Return a result of the operation as a truly subscription status
        return response.status; // i.e. response.status === true;
    }

    // eslint-disable-next-line class-methods-use-this
    public async removeWhalesSubscription(): Promise<boolean> {
        // Check if the module can manage subscriptions
        if (!this.userAuth) {
            throw UserManagerError.NotAuthorizedToManage;
        }

        // Make a request to deactivate the subscription
        const response = await this.userAuth.sendRequest<
            ServiceWhalesRequestParameters,
            ServiceWhalesResponse
        >({
            path: ServicesRequestPath.Whales,
            params: {
                action: 'deactivate',
            },
        });

        // Check for errors in response
        if ('error' in response) {
            const { error } = response;
            // TODO: process `errorCode` if needed
            log.error('Failed to remove a whales subscription:', response);
            throw new Error(error);
        }

        // Return a result of the operation as a falsy subscription status
        return !response.status; // i.e. response.status === false;
    }

    public async getProvidedAddresses(): Promise<ProvidedAddresses> {
        const { userId } = this;
        if (!userId) {
            throw UserManagerError.NoUser;
        }

        return queryProvidedAddresses(userId);
    }

    public async editAddressInfo(address: string, info: ProvidedAddressInfo): Promise<boolean> {
        // Check if the module can manage addresses
        if (!this.userAuth) {
            throw UserManagerError.NotAuthorizedToManage;
        }

        // Make a request to edit an address
        const response = await this.userAuth.sendRequest<
            UsersEditAddressRequestParameters,
            UsersEditAddressRequestResponse
        >({
            path: UsersRequestPath.EditAddress,
            params: {
                address,
                info,
            },
        });

        // Check for errors in response
        if ('error' in response) {
            const { error } = response;
            // TODO: process `errorCode` if needed
            log.error('Failed to edit the address:', address, response);
            throw new Error(error);
        }

        return response.edited;
    }

    public subscribeToAddresses(listener: UserAddressesListener): UserAddressesListenerDisposer {
        const { userId } = this;
        if (!userId) {
            throw UserManagerError.NoUser;
        }

        return subscribeToAddresses(userId, listener);
    }

    public async getProvidedExchanges(): Promise<ProvidedExchanges> {
        const { userId } = this;
        if (!userId) {
            throw UserManagerError.NoUser;
        }

        return queryProvidedExchanges(userId);
    }

    public async editExchangeInfo(
        sourceId: string,
        info: ProvidedExchangeSourceInfo,
    ): Promise<boolean> {
        // Check if the module can manage exchanges
        if (!this.userAuth) {
            throw UserManagerError.NotAuthorizedToManage;
        }

        // Make a request to edit an exchange
        const response = await this.userAuth.sendRequest<
            UsersEditExchangeRequestParameters,
            UsersEditExchangeRequestResponse
        >({
            path: UsersRequestPath.EditExchange,
            params: {
                sourceId,
                info,
            },
        });

        // Check for errors in response
        if ('error' in response) {
            const { error } = response;
            // TODO: process `errorCode` if needed
            log.error('Failed to edit the exchange:', sourceId, response);
            throw new Error(error);
        }

        return response.edited;
    }

    public subscribeToExchanges(listener: UserExchangesListener): UserExchangesListenerDisposer {
        const { userId } = this;
        if (!userId) {
            throw UserManagerError.NoUser;
        }

        return subscribeToExchanges(userId, listener);
    }

    // Internals

    /**
     * A getter for {@link IUserAuth} module from SDK to deal with assets in "Write" mode.
     */
    private get userAuth(): IUserAuth | undefined {
        return 'userAuth' in this.options ? this.options.userAuth : undefined;
    }

    /**
     * A getter for an ID of the user whose assets are loaded and processed by the module instance.
     */
    private get userId(): string | undefined {
        // Check if the userId was passed independently or indirectly with the UserAuth instance
        return 'userId' in this.options
            ? this.options.userId
            : this.options.userAuth.sessionInfo?.userId;
    }
}
