import type { ExchangeCurrency } from '@smartfolly/sdk';

/**
 * A default exchange currency value.
 */
export const DEFAULT_EXCHANGE_CURRENCY: ExchangeCurrency = 'USD';

/**
 * The key used to store the selected exchange currency value
 */
export const EXCHANGE_CURRENCY_KEY = 'AssetsService:ExchangeCurrency';
