import { Blockchains, blockchainsData } from '@smartfolly/frontend.currencies-service';

import type { Blockchain } from '../types';

type BuildOptions = {
    /**
     * Blockchain ID to build the asset with.
     */
    blockchain: Blockchains;
};

/**
 * Function to build the asset blockchain with.
 * @param options - options to build the asset blockchain with.
 */
export function buildAssetBlockchain({ blockchain }: BuildOptions): Blockchain {
    const blockchainInfo = blockchainsData[blockchain];
    if (!blockchainInfo) {
        throw new Error(`No blockchain data is found for the asset blockchain: ${blockchain}`);
    }

    return {
        id: blockchain,
        name: blockchainInfo.name,
        nativeToken: blockchainInfo.nativeToken,
    };
}
