/* eslint-disable no-bitwise, no-plusplus */

// Source: Harmony One SDK
// https://github.com/harmony-one/sdk/blob/master/packages/harmony-crypto/src/bech32.ts

/**
 * convertBits
 *
 * groups buffers of a certain width to buffers of the desired width.
 *
 * For example, converts byte buffers to buffers of maximum 5 bit numbers,
 * padding those numbers as necessary. Necessary for encoding Ethereum-style
 * addresses as bech32 ones.
 *
 * @param data - ?
 * @param fromWidth - ?
 * @param toWidth - ?
 * @param pad - ?
 * @returns Buffer | null
 */
export function convertBits(data: Buffer, fromWidth: number, toWidth: number, pad: boolean = true) {
    let acc = 0;
    let bits = 0;
    const ret = [];
    const maxv = (1 << toWidth) - 1;
    // tslint:disable-next-line
    for (let p = 0; p < data.length; ++p) {
        const value = data[p]!;
        if (value < 0 || value >> fromWidth !== 0) {
            return null;
        }
        acc = (acc << fromWidth) | value;
        bits += fromWidth;
        while (bits >= toWidth) {
            bits -= toWidth;
            ret.push((acc >> bits) & maxv);
        }
    }

    if (pad) {
        if (bits > 0) {
            ret.push((acc << (toWidth - bits)) & maxv);
        }
    } else if (bits >= fromWidth || (acc << (toWidth - bits)) & maxv) {
        return null;
    }

    return Buffer.from(ret);
}
