import { Blockchains } from './Blockchains';
import { Tokens } from './Tokens';

export type BlockchainData = {
    /**
     * Name of the blockchain (e.g. Bitcoin)
     */
    name: string;

    /**
     * Identifier of the native blockchain token.
     * Note: equals to CoinMarketCap unique `slug` identifier.
     */
    nativeToken: Tokens;

    /**
     * Name used by Tatum.io API (e.g. bitcoin)
     */
    tatumName: string;
};

type BlockchainsData = {
    /**
     * Blockchain keys from Blockchains (ex. BTC, ETH)
     */
    [key in Blockchains]: BlockchainData;
};

export const blockchainsData: BlockchainsData = {
    [Blockchains.ADA]: {
        name: 'Cardano',
        nativeToken: Tokens.cardano,
        tatumName: 'ada',
    },
    [Blockchains.ALGO]: {
        name: 'Algorand',
        nativeToken: Tokens.algorand,
        tatumName: 'algorand',
    },
    [Blockchains.ARB]: {
        name: 'Arbitrum',
        nativeToken: Tokens.ethereum, // Native balance in ETH (https://arbiscan.io/address/0x745a0fabc4f69bb08f969d991e0f9dea208ae440)
        tatumName: 'arbitrum',
    },
    [Blockchains.BTC]: {
        name: 'Bitcoin',
        nativeToken: Tokens.bitcoin,
        tatumName: 'bitcoin',
    },
    [Blockchains.BCH]: {
        name: 'Bitcoin Cash',
        nativeToken: Tokens['bitcoin-cash'],
        tatumName: 'bcash',
    },
    [Blockchains.BSC]: {
        name: 'BNB Smart Chain',
        nativeToken: Tokens.bnb,
        tatumName: 'bsc',
    },
    [Blockchains.BNB]: {
        name: 'BNB Beacon Chain',
        nativeToken: Tokens.bnb,
        tatumName: 'bnb',
    },
    [Blockchains.CELO]: {
        name: 'Celo',
        nativeToken: Tokens.celo,
        tatumName: 'celo',
    },
    [Blockchains.DOGE]: {
        name: 'Dogecoin',
        nativeToken: Tokens.dogecoin,
        tatumName: 'dogecoin',
    },
    [Blockchains.EGLD]: {
        name: 'MultiversX',
        nativeToken: Tokens['multiversx-egld'],
        tatumName: 'egld',
    },
    [Blockchains.ETH]: {
        name: 'Ethereum',
        nativeToken: Tokens.ethereum,
        tatumName: 'ethereum',
    },
    [Blockchains.EVER]: {
        name: 'Everscale',
        nativeToken: Tokens.everscale,
        tatumName: '',
    },
    [Blockchains.FLOW]: {
        name: 'Flow',
        nativeToken: Tokens.flow,
        tatumName: 'flow',
    },
    [Blockchains.ONE]: {
        name: 'Harmony',
        nativeToken: Tokens.harmony,
        tatumName: 'one',
    },
    [Blockchains.OP]: {
        name: 'Optimism',
        nativeToken: Tokens.ethereum, // Native balance in ETH (https://optimistic.etherscan.io/address/0x0D0707963952f2fBA59dD06f2b425ace40b492Fe)
        tatumName: 'optimism-mainnet',
    },
    [Blockchains.KAS]: {
        name: 'Kaspa',
        nativeToken: Tokens.kaspa,
        tatumName: 'kaspa',
    },
    [Blockchains.KLAY]: {
        name: 'Klaytn',
        nativeToken: Tokens.klaytn,
        tatumName: 'klaytn',
    },
    [Blockchains.KCS]: {
        name: 'KuCoin',
        nativeToken: Tokens['kucoin-token'],
        tatumName: 'kcs',
    },
    [Blockchains.LTC]: {
        name: 'Litecoin',
        nativeToken: Tokens.litecoin,
        tatumName: 'litecoin',
    },
    [Blockchains.MATIC]: {
        name: 'Polygon',
        nativeToken: Tokens.polygon,
        tatumName: 'polygon',
    },
    [Blockchains.SOL]: {
        name: 'Solana',
        nativeToken: Tokens.solana,
        tatumName: 'solana',
    },
    [Blockchains.XLM]: {
        name: 'Stellar',
        nativeToken: Tokens.stellar,
        tatumName: 'xlm',
    },
    [Blockchains.TON]: {
        name: 'Toncoin',
        nativeToken: Tokens.toncoin,
        tatumName: '',
    },
    [Blockchains.TRX]: {
        name: 'Tron',
        nativeToken: Tokens.tron,
        tatumName: 'tron',
    },
    [Blockchains.VENOM]: {
        name: 'Venom',
        nativeToken: Tokens.venom,
        tatumName: '',
    },
    [Blockchains.VET]: {
        name: 'VeChain',
        nativeToken: Tokens.vechain,
        tatumName: 'vet',
    },
    [Blockchains.XDC]: {
        name: 'XinFin',
        nativeToken: Tokens['xdc-network'],
        tatumName: 'xdc',
    },
    [Blockchains.XRP]: {
        name: 'XRP',
        nativeToken: Tokens.xrp,
        tatumName: 'xrp',
    },
};
