/**
 * Blockchain config
 */

const {
    REACT_APP_BLOCKCHAIN,
    REACT_APP_BLOCKCHAIN_TOKEN,
    REACT_APP_BLOCKCHAIN_NAME,
    REACT_APP_BLOCKCHAIN_TOKEN_NAME,
    REACT_APP_BLOCKCHAIN_URL,
} = process.env;

export const config = {
    blockchain: REACT_APP_BLOCKCHAIN,
    token: REACT_APP_BLOCKCHAIN_TOKEN,
    name: REACT_APP_BLOCKCHAIN_NAME,
    tokenName: REACT_APP_BLOCKCHAIN_TOKEN_NAME,
    url: REACT_APP_BLOCKCHAIN_URL,
};
