import { gql } from '@apollo/client';

// Internal constants

// Enums

export enum AuthUserRequestPath {
    Prepare = 'auth/prepare',
    Login = 'auth/login',
    Logout = 'auth/logout',
    SimpleLogin = 'auth/simpleLogin',
    AddProvider = 'auth/addProvider',
    Validate = 'auth/validate',
    // Use "shorten" service to store some temporary auth data
    Shorten = 'shorten',
}

// GraphQL

export const authCollection = 'auth';

const authFields = `
    nonce
    provider
    telegramUserId
`; // Add `created`, `ethAddress` if needed

export const authSubscription = gql`
    subscription ($authId: String!) {
        ${authCollection}(authId: $authId) { ${authFields} }
    }
`;
