import { computed, makeObservable, observable, runInAction } from 'mobx';

import { Log } from '@smartfolly/common.utilities';

import { Assets, IAssetsManager, IAssetScanner, AssetScanner } from '@smartfolly/sdk';

const log = new Log('AssetsService:Assets');

export class AssetsActualizer {
    // Properties

    /**
     * An instance of the AssetScanner to work with when scanning the assets.
     */
    private assetScanner: IAssetScanner;

    /**
     * A private observable map of all assets provided by {@link assetsManager}.
     */
    private allAssetsMap: Assets = {};

    /**
     * A private instance of the subscriptions on all assets updates with the disposers.
     */
    private allAssetsSubscriptions: { [assetId: string]: { disposer: () => void } } = {};

    // Constructor

    public constructor(assetsManager: IAssetsManager) {
        this.assetScanner = new AssetScanner({ assetsManager });

        makeObservable<AssetsActualizer, 'allAssetsMap'>(this, {
            allAssets: computed,
            allAssetsMap: observable,
        });
    }

    // Getters & Setters

    /**
     * A computed map of all assets.
     */
    public get allAssets(): Assets {
        return this.allAssetsMap;
    }

    /**
     * Set the map of all assets.
     */
    public set allAssets(allAssets: Assets) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter

        // Note: add a guard due to the fact the backend might return assets with "NaN" balances
        this.allAssetsMap = Object.keys(allAssets).reduce<Assets>((acc, key) => {
            const asset = allAssets[key]!;
            if (asset.balance === 'NaN') {
                return acc;
            }

            acc[key] = asset;
            return acc;
        }, {});

        // Update subscriptions on all assets updates.
        this.updateSubscriptionsOnAssets();
    }

    // Internals

    /**
     * Update the subscriptions (i.e. create or dispose) on all assets updates.
     */
    private updateSubscriptionsOnAssets(): void {
        // Ensure to create the subscriptions on all available assets first
        Object.keys(this.allAssetsMap).forEach(assetId => {
            // Check if the subscription on the asset updates exists
            if (this.allAssetsSubscriptions[assetId]) {
                // Do nothing
                return;
            }

            // Create a subscription on the asset updates
            const disposer = this.assetScanner.subscribe(assetId, (error, updated) => {
                if (error || updated === undefined) {
                    // Note: missing the updated not-nullish data isn't a proper behaviour as well
                    log.error(`Failed to scan the data of asset "${assetId}" with error:`, error);
                    return;
                }

                if (updated === null) {
                    // An asset has been removed if it's `null`
                    // Remove the asset from the map in action
                    runInAction(() => {
                        delete this.allAssetsMap[assetId];
                    });
                } else {
                    // Update the asset in the map in action
                    runInAction(() => {
                        this.allAssetsMap[assetId] = updated;
                    });
                }
            });

            // Save a disposer in the corresponding map
            this.allAssetsSubscriptions[assetId] = { disposer };
        });

        // Then dispose all the outdated subscriptions if any
        Object.keys(this.allAssetsSubscriptions).forEach(assetId => {
            // Check if the asset still present
            if (this.allAssetsMap[assetId]) {
                // Do nothing
                return;
            }

            // Dispose an outdated subscription for missing asset
            const { disposer } = this.allAssetsSubscriptions[assetId]!;
            disposer();

            // Delete the subscription
            delete this.allAssetsSubscriptions[assetId];
        });
    }
}
