import { Account, TonConnectUI } from '@tonconnect/ui';

import { ProvidersError, tonMainnetChain } from '../../constants';

import type {
    GetAccountsResponse,
    IProvider,
    ProviderInfo,
    SignMessageOptions,
    SignMessageResponse,
} from '../../types';

export class TonConnectProvider implements IProvider<'TonConnect'> {
    // Properties

    /**
     * A private instance of TonConnect client.
     */
    private tonConnectClient?: TonConnectUI;

    // Interface

    public async checkIfAvailable(): Promise<boolean> {
        try {
            const connector = await this.getConnector();
            return !!connector;
        } catch (error) {
            if (error === ProvidersError.TonConnect.NotReadyToInitialize) {
                return false;
            }

            throw error;
        }
    }

    public async disconnect(): Promise<void> {
        // Get the connector
        const connector = await this.getConnector();

        // Check if connected
        if (connector.connected) {
            // Disconnect if connected
            await connector.disconnect();
        } else {
            // Do nothing if no
        }
    }

    public async getAccounts(): Promise<GetAccountsResponse> {
        // Get the connector
        const connector = await this.getConnector();

        // Prepare the connection status processing function
        async function processConnectedAccount(
            account: Account | null,
        ): Promise<GetAccountsResponse> {
            // Check if any account is found
            if (!account) {
                // Throw if not
                throw ProvidersError.TonConnect.NoAccountFound;
            }

            // Return the found accounts
            return [
                {
                    address: account.address,
                    // Set the chain as Toncoin Mainnet taken from the WalletConnect related constants
                    // since this chain ID is general and can be used anywhere as CAIP-2 standard value
                    chainId: tonMainnetChain.id,
                },
            ];
        }

        // Check if we are already connected
        if (connector.connected) {
            // Get the account of the latest session
            const { account } = connector;
            // Process the connected account
            return processConnectedAccount(account);
        }

        // Try to get TonConnect wallet accounts
        return new Promise((resolve, reject) => {
            // Create an observer to listen to the TonConnect Modal
            const observer = new MutationObserver(mutationsList => {
                mutationsList.forEach(mutation => {
                    if (mutation.removedNodes.length > 0) {
                        // Modal is closed

                        // Disconnect the observer
                        observer.disconnect();

                        // Throw an error that the modal was closed
                        reject(ProvidersError.TonConnect.QRCodeClosed);
                    }
                });
            });

            // Set zIndex to tc-root element and listen to its child to know when a modal is closed
            const element = document.getElementById('tc-widget-root');
            if (element) {
                // Get its root
                const tcRoot = element.children[0] as HTMLElement;
                if (tcRoot) {
                    // Overlap everything by setting the max safe z-index (works in Safari 3)
                    tcRoot.style.pointerEvents = 'auto';
                    tcRoot.style.position = 'absolute';
                    tcRoot.style.zIndex = '16777271';

                    // Listen to the TonConnect Modal
                    observer.observe(tcRoot, { childList: true, subtree: false });
                }
            }

            // Connect to the wallet
            (async () => {
                try {
                    // Connect to TonConnect client
                    const { account } = await connector.connectWallet();

                    // Process the connected account
                    const accounts = await processConnectedAccount(account);

                    // Resolve the found accounts
                    resolve(accounts);
                } catch (error) {
                    // Reject an error if any
                    reject(error);
                } finally {
                    // Unsubscribe from the TonConnect modal events
                    observer.disconnect();
                }
            })();
        });
    }

    public async getInfo(): Promise<ProviderInfo<'TonConnect'>> {
        // Get the connector
        const connector = await this.getConnector();

        // Build-up the provider info
        const providerInfo: ProviderInfo<'TonConnect'> = { name: 'TonConnect' };

        // Get the peered wallet info
        if (connector.connected && connector.wallet) {
            providerInfo.peer = {
                description: '', // Empty
                icons: [connector.wallet.imageUrl],
                name: connector.wallet.name,
                url: connector.wallet.aboutUrl,
            };
        }

        return providerInfo;
    }

    // eslint-disable-next-line class-methods-use-this
    public async signMessage(_options: SignMessageOptions): Promise<SignMessageResponse> {
        // Throw an error as message signing is not supported for Ton Connect by us
        throw ProvidersError.TonConnect.NoSignatureMade;
    }

    // Internals

    /**
     * A lazy getter of the connector to TonConnect client.
     */
    private async getConnector(): Promise<TonConnectUI> {
        if (this.tonConnectClient) {
            return this.tonConnectClient;
        }

        // Check if can initialize the TonConnect client
        // Note: in order to make it work the web is required to put the manifest in the root, i.e.
        // `${window.location.origin}/tonconnect-manifest.json`, in the following format:
        // {
        //     "url": "<app-url>",                        // required
        //     "name": "<app-name>",                      // required
        //     "iconUrl": "<app-icon-url>",               // required
        //     "termsOfUseUrl": "<terms-of-use-url>",     // optional
        //     "privacyPolicyUrl": "<privacy-policy-url>" // optional
        // }
        const host = window.location.origin; // 'http://192.168.0.181:3000'; // to test locally
        const manifestUrl = `${host}/tonconnect-manifest.json`;
        const response = await fetch(manifestUrl);
        if (response.status !== 200) {
            throw ProvidersError.TonConnect.NotReadyToInitialize;
        }

        // Initialize the client
        this.tonConnectClient = new TonConnectUI({ manifestUrl });
        return this.tonConnectClient;
    }
}
