import source from '../assets/tokensData.json';

import type { Symbols } from './Symbols';
import type { Tokens } from './Tokens';

export type TokenData = {
    /**
     * Token id by Market Cap
     */
    marketCapId: number;
    /**
     * Token symbol (e.g. BTC)
     */
    symbol: Symbols;
    /**
     * Token name for visual identity
     */
    name: string;
    /**
     * URL for token icon.
     * Note: It's a link to a CoinMarketCap hosted logo png for this token. 64px is a default size.
     * Replace "64x64" in the image path with these alternative sizes: 16, 32, 64, 128, 200.
     */
    icon: string;
};

export const tokensData = source as { [token in Tokens]: TokenData };
