import { Log } from '@smartfolly/common.utilities';

import type { Blockchains } from '@smartfolly/frontend.currencies-service';

import type { Asset, BlockchainAsset, BlockchainAssetWithContract } from '../types';

const log = new Log('getExplorerLinkToAsset');

type LinkGenerator<A extends BlockchainAsset> = (asset: A) => string | undefined;

/**
 * Function to process the explorer link for the given asset and links generators.
 * @param asset - an asset to get the explorer link for.
 * @param generators - a map of generators to generate the links.
 * @returns an explorer link for the asset if found.
 */
function getExplorerLink(
    asset: BlockchainAsset,
    {
        genNativeLink,
        genFTLink,
        genStakingPoolLink,
    }: {
        genNativeLink: LinkGenerator<BlockchainAsset>;
        genFTLink?: LinkGenerator<BlockchainAssetWithContract>;
        genStakingPoolLink?: LinkGenerator<BlockchainAssetWithContract>;
    },
) {
    if ('contract' in asset) {
        const { contractType } = asset;

        if (contractType === 'FT' && genFTLink) {
            return genFTLink(asset);
        }

        if (contractType === 'StakingPool' && genStakingPoolLink) {
            return genStakingPoolLink(asset);
        }

        // Log an error and fallback to the default address link
        log.error(
            `Link for "${contractType}" contract isn't supported for ${asset.wallet.blockchain.id}`,
        );
    }

    return genNativeLink(asset);
}

export const explorerLinks: Record<Blockchains, (asset: BlockchainAsset) => string | undefined> = {
    ADA: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://explorer.cardano.org/en/address?address=${address}`,
            // Note: FT is supported, but there is no specific page for FT transactions
            genFTLink: ({ wallet: { address } }) =>
                `https://explorer.cardano.org/en/address?address=${address}`,
        }),

    ALGO: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://algoexplorer.io/address/${address}`,
            // Note: FT is supported, but there is no specific page for FT transactions
            genFTLink: ({ wallet: { address } }) => `https://algoexplorer.io/address/${address}`,
        }),

    ARB: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) => `https://arbiscan.io/address/${address}`,
        }),

    BNB: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://explorer.bnbchain.org/address/${address}`,
            // Note: FT is supported, but there is no specific page for FT transactions
            genFTLink: ({ wallet: { address } }) =>
                `https://explorer.bnbchain.org/address/${address}`,
        }),

    BCH: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://www.blockchain.com/bch/address/${address}`,
        }),

    BSC: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) => `https://bscscan.com/address/${address}`,
            genFTLink: ({ wallet: { address }, contract }) =>
                `https://bscscan.com/token/${contract}?a=${address}`,
        }),

    BTC: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://www.blockchain.com/btc/address/${address}`,
        }),

    CELO: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) => `https://celoscan.io/address/${address}`,
            genFTLink: ({ wallet: { address }, contract }) =>
                `https://celoscan.io/token/${contract}?a=${address}`,
        }),

    DOGE: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) => `https://dogechain.info/address/${address}`,
        }),

    EGLD: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://explorer.multiversx.com/accounts/${address}`,
            genFTLink: ({ wallet: { address }, contract }) =>
                `https://explorer.multiversx.com/accounts/${address}?token=${contract}`,
        }),

    ETH: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) => `https://etherscan.io/address/${address}`,
            genFTLink: ({ wallet: { address }, contract }) =>
                `https://etherscan.io/token/${contract}?a=${address}`,
        }),

    EVER: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) => `https://everscan.io/accounts/${address}`,
            genFTLink: ({ wallet: { address }, contract }) =>
                `https://everscan.io/accounts/${address}/token-transactions?tokens-tx-tokens=${contract}`,
            // Note: the staking pool link includes only the messages to the staking pool
            genStakingPoolLink: ({ wallet: { address }, contract }) =>
                `https://everscan.io/accounts/${address}?msg-dst=${contract}&msg-dst-filter=include`,
        }),

    FLOW: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) => `https://flowscan.org/account/${address}`,
        }),

    KAS: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://explorer.kaspa.org/addresses/${address}`,
        }),

    KCS: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://explorer.kcc.io/en/address/${address}`,
            // Note: FT is supported, but there is no specific page for FT transactions
            genFTLink: ({ wallet: { address } }) => `https://explorer.kcc.io/en/address/${address}`,
        }),

    KLAY: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://scope.klaytn.com/account/${address}?tabId=txList`,
            // Note: the FT link looks weird due to token "contract" duplication...
            genFTLink: ({ wallet: { address }, contract }) =>
                `https://scope.klaytn.com/token/${contract}?tabId=tokenTransfer&account=${address}&token=${contract}`,
        }),

    LTC: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://blockchair.com/litecoin/address/${address}`,
        }),

    MATIC: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://polygonscan.com/address/${address}`,
            genFTLink: ({ wallet: { address }, contract }) =>
                `https://polygonscan.com/token/${contract}?a=${address}`,
        }),

    ONE: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://explorer.harmony.one/address/${address}`,
            // Note: FT is supported, but there is no specific page for FT transactions
            // Note2: HRC20 Transfers tab also does not work there!!!
            genFTLink: ({ wallet: { address } }) =>
                `https://explorer.harmony.one/address/${address}`,
        }),

    OP: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://optimistic.etherscan.io/address/${address}`,
        }),

    SOL: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://explorer.solana.com/address/${address}`,
            genFTLink: ({ wallet: { address }, contract }) =>
                `https://explorer.solana.com/address/${address}/tokens?filter=${contract}`,
        }),

    TON: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) => `https://tonscan.org/address/${address}`,
            // Note: FT is supported, but there is no specific page for FT transactions
            genFTLink: ({ wallet: { address } }) => `https://tonscan.org/address/${address}`,
            // Note: Staking is supported, but there is no specific page for Staking transactions
            genStakingPoolLink: ({ wallet: { address } }) =>
                `https://tonscan.org/address/${address}`,
        }),

    TRX: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) => `https://tronscan.org/#/address/${address}`,
            // Note: FT is supported, but there is no specific page for FT transactions
            // Note2: Filtering transfers by the token works but does not provide a link!!!
            genFTLink: ({ wallet: { address } }) =>
                `https://tronscan.org/#/address/${address}/transfers`,
        }),

    VENOM: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://testnet.venomscan.com/accounts/${address}`,
        }),

    VET: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://explore.vechain.org/accounts/${address}/`,
        }),

    XLM: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://stellarchain.io/accounts/${address}#`,
        }),

    XDC: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) =>
                `https://explorer.xinfin.network/address/${address}#transactions`,
            genFTLink: ({ wallet: { address }, contract }) =>
                `https://explorer.xinfin.network/tokens/${contract}/xrc20/${address}#token-transfer`,
        }),

    XRP: (asset: BlockchainAsset) =>
        getExplorerLink(asset, {
            genNativeLink: ({ wallet: { address } }) => `https://xrpscan.com/account/${address}`,
        }),

    // The following blockchains are not supported, but we keep them to save the explorer links >>>

    // NEO: (asset: BlockchainAsset) =>
    //     getExplorerLink(asset, {
    //         genNativeLink: ({ wallet: { address } }) => `https://neoscan.io/address/${address}`,
    //     }),

    // QTUM: (asset: BlockchainAsset) =>
    //     getExplorerLink(asset, {
    //         genNativeLink: ({ wallet: { address } }) => `https://qtum.info/address/${address}`,
    //     }),
};

/**
 * Get an explorer link to an asset to see the details.
 * @param asset - an asset to get the explorer link to.
 * @returns an explorer link for the asset if found.
 */
export function getExplorerLinkToAsset(asset: Asset): string | undefined {
    // Get the asset wallet
    const { wallet } = asset;

    // Check if the asset has a blockchain data
    if (!('blockchain' in wallet)) {
        return undefined;
    }

    // Find an explorer link getter for the asset blockchain
    const explorerLink = explorerLinks[wallet.blockchain.id];

    // Check if the explorer link getter is found
    if (!explorerLink) {
        log.error('Failed to find an explorer link to the asset:', asset);
        return undefined;
    }

    // Get and return the explorer link for the asset
    return explorerLink(asset as BlockchainAsset);
}
