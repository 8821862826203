import { ApolloClient, InMemoryCache } from '@apollo/client';
import type { NormalizedCacheObject } from '@apollo/client';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import WebSocket from 'isomorphic-ws';

import { Async } from '@smartfolly/common.utilities';

import type { ResponseError, GraphQLErrorCodesKeys } from '@smartfolly/server';

import { GraphQLErrorCodes, ServerConnectorError } from '../constants';

import type {
    DataListener,
    DataListenerDisposer,
    GQLQueryOptions,
    IServerConnector,
    ParametersShape,
    SendRequestOptions,
    ServerConnectorConfig,
    SimpleSendRequestOptions,
} from '../types';

import { sendGETRequest, sendPOSTRequest } from '../utils';

class ServerConnector implements IServerConnector {
    // Properties

    /**
     * A private instance of the module config.
     */
    private config?: ServerConnectorConfig;

    /**
     * Apollo client instance for queries and subscriptions to our Apollo server.
     */
    private apolloClient: ApolloClient<NormalizedCacheObject> | undefined = undefined;

    // Interface

    public configure(config: ServerConnectorConfig): void {
        // Save provided config
        this.config = config;

        // https://www.apollographql.com/docs/react/data/subscriptions/
        // We use WebSocket protocol for GraphQL
        const wsLink = new GraphQLWsLink(
            createClient({
                // https://community.apollographql.com/t/cannot-get-graphqlwslink-to-work-subscriptions-cant-resolve-fs/3444
                webSocketImpl: WebSocket,
                // Connect to the server GraphQL endpoint
                url: `${config.subscriptionProtocol}${config.host}/graphql`,
                // Ping the server every 10 seconds
                keepAlive: 10000,
                // Retry the connection on any failure or closure
                // Note: this one is especially important for iOS & MacOS
                // due to "kNWErrorDomainPOSIX error 53 - Software caused connection abort"
                // when switching between the browser and other apps.
                // Seem to be connected to the following issue:
                // https://developer.apple.com/forums/thread/106838
                shouldRetry: () => true,
                retryAttempts: 10,
                retryWait: async retries => {
                    // start wait from 1 sec, then 6, 11, 16, etc
                    const wait = retries * 5000 + 1000;
                    await Async.timeout(wait);
                },
            }),
        );

        // Create apollo client
        this.apolloClient = new ApolloClient({
            link: wsLink,
            cache: new InMemoryCache(),
            // https://www.apollographql.com/docs/react/caching/overview
            // Apollo Client stores the results of your GraphQL queries in a local,
            // normalized, in-memory cache. This enables Apollo Client to respond
            // almost immediately to queries for already-cached data,
            // without even sending a network request.
            //
            // So we could get non-actual data
            // Disable client cache till we find better solution
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'ignore',
                },
                query: {
                    fetchPolicy: 'no-cache', // disable client cache
                    errorPolicy: 'all',
                },
            },
        });
    }

    public async sendGETRequest<Response>({
        path,
    }: SimpleSendRequestOptions): Promise<Response | ResponseError> {
        // Check for config presence
        const { host: configuredHost, queryProtocol } = this.checkForConfigPresence();

        // Send GET-request with the given path
        const response = await sendGETRequest<Response>({
            path,
            host: `${queryProtocol}${configuredHost}`,
        });

        // Return a response if no "breaking" error
        return response;
    }

    public async sendPOSTRequest<Parameters extends ParametersShape, Response>({
        path,
        params,
    }: SendRequestOptions<Parameters>): Promise<Response | ResponseError> {
        // Check for config presence
        const { host: configuredHost, queryProtocol } = this.checkForConfigPresence();

        // Send POST-request with the resulted parameters
        const response = await sendPOSTRequest<Parameters, Response>({
            path,
            host: `${queryProtocol}${configuredHost}`,
            params,
        });

        // Return a response if no "breaking" error
        return response;
    }

    public async query<Parameters extends ParametersShape, Response>({
        collection,
        query,
        variables,
    }: GQLQueryOptions<Parameters>): Promise<Response | ResponseError> {
        // Check for Apollo client presence
        if (!this.apolloClient) {
            // Apollo client is not configured
            throw ServerConnectorError.NoConfig;
        }

        // Execute the query
        const { data, errors } = await this.apolloClient.query<
            { [collectionName: string]: Response },
            Parameters
        >({
            query,
            variables,
        });

        // Check for errors
        if (errors && errors.length > 0) {
            // We don't expect to have more than one error in a request
            const error = errors[0]!;
            return {
                error: error.message,
                errorCode:
                    GraphQLErrorCodes[error.extensions?.code as GraphQLErrorCodesKeys] ??
                    GraphQLErrorCodes.UNKNOWN_ERROR,
            };
        }

        // Resolve the queries data
        const response = data[collection];
        if (!response) {
            return {
                error: 'No response found for the given collection',
                errorCode: GraphQLErrorCodes.UNKNOWN_ERROR,
            };
        }

        return response;
    }

    public subscribe<Parameters extends ParametersShape, Response>(
        { collection, query, variables }: GQLQueryOptions<Parameters>,
        listener: DataListener<Response>,
    ): DataListenerDisposer {
        // Check for Apollo client presence
        if (!this.apolloClient) {
            // Apollo client is not configured
            throw ServerConnectorError.NoConfig;
        }

        // Start a subscription
        const subscription = this.apolloClient
            .subscribe<{ [collection: string]: Response }, Parameters>({ query, variables })
            .subscribe(
                ({ data }) => {
                    listener(undefined, data ? data[collection] : data);
                },
                error => listener(error),
            );

        // Return a subscription disposer
        return () => subscription.unsubscribe();
    }

    // Internals

    /**
     * Method to check for the config presence.
     * @returns the module config
     * @throws an error if the config is not found.
     */
    private checkForConfigPresence(): ServerConnectorConfig {
        if (!this.config) {
            // Module is not configured
            throw ServerConnectorError.NoConfig;
        }

        return this.config;
    }
}

/**
 * A shared singleton to connect to the server with.
 */
export const serverConnector = new ServerConnector();
