import type { SessionTypes } from '@walletconnect/types';

import { ethereumMainnetChain, ProvidersError } from '../constants';

import type { Account, ChainId } from '../types';

/**
 * Function to get the list of accounts from the given session.
 * @param session - a SignClient session instance to find the accounts for.
 * @param chains - a list of chains supported by the given session.
 * @returns a list of found accounts with chain IDs if detected.
 * @throws an error if there is an unrecognized account with unknown chain.
 */
export function findAccountsForSession(session: SessionTypes.Struct, chains: ChainId[]): Account[] {
    // Find the accounts
    return Object.values(session.namespaces).reduce<Account[]>((acc, namespace) => {
        // Remove the chain prefix from the accounts
        return acc.concat(
            namespace.accounts.map(account => {
                // Find the chain the account belongs to
                const accountChain = chains.find(chain => account.startsWith(chain));

                // Throw an error if the account chain is not detected,
                // i.e. the account cannot be recognized
                if (!accountChain) {
                    // Note: should not happen normally
                    throw ProvidersError.WalletConnect.AccountUnrecognized;
                }

                // Get the address for the account by slicing the account chain prefix
                const address = account.slice(`${accountChain}:`.length);

                // Return the account address with the chain ID
                return {
                    // Unlike MetaMaskExt, WalletConnect returns checksummed address for Ethereum
                    // See: https://ethereum.stackexchange.com/questions/2045/is-ethereum-wallet-address-case-sensitive
                    // We don't want it since we use a lowercased `ethAddress` for authorization
                    // TODO: remove once properly processed on the backend side
                    address:
                        accountChain === ethereumMainnetChain.id ? address.toLowerCase() : address,
                    chainId: accountChain,
                };
            }),
        );
    }, []);
}
